const registrationFields = () => {
  return {
    customer: {
      email: '',
      password: '',
      login: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      additional: {
        companyName: '',
        infoSource: '',
        emailSubscribe: '',
      },
    },
    company_first_step: {
      login: '',
      password: '',
      email: '',
      additional: {
        infoSource: '',
      },
    },
    company_second_step: {
      iban: '',
      person_country: "CH",
      companyName: '',
      firstName: '',
      lastName: '',
      personDataDob: '',
      additional: {
        emailSubscribe: false,
        infoSource: '',
      },
    },
    errors_company: {
      login: '',
      password: '',
      email: '',
      iban: '',
      companyName: '',
      firstName: '',
      lastName: '',
      personDataDob: '',
      fromBE: '',
    },
    individual: {
      iban: '',
      firstName: '',
      lastName: '',
      personDataDob: '',
      person_country: 'CH',
    },
  }
};

export default registrationFields
