import React, { Component } from 'react';
import { connect } from 'react-redux'
import visa from '../../../../assets/visa.svg';
import master from '../../../../assets/mastercard.svg';
import arrow_right from '../../../../assets/small_right.svg';
import arrow_left from '../../../../assets/small_left.svg';
import Pagination from '../../../../shared/Pagination/Pagination';
import Button from '../../../../shared/Button';
import moment from 'moment/min/moment-with-locales';
import { payout } from '../../../../redux/actions/transactionActions'

moment.locale('de');

class AdminTransactionsTable extends Component {
  header = {
    date: 'Datum',
    paymentInfo: 'IBAN oder Stripe id',
    projectID: 'ProjektID',
    projectName: 'Projektname',
    type: 'Typ',
    amount: 'Betrag',
    status: 'Status',
    email: 'E-Mail-Adresse',
    login: 'Benutzername',
  };

  addZero = (number, maxLength) => {
    number = number.toString();
    return number.length < maxLength
      ? this.addZero('0' + number, maxLength)
      : number;
  };

  paymentMethod = (brand, last4) => {
    if (brand === 'visa' || brand === 'master') {
      switch (brand) {
        case 'visa':
          return (
            <span className="payment-method">
              <img src={visa} alt="Visa" /> •••• •••• •••• {last4}
            </span>
          );
        default:
          return (
            <span className="payment-method">
              <img src={master} alt="Visa" /> •••• •••• •••• {last4}
            </span>
          );
      }
    } else {
      return;
    }
  };

  checkStatus = (status) => {
    switch (status) {
      case 'PAID':
        return 'complete';
      case 'CANCELED':
      case 'WAIT':
        return 'pending';
      default:
        break;
    }
  };

  handlePageChange = ({ selected }) => {
    this.props.changePage(selected + 1);
  };
  render() {
    const { header, addZero, checkStatus, handlePageChange } = this;
    const { transactions, limit, page, total, totalPages } = this.props;

    return (
      <div className="transactionsWrapper-admin">
        <h1>Transaktionen</h1>
        <div className="table-wrapper">
          <ul>
            <li className="tableHeader">
              <span>{header.date}</span>
              <span>{header.paymentInfo}</span>
              <span>{header.projectID}</span>
              <span>{header.projectName}</span>
              <span>{header.email}</span>
              <span>{header.login}</span>
              <span>{header.type}</span>
              <span>{header.amount}</span>
              <span>{header.status}</span>
              <span></span>
            </li>
            {transactions.map((item, index) => {
              const payment =
                item.group === 'PAYOUT' && item.receiver && item.receiver.additional
                  ? item.receiver.additional.iban
                  : item.receiver.stripeId;
              const isDisabled = checkStatus(item.status) === 'complete';
              return (
                <li key={index} className="table-item">
                  <div>
                    <span className="mobile-view">{header.date}</span>
                    <span>
                      {moment(item.creationDate).format('DD MMM YYYY')}
                    </span>
                  </div>
                  <div>
                    <span className="mobile-view">{header.paymentInfo}</span>
                    {payment}
                  </div>
                  <div>
                    <span className="mobile-view">{header.projectID}</span>
                    <span>#{addZero(item.project._id, 6)}</span>
                  </div>
                  <div>
                    <span className="mobile-view">{header.projectName}</span>
                    <span>{item.project.name}</span>
                  </div>
                  <div>
                    <span className="mobile-view">{header.email}</span>
                    <span>{item.receiver.email}</span>
                  </div>
                  <div>
                    <span className="mobile-view">{header.login}</span>
                    <span>{item.receiver.login}</span>
                  </div>
                  <div>
                    <span className="mobile-view">{header.type}</span>
                    <span>{item.group}</span>
                  </div>
                  <div className="mobile-view amount-block">
                    <span
                      className={`amount ${item.type === 'PAYMENT' ? ' ' : 'profit'
                        }`}>
                      {item.type === 'PAYMENT' ? '- ' : '+ '}
                      {item.amount ? item.amount / 100 + ' ' + (item.currency ? item.currency : 'CHF') : '-'}
                    </span>
                    <span
                      className={`status ${checkStatus(
                        item.status.toLowerCase()
                      )}`}>
                      {checkStatus(item.status.toLowerCase())}
                    </span>
                  </div>
                  <span
                    className={`amount ${item.type === 'PAYMENT' ? ' ' : 'profit'
                      }`}>
                    {item.type === 'PAYMENT' ? '- ' : '+ '}
                    {item.amount ? item.amount / 100 + ' ' + (item.currency ? item.currency : 'CHF') : '-'}
                  </span>
                  <span className={`status ${checkStatus(item.status)}`}>
                    {checkStatus(item.status)}
                  </span>
                  <span>
                    <Button
                      text="bezahlen"
                      isDisabled={isDisabled}
                      isDisabledClass={isDisabled}
                      handleClick={() => this.props.payout(item._id)}
                    />
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
        {total > 5 && (
          <Pagination
            currentPage={page}
            postsPerPage={limit}
            totalPages={totalPages}
            paginate={handlePageChange}
            pageRangeDisplayed={2}
            nextLabel={<img src={arrow_right} alt="arrow_right" />}
            previousLabel={<img src={arrow_left} alt="arrow_left" />}
            marginPagesDisplayed={1}
            breakLabel={'...'}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { transactionReducer } = state
  return {
    transactionReducer,
  }
};

export default connect(mapStateToProps, {
  payout,
})(AdminTransactionsTable);