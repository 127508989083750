import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import FaberlingLogo from '../../../../assets/logo.png';
import menu from '../../../../assets/menu.svg';
import close from '../../../../assets/closeChat.svg';
import Notify from '../../../../assets/notify.svg';

function MainResponsive({ user, openMenu, isShow, closeMenu }) {
  return (
    <div className="main">
      <div className="left-side">
        {isShow && user ? (
          <div className="user-name">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 10C16 12.2091 14.2091 14 12 14C9.79086 14 8 12.2091 8 10C8 7.79086 9.79086 6 12 6C14.2091 6 16 7.79086 16 10ZM15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10Z"
                fill="#999999"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 21C14.2495 21 16.3063 20.1747 17.884 18.8103C16.4287 17.8399 14.5414 17 12.5 17C10.1983 17 8.09268 18.0676 6.58103 19.1864C8.08845 20.3249 9.96538 21 12 21ZM12.5 16C14.8896 16 17.038 17.0115 18.6177 18.0997C20.0966 16.496 21 14.3535 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 14.5673 4.07495 16.8835 5.79932 18.5232C7.41105 17.2896 9.80178 16 12.5 16Z"
                fill="#999999"
              />
            </svg>

            <p>
              {user.firstName} {user.lastName}
            </p>
          </div>
        ) : (
          <Link to="/">
            <img src={FaberlingLogo} alt="FaberlingLogo" style={{height: 20}} />
          </Link>
        )}
      </div>
      <div className="right-side">
        {/* {user && (
          <div className="notify">
            <img src={Notify} alt="Bell" />
            <div className="count">5</div>
          </div>
        )} */}
        {isShow ? (
          <img src={close} alt="burger-menu" onClick={closeMenu} />
        ) : (
          <img src={menu} alt="burger-menu" onClick={openMenu} />
        )}
      </div>
    </div>
  );
}

export default withRouter(MainResponsive);
