import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomeRouter from './HomeRouter';
import LoginCard from '../pages/LogIn/LoginCard';
import BasicInfoRouter from './BasicInfoRouter';
import ScrollToTop from './ScrollToTop';

export default () => (
  <Router>
    <ScrollToTop>
      <Switch>
        <Route
          exact
          path="/:languageID/login"
          render={(props) => (
            <div>
              <LoginCard
                url="/login"
                pathname="login"
                location={props.location}
                history={props.history}
              />
            </div>
          )}
        />
        <Route
          exact
          path="/:languageID/registration"
          render={(props) => (
            <div>
              <LoginCard
                url="/registration"
                pathname="registration"
                location={props.location}
                history={props.history}
              />
            </div>
          )}
        />
        <Route
          exact
          path="/:languageID/forgot-password"
          render={(props) => (
            <div>
              <LoginCard
                location={props.location}
                history={props.history}
                url="/forgot-password"
                pathname="forgotPassword"
              />
            </div>
          )}
        />
        <Route
          path={`/:languageID/password/reset`}
          render={(props) => (
            <div>
              <LoginCard
                location={props.location}
                history={props.history}
                url="/:languageID/password/reset"
                pathname="passwordReset"
              />
            </div>
          )}
        />
        <Route path={'/:languageID/basic-info'} component={BasicInfoRouter} />
        <Route path={'/:languageID'} component={HomeRouter} />
        <Route path={'/'} component={HomeRouter} />
        {/* <Route path={'/de'} component={HomeRouter} /> */}
      </Switch>
    </ScrollToTop>
  </Router>
);