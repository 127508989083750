import React, { Component } from 'react';
import FormInput from '../../../shared/FormInput';
import Button from '../../../shared/Button';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import ForgotPassSuccess from './modals/ForgotPassSuccess';

import { onBlur, onFocus, checkToken, onChangeReset, submitResetForm, } from '../../../redux/actions/forgotPassActions'
import { toggleModal } from '../../../redux/actions/modalActions'

class ResetPassword extends Component {
  componentDidMount() {
    const query = this.props.location.search.split('?token=');
    if (query.length <= 1) return
    this.props.checkToken(query[1])
  }
  render() {
    const {
      forgotPassReducer,
      modalReducer,
      onBlur, onFocus, onChangeReset, submitResetForm,
      toggleModal,
    } = this.props
    const { formState } = forgotPassReducer;
    const { pathname } = this.props.location;
    const languageID = pathname.split('/')[1];
    return (
      <>
        <div className="form-wrapper">
          <p className="title">Benutzername oder Passwort vergessen?</p>
          <p className="error-be">{formState.errors.fromBE}</p>
          <form
            className="forgotPassForm"
            onSubmit={submitResetForm}>
            <FormInput
              name="old_pass"
              label="Neues Passwort"
              type="password"
              error={formState.errors.old_pass}
              value={forgotPassReducer.resetPasswordFields.old_pass}
              handleChange={(e) => onChangeReset(e)}
              handleBlur={(e) => onBlur(e)}
              handleFocus={(e) => onFocus(e)}
            />
            <FormInput
              name="new_pass"
              label="Neues Passwort bestätigen"
              type="password"
              error={formState.errors.new_pass}
              value={forgotPassReducer.resetPasswordFields.new_pass}
              handleChange={(e) => onChangeReset(e)}
              handleBlur={(e) => onBlur(e)}
              handleFocus={(e) => onFocus(e)}
            />
            <Button
              type="submit"
              text="ABSENDEN"
              isLoading={forgotPassReducer.formState.isLoading}
            />
          </form>
        </div>
        <Modal
          centered
          size="xl"
          show={modalReducer.componentName === 'resetPassSuccess'}
          onHide={() => toggleModal('', false)}>
          <ForgotPassSuccess
            onClose={() => this.props.history.push(`${languageID}/login`)}
            text="Sie haben Ihr Passwort erfolgreich geändert."
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { forgotPassReducer, modalReducer } = state
  return {
    forgotPassReducer,
    modalReducer
  }
};

export default connect(mapStateToProps, {
  onBlur, onFocus, checkToken, onChangeReset, submitResetForm,
  toggleModal,
})(withRouter(ResetPassword));