import React from 'react';
import { stylesGarden, styles } from '../../../constants/firstStep';


const Style = React.forwardRef(({ style, id }, ref) => {
  const selectedStyles = [...stylesGarden, ...styles].filter(item => style.some(item2 => item2 === item.value));
  return (
    <div className="style" id={id} ref={ref}>
      <h2>Hausstil</h2>
      <div className="cards">
        {selectedStyles.map((item, index) => (
          <div key={index.title} className="card-wrapper">
            <div className="img-container">
              <img src={item.src} alt="project style" />
            </div>
            <p>{item.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
});

export default Style;
