import React from 'react';
import { useDispatch } from 'react-redux'
import close from '../../assets/close.svg';
import Button from '../Button';
import { setWinner } from '../../redux/actions/conceptActions'
export default function SubmitWinner({ onClose, id, projectId, changeWinner }) {
  const dispatch = useDispatch()
  const addZero = (number, maxLength) => {
    number = number.toString();
    return number.length < maxLength
      ? addZero('0' + number, maxLength)
      : number;
  };
  return (
    <div className="submit-winner-concept-wrapper">
      <div className="close" onClick={onClose}>
        <img src={close} alt="close" />
      </div>

      <div className="submit-winner-concept-wrapper__content">
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M27.5 31C27.7761 31 28 30.7761 28 30.5V17.5C28 17.2239 27.7761 17 27.5 17C27.2239 17 27 17.2239 27 17.5V30.5C27 30.7761 27.2239 31 27.5 31Z"
            fill="black"
          />
          <path
            d="M27.5 36C28.0523 36 28.5 36.4477 28.5 37C28.5 37.5523 28.0523 38 27.5 38C26.9477 38 26.5 37.5523 26.5 37C26.5 36.4477 26.9477 36 27.5 36Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28 48C39.0457 48 48 39.0457 48 28C48 16.9543 39.0457 8 28 8C16.9543 8 8 16.9543 8 28C8 39.0457 16.9543 48 28 48ZM28 47C38.4934 47 47 38.4934 47 28C47 17.5066 38.4934 9 28 9C17.5066 9 9 17.5066 9 28C9 38.4934 17.5066 47 28 47Z"
            fill="black"
          />
        </svg>
        <h1>Gewinner Auswahl</h1>
        <p>Bestätigung Gewinner-Konzept #{addZero(id, 3)}</p>
        <div className="buttons">
          <Button
            text="Ja"
            handleClick={() => {
              dispatch(setWinner(id, projectId))
              changeWinner('');
              onClose();
            }}
          />
          <div className="cancel">
            <Button text="Abbrechen" handleClick={onClose} />
          </div>
        </div>
      </div>
    </div>
  );
}
