import React, { Component, Fragment } from "react";
import { NavLink, withRouter } from 'react-router-dom';
import UserIcon from '../../../../assets/user.svg';
import Chat from '../../../../assets/chat.svg';
import { communicationTitles } from '../../../../constants/communicationTitles';

import { connect } from 'react-redux'
import { toggleChat } from '../../../../redux/actions/communicationActions'
import { socket } from "../../../../services/websocket";

class ContactBar extends Component {

  // Connect to all chats for realtime update
  componentDidUpdate(prevProps) {
    if (prevProps.communicationReducer.conversations.length !== this.props.communicationReducer.conversations.length) {
      console.log(prevProps.communicationReducer.conversations)
      this.props.communicationReducer.conversations.forEach(({ _id }) => {
        socket.emit('connectChat', _id, (error) => {
          if (error) {
            console.log(error)
          } else {
            socket.on('welcome', (data) => {
              console.log(data)
            });
          }
        });
      })
    }
  }

  render() {
    const { toggleChat, communicationReducer } = this.props
    const { url } = this.props.match;
    const { totalPublicComments, conversationNav, conversations } = communicationReducer;
    const contacts = conversationNav;
    const { PUBLIC_COMMENTS_TITLE, PRIVATE_COMMENTS_GROUP_TITLE } = communicationTitles;

    socket.on('online', (data) => {
      console.log(data)
    });

    return (
      <div className="navigation-container">
        <NavLink
          exact
          to={`${url}`}
          activeClassName="selected"
          onClick={() => toggleChat(false)}>
          <span className="nav-elem">
            <img src={Chat} alt="Chat Icon" />
            <span>{PUBLIC_COMMENTS_TITLE}</span>
            {totalPublicComments > 0 && (
              <span className="unread-messages">{totalPublicComments}</span>
            )}
          </span>
        </NavLink>
        {contacts.length > 0 && (
          <Fragment>
            <div className="list-label">{PRIVATE_COMMENTS_GROUP_TITLE}</div>
            <ul className="nav-list">
              {contacts.map((contact, index) => {
                const { id, login } = contact;
                //TODO Add number of comments to offer object
                const comments = 0;
                return (
                  <NavLink
                    to={`${url}/${id}`}
                    activeClassName="selected"
                    key={index}
                    onClick={() => toggleChat(id)}>
                    <li className="nav-elem">
                      <img src={UserIcon} alt="User Icon" />
                      <span>{`${login}`}</span>
                      {comments.length > 0 && (
                        <span className="unread-messages">
                          {comments.length}
                        </span>
                      )}
                    </li>
                  </NavLink>
                );
              })}
            </ul>
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { communicationReducer } = state
  return {
    communicationReducer,
  }
};

export default connect(mapStateToProps, {
  toggleChat
})(withRouter(ContactBar));