import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux'
import Header from '../shared/Header';
import Footer from '../shared/Footer/Footer';
import email from '../assets/email.svg';
//FIXIT don't delete this code -->
// import rate from '../assets/rate.svg';
import CommonInfo from '../shared/CommonInfo/CommonInfo';
import CallUsBlock from '../shared/CommonInfo/components/CallUsBlock/CallUsBlock';
//FIXIT don't delete this code -->
// import RateBlock from '../shared/CommonInfo/components/RateBlock/RateBlock';
import CreateProject from '../pages/CreateProject';
import NotFound from '../pages/NotFound';
import ProfileRouter from './ProfileRouter';
import Auth from '../shared/AuthHOC/AuthHOC';
import { getProfileDetails } from '../redux/actions/profileActions'

class HomeRouter extends Component {
  componentDidMount() {
    const { User } = this.props.globalReducer;
    if (User) this.props.getProfileDetails();
  }
  render() {
    const { url } = this.props.match;
    const { pathname } = this.props.location;
    const languageID  = pathname.split('/')[1]
    // console.log(url)
    // console.log(languageID)
    const place = pathname.split('/')[3];
    const isActive =
      place === 'my-projects' ||
      place === 'transactions' ||
      place === 'project';
    return (
      <div className="home-page">
        <Header />

        {!isActive && (
          <div className="commonInfo">
            {/* //FIXIT don't delete this code --> <CommonInfo src={rate} children={<RateBlock />} /> */}
            <CommonInfo src={email} children={<CallUsBlock />} />
          </div>
        )}
        <Switch>
          <Route path={`${url}/create-project/`} component={CreateProject} />
          <Route path={`${url}/profile/`} component={ProfileRouter} />
          <Route
            path={`${url}media/:fileId`}
            render={(props) => <img src={props.location.pathname} alt="test" />}
          />

          <Route component={NotFound} />
        </Switch>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { globalReducer } = state
  return {
    globalReducer,
  }
};

export default connect(mapStateToProps, {
  getProfileDetails
})(Auth(HomeRouter));