import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { closeSnack, } from '../../redux/actions/snackbarActions'

import close from '../../assets/close.svg'

const Snackbar = () => {
  const dispatch = useDispatch()
  const snackbarReducer = useSelector(state => state.snackbarReducer)
  const { isShown, alert, messageText } = snackbarReducer;

  return (
    <div className={`snackbar ${isShown ? `show ${alert.type}` : ''}`}>
      <span className="title">{alert.title}</span>
      <span className="message">{messageText}</span>
      <span onClick={dispatch(closeSnack)}>
        <img src={close} alt="close" />
      </span>
    </div>
  );
};

export default Snackbar;