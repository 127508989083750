import { GET_TARIFFS, LOGOUT } from '../types'
import { defaultValuesCreateProject } from '../../constants/defaultValueCreateProject';

const initialState = () => {
    return {
        tariffs: []
    }
}

export default function (state = initialState(), action) {
    const { type, payload } = action;
    switch (type) {
        case GET_TARIFFS:
            return {
                ...state,
                tariffs: payload,
                loading: false,
            }
        case LOGOUT:
            return initialState
        default:
            return state

    }
}