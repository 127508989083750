import { GET_EXTRA_TARIFFS } from '../types'
import { instance } from "../http";

export const getExtraTariffs = () => (dispatch, getState) => {
    instance.get("/extra-tariffs")
    .then(res => dispatch({
        type: GET_EXTRA_TARIFFS,
        payload: res.data
    }))
    .catch(error => console.log(error))
}