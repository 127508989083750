import { CHANGE_AUTH_FIELD, CLEAR_AUTH, AUTH_LOADING, AUTH_VALIDITY, AUTH_BLUR, AUTH_FOCUS, AUTH_SUCCESS, AUTH_SET_ERROR_BE, LOGOUT } from '../types'
import { httpNames } from '../../constants/httpNames';
import validator from '../../helpers/validator/validation';
import errorsString from '../../helpers/validator/errorsString';
import { setItem } from '../localStorageActions'

function defaultFields() {
    return {
        password: '',
        login: '',
    };
}

function initialState() {
    return {
        fields: defaultFields(),
        formState: {
            isLoading: false,
            isFormValid: true,
            errors: {
                email: '',
                login: '',
                password: '',
                fromBE: '',
            },
        },
    };
}

export default function (state = initialState(), action) {
    const { type, payload } = action;
    switch (type) {
        case AUTH_SET_ERROR_BE:
            var formState = { ...state.formState }
            formState.errors.fromBE = payload;
            return {
                ...state,
                formState
            }
        case AUTH_LOADING:
            var formState = { ...state.formState }
            formState.isLoading = payload;
            return {
                ...state,
                formState
            }
        case CHANGE_AUTH_FIELD:
            var formState = { ...state.formState }
            formState.errors[payload.name] = "";
            var fields = { ...state.fields }
            fields[payload.name] = payload.value;

            const validatorObj = validator.validate(payload.name, payload.value);
            formState.errors[payload.name] = validatorObj.error;
            // return validatorObj.isValid;
            return {
                ...state,
                fields,
                formState,
            }
        case AUTH_FOCUS:
            var formState = { ...state.formState }
            formState.errors[payload.name] = '';
            formState.errors.fromBE = '';
            return {
                ...state,
                formState
            }
        case AUTH_BLUR:
            var formState = { ...state.formState }
            if (!payload.value) {
                formState.errors[payload.name] = errorsString.COMMON_ERRORS.IS_REQ;
            }
            return {
                ...state,
                formState
            }
        case AUTH_VALIDITY:
            return state
        case AUTH_SUCCESS:
            setItem(httpNames.TOKEN_NAME, payload.token)
            return {
                ...state,
                success: payload ? true : false,
                loading: false
            }
        case LOGOUT:
        case CLEAR_AUTH:
            return initialState()
        default:
            return state
    }
}
