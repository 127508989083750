import React, { Component } from 'react';
import { connect } from 'react-redux'
import FormInput from '../../../../shared/FormInput';
import Button from '../../../../shared/Button';
import { userRole } from '../../../../constants/userRole';

import { onProfileChange, onSubmitProfile } from '../../../../redux/actions/profileActions'

class AccountDetails extends Component {
  render() {
    const {
      profileReducer,
      onProfileChange,
      onSubmitProfile
    } = this.props
    const { fields, formState, isShowBtn } = profileReducer;
    const isCustomer = fields.role === userRole.CUSTOMER;

    return (
      <div>
        <p className="main-title">Account Details</p>
        <p className="sub-title">
          Ihre Daten sind streng vertraulich und nur Ihnen und unserem Admin
          zugänglich.
        </p>
        <form className="accauntForm" onSubmit={onSubmitProfile}>
          <FormInput
            name="lastName"
            label="Vorname"
            value={fields.lastName || ''}
            handleChange={(e) => onProfileChange(e)}
            error={formState.errors.lastName}
          />
          <FormInput
            name="firstName"
            label="Nachname"
            value={fields.firstName || ''}
            handleChange={(e) => onProfileChange(e)}
            error={formState.errors.firstName}
          />
          {fields.additional && !isCustomer && (
            <FormInput
              name="companyName"
              label="Firma"
              value={fields.additional.companyName || ''}
              handleChange={(e) => onProfileChange(e)}
              error={formState.errors.companyName}
            />
          )}
          <FormInput
            name="phoneNumber"
            label="Telefonnummer"
            value={fields.phoneNumber || ''}
            handleChange={(e) => onProfileChange(e)}
            error={formState.errors.phoneNumber}
          />
          {isShowBtn && (
            <div className="btn-width">
              <Button
                text="DATEN SPEICHERN"
                type="submit"
                isLoading={formState.isLoading}
              />
            </div>
          )}
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    profileReducer
  } = state
  return {
    profileReducer
  }
};

export default connect(mapStateToProps, {
  onProfileChange,
  onSubmitProfile
})(AccountDetails);