import React, { Component } from 'react';
import { connect } from 'react-redux'
import user_big from '../../assets/user_big.svg';
import logoutImage from '../../assets/logout.svg';
import AccountDetails from './components/AccountDetails/AccountDetails';
import ProfileDetails from './components/ProfileDetails/ProfileDetails';
import ChangePassword from './components/ChangePassword/ChangePassword';
import Button from '../../shared/Button';
import { userRole } from '../../constants/userRole';

import { getProfileDetails } from '../../redux/actions/profileActions'
import { logout } from '../../redux/actions/globalActions'

class ProfilePage extends Component {
  constructor() {
    super();
    this.state = { showChangePassword: false };
  }

  handleClick = (status) => {
    this.setState({ showChangePassword: status });
  };

  getAccountName = () => {
    switch (this.props.globalReducer.User.role) {
      case userRole.COMPANY:
        return 'Architekten';
      case userRole.CUSTOMER:
        return 'Bauherren';
      case userRole.ADMIN:
        return 'Admin'
      default:
        return 'Gast';
    }
  }

  componentDidMount() {
    this.props.getProfileDetails();
  }

  render() {
    const {
      profileReducer,
      logout
    } = this.props
    const { showChangePassword } = this.state;
    const { profile } = profileReducer;

    return (
      <div className="page-wrapper">
        <div className="title">
          <img src={user_big} alt="user_big" />
          <p className="user-name">
            Willkommen {profile && profile.firstName}{' '}
            {profile && profile.lastName}!
          </p>
          <p className="account-role">{this.getAccountName()}-Account</p>
        </div>
        <div className="profile-wrapper">
          <div>
            <ProfileDetails />
            {showChangePassword ? (
              <div className="password-wrapper">
                <ChangePassword closeChangePassword={this.handleClick} />
              </div>
            ) : (
                <div className="btn-width">
                  <Button
                    handleClick={() => this.handleClick(true)}
                    text="Passwort ändern"
                  />
                </div>
              )}
            <br />
          </div>
          <AccountDetails />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { profileReducer, globalReducer } = state
  return {
    profileReducer,
    globalReducer,
  }
};

export default connect(mapStateToProps, {
  getProfileDetails,
  logout,
})(ProfilePage);
