import React, { Component } from 'react';
import { connect } from 'react-redux'
import FinishMessage from '../../pages/CreateProject/components/FinishMessage';
import close from '../../assets/close.svg';

import { resetProjectData, setRedirect } from '../../redux/actions/createProjectActions'
import { getOneTransaction, closeModal, setLinkInterval } from '../../redux/actions/transactionActions'

class Iframe extends Component {
  constructor() {
    super();
    this.state = { show: true };
  }

  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = () => this.setState({ show: true });

  componentDidMount() {
    const { getOneTransaction, transactionReducer } = this.props;
    getOneTransaction(transactionReducer.transactionAnswer._id);
  }

  componentDidUpdate() {
    const { transactionReducer, getOneTransaction, closeModal, setLinkInterval } = this.props
    const { payment, transactionAnswer, interval } = transactionReducer;
    if (payment === 'REQUIRES_ACTION') {
      // This is not defined?
      const interval = setInterval(async () => {
        getOneTransaction(transactionAnswer._id);
      }, 2000);
      setLinkInterval(interval);
    } else {
      // This is not defined?
      clearInterval(interval);
    }
    if (payment === 'REQUIRES_PAYMENT_METHOD') {
      closeModal();
    }
  }

  checkPayment = (status) => {
    const {
      resetProjectData,
      setRedirect,
      projectReducer,
      transactionReducer,
      closeModal,
      location
    } = this.props
    const { createdProjectData } = projectReducer;
    switch (status) {
      case 'REQUIRES_ACTION':
        return (
          <div>
            <div className="close" onClick={closeModal}>
              <img src={close} alt="close" />
            </div>
            <iframe
              src={transactionReducer.transactionAnswer.actionUrl}
              title="auth-frame"
              width="100%"
              height="100%"></iframe>
          </div>
        );
      case 'SUCCEEDED':
        return (
          <FinishMessage
            projectId={transactionReducer.cardDetails.projectId}
            onClose={() => {
              closeModal();
              setRedirect(true);
              setTimeout(() => {
                resetProjectData();
              }, 1000);
            }}
            projectName={createdProjectData.name}
            currency={createdProjectData.currency}
          />
        );
      default:
        return <div>Laden...</div>;
    }
  };

  render() {
    const { payment } = this.props.transactionReducer;
    return <div className="iframe-wrapper">{this.checkPayment(payment)}</div>;
  }
}

const mapStateToProps = (state) => {
  const { projectReducer, transactionReducer } = state
  return {
    projectReducer,
    transactionReducer
  }
};

export default connect(mapStateToProps, {
  resetProjectData, setRedirect, getOneTransaction, closeModal, setLinkInterval
})(Iframe);
