import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '../../../../../shared/SmallButton';

//Import Redux Actions
import { clearStore } from '../../../../../redux/actions/registrationActions'
import { clearAuthStore } from '../../../../../redux/actions/authActions'

export default function ToggleForm({ location, pathname, redirectUrl }) {
  const dispatch = useDispatch()
  let history = useHistory();
  const handleClick = pathname => {
    var languageID = location.pathname.split('/')[1]
    redirectUrl !== '/'
      ? history.push(`/${languageID}/${pathname}?redirectUrl=${redirectUrl}`)
      : history.push(`/${languageID}/${pathname}`);
    dispatch(clearStore())
    dispatch(clearAuthStore())
  };

  return (
    <Fragment>
      {pathname === 'registration' ? (
        <Fragment>
          <div className="loginChangeContainer">
            <p className="title">Anmelden</p>
            <p className="subTitle">
              Wenn Sie ein Konto haben, <br /> melden Sie sich mit Ihrer
              <br />
              E-Mail-Adresse an
            </p>
            <div>
              <Button
                text="Anmelden"
                handleClick={() => handleClick('login')}
              />
            </div>
          </div>
        </Fragment>
      ) : (
          <Fragment>
            <div className="loginChangeContainer">
              <p className="title">Registrierung</p>
              <p className="subTitle">
                Das Erstellen eines Kontos hat <br /> viele Vorteile.
            </p>
              <div>
                <Button
                  text="Registrieren"
                  handleClick={() => handleClick('registration')}
                />
              </div>
            </div>
          </Fragment>
        )}
    </Fragment>
  );
}

ToggleForm.propTypes = {
  componentName: PropTypes.string,
  onClose: PropTypes.func,
  message: PropTypes.string,
};
