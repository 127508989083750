export const titles = {
  secondStepCounterTitles: [
    {
      title: 'Anzahl Geschosse',
      value: 'numOfFloors',
    },
    {
      title: 'Anzahl Zimmer',
      value: 'numOfRooms',
    },
    {
      title: 'Anzahl Badezimmer',
      value: 'numOfBathrooms',
    },
    {
      title: 'Anzahl Gäste-WC/Bad',
      value: 'numOfGuestWCBat',
    },
    {
      title:
        'Wie viele Aussenparkmöglichkeiten (Carport, Parkplatz) brauchen Sie?',
      value: 'numOfParkings',
    },
  ],
  secondStepSwitcherTitles_AnbauAufbau: [
    {
      title:
        'Möchten Sie einen Aussenbereich (wie z.B. Terrasse, Loggia, Balkon)?',
      value: 'hasTerrace',
    },
    {
      title: 'Möchten Sie einen Wintergarten?',
      value: 'hasWinterGarden',
    },
  ],
  secondStep_AnbauAufbau_Merh: [
    // {
    //   title: 'Anzahl Geschosse',
    //   value: 'numOfFloors',
    // },
    {
      title: 'Anzahl Wohnungen',
      value: 'numberOfApartments',
    },
    {
      title:
        'Wie viele Aussenparkmöglichkeiten (Carport, Parkplatz) brauchen Sie?',
      value: 'numOfParkings',
    },
  ],
  secondStepSwitcherTitles: [
    {
      title: 'Möchten Sie eine Tiefgarage?',
      value: 'hasUndergroundParking',
    },
    {
      title: 'Möchten Sie einen Keller/ ein Untergeschoss?',
      value: 'hasBasement',
    },
    {
      title:
        'Möchten Sie einen Aussenbereich in den oberen Geschossen (wie z.B. eine Terrasse, eine Loggia oder ein Balkon)?',
      value: 'hasTerrace',
    },
    {
      title: 'Möchten Sie einen Wintergarten?',
      value: 'hasWinterGarden',
    },
  ],
  secondStepRadioTitles: ['Küche', 'Raumgestaltung'],
  secondStepCheckboxTitles: ['Atrium', 'Galerie'],
  secondStepCheckboxTitles_AnbauAufbau: ['Umbau', 'Anbau', 'Aufstockung'],
  secondStepNumberSizesApartment: [
    "1.5 Zimmerwohnung", 
    "2.5 Zimmerwohnung", 
    "3.5 Zimmerwohnung", 
    "4.5 Zimmerwohnung", 
    "5.5 Zimmerwohnung", 
    "6.5 Zimmerwohnung", 
    "7.5 Zimmerwohnung"
  ],
  thirdStepCounterTitles: [
    {
      title: 'Projektlaufzeit in Tage',
      value: 'projectDuration',
    }
  ]
};
