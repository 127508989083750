import React from "react";
import PropTypes from "prop-types";
import minus from "../../assets/count_minus.svg";
import plus from "../../assets/count_plus.svg";

const CounterBox = ({ onClick, counter, title }) => {
  return (
    <div className="countWrapper">
      <button
        className="countBtn"
        onClick={() => {
          console.log(title)
          if (title && title === 'projectDuration'){
            onClick(counter > 7 ? counter - 1 : 7)
          } else {
            onClick(counter > 0 ? counter - 1 : 0)
          }
        }}
      >
        <img src={minus} alt="minus" />
      </button>
      <p className="count">{counter}</p>
      <button className="countBtn" onClick={() => onClick(counter + 1)}>
        <img src={plus} alt="plus" />
      </button>
    </div>
  );
}

CounterBox.propTypes = {
  counter: PropTypes.number,
  onClick: PropTypes.func
};

export default CounterBox;
