import React from 'react';
import { Fragment } from 'react';

const AdditionalInfo = React.forwardRef(({ infoData, id, currency }, ref) => {
  return (
    <div className="further-information" id={id} ref={ref}>
      <h2>PLZ, Parzellen-Nr.</h2>
      <div className="list-data">
        <div className="post-item">
          <p>Postleitzahl</p>
          <span>{infoData.postalCode}</span>
        </div>
        {currency === 'EUR' ? <Fragment>
          <div className="post-item">
            <p>Flur</p>
            <span>{infoData.plot}</span>
          </div>
          <div className="post-item">
            <p>Flurstück</p>
            <span>{infoData.plotNo}</span>
          </div>
        </Fragment>
          : <Fragment>
            <div className="post-item">
              <p>Parzellen-Nummer</p>
              <span>{infoData.postOfficeNumber}</span>
            </div>
          </Fragment>}
      </div>
    </div>
  );
});

export default AdditionalInfo;
