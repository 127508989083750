import React, { Fragment } from 'react';
import Button from '../Button';
import close from '../../assets/close.svg';
import FormInput from '../../shared/FormInput';

const AcceptProject = ({ 
  onClose, 
  project, 
  isLoading,
  onSubmit,
  onHandleChangeInput,
  invoiceAmount 
}) => {
  return (
    <Fragment>
      <div className="close" onClick={onClose}>
        <img src={close} alt="close" />
      </div>
      <div className="submit-concept-wrapper">
        <p className="main-title">Projekt genehmigen</p>
        <p className="error-be">{/*errors.fromBE*/}</p>
        <form className="submit-concept-form" onSubmit={onSubmit}>
          <p className="error">{/*errors.comments*/}</p>
          <Button isLoading={isLoading} type="submit" text="Genehmigen" />
        </form>
      </div>
    </Fragment>
  );
}

export default AcceptProject;