import React, { Component } from 'react';
import NoTransactions from './NoTransactions/NoTransactions';
import DesignerTransactionsTable from './TransactionsTable/DesignerTransactionsTable';

import { connect } from 'react-redux'
import { handleRequestPage } from '../../../redux/actions/transactionActions'

class DesignerPage extends Component {
  render() {
    const { transactionReducer, handleRequestPage } = this.props;
    const { transactions } = transactionReducer
    return (
    <div className="designer-page">
      {transactions.result.length <= 0 ? (
        <NoTransactions />
      ) : (
        <DesignerTransactionsTable
          limit={transactions.limit}
          totalPages={transactions.totalPages}
          page={transactions.page}
          transactions={transactions.result}
          total={transactions.total}
          changePage={handleRequestPage}
        />
      )}
    </div>
  );
  }
}

const mapStateToProps = (state) => {
  const { transactionReducer } = state
  return {
    transactionReducer,
  }
};

export default connect(mapStateToProps, {
  handleRequestPage
})(DesignerPage);