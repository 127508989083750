import React from 'react';

const Data = React.forwardRef(
  ({ detailedProject, paymentProcessing, id, checkStatus, tariffName }, ref) => {
    const days = detailedProject.details.projectDuration ? detailedProject.details.projectDuration : '';
    return (
      <div className="data" id={id} ref={ref}>
        <h2>Daten (nicht veröffentlicht)</h2>
        <div className="info">
          <div>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.75 6.75002L3.75 9.75001L11.25 2.25002"
                stroke="#219653"
                strokeWidth="1.4"
                strokeLinecap="round"
              />
            </svg>
            Einstellgebühr
          </div>
          <div>
            {checkStatus(tariffName === 'Premium')}
            Preisgeld Premium
          </div>
          <div>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.75 6.75002L3.75 9.75001L11.25 2.25002"
                stroke="#219653"
                strokeWidth="1.4"
                strokeLinecap="round"
              />
            </svg>
            {days} Werktage
          </div>
          <div>
            {checkStatus(paymentProcessing === 'MONEY_BACK')}
            Preisgeld-zurück-Garantie
          </div>
        </div>
      </div>
    );
  }
);

export default Data;
