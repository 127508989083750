import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Modal } from 'react-bootstrap';
import OfferCard from '../../../shared/OfferCard';
import Pagination from '../../../shared/Pagination';
import arrow_right from '../../../assets/small_right.svg';
import arrow_left from '../../../assets/small_left.svg';
import SubmitWinner from '../../../shared/SubmitWinner';
import { clearConcepts, setProjectId, handleRequestPage, setRating } from '../../../redux/actions/conceptActions'
import moment from 'moment/min/moment-with-locales';
moment.locale('de');
const CustomerConcepts = ({ id }) => {
  const dispatch = useDispatch()
  const [idWinner, setIdWinner] = useState('');
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    dispatch(setProjectId(id))
    return () => dispatch(clearConcepts())
  }, [id])

  const handlePageChange = ({ selected }) => {
    dispatch(handleRequestPage(selected + 1))
  };
  const { concepts } = useSelector(state => state.conceptReducer)
  const { detailedProject } = useSelector(state => state.projectReducer)


  if (!concepts || !detailedProject) return <div>Laden...</div>;

  const { total, limit, page, result, totalPages } = concepts;

  const today = Date.now();
  return (
    <div className="concepts">
      <div className="search">
        <div className="search-left-side">
          <span className="title">Insgesamt:</span>
          <span className="element">{total} Konzepte</span>
        </div>
        <div className="search-right-side">
          <span className="title">Sortiere nach:</span>
          <span className="element active">Datum hinzugefügt</span>
          <span className="element">Autor</span>
          <span className="element">Bewertung</span>
        </div>
        <div className="search-right-side-responsive">
          <span className="title">Sortiere nach:</span>
          <span className="element active">Datum hinzugefügt</span>
        </div>
      </div>
      <div className="offers">
        {result.map((item) => (
          <OfferCard
            key={item._id}
            rating={item.rating}
            description={item.description}
            id={item._id}
            setRating={(id, rating) => dispatch(setRating(id, rating))}
            isActiveRating={true}
            account={item.accountId}
            img={item.thumbnailUrl}
            isSetWinner={idWinner === item._id}
            handleShow={handleShow}
            setIdWinner={() => setIdWinner(item._id)}
            hasWinner={detailedProject.hasWinner}
            isWinner={detailedProject.winnerIds.find((winner) => {
              if (winner._id === item._id) return true;
              else return false;
            })}
            isNew={
              moment(item.creationDate).format('D') ===
              moment(today).format('D')
            }
          />
        ))}
      </div>
      {total > 12 && (
        <Pagination
          currentPage={page}
          postsPerPage={limit}
          totalPages={totalPages}
          paginate={handlePageChange}
          pageRangeDisplayed={2}
          nextLabel={<img src={arrow_right} alt="arrow_right" />}
          previousLabel={<img src={arrow_left} alt="arrow_left" />}
          marginPagesDisplayed={1}
          breakLabel={'...'}
        />
      )}

      <Modal centered size="xl" show={show} onHide={handleClose}>
        <SubmitWinner
          onClose={handleClose}
          id={idWinner}
          projectId={detailedProject._id}
          changeWinner={setIdWinner}
        />
      </Modal>
    </div>
  );
};

export default CustomerConcepts;
