import React from 'react';
import PropTypes from 'prop-types';
import FaberlingLogo from '../../../assets/logo.png';
import { CallUs } from './components/CallUs/CallUs';
import Authorization from '../Authorization/Authorization';
import Registration from '../Registration/Registration';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import ToggleForm from './components/ToggleForm/ToggleForm';
import Auth from '../../../shared/AuthHOC/AuthHOC';
import ResetPassword from '../ForgotPassword/ResetPassword';

const LoginCard = ({ url, pathname, location }) => {
  console.log(pathname)
  const languageID = location.pathname.split('/')[1];
  const components = {
    login: <Authorization location={location} languageID={languageID} />,
    registration: <Registration />,
    forgotPassword: <ForgotPassword languageID={languageID} />,
    passwordReset: <ResetPassword />
  };
  let redirectUrl = '/';
  if (location.search) {
    const redirect = location.search.split('?redirectUrl=');
    redirectUrl = redirect[1];
  }
  return (
    <div className="loginWrapper">
      <div className="leftContainer">
        <a href={redirectUrl}>
          <img
            src={FaberlingLogo}
            alt="FaberlingLogo"
            className="logoFaberling"
          />
        </a>
        <ToggleForm url={url} pathname={pathname} redirectUrl={redirectUrl} location={location} />
        <CallUs />
      </div>
      <div className="rightContainer">
        <a href={redirectUrl} className="mobileLogo">
          <img src={FaberlingLogo} alt="FaberlingLogo" style={{ width: '90%' }} />
        </a>
        <div className="formContainer">{components[pathname]}</div>
      </div>
    </div>
  );
};

export default Auth(LoginCard);

LoginCard.propTypes = {
  componentName: PropTypes.string,
};
