import React, { Fragment } from 'react';
import { useSelector } from 'react-redux'
import LeftSide from './LeftSide/LeftSide';
import RightSide from './RightSIde/RightSide';

const Additional = ({ role, pathname }) => {
  const { detailedProject } = useSelector(state => state.projectReducer)
  const conceptId = pathname.split('/')[6];
  const languageID = pathname.split('/')[1];

  if (!detailedProject) return <div>Loading...</div>;
  return (
    <Fragment>
      <div className="header-additional">
        <LeftSide role={role} detailedProject={detailedProject} />
        <RightSide detailedProject={detailedProject} conceptId={conceptId} languageID={languageID} />
      </div>
    </Fragment>
  );
}

export default Additional;
