import React from 'react';
import moment from 'moment/min/moment-with-locales';
moment.locale('de');

export default function RightSide({ detailedProject, conceptId, languageID }) {
  const checkCategory = (category) => {
    switch (category) {
      case 'BUILDING':
        return 'Neubau';
      case 'RENOVATION':
        return 'Anbau & Aufbau';
      case 'GARDEN':
        return 'Garten- & Landschaftsbau';
      case 'SPECIAL':
        return 'Spezialprojekt';
      case 'PRODUKT':
        return 'Produkt';
      default:
        break;
    }
  };
  const checkStatus = (status) => {
    switch (status) {
      case 'ACCEPTED':
        return 'laufend';
      case 'REVIEW':
        return 'faberling coach';
      case 'ON_MODERATION':
        return 'entwurf';
      case 'DECLINED':
        return 'abgesagt';
      case 'CLOSED':
        return 'genehmigt';
      default:
        break;
    }
  };

  const addZero = (number, maxLength = 6) => {
    number = number.toString();
    return number.length < maxLength
      ? addZero('0' + number, maxLength)
      : number;
  };

  const currencyFilter = {
    ch: 'CHF',
    de: 'EUR',
  }

  return (
    <div
      className={
        conceptId
          ? 'header-additional-right-side hide-right-side'
          : 'header-additional-right-side'
      }>
      <div>
        <span className="type">
          {checkCategory(detailedProject.category)} – {detailedProject.type}
        </span>
        {
          detailedProject.status === 'REVIEW' ? (
            <span className={`status faberling-coach`}>
              {checkStatus(detailedProject.status)}
            </span>
          ) : (
            <span className={`status ${detailedProject.status}`}>
              {checkStatus(detailedProject.status)}
            </span>
          )
        }
      </div>
      <div className="general-info">
        <div className="general-info-element">
          <span className="title">ProjektID</span>
          <span>#{addZero(detailedProject._id, 6)}</span>
        </div>
        <div className="general-info-element">
          <span className="title">Ende des Projektes</span>
          {
            detailedProject.status === 'ON_MODERATION' || detailedProject.status === 'REVIEW' ? null : (
              <span className="data">
                {moment(detailedProject.endDate).format('DD. MMM YYYY, HH:mm')}
              </span>
            )
          }
        </div>
        <div className="general-info-element">
          <span className="title">Preisgeld</span>
          <span className="amount">
            {/* calculate the prize money by taking the payment amout and removing the service fee */}
            {detailedProject.paymentAmount > 0 ? detailedProject.currency + ((detailedProject.paymentAmount - 5000) / 100).toLocaleString('de-CH') : ''}
          </span>
        </div>
      </div>
    </div>
  );
}
