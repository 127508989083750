import house from '../assets/house.svg';
import garden from '../assets/garden.svg';
import special from '../assets/special.svg';
import product from '../assets/plan-digitalization.png';
import multifamily from '../assets/multifamily.svg';

import KlassischeArchitektur from '../assets/houseStyles/KlassischeArchitektur.png';
import BlobArchitectur from '../assets/houseStyles/Blob-Architectur.png';
import Bungalow from '../assets/houseStyles/Bungalow.png';
import Designerhaus from '../assets/houseStyles/Designerhaus.png';
import MediterranesHaus from '../assets/houseStyles/MediterranesHaus.png';
import Landhaus from '../assets/houseStyles/Landhaus.png';
import Kubus from '../assets/houseStyles/Kubus_Minimalismus.png';
import Chalet from '../assets/houseStyles/Chalet_Cottage.png';
import Schwedenhaus from '../assets/houseStyles/Schwedenhaus.png';
import Fachwerkhaus from '../assets/houseStyles/Fachwerkhaus.png';
import Blockhaus from '../assets/houseStyles/Blockhaus.png';
import Friesenhaus from '../assets/houseStyles/Friesenhaus.png';

import Holzbau from '../assets/constrMethods/Holzbau.png';
import Massivbau from '../assets/constrMethods/Massivbau.png';
import Mischbau from '../assets/constrMethods/Mischbau.png';
import Stahlbau from '../assets/constrMethods/Stahlbau.png';

import Flachdach from '../assets/roofType/Flachdach.svg';
import Krüppelwalmdach from '../assets/roofType/Krüppelwalmdach.svg';
import Mansarddach from '../assets/roofType/Mansarddach.svg';
import Pultdach from '../assets/roofType/Pultdach.svg';
import Satteldach from '../assets/roofType/Satteldach.svg';
import Tonnendach from '../assets/roofType/Tonnendach.svg';
import Walmdach from '../assets/roofType/Walmdach.svg';

import JapanischerGarten from '../assets/gardenStyles/Japanischer Garten.png';
import SpanischerGarten from '../assets/gardenStyles/Spanischer Garten.png';
import EnglischerGarten from '../assets/gardenStyles/Englischer Garten.png';
import ChinesischerGarten from '../assets/gardenStyles/Chinesischer Garten.png';
import NaturgartenBauerngarten from '../assets/gardenStyles/Naturgarten Bauerngarten.png';
import StylingDesignGarten from '../assets/gardenStyles/Styling-Design Garten.png';
import SchlichterGarten from '../assets/gardenStyles/Schlichter Garten.png';
import Themengarten from '../assets/gardenStyles/Themengarten.png';
import NatursteinGartenTyp1 from '../assets/gardenStyles/Naturstein Garten Typ 1.png';
import LandhausgartenCottageGarten from '../assets/gardenStyles/Landhausgarten Cottage Garten.png';
import GartenMitPool from '../assets/gardenStyles/Garten mit Pool.png';
import NatursteinGartenTyp2 from '../assets/gardenStyles/Naturstein Garten Typ 2.png';



export const categories = [
  {
    value: 'BUILDING',
    title: 'Neubau',
  },
  // {
  //   value: 'RENOVATION',
  //   title: 'Umbau',
  // },
  // {
  //   value: 'GARDEN',
  //   title: 'Garten- & Landschaftsbau',
  // },
  // {
  //   value: 'SPECIAL',
  //   title: 'Spezialprojekt',
  // },
  // {
  //   value: 'PRODUKT',
  //   title: 'Produkt',
  // },
];

export const typesNeubau = [
  {
    value: 'Einfamilienhaus',
    src: house,
  },
  // Hidden temporarily
  {
    value: 'Mehrfamilienhaus',
    src: multifamily,
  },
];

export const typesGarten = [
  {
    value: 'Garten- & Landschaftsbau',
    src: garden,
  },
];

export const typesSpezialprojekt = [
  {
    value: 'Spezialprojekt',
    src: special,
  },
];

export const typesProduct = [
  {
    value: 'Plan-Digitalisierung',
    src: product,
  },
];

export const styles = [
  {
    title: 'Klassische Architektur',
    value: 'Klassische Architektur',
    src: KlassischeArchitektur,
  },
  {
    title: 'Blob-Architektur (Freiform-Haus)',
    value: 'Blob-Architektur (Freiform-Haus)',
    src: BlobArchitectur,
  },
  {
    title: 'Bungalow',
    value: 'Bungalow',
    src: Bungalow,
  },
  {
    title: 'Designerhaus',
    value: 'Designerhaus',
    src: Designerhaus,
  },
  {
    title: 'Mediterranes Haus',
    value: 'Mediterranes Haus',
    src: MediterranesHaus,
  },
  {
    title: 'Landhaus',
    value: 'Landhaus',
    src: Landhaus,
  },
  {
    title: 'Kubus/ Minimalismus (Bauhaus)',
    value: 'Kubus/ Minimalismus (Bauhaus)',
    src: Kubus,
  },
  {
    title: 'Chalet/ Cottage',
    value: 'Chalet/ Cottage',
    src: Chalet,
  },
  {
    title: 'Schwedenhaus',
    value: 'Schwedenhaus',
    src: Schwedenhaus,
  },
  {
    title: 'Fachwerkhaus',
    value: 'Fachwerkhaus',
    src: Fachwerkhaus,
  },
  {
    title: 'Blockhaus',
    value: 'Blockhaus',
    src: Blockhaus,
  },
  {
    title: 'Friesenhaus',
    value: 'Friesenhaus',
    src: Friesenhaus,
  },
];

export const stylesGarden = [
  {
    title: 'Japanischer Garten',
    value: 'Japanischer Garten',
    src: JapanischerGarten,
  },
  {
    title: 'Spanischer Garten',
    value: 'Spanischer Garten',
    src: SpanischerGarten,
  },
  {
    title: 'Englischer Garten',
    value: 'Englischer Garten',
    src: EnglischerGarten,
  },
  {
    title: 'Chinesischer Garten',
    value: 'Chinesischer Garten',
    src: ChinesischerGarten,
  },
  {
    title: 'Naturgarten Bauerngarten',
    value: 'Naturgarten Bauerngarten',
    src: NaturgartenBauerngarten,
  },
  {
    title: 'Styling- Design Garten',
    value: 'Styling- Design Garten',
    src: StylingDesignGarten,
  },
  {
    title: 'Schlichter Garten',
    value: 'Schlichter Garten',
    src: SchlichterGarten,
  },
  {
    title: 'Themengarten',
    value: 'Themengarten',
    src: Themengarten,
  },
  {
    title: 'Naturstein Garten Typ 1',
    value: 'Naturstein Garten Typ 1',
    src: NatursteinGartenTyp1,
  },
  {
    title: 'Landhausgarten Cottage Garten',
    value: 'Landhausgarten Cottage Garten',
    src: LandhausgartenCottageGarten,
  },
  {
    title: 'Garten mit Pool',
    value: 'Garten mit Pool',
    src: GartenMitPool,
  },
  {
    title: 'Naturstein Garten Typ 2',
    value: 'Naturstein Garten Typ 2',
    src: NatursteinGartenTyp2,
  },
];
export const constrMethods = [
  {
    title: 'Massivbau',
    value: 'Massivbau',
    src: Massivbau,
  },
  {
    title: 'Holzbau',
    value: 'Holzbau',
    src: Holzbau,
  },
  {
    title: 'Stahlbau',
    value: 'Stahlbau',
    src: Stahlbau,
  },
  {
    title: 'Mischbau',
    value: 'Mischbau',
    src: Mischbau,
  },
];
export const roofType = [
  {
    title: 'Pultdach/ Scheddach',
    value: 'Pultdach/ Scheddach',
    src: Pultdach,
  },
  {
    title: 'Satteldach',
    value: 'Satteldach',
    src: Satteldach,
  },
  {
    title: 'Walmdach/ Zeltdach',
    value: 'Walmdach/ Zeltdach',
    src: Walmdach,
  },
  {
    title: 'Krüppelwalmdach',
    value: 'Krüppelwalmdach',
    src: Krüppelwalmdach,
  },
  {
    title: 'Flachdach',
    value: 'Flachdach',
    src: Flachdach,
  },
  {
    title: 'Tonnendach/ Kuppeldach',
    value: 'Tonnendach/ Kuppeldach',
    src: Tonnendach,
  },
  {
    title: 'Mansarddach',
    value: 'Mansarddach',
    src: Mansarddach,
  },
];
