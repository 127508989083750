import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import yes from '../../assets/yes.svg';
import no from '../../assets/no.svg';
import checked from '../../assets/checked.svg';
import Button from '../Button';

export default function UpsellCard({
  id,
  selectedGroup,
  amount,
  onClick,
  selectableType,
  name,
  description,
  currency,
  additionalInfo,
  isHypoReady,
  languageID
}) {
  /**
   * 0 = not selectable; 1 = selectable; 2 = selected by default but not removable
   * */
  let isSelected = selectedGroup.includes(id);
  let isSelectable = true;
  // let isSelectable = false; -- old structure [17-06-2021] --
  let isIncluded = false; // price included in the package

  if (isSelected) {
    if (selectedGroup.includes(languageID === 'ch' ? 14 : 18)) {
      const includedArr = languageID === 'ch' ? [11, 12, 13] : [15, 16, 17]
      if (includedArr.includes(id)) {
        isSelectable = false;
        isIncluded = true;
      }
    } else if (selectedGroup.includes(languageID === 'ch' ? 13 : 17)) {
      const includedArr = languageID === 'ch' ? [11, 12] : [15, 16]
      if (includedArr.includes(id)) {
        isSelectable = false;
        isIncluded = true;
      }
    } else if (selectedGroup.includes(languageID === 'ch' ? 12 : 16)) {
      const includedArr = languageID === 'ch' ? [11] : [15]
      if (includedArr.includes(id)) {
        isSelectable = false;
        isIncluded = true;
      }
    } else {
      isSelectable = true;
      isIncluded = false;
    }
  }

  // -- old structure [17-06-2021] --
  // if (selectableType == 2) {
  //   isSelectable = isSelected = isIncluded = true;
  // } else {
  //   if (selectableType == 1) {
  //     isSelectable = true;
  //     isSelected = selectedGroup.includes(id);
  //   }
  // }

  return (
    <div
      className={`upsellWrapper ${isSelected ? 'checked' : ''} ${isIncluded && ''} ${additionalInfo ? 'has-info' : ''}`}
      // onClick={() => isSelectable && (selectableType == 1) ? onClick(id) : {}}>
      >
      {isSelected && !isIncluded && <img className="checked included" src={checked} alt="checked" />}
      <div 
        id={`item-wrapper-${id}`}
        className={`item top ${isSelectable ? null : 'disabled'}`}
        style={{ cursor: !isSelectable ? 'not-allowed': 'pointer'}}
        onClick={() => isSelectable ? onClick(id) : {}}>
        <div className="item container">
          <p className="title" id={`title-${id}`}>{name}</p>
          <p className="title-mobile">{`${name} enthält:`}</p>
        </div>
        <div className="item desc">
          <p className="description desc-body">{description}</p>
          <div id={`info-${id}`} className="additional-info">
            {additionalInfo ? (
              <Fragment>
                <ul>
                  {additionalInfo.map((info, i) => {
                    if ((additionalInfo.length - 1) == i) { return false }
                    return <li key={i}>{info}</li>
                  })}
                </ul>
                <p style={{ marginTop: additionalInfo.length > 1 ? 20 : 0 }}>{additionalInfo[additionalInfo.length - 1]}</p>
              </Fragment>
            ) : null}
          </div>
        </div>
        <div className="item price">
          {
            isIncluded ? (
              <p className="amount">inbegriffen</p>
            ) : (
              <div>
                <p className="amount">{currency} {(amount / 100).toLocaleString('de-CH')}</p>
                <p className="description">inkl. {languageID === 'ch' ? '7,7%' : '19%'} MwSt.</p>
              </div>
            )
          }
        </div>
      </div>
      {additionalInfo ? (
        <div className="toggle-info-m" onClick={() => {
          const toggleText = document.getElementById(`toggleText${id}`)
          const toggleTextVal = toggleText.getAttribute("value")
          const itemWrapper = document.getElementById(`item-wrapper-${id}`)
          // const allToggleText = document.querySelectorAll(`*[id^="toggleText"]`)[0]

          // allToggleText.textContent = "+ more info"
          // allToggleText.setAttribute("value", "+")
          // // document.querySelectorAll(`*[id^="item-wrapper-"]`)[0].parentElement.classList.remove("hover")
          // document.getElementsByClassName("item")[0].classList.remove("hover")

          if (toggleTextVal === "+") {
            toggleText.textContent = "- less info"
            toggleText.setAttribute("value", "-")
            itemWrapper.parentElement.classList.add("highlight")
            // itemWrapper.classList.add("hover")

            const infoID = document.getElementById(`info-${id}`)
            infoID.classList.add("show")
            infoID.previousSibling.classList.add("hide")
            document.getElementById(`title-${id}`).textContent = `${name} enthält:`
          } else {
            toggleText.textContent = "+ more info"
            toggleText.setAttribute("value", "+")
            itemWrapper.parentElement.classList.remove("highlight")
            // itemWrapper.classList.remove("hover")

            const infoID = document.getElementById(`info-${id}`)
            infoID.classList.remove("show")
            infoID.previousSibling.classList.remove("hide")
            document.getElementById(`title-${id}`).textContent = name
          }
        }}><b id={`toggleText${id}`} value="+">+ more info</b></div>
      ) : null}
    </div>
  );
}

UpsellCard.propTypes = {
  isSelect: PropTypes.bool,
  onSelect: PropTypes.func,
  name: PropTypes.string,
  amount: PropTypes.number,
};
