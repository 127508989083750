import { CHANGE_VALUES, SET_VALUES, LOGOUT, CHANGE_CREATEPROJECT_VALUES, CHANGE_CREATEPROJECT_DETAILS_VALUES, CHANGE_CREATEPROJECT_BOXES, CHANGE_CONFIRMATIONBOXES, FILE_DOWNLOADING, FILE_UPLOADED, UPLOAD_PROJECT_FAIL, DELETE_FILE, SAVE_PROJECT, RESET_PROJECT, SET_PROJECT_DATA, GET_PROJECT_OPTIONS, PROJECT_ERROR, CHANGE_OPTIONS_VALUES, PROJECT_REDIRECT, CHANGE_EMAIL, RESET_CONFIRMATIONBOXES } from '../types'
import { defaultValuesCreateProject } from '../../constants/defaultValueCreateProject';
import { setItem, removeItem } from '../localStorageActions'
import errorsString from '../../helpers/validator/errorsString';
import validator from '../../helpers/validator/validation';

const defaultConfirmationBoxes = () => {
    return {
        rules: false,
        informed: false,
    }
}

const initialState = () => {
    return {
        category: 'BUILDING',
        type: 'Einfamilienhaus',
        valuesCreateProject: defaultValuesCreateProject(`BUILDING-Einfamilienhaus`),
        options: [],
        redirectToCreateProject: false,
        paymentMethod: 'VisaMastercard',
        confirmationBoxes: defaultConfirmationBoxes(),
        emailState: {
            isValid: true,
            error: false,
        },
        isLoading: false,
        // variable for file's validation
        isDocValid: {
            isSizeValid: true,
            isFileValid: true,
            download: false,
        },
        files: [],
        step: 'style',
    }
}

const findElemntInArray = (array, value) => {
    return array.includes(value);
};

export default function (state = initialState(), action) {
    const { type, payload } = action;
    if (type == SET_VALUES) {
        return {
            ...state,
            ...payload,
        }
    }
    if (type == CHANGE_VALUES) {
        const { category, type } = payload
        var CategoryType = `${category ? category : state.category}-${type ? type : state.type}`
        return {
            ...state,
            ...payload,
            // Check that the category or type is provided and update accordingly
            valuesCreateProject: defaultValuesCreateProject(CategoryType)
        }
    }
    if (type == CHANGE_CREATEPROJECT_VALUES) {
        var { fieldName, value } = payload
        var valuesCreateProject = { ...state.valuesCreateProject }

        if (fieldName === 'extra') {
            valuesCreateProject[fieldName] = value;
        } else {
            if (Array.isArray(state.valuesCreateProject[fieldName])) {
                if (findElemntInArray(state.valuesCreateProject[fieldName], value)) {
                    if (
                        valuesCreateProject[fieldName].length === 1 &&
                        fieldName !== 'style'
                    )
                        return null;
                    valuesCreateProject[fieldName].splice(
                        valuesCreateProject[fieldName].indexOf(value),
                        1
                    );
                } else {
                    valuesCreateProject[fieldName].push(value);
                }
            } else {
                valuesCreateProject[fieldName] = value;
            }
        }
        return {
            ...state,
            valuesCreateProject
        }
    }
    if (type == CHANGE_CREATEPROJECT_DETAILS_VALUES) {
        var { fieldName, value } = payload
        var valuesCreateProject = { ...state.valuesCreateProject }
        if (fieldName === 'requirements' || fieldName === 'requirementsForArchitect') {
            valuesCreateProject.details[fieldName][0].value = value
        } else if (fieldName === 'roomApartmentDetails'){
            valuesCreateProject.details[fieldName] = value
        } else {
            if (Array.isArray(state.valuesCreateProject.details[fieldName])) {
                if (
                    findElemntInArray(
                        valuesCreateProject.details[fieldName],
                        value
                    )
                ) {
                    if (valuesCreateProject.details[fieldName].length === 1)
                        return {
                            ...state,
                        }
                    valuesCreateProject.details[fieldName].splice(
                        valuesCreateProject.details[fieldName].indexOf(value),
                        1
                    );
                } else {
                    valuesCreateProject.details[fieldName].push(value);
                }
            } else {
                valuesCreateProject.details[fieldName] = value;
            }
        }
        return {
            ...state,
            valuesCreateProject
        }
    }
    if (type == CHANGE_CREATEPROJECT_BOXES) {
        var { fieldName, boxesName } = payload
        var valuesCreateProject = { ...state.valuesCreateProject }
        valuesCreateProject[boxesName][fieldName] = !state.valuesCreateProject[
            boxesName
        ][fieldName];
        return {
            ...state,
            valuesCreateProject
        }
    }
    if (type == CHANGE_CONFIRMATIONBOXES) {
        var { fieldName } = payload
        var confirmationBoxes = { ...state.confirmationBoxes }
        confirmationBoxes[fieldName] = !state.confirmationBoxes[fieldName];
        return {
            ...state,
            confirmationBoxes
        }
    }
    if (type == FILE_DOWNLOADING) {
        var isDocValid = { ...isDocValid }
        isDocValid.download = true
        return {
            ...state,
            isDocValid
        }
    }
    if (type == UPLOAD_PROJECT_FAIL) {
        var isDocValid = { ...isDocValid }
        isDocValid.download = false
        return {
            ...state,
            isDocValid
        }
    }
    if (type == FILE_UPLOADED) {
        const { fileName, path, _id, fullURL } = payload
        var valuesCreateProject = { ...state.valuesCreateProject }
        var isDocValid = { ...isDocValid }
        var files = state.files
        files.push({
            name: fileName,
            path: path,
            id: _id,
            url: fullURL,
        });
        valuesCreateProject.details.files.push(_id);
        isDocValid.download = false;
        return {
            ...state,
            valuesCreateProject,
            isDocValid,
            files
        }
    }
    if (type == DELETE_FILE) {
        var indexFile = state.files.includes(state.files[payload]);
        if (indexFile) {
            var valuesCreateProject = { ...state.valuesCreateProject }
            valuesCreateProject.details.files = [
                ...state.valuesCreateProject.details.files.slice(0, payload),
                ...state.valuesCreateProject.details.files.slice(payload + 1),
            ];
            return {
                ...state,
                files: [
                    ...state.files.slice(0, payload),
                    ...state.files.slice(payload + 1),
                ],
                valuesCreateProject
            }
        }
        return state
    }
    if (type == SAVE_PROJECT) {
        const attributes = {
            step: payload,
            data: state.valuesCreateProject,
            options: state.options,
        };
        setItem('projectInfo', JSON.stringify(attributes));
        return {
            ...state,
            step: payload,
        }
    }
    if (type == RESET_PROJECT) {
        removeItem('projectInfo');
        removeItem('createdProjectData');
        return { ...initialState() }
    }
    if (type == SET_PROJECT_DATA) {
        var valuesCreateProject = payload.valuesCreateProject ? payload.valuesCreateProject : defaultValuesCreateProject('BUILDING-Einfamilienhaus')
        return {
            ...state,
            valuesCreateProject,
            step: payload.step
        }
    }
    if (type == GET_PROJECT_OPTIONS) {
        return {
            ...state,
            options: payload
        }
    }
    if (type == CHANGE_OPTIONS_VALUES) {
        var valuesCreateProject = { ...state.valuesCreateProject }
        valuesCreateProject.projectOptions.splice(0, 1, payload);
        return {
            ...state,
            valuesCreateProject
        }
    }
    if (type == PROJECT_REDIRECT) {
        return {
            ...state,
            redirectToCreateProject: payload
        }
    }
    if (type == CHANGE_EMAIL) {
        var emailState = { ...emailState }
        if (!payload) {
            emailState.isValid = false;
            emailState.error = errorsString.EMAIL.IS_EMPTY;
        } else {
            if (validator.validatorEmail(payload).error) {
                emailState.isValid = false;
                emailState.error = errorsString.EMAIL.WRONG_FORMAT;
            } else {
                emailState.isValid = true;
                emailState.error = false;
            }
        }
        return {
            ...state,
            emailState
        }
    }
    if (type == RESET_CONFIRMATIONBOXES) {
        return {
            ...state,
            confirmationBoxes: defaultConfirmationBoxes
        }
    }
    if (type == LOGOUT) {
        return initialState()
    }
    return state
}