import React, { Component } from 'react';
import FormInput from '../../../../../shared/FormInput';
import Button from '../../../../../shared/Button';
import SmallButton from '../../../../../shared/SmallButton';
import { communicationTitles } from '../../../../../constants/communicationTitles';

import { connect } from 'react-redux'
import { createComment } from '../../../../../redux/actions/communicationActions'
import { socket } from '../../../../../services/websocket'

class CreateMessage extends Component {
  state = {
    text: '',
  };

  onChange = (e) => {
    this.setState({ text: e.target.value });
  };

  onKeyDown = (e) => {
    if (e.keyCode === 13 && this.state.text) {
      this.onSendMessage();
    }
  };

  onSendMessage = () => {
    let newMessage = {
      message: this.state.text,
      conversationId: this.props.offerId ? this.props.offerId : this.props.projectId,
    };
    socket.emit('sendMessage', { conversationId: this.props.offerId ? this.props.offerId : this.props.projectId }, (error) => {
      if (error) {
        window.alert(error)
      }
    });
    this.props.createComment(
      this.props.projectId,
      newMessage
    );
    this.setState({ text: '' });
  };

  render() {
    const { PLACEHOLDER, SEND_COMMENT } = communicationTitles;

    return (
      <div className="typeMessage-container">
        <FormInput
          name="chatInput"
          type="text"
          placeholder={PLACEHOLDER}
          handleChange={this.onChange}
          onKeyDown={this.onKeyDown}
          value={this.state.text}
        />
        <Button
          text={SEND_COMMENT}
          handleClick={this.onSendMessage}
          icon={
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 2L6.00006 12.5L1 23L23 12.5L1 2ZM19.6307 12L3.11621 4.11806L6.86955 12L19.6307 12ZM6.86955 13L3.11621 20.8819L19.6307 13L6.86955 13Z"
              />
            </svg>
          }
        />
        <div className="responsive-btn">
          <SmallButton
            handleClick={this.onSendMessage}
            icon={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1 2L6.00006 12.5L1 23L23 12.5L1 2ZM19.6307 12L3.11621 4.11806L6.86955 12L19.6307 12ZM6.86955 13L3.11621 20.8819L19.6307 13L6.86955 13Z"
                />
              </svg>
            }
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { communicationReducer } = state
  return {
    communicationReducer,
  }
};

export default connect(mapStateToProps, {
  createComment
})(CreateMessage);