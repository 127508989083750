import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  createProjectTitle,
} from '../../../../constants/titles';
import { withRouter } from 'react-router-dom';

class ProjectHeader extends Component {
  goToStep = (value, languageID) => {
    const { history } = this.props
    const place = this.props.createProjectReducer.step;
    let step = '';
    switch (place) {
      case 'style':
        step = ['style', 'instruction'].includes(value) ? value : '';
        break;
      case 'instruction':
        step = ['style', 'instruction', 'tariffs'].includes(value) ? value : '';
        break;
      case 'tariffs':
        step = ['style', 'instruction', 'tariffs', 'pay'].includes(value)
          ? value
          : '';
        break;
      default:
        break;
    }
    if (step) {
      console.log(languageID)
      history.push(`/${languageID}/create-project/${step}`);
    }
  }

  render() {
    const languageID = this.props.languageID
    return (
      <>
        {createProjectTitle.map((item, key) => (
          <div
            className="not-active projectStep"
            key={key}
            onClick={() => this.goToStep(item.value, languageID)}>
            {item.title}
          </div>
        ))}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { createProjectReducer } = state
  return {
    createProjectReducer,
  }
};

export default connect(mapStateToProps, {

})(withRouter(ProjectHeader));