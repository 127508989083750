import React from 'react';
import PropTypes from 'prop-types';
import yes from '../../assets/yes.svg';
import no from '../../assets/no.svg';
import checked from '../../assets/checked.svg';
import Button from '../Button';

export default function TariffGarden({
  id,
  name,
  amount,
  advantages,
  onClick,
  selectedTariff,
  basicInfoTariffs
}) {
  const getAdvantagesbyType = type => {
    const elements = advantages.filter(
      advantage => advantage.description === type
    );
    return elements.map(item => (
      <div className="item-advantage" key={item.name}>
        {JSON.parse(item.value) ? (
          <img src={yes} alt="yes" />
        ) : (
            <img src={no} alt="no" />
          )}
        <p className={`advantage-name ${JSON.parse(item.value)}`}>
          {item.name}
        </p>
      </div>
    ));
  };

  const isSelect = selectedTariff === id;
  return (
    <div
      className={`tariffWrapper ${isSelect ? 'checked' : ''}  ${name}`}
      onClick={() => onClick(id)}>
      {isSelect && <img className="checked" src={checked} alt="checked" />}
      <div className="item top">
        <p className="item-title">{name}</p>
        <p className="amount">{amount / 100} CHF</p>
        <p className="tax">inkl. MwSt.</p>
      </div>
      <div className="item">
        <p className="item-title">Service</p>
        {getAdvantagesbyType('Service')}
      </div>
      <div className="item bottom">
        <div className="item-container">
          <p className="item-title">Leistungen</p>
          {getAdvantagesbyType('Leistungen')}
        </div>

        {!basicInfoTariffs && (
          <Button
            isDisabledClass={!isSelect}
            text={isSelect ? 'gewählt' : 'Weiter'}
          />
        )}
      </div>
    </div>
  );
}

TariffGarden.propTypes = {
  isSelect: PropTypes.bool,
  onSelect: PropTypes.func,
  name: PropTypes.string,
  amount: PropTypes.number,
};
