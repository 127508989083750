import React from 'react';
import PropTypes from 'prop-types';
import arrow_right from '../../../../assets/small_right.svg';
import arrow_left from '../../../../assets/small_left.svg';
import Pagination from '../../../../shared/Pagination/Pagination';
import moment from 'moment/min/moment-with-locales';
moment.locale('de');
const DesignerTransactionsTable =
  ({ transactions, limit, page, total, totalPages, changePage }) => {
    const header = {
      date: 'Datum',
      projectID: 'ProjektID',
      projectName: 'Projektname',
      comment: 'Kommentar',
      amount: 'Betrag',
      status: 'Status'
    };

    const addZero = (number, maxLength) => {
      number = number.toString();
      return number.length < maxLength
        ? addZero('0' + number, maxLength)
        : number;
    };

    const getComment = group => {
      switch (group) {
        case 'PAYOUT':
          return 'Preisgeld';
        case 'REFAUND':
          return 'Einstellgebühr';
        default:
          return '-'
      }
    }

    const checkStatus = (status) => {
      switch (status) {
        case 'PAID':
          return 'complete';
        case 'CANCELED':
        case 'WAIT':
          return 'pending';
        default:
          break;
      }
    };

    const handlePageChange = ({ selected }) => {
      changePage(selected + 1);
    };

    return (
      <div className="transactionsWrapper-designer">
        <h1>Transaktionen</h1>
        <div className="table-wrapper">
          <ul>
            <li className="tableHeader">
              <span>{header.date}</span>
              <span>{header.projectID}</span>
              <span>{header.projectName}</span>
              <span>{header.comment}</span>
              <span>{header.amount}</span>
              <span>{header.status}</span>
            </li>
            {transactions.map((item, index) => (
              <li key={index} className="table-item">
                <div>
                  <span className="mobile-view">{header.date}</span>
                  <span>
                    {moment(item.creationDate).format('DD MMM YYYY, HH:mm')}
                  </span>
                </div>
                <div>
                  <span className="mobile-view">{header.projectID}</span>
                  <span>#{addZero(item.project._id, 6)}</span>
                </div>
                <div>
                  <span className="mobile-view">{header.projectName}</span>
                  <span>{item.project.name}</span>
                </div>
                <div>
                  <span className="mobile-view">{header.comment}</span>
                  <span>{getComment(item.group)}</span>
                </div>
                <div className="mobile-view amount-block">
                  <span className={`amount`}>
                    {item.amount ? item.amount / 100 + ' ' + (item.currency ? item.currency : 'CHF') : '-'}
                  </span>
                </div>
                <span className={`amount`}>
                  {item.amount ? item.amount / 100 + ' ' + (item.currency ? item.currency : 'CHF') : '-'}
                </span>
                <div>
                  <span className={`status ${checkStatus(item.status)}`}>
                    {checkStatus(item.status)}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
        {total > 5 && (
          <Pagination
            currentPage={page}
            postsPerPage={limit}
            totalPages={totalPages}
            paginate={handlePageChange}
            pageRangeDisplayed={2}
            nextLabel={<img src={arrow_right} alt="arrow_right" />}
            previousLabel={<img src={arrow_left} alt="arrow_left" />}
            marginPagesDisplayed={1}
            breakLabel={'...'}
          />
        )}
      </div>
    );
  }

export default DesignerTransactionsTable;

DesignerTransactionsTable.propTypes = {
  transactions: PropTypes.array,
};
