import errorsString from '../../helpers/validator/errorsString';
import { FIRSTSTEP_CHANGE_BOXES, FIRSTSTEP_CHANGE_REGISTRATION_FIELD, FIRSTSTEP_CLEAR_REGISTRATION, FIRSTSTEP_FORM_LOADING, FIRSTSTEP_FORM_VALIDITY, FIRSTSTEP_REGISTER_BLUR, FIRSTSTEP_REGISTER_FOCUS, FIRSTSTEP_REGISTER_SUCCESS, FIRSTSTEP_SET_ERROR_BE, UNIQUE_FAIL } from '../types';
import { instance } from "../http";

export const setErrorBE = message => dispatch => {
    dispatch({
        type: FIRSTSTEP_SET_ERROR_BE,
        payload: message
    })
};

export const setLoading = status => dispatch => {
    dispatch({
        type: FIRSTSTEP_FORM_LOADING,
        payload: status
    })
};

export const onChange = ({ target }) => dispatch => {
    dispatch({
        type: FIRSTSTEP_CHANGE_REGISTRATION_FIELD,
        payload: target
    })

};

export const changeBoxes = (boxesName, fieldName) => dispatch => {
    dispatch({
        type: FIRSTSTEP_CHANGE_BOXES,
        payload: { boxesName, fieldName }
    })
};

export const onFocus = ({ target }) => dispatch => {
    dispatch({
        type: FIRSTSTEP_REGISTER_FOCUS,
        payload: target
    })
};
export const onBlur = ({ target }) => dispatch => {
    dispatch({
        type: FIRSTSTEP_REGISTER_BLUR,
        payload: target
    })
};

export const goToSecondStep = event => (dispatch, getState) => {
    event.preventDefault();
    dispatch(checkFirstStep())
};

export const checkFirstStep = () => (dispatch, getState) => {
    dispatch(setLoading(true))
    var { fieldsFirstStep, formState } = getState().registrationFirstStepReducer
    Object.keys(fieldsFirstStep).forEach(item => {
        if (item !== 'additional') {
            if (!fieldsFirstStep[item]) {
                formState.errors[item] = errorsString.COMMON_ERRORS.IS_REQ;
            }
        }
    });
    const isAllFieldsValid = Object.values(formState.errors).every(
        field => !field
    );
    if (!isAllFieldsValid) {
        formState.isFormValid = false;
        dispatch({
            type: FIRSTSTEP_FORM_VALIDITY,
            payload: {
                formState,
                fieldsFirstStep
            }
        })
        dispatch(setLoading(false))
        return
    }
    formState.isFormValid = true;
    dispatch({
        type: FIRSTSTEP_FORM_VALIDITY,
        payload: {
            formState,
            fieldsFirstStep
        }
    })
    const { login, email } = fieldsFirstStep
    instance.get('auth/check/exist', {
        params: { login, email },
    })
        .then(response => {
            const isValid = Object.values(response.data).every(
                (value) => value !== 'used'
            );
            console.log(isValid)
            if (!isValid) {
                dispatch({
                    type: UNIQUE_FAIL,
                    payload: response.data
                })
            } else {
                dispatch({
                    type: FIRSTSTEP_REGISTER_SUCCESS,
                })
            }
            dispatch(setLoading(false))
        })
        .catch(error => {
            console.log(error)
        })
};

export const clearFirstStepStore = () => dispatch => {
    dispatch({
        type: FIRSTSTEP_CLEAR_REGISTRATION
    })
}
