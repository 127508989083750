import React, { useEffect, useState } from 'react';
import close from '../../assets/close.svg';
import faqService from '../../services/faqService';
import { useLocation } from 'react-router-dom';

export default ({ onClose, }) => {
    const location = useLocation()
    const [content, setContent] = useState({ title: '', text: '' })
    const pathname = location.pathname
    const languageID = pathname.split('/')[1]
    useEffect(() => {
        (async () => {
            try {
              const responseContent = await faqService.getTermsContent(languageID);
              setContent({
                title: responseContent.data[0].title.rendered,
                text: responseContent.data[0].content.rendered,
              });
            } catch(error) {
                console.error(error)
            }
            
        })()
    }, [])
  return (
    <div className="modal-terms">
      <div className="close" onClick={() => onClose('', false)}>
        <img src={close} alt="close" />
      </div>
      <h2>{content.title}</h2>
      <div
        className="content-container"
        dangerouslySetInnerHTML={{ __html: content.text }}></div>
    </div>
  );
};
