import { combineReducers } from 'redux';

import createProjectReducer from './createProjectReducer';
import tariffReducer from './tariffReducer'
import extraTariffReducer from './extraTariffReducer'
import globalReducer from './globalReducer'
import snackbarReducer from './snackbarReducer'
import projectReducer from './projectReducer.js'
import modalReducer from './modalReducer'
import registrationReducer from './registrationReducer'
import transactionReducer from './transactionReducer'
import registrationCompanyReducer from './registrationCompanyReducer'
import registrationIndividualReducer from './registrationIndividualReducer'
import registrationFirstStepReducer from './registrationFirstStepReducer'
import authReducer from './authReducer'
import profileReducer from './profileReducer'
import additionalInfoProjectReducer from './additionalInfoProjectReducer'
import blogReducer from './blogReducer'
import communicationReducer from './communicationReducer'
import submitConceptReducer from './submitConceptReducer'
import conceptReducer from './conceptReducer'
import updateConceptReducer from './updateConceptReducer'
import uploadDocReducer from './uploadDocReducer'
import forgotPassReducer from './forgotPassReducer'
import voucherCodeReducer from './voucherCodeReducer';

const reducers = combineReducers({
    createProjectReducer,
    tariffReducer,
    extraTariffReducer,
    globalReducer,
    snackbarReducer,
    projectReducer,
    modalReducer,
    registrationReducer,
    transactionReducer,
    registrationCompanyReducer,
    registrationIndividualReducer,
    registrationFirstStepReducer,
    authReducer,
    profileReducer,
    additionalInfoProjectReducer,
    blogReducer,
    communicationReducer,
    submitConceptReducer,
    conceptReducer,
    updateConceptReducer,
    uploadDocReducer,
    forgotPassReducer,
    voucherCodeReducer
});

export default reducers;
