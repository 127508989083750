import React from 'react';
import email from '../../../../../assets/email.svg';

export const CallUs = () => {
  return (
    <div className="callUsContainer">
      <p className="title">Haben Sie Fragen?</p>
      <p className="subTitle">Wir beraten Sie gerne.</p>
      <a href="mailto:frage@faberling.com" >
        <img src={email} alt="email" />
      </a>
      <p className="phone">frage@faberling.com</p>
    </div>
  );
};
