import errorsString from '../../helpers/validator/errorsString';
import { CHANGE_BOXES, CHANGE_REGISTRATION_FIELD, CLEAR_REGISTRATION, FORM_LOADING, FORM_VALIDITY, REGISTER_BLUR, REGISTER_FOCUS, REGISTER_SUCCESS, REGISTRATION_UPDATE_EMAIL, SET_ERROR_BE } from '../types';
import { instance } from "../http";
import { loginAuth } from './authActions'

export const setErrorBE = message => dispatch => {
    dispatch({
        type: SET_ERROR_BE,
        payload: message
    })
};

export const updateEmail = email => dispatch => {
    dispatch({
        type: REGISTRATION_UPDATE_EMAIL,
        payload: email
    })
}

export const setLoading = status => dispatch => {
    dispatch({
        type: FORM_LOADING,
        payload: status
    })
};

export const onChange = ({ target }) => dispatch => {
    dispatch({
        type: CHANGE_REGISTRATION_FIELD,
        payload: target
    })

};

export const changeBoxes = (boxesName, fieldName) => dispatch => {
    dispatch({
        type: CHANGE_BOXES,
        payload: { boxesName, fieldName }
    })
};

export const onFocus = ({ target }) => dispatch => {
    dispatch({
        type: REGISTER_FOCUS,
        payload: target
    })
};
export const onBlur = ({ target }) => dispatch => {
    dispatch({
        type: REGISTER_BLUR,
        payload: target
    })
};

export const onSubmit = event => (dispatch, getState) => {
    event.preventDefault();
    dispatch(checkForm())
};

export const checkForm = () => (dispatch, getState) => {
    var { fields, formState, confirmationBoxes } = getState().registrationReducer
    Object.keys(fields).forEach(item => {
        if (item !== 'additional' && item !== 'phoneNumber') {
            if (!fields[item]) {
                formState.errors[item] = errorsString.COMMON_ERRORS.IS_REQ;
            }
        }
    });
    var formErrors = formState.errors
    delete formErrors.phoneNumber
    const isAllFieldsValid = Object.values(formState.errors).every(
        field => !field
    );
    if (!isAllFieldsValid) {
        formState.isFormValid = false;
        dispatch({
            type: FORM_VALIDITY,
            payload: {
                formState,
                fields
            }
        })
        dispatch(setLoading(false))
        return
    }
    formState.isFormValid = true;
    dispatch({
        type: FORM_VALIDITY,
        payload: {
            formState,
            fields
        }
    })
        const data = {
            ...fields,
            phoneNumber: fields.phoneNumber
                ? fields.phoneNumber
                : undefined,
            additional: {
                companyName: fields.firstName + fields.lastName,
                emailSubscribe: confirmationBoxes.emailSubscribe,
                infoSource: fields.additional.infoSource
                    ? fields.additional.infoSource
                    : undefined,
            },
        };
        dispatch(registration(data, fields))
};

export const registration = (data, fields) => dispatch => {
    dispatch(setLoading(true))
    instance.post('/auth/registration/customer', data)
        .then(response => {
            dispatch({
                type: REGISTER_SUCCESS,
                payload: response.data
            })
            const user = {
                login: response.data.login,
                password: fields.password,
            };
            dispatch(setLoading(false))
            dispatch(loginAuth(user))
        })
        .catch(error => {
            if (error.response.status >= 400 && error.response.status <= 500) {
                dispatch(setErrorBE(error.response.data.message))
            }
            dispatch(setLoading(false))
            throw error;
        })
};

export const clearStore = () => dispatch => {
    dispatch({
        type: CLEAR_REGISTRATION
    })
}
