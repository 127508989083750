import { GET_VOUCHER_CODE, LOGOUT } from '../types'

const initialState = () => {
    return {
        voucherCode: []
    }
}

export default function (state = initialState(), action) {
    const { type, payload } = action;
    switch (type) {
        case GET_VOUCHER_CODE:
            return {
                ...state,
                voucherCode: payload,
                loading: false,
            }        
        case LOGOUT:
            return initialState
        default:
            return state

    }
}