import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import FormInput from '../../../../../../../../../shared/FormInput';
import {
  onChangeSecondStep,
  onBlur,
  onFocus,
} from '../../../../../../../../../redux/actions/registrationCompanyActions'

class PersonData extends Component {
  render() {
    const {
      onChangeSecondStep,
      onBlur,
      onFocus,
    } = this.props
    const {
      fieldsSecondStep,
      formState,
    } = this.props.registrationCompanyReducer;
    return (
      <Fragment>
        <FormInput
          name="lastName"
          label="Vorname"
          error={formState.errors.lastName}
          value={fieldsSecondStep.lastName}
          handleChange={e => onChangeSecondStep(e)}
          handleBlur={e => onBlur(e)}
          handleFocus={e => onFocus(e)}
        />
        <FormInput
          name="firstName"
          label="Nachname"
          error={formState.errors.firstName}
          value={fieldsSecondStep.firstName}
          handleChange={e => onChangeSecondStep(e)}
          handleBlur={e => onBlur(e)}
          handleFocus={e => onFocus(e)}
        />
        <FormInput
          name="personDataDob"
          label="Geburtsdatum"
          placeholder="DD.MM.YYYY"
          error={formState.errors.personDataDob}
          value={fieldsSecondStep.personDataDob}
          handleChange={e => onChangeSecondStep(e)}
          handleBlur={e => onBlur(e)}
          handleFocus={e => onFocus(e)}
        />
        <FormInput
          name="iban"
          label="IBAN"
          placeholder="CH00000000000000000000"
          error={formState.errors.iban}
          value={fieldsSecondStep.iban}
          handleChange={e => onChangeSecondStep(e)}
          handleBlur={e => onBlur(e)}
          handleFocus={e => onFocus(e)}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { registrationCompanyReducer } = state
  return {
    registrationCompanyReducer,
  }
};

export default connect(mapStateToProps, {
  onChangeSecondStep,
  onBlur,
  onFocus,
})(PersonData);
