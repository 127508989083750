import React, { Component } from 'react';
import { connect } from 'react-redux'
import { userRole } from '../../constants/userRole';
import DesignerPage from './components/DesignerPage';
import CustomerPage from './components/CustomerPage';
import AdminPage from './components/AdminPage';

import { clearTransactions, getTransactions, getTransactionsForAdmin } from '../../redux/actions/transactionActions'

class TransactionsPage extends Component {
  componentDidMount() {
    const { getTransactions, getTransactionsForAdmin } = this.props;
    this.props.globalReducer.User.role === userRole.CUSTOMER
      ? getTransactions()
      : getTransactionsForAdmin();
  }

  componentWillUnmount() {
    this.props.clearTransactions();
  }

  components = {
    [userRole.COMPANY]: <DesignerPage />,
    [userRole.CUSTOMER]: <CustomerPage />,
    [userRole.ADMIN]: <AdminPage />,
  };

  render() {
    const {
      globalReducer,
      transactionReducer,
    } = this.props
    const { transactions } = transactionReducer;
    const { User } = globalReducer;
    const { role } = User;
    if (!transactions) return <div>Loading...</div>;

    return <div className="page-wrapper">{this.components[role]}</div>;
  }
}

const mapStateToProps = (state) => {
  const { globalReducer, transactionReducer } = state
  return {
    globalReducer,
    transactionReducer,
  }
};

export default connect(mapStateToProps, {
  clearTransactions, getTransactions, getTransactionsForAdmin 
})(TransactionsPage);
