import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Header from '../shared/Header';
import Footer from '../shared/Footer/Footer';
import email from '../assets/email.svg';
import CommonInfo from '../shared/CommonInfo/CommonInfo';
import CallUsBlock from '../shared/CommonInfo/components/CallUsBlock/CallUsBlock';
import NotFound from '../pages/NotFound';
import FAQPage from '../pages/FAQpage';

class BasicInfoRouter extends Component {
  state = {
    isMobile: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { isMobile } = this.state;
    const { url } = this.props.match;

    return (
      <div className="basic-info-page">
        <Header isMobile={isMobile} />
        <div className="commonInfo">
          <CommonInfo src={email} children={<CallUsBlock />} />
        </div>
        <Switch>
          <Route
            path={`${url}/faq`}
            render={() => (
              <div>
                <FAQPage />
              </div>
            )}
          />
          <Route component={NotFound} />
        </Switch>
        <Footer />
      </div>
    );
  }
}

export default BasicInfoRouter;
