import React from 'react';
import briefing from '../../../assets/briefing.png';

const Roof = React.forwardRef(({ skeletonType, roofType, id }, ref) => {
  return (
    <div className="construction-roof-container" id={id} ref={ref}>
      <div className="construction">
        <h2>Bauweise</h2>
        <div className="card-wrapper">
          <div className="img-container">
            <img src={briefing} alt="construction" />
          </div>
          <p>{skeletonType}</p>
        </div>
      </div>
      <div className="roof-type">
        <h2>Dachtyp</h2>
        <div className="card-wrapper">
          <div className="img-container">
            <img src={briefing} alt="roof-typ" />
          </div>
          <p>{roofType}</p>
        </div>
      </div>
    </div>
  );
});

export default Roof;
