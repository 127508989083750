import React, { Component } from 'react';
import { connect } from 'react-redux'
import FormInput from '../../../../../../../shared/FormInput';
import Button from '../../../../../../../shared/Button';
import Checkbox from '../../../../../../../shared/CheckBox';
import { checkBoxTitle } from '../../../../../../../constants/subscribeTitle';
import {
  onChange,
  onBlur,
  onFocus,
  changeBoxes,
} from '../../../../../../../redux/actions/registrationFirstStepActions'

class FirstStep extends Component {
  render() {
    const {
      onChange,
      onBlur,
      onFocus,
      changeBoxes,
    } = this.props
    const {
      formState,
      fieldsFirstStep,
      confirmationBoxes,
    } = this.props.registrationFirstStepReducer;
    const { onClick } = this.props;
    return (
      <form>
        <FormInput
          name="login"
          label="Benutzername"
          error={formState.errors.login}
          value={fieldsFirstStep.login}
          handleChange={(e) => onChange(e)}
          handleBlur={(e) => onBlur(e)}
          handleFocus={(e) => onFocus(e)}
          hasInfo={true}
          infoBlock="Der gewählte Benutzername wird den Bauherren angezeigt. Sie können auch einen Fantasienamen wählen"
        />
        <FormInput
          name="email"
          label="E-Mail-Adresse"
          error={formState.errors.email}
          value={fieldsFirstStep.email}
          handleChange={(e) => onChange(e)}
          handleBlur={(e) => onBlur(e)}
          handleFocus={(e) => onFocus(e)}
          hasInfo={true}
          infoBlock="Ihre E-Mail-Adresse sieht nur der Administrator von Faberling.ch. Auf der Plattform sind Sie anonym unterwegs."
        />
        <FormInput
          name="password"
          type="password"
          label="Passwort"
          error={formState.errors.password}
          value={fieldsFirstStep.password}
          handleChange={(e) => onChange(e)}
          handleBlur={(e) => onBlur(e)}
          handleFocus={(e) => onFocus(e)}
        />
        <FormInput
          name="infoSource"
          placeholder="Woher kennen Sie uns? (Optional)"
          value={fieldsFirstStep.infoSource}
          handleChange={(e) => onChange(e)}
        />
        <div className="subscribeBox">
          <Checkbox
            value={true}
            onClick={() => changeBoxes('confirmationBoxes', 'rules')}
            checkedField={confirmationBoxes.rules}
            title={checkBoxTitle.agree}
            className="subcsribe"
            isTerms={true}
          />
          <Checkbox
            value={true}
            onClick={() => changeBoxes('confirmationBoxes', 'emailSubscribe')}
            checkedField={confirmationBoxes.emailSubscribe}
            title={checkBoxTitle.subscribe}
            className="subcsribe"
          />
        </div>
        <Button
          handleClick={onClick}
          text="registrieren"
          isDisabled={confirmationBoxes.rules ? false : true}
          isDisabledClass={confirmationBoxes.rules ? false : true}
        />
      </form>
    );
  }
}
const mapStateToProps = (state) => {
  const { registrationFirstStepReducer } = state
  return {
    registrationFirstStepReducer,
  }
};

export default connect(mapStateToProps, {
  onChange,
  onBlur,
  onFocus,
  changeBoxes,
})(FirstStep);
