import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import OfferCard from '../../../shared/OfferCard';
import Pagination from '../../../shared/Pagination';
import arrow_right from '../../../assets/small_right.svg';
import arrow_left from '../../../assets/small_left.svg';
import { setProjectId, clearConcepts, setRating, } from '../../../redux/actions/conceptActions'
const DesignerConcepts = ({ id }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setProjectId(id));
    return () => dispatch(clearConcepts());
  }, [id]);

  const handlePageChange = ({ selected }) => {
    console.log(selected + 1);
  };

  const { concepts, allConcepts } = useSelector(state => state.conceptReducer)
  const { detailedProject } = useSelector(state => state.projectReducer)

  if (!concepts || !allConcepts || !detailedProject)
    return <div>Loading...</div>;

  const { total, limit, page, result, totalPages } = concepts;
  const resultAll = allConcepts.result;
  result.forEach((myConcept) =>
    resultAll.forEach((concept, index) => {
      if (myConcept._id === concept._id) resultAll.splice(index, 1);
    })
  );

  return (
    <div className="concepts">
      <div className="search">
        <div className="search-left-side">
          <span className="title">Insgesamt:</span>
          <span className="element">{allConcepts.total} Konzepte</span>
        </div>
        <div className="search-right-side">
          <span className="title">Sortiere nach:</span>
          <span className="element active">Datum hinzugefügt</span>
          <span className="element">Autor</span>
          <span className="element">Bewertung</span>
        </div>
      </div>
      <div className="offers">
        {result.map((item) => (
          <OfferCard
            rating={item.rating}
            key={item._id}
            description={item.description}
            id={item._id}
            setRating={() => dispatch(setRating())}
            isActiveRating={false}
            account={item.accountId}
            star={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.5057 14.3158L17.5144 20.1971L12.2327 17.4203L12 17.2979L11.7673 17.4203L6.48557 20.1971L7.49427 14.3159L7.53872 14.0567L7.35037 13.8732L3.07489 9.70798L8.98049 8.85019L9.24246 8.81214L9.35825 8.57409L12 3.14308L14.6417 8.57409L14.7575 8.81214L15.0195 8.85019L20.9251 9.70798L16.6496 13.8732L16.4613 14.0566L16.5057 14.3158Z"
                  stroke="black"
                />
              </svg>
            }
            isDesigner={true}
            img={item.thumbnailUrl}
            hasWinner={detailedProject.hasWinner}
            isWinner={detailedProject.winnerIds.find((winner) => {
              if (winner._id === item._id) return true;
              else return false;
            })}
          />
        ))}
        {resultAll.map((item) => (
          <OfferCard
            rating={item.rating}
            key={item._id}
            description={item.description}
            id={item._id}
            setRating={setRating}
            isActiveRating={false}
            star={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.5057 14.3158L17.5144 20.1971L12.2327 17.4203L12 17.2979L11.7673 17.4203L6.48557 20.1971L7.49427 14.3159L7.53872 14.0567L7.35037 13.8732L3.07489 9.70798L8.98049 8.85019L9.24246 8.81214L9.35825 8.57409L12 3.14308L14.6417 8.57409L14.7575 8.81214L15.0195 8.85019L20.9251 9.70798L16.6496 13.8732L16.4613 14.0566L16.5057 14.3158Z"
                  stroke="black"
                />
              </svg>
            }
            isDesigner={true}
            isGray={true}
            account={item.accountId}
            hasWinner={detailedProject.hasWinner}
            isWinner={detailedProject.winnerIds.find((winner) => {
              if (winner._id === item._id) return true;
              else return false;
            })}
          />
        ))}
      </div>
      {total > 12 && (
        <Pagination
          currentPage={page}
          postsPerPage={limit}
          totalPages={totalPages}
          paginate={handlePageChange}
          pageRangeDisplayed={2}
          nextLabel={<img src={arrow_right} alt="arrow_right" />}
          previousLabel={<img src={arrow_left} alt="arrow_left" />}
          marginPagesDisplayed={1}
          breakLabel={'...'}
        />
      )}
    </div>
  );
};

export default DesignerConcepts;
