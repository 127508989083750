import React from 'react';
import PropTypes from 'prop-types';

class Switch extends React.Component {
  render() {
    const { value, onClick } = this.props;

    return (
      <div className="toggle-switch rounded">
        <div className="toggle-switch-wrapper">
          <div
            className={`slider ${value && 'isChecked'}`}
            onClick={() => onClick(!value)}></div>
        </div>
      </div>
    );
  }
}

export default Switch;

Switch.propTypes = {
  value: PropTypes.bool,
  name: PropTypes.string,
};
