import { UPDATE_CONCEPT_FILE_SIZE_VALID, CHANGE_UPDATE_CONCEPT_VALUES, UPDATE_CONCEPT_LOADING, RESET_UPDATE_CONCEPT, UPDATE_CONCEPT_DELETE_FILE, UPDATE_CONCEPT_ERRORS, UPDATE_CONCEPT_FILE_DOWNLOADING, UPDATE_CONCEPT_FOCUS, UPDATE_CONCEPT_LOADING_PERCENTAGE, UPDATE_CONCEPT_SET_FILES, UPDATE_CONCEPT_SET_PREVIEW, UPLOAD_FAIL, LOGOUT, UPDATE_CONCEPT_SUCCESS } from '../types'

function initialState() {
    return {
        files: [],
        success: false,
        isLoading: false,
        errorsFromBE: '',
        isfileValid: {
            isSizeValid: true,
            isFileValid: true,
            download: false,
        },
        loadingPercentage: 0,
    };
}

export default function (state = initialState(), action) {
    const { type, payload } = action;
    switch (type) {
        case UPDATE_CONCEPT_FILE_SIZE_VALID:
            var isfileValid = { ...state.isfileValid }
            isfileValid.isSizeValid = payload.state;
            return {
                ...state,
                isfileValid
            }
        case UPDATE_CONCEPT_FILE_DOWNLOADING:
            var isfileValid = { ...state.isfileValid }
            isfileValid.download = payload.state
            return {
                ...state,
                isfileValid,
                loadingPercentage: 0
            }
        case UPDATE_CONCEPT_LOADING:
            return {
                ...state,
                isLoading: payload
            }
        case UPDATE_CONCEPT_LOADING_PERCENTAGE:
            return {
                ...state,
                loadingPercentage: payload
            }
        case UPDATE_CONCEPT_SET_FILES:
            var isfileValid = { ...state.isfileValid }
            isfileValid.files.download = false
            return {
                ...state,
                files: [...state.files, payload]
            }
        case UPDATE_CONCEPT_SET_PREVIEW:
            var isfileValid = { ...state.isfileValid }
            isfileValid.preview.download = false
            var formFields = { ...state.formFields }
            formFields.preview = {
                name: payload.fileName,
                path: payload.path,
                id: payload._id,
                url: payload.fullURL,
            };
            return {
                ...state,
                files: [...state.files, payload],
                formFields
            }
        case UPLOAD_FAIL:
            var isfileValid = { ...state.isfileValid }
            isfileValid.download = false
            return {
                ...state,
                isfileValid,
                isLoading: false,
            }
        case UPDATE_CONCEPT_FOCUS:
            var errors = { ...state.errors }
            errors.fromBE = ''
            return {
                ...state,
                errors
            }
        case CHANGE_UPDATE_CONCEPT_VALUES:
            var { value } = payload
            var formFields = state.formFields
            formFields.comments = value;
            return {
                ...state,
                formFields
            }
        case UPDATE_CONCEPT_DELETE_FILE:
            var indexFile = state.files.includes(state.files[payload]);
            if (indexFile) {
                var files
                files = [
                    ...state.files.slice(0, payload),
                    ...state.files.slice(payload + 1),
                ];
                return {
                    ...state,
                    files,
                }
            }
            return state
        case UPDATE_CONCEPT_SUCCESS:
            return {
                ...state,
                success: payload ? true : false
            }
        case UPDATE_CONCEPT_ERRORS:
            return {
                ...state,
                errorsFromBE: payload
            }
        case RESET_UPDATE_CONCEPT:
        case LOGOUT:
            return { ...initialState() }
        default:
            return {
                ...state,
            }
    }
}
