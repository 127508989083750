import { ADD_PROJECT_COMMENT, CLEAR_PROJECTS, CLEAR_PROJECT_INFO, GET_PROJECTS, GET_PROJECT_INFO, LOGOUT, POST_PROJECT, POST_PROJECT_FAIL, POST_TRANSACTION, PROJECTS_FAIL, SET_PROJECT_RATING, TRANSACTION_ERROR, UPDATE_PAGE } from '../types'
import { setItem, getItem, removeItem } from '../localStorageActions'

function defaultParams() {
    return {
        status: null,
        total: '',
        page: 1,
        limit: 10,
        sort: 'desc',
        sort_by: '_id',
    }
}

function initialState() {
    return {
        projects: null,
        detailedProject: null,
        createdProjectData: JSON.parse(getItem('createdProjectData')) || {},
        params: defaultParams(),
        redirect: false
    };
}

export default function (state = initialState(), action) {
    const { type, payload } = action;
    switch (type) {
        case POST_PROJECT:
            setItem('createdProjectData', JSON.stringify(payload))
            return {
                ...state,
                createdProjectData: payload,
                redirect: true
            }
        case POST_PROJECT_FAIL:
            return {
                ...state,
                redirect: payload.status === 401 ? true : false
            }
        case GET_PROJECTS:
            return {
                ...state,
                projects: payload,
                redirect: false
            }
        case CLEAR_PROJECTS:
            return {
                ...state,
                projects: null,
                redirect: false
            }
        case UPDATE_PAGE:
            var params = state.params
            params.page = payload
            return {
                ...state,
                params,
                redirect: false
            }
        case ADD_PROJECT_COMMENT:
        case GET_PROJECT_INFO:
            return {
                ...state,
                detailedProject: payload,
                redirect: false
            }
        case CLEAR_PROJECT_INFO:
            removeItem('createdProjectData');
            return {
                ...state,
                detailedProject: null,
                redirect: false
            }
        case POST_TRANSACTION:
            return {
                ...state,
                redirect: true
            }
        case LOGOUT:
            return initialState()
        case SET_PROJECT_RATING:
        case PROJECTS_FAIL:
        default:
            return {
                ...state,
                redirect: false
            }

    }
}
