import React, { Component } from 'react';
import { connect } from 'react-redux'
import { userRole } from '../../constants/userRole';
import CustomerPage from './components/CustomerPage';
import DesignerPage from './components/DesignerPage';

import { handleRequestPage, getProjects, clearProjects } from '../../redux/actions/ProjectActions'

class MyProject extends Component {
  componentDidMount() {
    this.props.getProjects();
  }

  componentWillUnmount() {
    this.props.clearProjects();
  }

  render() {
    const {
      globalReducer,
      projectReducer,
      handleRequestPage,
      location
    } = this.props
    const { projects } = projectReducer;
    const { User } = globalReducer;
    const { role } = User;
    const languageID = location.pathname.split('/')[1]

    if (!projects) return <div>Laden...</div>;

    return (
      <div className="page-wrapper">
        {role === userRole.COMPANY ? (
          <DesignerPage
            projects={projects}
            handleRequestPage={handleRequestPage}
            languageID={languageID}
          />
        ) : (
            <CustomerPage
              projects={projects}
              handleRequestPage={handleRequestPage}
              languageID={languageID}
            />
          )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { globalReducer, projectReducer } = state
  return {
    globalReducer,
    projectReducer,
  }
};

export default connect(mapStateToProps, {
  handleRequestPage, getProjects, clearProjects
})(MyProject);
