export default {
  'Account Not Found': 'Konto wurde nicht gefunden',
  'Authorization Fail': 'Autorisierung fehlgeschlagen',
  "Mime Type Of File Doesn't Supported!":
    'Mime-Typen werden nicht unterstützt.',
  'No Files Were Uploaded. Make Sure You Have Field With Name «file» In Your Request.':
    'Es wurden keine Dateien hochgeladen. Stellen Sie sicher, dass Sie eine Datei ausgewählt haben.',
  'Project Option Not Found.': 'Projektoption nicht gefunden.',
  'Project Option Already Exist.': 'Projektoption existiert bereits.',
  'Project Not Found.': 'Projekt nicht gefunden.',
  'Email Already Exists.': 'E-Mail existiert bereits.',
  'Login Already Exists.': 'Benutzename existiert bereits.',
  'Sorry, The Project Does Not Exist Or Was Closed.':
    'Das Projekt existiert nicht oder wurde geschlossen.',
  'Request Not Found.': 'Anfrage nicht gefunden.',
  'Link Time Has Expired.': 'Der Link ist abgelaufen.',
  'Offer Not Found.': 'Angebot nicht gefunden.',
  'Project Has Status %s.': 'Das Projekt hat den Status %s.',
  'Project Already Paid.': 'Projekt ist bereits bezahlt.',
  'Email Not Found': 'E-Mail wurde nicht gefunden.',
  'Tariff Not Found': 'Tarif wurde nicht gefunden.',
  "You Don't Have Permissions To Update This Offer.":
    'Sie haben nicht die Berechtigung, dieses Angebot zu aktualisieren.',
  "You Don't Have Permissions To Read This Offer.":
    'Sie haben nicht die Berechtigung, dieses Angebot zu lesen.',
  "You Don't Have Permissions To Update This Project.":
    'Sie haben nicht die Berechtigung, dieses Projekt zu aktualisieren.',
  "You Don't Have Permissions To Update This Transaction.":
    'Sie haben nicht die Berechtigung, diese Transaktion zu aktualisieren.',
  "You Don't Have Permissions To Read This Transaction.":
    'Sie haben nicht die Berechtigung, diese Transaktion zu lesen.',
  'Sorry, This Project Is Probably Closed.':
    'Dieses Projekt ist wahrscheinlich geschlossen.',
  'Project Option With Current Name Already Exists':
    'Projektoption mit diesem Namen existiert bereits.',
  'Transaction Already Exist': 'Transaktion bereits vorhanden.',
  'Payout Request Not Found.': 'Auszahlungsantrag nicht gefunden.',
  'Tariff With Current Name Already Exists.':
    'Tarif mit diesem Namen existiert bereits.',
  'Account With Stripeid "%s" Not Found':
    'Konto mit StripeID "%s" nicht gefunden.',
  "You Don't Have Permissions To See Offers From Current Project":
    'Sie haben nicht die Berechtigung, Angebote des aktuellen Projekts zu sehen.',
  'As You Have Not Submitted Any Concept To This Closed Project You Do Not Have The Permission To See The Project Details':
    'Sie haben an diesem bereits beendeten Projekt nicht teilgenommen, daher können Sie keine Details sehen.',
  "You Have No Rights To Update Your Password, Because You Don't Have A Registered Account":
    'Sie haben kein Recht das Passwort zu aktualisieren, da Sie kein registriertes Konto haben.',
  'Old Password Does Not Match': 'Altes Passwort stimmt nicht überein.',
  'Your card number is incorrect.': 'Die Kartennummer ist falsch.',
  'Your card number is not a valid credit card number.':
    'Die Kartennummer ist keine gültige Kreditkartennummer.',
  "Your card's expiration month is invalid.":
    'Der Verfallmonat der Karte ist ungültig.',
  "Your card's expiration year is invalid.":
    'Das Ablaufjahr der Karte ist ungültig.',
  "Your card's security code is invalid.":
    'Der Sicherheitscode der Karte ist ungültig.',
  'Your card has expired.': 'Die Karte ist abgelaufen.',
  "Your card's security code is incorrect.":
    'Der Sicherheitscode der Karte ist falsch.',
  "Your card's zip code failed validation.":
    'Die Postleitzahl der Karte hat die Gültigkeitsprüfung nicht bestanden.',
  'Your card was declined.': 'Die Karte wurde abgelehnt.',
  'Your card has insufficient funds.': 'Die Karte wurde abgelehnt.',
  'Your is no card on a customer that is being charged.':
    'Es gibt keine Karte bei einem Kunden, die belastet wird.',
  'An error occurred while processing the card.':
    'Bei der Bearbeitung der Karte ist ein Fehler aufgetreten.',
  "An error occurred due to requests hitting the API too quickly. Please let us know if you're consistently running into this error.":
    'Ein Fehler ist aufgrund von zu schnell auf die API zugreifenden Anfragen aufgetreten. Bitte lassen Sie uns wissen, wenn Sie immer wieder auf diesen Fehler stoßen.',
  '"number" is required': 'Kartennummer ist erforderlich',
  '"cvc" is required': 'CVV2 ist erforderlich',
  '"exp_month" must be less than or equal to 12':
    'Der Verfallsmonat muss kleiner oder gleich 12 sein.',
  '"exp_month" must be a number':
    'Der Verfallsmonat muss eine Nummer sein',
  '"exp_year" must be larger than or equal to 2021':
    'Das Ablaufdatum muss in der Zukunft liegen.',
  '"exp_year" must be a number':
    'Das Ablaufdatum muss eine Nummer sein',
  '"dob.month" must be less than or equal to 12':
    'Der Monat muss kleiner oder gleich 12 sein.',
  'Invalid Voucher': 'Ungültige Gutschein'
};
