import {
    INDIVIDUAL_SET_ERROR_BE,
    INDIVIDUAL_FORM_LOADING,
    INDIVIDUAL_CHANGE_REGISTRATION_FIELD,
    INDIVIDUAL_CHANGE_BOXES,
    INDIVIDUAL_REGISTER_FOCUS,
    INDIVIDUAL_REGISTER_BLUR,
    INDIVIDUAL_FORM_VALIDITY,
    INDIVIDUAL_REGISTER_SUCCESS,
    INDIVIDUAL_CLEAR_REGISTRATION
} from '../types'
import { instance } from "../http";
import { loginAuth } from './authActions'

export const setErrorBE = message => dispatch => {
    dispatch({
        type: INDIVIDUAL_SET_ERROR_BE,
        payload: message
    })
};

export const setLoading = status => dispatch => {
    dispatch({
        type: INDIVIDUAL_FORM_LOADING,
        payload: status
    })
};

export const onChangeSecondStep = ({ target }) => dispatch => {
    dispatch({
        type: INDIVIDUAL_CHANGE_REGISTRATION_FIELD,
        payload: target
    })
};

export const changeBoxes = (boxesName, fieldName) => dispatch => {
    dispatch({
        type: INDIVIDUAL_CHANGE_BOXES,
        payload: { boxesName, fieldName }
    })
};

export const onFocus = ({ target }) => dispatch => {
    dispatch({
        type: INDIVIDUAL_REGISTER_FOCUS,
        payload: target
    })
};
export const onBlur = ({ target }) => dispatch => {
    dispatch({
        type: INDIVIDUAL_REGISTER_BLUR,
        payload: target
    })
};

export const onSubmitIndividual = event => (dispatch, getState) => {
    event.preventDefault();
    dispatch(checkSecondStep())
};

export const checkSecondStep = () => (dispatch, getState) => {
    var { formState, fieldsSecondStep, fields } = getState().registrationIndividualReducer
    const isAllFieldsValid = Object.values(formState.errors).every(
        field => !field
    );
    if (!isAllFieldsValid) {
        formState.isFormValid = false;
        dispatch({
            type: INDIVIDUAL_FORM_VALIDITY,
            payload: {
                formState,
            }
        })
        return;
    }
    formState.isFormValid = true;
    dispatch({
        type: INDIVIDUAL_FORM_VALIDITY,
        payload: {
            formState,
            fields
        }
    })
    var { fieldsFirstStep, confirmationBoxes } = getState().registrationFirstStepReducer
    const dob = fieldsSecondStep.personDataDob.split('.');
    const data = {
        login: fieldsFirstStep.login,
        password: fieldsFirstStep.password,
        iban: fieldsSecondStep.iban,
        address: {
            country: fieldsSecondStep.iban.substring(0, 2) ? fieldsSecondStep.iban.substring(0, 2) : 'CH',
        },
        dob: {
            day: dob[0],
            month: dob[1],
            year: dob[2],
        },
        firstName: fieldsSecondStep.firstName,
        lastName: fieldsSecondStep.lastName,
        email: fieldsFirstStep.email,
        additional: {
            emailSubscribe:
                confirmationBoxes.emailSubscribe,
            infoSource: fieldsFirstStep.additional
                .infoSource
                ? fieldsFirstStep.additional.infoSource
                : undefined,
        },
        businessType: 'individual',
    };
    dispatch(registration(data, fieldsFirstStep));
}

export const registration = (data, fieldsFirstStep) => dispatch => {
    dispatch(setLoading(true))
    instance.post('/auth/registration/company-alt', data)
        .then(response => {
            dispatch({
                type: INDIVIDUAL_REGISTER_SUCCESS,
                payload: response.data
            })
            const user = {
                login: response.data.login,
                password: fieldsFirstStep.password,
            };
            dispatch(setLoading(false))
            dispatch(loginAuth(user))
        })
        .catch(error => {
            if (error.response.status >= 400 && error.response.status <= 500) {
                dispatch(setErrorBE(error.response.data.message))
            }
            console.log(error)
            dispatch(setLoading(false))
            throw error;
        })
};

export const clearIndividualStore = () => dispatch => {
    dispatch({
        type: INDIVIDUAL_CLEAR_REGISTRATION
    })
}