import { INDIVIDUAL_CHANGE_BOXES, INDIVIDUAL_CHANGE_REGISTRATION_FIELD, INDIVIDUAL_CLEAR_REGISTRATION, INDIVIDUAL_FORM_LOADING, INDIVIDUAL_FORM_VALIDITY, INDIVIDUAL_REGISTER_BLUR, INDIVIDUAL_REGISTER_FOCUS, INDIVIDUAL_REGISTER_SUCCESS, INDIVIDUAL_SET_ERROR_BE } from '../types'
import registrationFields from '../../constants/registrationFields';
import validator from '../../helpers/validator/validation';
import errorsString from '../../helpers/validator/errorsString';

function initialState() {
    return {
        success: false,
        fieldsSecondStep: { ...registrationFields().company_second_step },
        formState: {
            isLoading: false,
            isFormValid: true,
            errors: {
                ...registrationFields().errors_company,
            },
        }
    }
}

export default function (state = initialState(), action) {
    const { type, payload } = action;
    switch (type) {
        case INDIVIDUAL_SET_ERROR_BE:
            var formState = { ...state.formState }
            formState.errors.fromBE = payload;
            return {
                ...state,
                formState
            }
        case INDIVIDUAL_FORM_LOADING:
            var formState = { ...state.formState }
            formState.isLoading = payload;
            return {
                ...state,
                formState
            }
        case INDIVIDUAL_CHANGE_REGISTRATION_FIELD:
            var fieldsSecondStep = { ...state.fieldsSecondStep }
            if (payload.name === 'iban') {
                payload.value = payload.value.replace(/\s/g, '');
                if (payload.value.length < 23) {
                    fieldsSecondStep[payload.name] = payload.value.toUpperCase();
                }
            } else {
                fieldsSecondStep[payload.name] = payload.value
            }
            var formState = { ...state.formState }
            const validatorObj = validator.validate(payload.name, fieldsSecondStep[payload.name]);
            formState.errors[payload.name] = validatorObj.error;
            return {
                ...state,
                fieldsSecondStep,
                formState,
            }
        case INDIVIDUAL_CHANGE_BOXES:
            const { boxesName, fieldName } = payload
            var obj = { ...state[boxesName] }
            obj[fieldName] = !state[boxesName][fieldName];
            return {
                ...state,
                [boxesName]: obj
            }
        case INDIVIDUAL_REGISTER_FOCUS:
            var formState = { ...state.formState }
            formState.errors[payload.name] = '';
            formState.errors.fromBE = '';
            return {
                ...state,
                formState
            }
        case INDIVIDUAL_REGISTER_BLUR:
            var formState = { ...state.formState }
            if (!payload.value) {
                formState.errors[payload.name] = errorsString.COMMON_ERRORS.IS_REQ;
            }
            return {
                ...state,
                formState
            }
        case INDIVIDUAL_FORM_VALIDITY:
            return {
                ...state,
                ...payload
            }
        case INDIVIDUAL_REGISTER_SUCCESS:
            return {
                ...state,
                success: payload ? true : false,
                loading: false
            }
        case INDIVIDUAL_CLEAR_REGISTRATION:
            return initialState()
        default:
            return {
                ...state,
            }
    }
}
