import React, { Component } from 'react';
import PropTypes from 'prop-types';
import checked from '../../../assets/checked.svg';
import HasInfo from '../../InfoIcon/HasInfo';
import info from '../../../assets/info.svg';

class SelectableCard extends Component {
  render() {
    const {
      src,
      path,
      isChangesColor,
      hasInfo,
      title,
      value,
      innerTitle,
      isGarden,
    } = this.props;
    let selected;
    const { fieldName, onChange } = this.context.selectedCardGroup;
    if (Array.isArray(path[fieldName])) {
      selected = path[fieldName].includes(this.props.value);
    } else {
      selected = path[fieldName] === this.props.value;
    }
    const additionalClass = isGarden ? 'garden-img' : '';
    return (
      <div className="cardWrapper" onClick={onChange.bind(this, value)}>
        <div
          className={`imgContainer ${additionalClass} ${
            selected ? 'checked' : ''
          } ${!selected && isChangesColor ? 'gray-layer' : ''} ${
            innerTitle ? 'with-inner-title' : ''
          }`}>
          {hasInfo && <HasInfo className="cardHasInfo" src={info} />}
          <img
            src={src}
            alt="pictures"
            className={`selectable-card-img ${additionalClass}`}
          />
          {innerTitle && <p className="inner-title">{innerTitle}</p>}
          {selected && <img className="checked" src={checked} alt="checked" />}
        </div>
        {title && <p className="title">{title}</p>}
      </div>
    );
  }
}

SelectableCard.contextTypes = {
  selectedCardGroup: PropTypes.object,
};

SelectableCard.propTypes = {
  isSelect: PropTypes.bool,
  src: PropTypes.string,
  onSelect: PropTypes.func,
  isChangesColor: PropTypes.bool,
  hasInfo: PropTypes.bool,
  title: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
};

export default SelectableCard;
