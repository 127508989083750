import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux'
import LeftSide from './components/LeftSide';
import CentralSide from './components/CentralSide/CentralSide';
import RightSide from './components/RightSide/RightSide';
import Additional from './components/Additional';
import MainResponsive from './components/MainResponsive/MainResponsive';
import Menu from '../Menu';
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: false,
    };
  }

  openMenu = () => {
    this.setState({ isShow: true });
  };

  closeMenu = () => {
    this.setState({ isShow: false });
  };

  render() {
    const { globalReducer } = this.props
    const { pathname } = this.props.location;
    const { isMobile } = this.props;

    const { isShow } = this.state;
    const languageID = pathname.split('/')[1]
    const isDetailedProject = pathname.split('/')[3] === 'project';
    const isCreateProject = pathname.split('/')[2] === 'create-project';
    let role = '';
    const { User } = globalReducer;
    if (User) role = User.role;

    return (
      <div className="header">
        <div className="header-main">
          <LeftSide role={role} />
          {!isMobile && <CentralSide role={role} />}
          <RightSide languageID={languageID} />
        </div>
        <div className={`header-main-responsive ${isShow}`}>
          <MainResponsive
            user={User}
            openMenu={this.openMenu}
            isShow={isShow}
            closeMenu={this.closeMenu}
          />
        </div>
        <div className={`header-menu-responsive ${isShow}`}>
          <Menu user={User} isShow={isShow} role={role} languageID={languageID}/>
        </div>
        <div className='header-navigation-responsive'>
          {isCreateProject && <CentralSide role={role} />}
        </div>
        {isDetailedProject && <Additional role={role} pathname={pathname} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { globalReducer } = state
  return {
    globalReducer,
  }
};

export default connect(mapStateToProps, {

})(withRouter(Header));