import React from 'react';
import PropTypes from 'prop-types';

const ConceptFile = ({ name, type, link, icon, date, size }) => {
  return (
    <div className="concept-demo">
      <span className="type">{type && type}</span>
      <section className="file">
        <span>{name}</span>
      </section>
      <section className="file-data">{size && <span>{size} KB</span>}</section>
      <section className="file-data">{date && <span>{date}</span>}</section>
      <section className="icon">
        {link ? (
          <a href={link} target="_blank" rel="noopener noreferrer">
            <img src={icon} alt="icon" />
          </a>
        ) : (
          <img src={icon} alt="icon" />
        )}
      </section>
    </div>
  );
}

ConceptFile.propTypes = {
  link: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
};

export default ConceptFile;
