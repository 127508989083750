import React from 'react';
import PropTypes from 'prop-types';
import visa from '../../../../assets/visa.svg';
import master from '../../../../assets/mastercard.svg';
import arrow_right from '../../../../assets/small_right.svg';
import arrow_left from '../../../../assets/small_left.svg';
import Pagination from '../../../../shared/Pagination/Pagination';
import moment from 'moment/min/moment-with-locales';
moment.locale('de');
const CustomerTransactionsTable =
  ({ transactions, limit, page, total, totalPages, changePage }) => {
    const header = {
      date: 'Datum',
      paymentInfo: 'Beschreibung',
      projectID: 'ProjektID',
      projectName: 'Projektname',
      comment: 'Kommentar',
      amount: 'Betrag',
      status: 'Status',
    };

    const addZero = (number, maxLength) => {
      number = number.toString();
      return number.length < maxLength
        ? addZero('0' + number, maxLength)
        : number;
    };

    const paymentMethod = (brand, last4) => {
      if (brand === 'visa' || brand === 'mastercard') {
        switch (brand) {
          case 'visa':
            return (
              <span className="payment-method">
                <img src={visa} alt="Visa" /> •••• •••• •••• {last4}
              </span>
            );
          default:
            return (
              <span className="payment-method">
                <img src={master} alt="mastercard" /> •••• •••• •••• {last4}
              </span>
            );
        }
      } else {
        return;
      }
    };

    const checkStatus = status => {
      switch (status) {
        case 'requires_action':
        case 'requires_payment_method':
        case 'requires_capture':
          return 'pending';
        case 'succeeded':
          return 'complete';
        default:
          break;
      }
    };

    const handlePageChange = ({ selected }) => {
      changePage(selected + 1);
    };

    return (
      <div className="transactionsWrapper">
        <h1>Transaktionen</h1>
        <div className="table-wrapper">
          <ul>
            <li className="tableHeader">
              <span>{header.date}</span>
              <span>{header.paymentInfo}</span>
              <span>{header.projectID}</span>
              <span>{header.projectName}</span>
              <span>{header.comment}</span>
              <span>{header.amount}</span>
              <span>{header.status}</span>
            </li>
            {transactions.map((item, index) => (
              <li key={index} className="table-item">
                <div>
                  <span className="mobile-view">{header.date}</span>
                  <span>
                    {moment(item.creationDate).format('DD MMM YYYY, HH:mm')}
                  </span>
                </div>
                <div>
                  <span className="mobile-view">{header.paymentInfo}</span>
                  {item.paymentMethod
                    ? paymentMethod(
                      item.paymentMethod.card.brand,
                      item.paymentMethod.card.last4
                    )
                    : '-'}
                </div>
                <div>
                  <span className="mobile-view">{header.projectID}</span>
                  <span>#{addZero(item.projectId._id, 6)}</span>
                </div>
                <div>
                  <span className="mobile-view">{header.projectName}</span>
                  <span>{item.projectId.name}</span>
                </div>
                <div>
                  <span className="mobile-view">{header.comment}</span>
                  <span>{item.description}</span>
                </div>
                <div className="mobile-view amount-block">
                  <span
                    className={`amount ${item.type === 'PAYMENT' ? ' ' : 'profit'
                      }`}>
                    {item.type === 'PAYMENT' ? '- ' : '+ '}
                    {item.amount ? item.amount / 100 + ' ' + (item.currency ? item.currency : 'CHF') : '-'}
                  </span>
                  <span
                    className={`status ${checkStatus(
                      item.status.toLowerCase()
                    )}`}>
                    {checkStatus(item.status.toLowerCase())}
                  </span>
                </div>
                <span
                  className={`amount ${item.type === 'PAYMENT' ? ' ' : 'profit'
                    }`}>
                  {item.type === 'PAYMENT' ? '- ' : '+ '}
                  {item.amount ? item.amount / 100 + ' ' + (item.currency ? item.currency : 'CHF') : '-'}
                </span>
                <span
                  className={`status ${checkStatus(
                    item.status.toLowerCase()
                  )}`}>
                  {checkStatus(item.status.toLowerCase())}
                </span>
              </li>
            ))}
          </ul>
        </div>
        {total > 5 && (
          <Pagination
            currentPage={page}
            postsPerPage={limit}
            totalPages={totalPages}
            paginate={handlePageChange}
            pageRangeDisplayed={2}
            nextLabel={<img src={arrow_right} alt="arrow_right" />}
            previousLabel={<img src={arrow_left} alt="arrow_left" />}
            marginPagesDisplayed={1}
            breakLabel={'...'}
          />
        )}
      </div>
    );
  }

export default CustomerTransactionsTable;

CustomerTransactionsTable.propTypes = {
  transactions: PropTypes.array,
};
