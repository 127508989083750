import React, { Component } from 'react';
import { connect } from 'react-redux'
import FormInput from '../../../../shared/FormInput';

import { onProfileChange } from '../../../../redux/actions/profileActions'

class ProfileDetails extends Component {
  render() {
    const {
      onProfileChange,
      profileReducer
    } = this.props
    const { fields, formState } = profileReducer;

    return (
      <div>
        <p className="main-title">Profil</p>
        <form className="accauntForm">
          <FormInput
            name="login"
            label="Benutzername"
            hasInfo={true}
            infoBlock="Benutzername"
            value={fields.login}
            error={formState.errors.login}
            disabled={true}
            handleChange={e => onProfileChange(e)}
          />
          <FormInput
            name="email"
            label="E-Mail-Adresse"
            hasInfo={true}
            infoBlock="E-Mail-Adresse"
            value={fields.email}
            error={formState.errors.email}
            handleChange={e => onProfileChange(e)}
          />
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    profileReducer
  } = state
  return {
    profileReducer
  }
};

export default connect(mapStateToProps, {
  onProfileChange,
})(ProfileDetails);