import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import arrow_right from '../../../../assets/arrow_right.svg';
import Pagination from '../../../../shared/Pagination/Pagination';
import small_right from '../../../../assets/small_right.svg';
import small_left from '../../../../assets/small_left.svg';
import Rating from '../../../../shared/Rating';
import moment from 'moment/min/moment-with-locales';
import { setRating } from '../../../../redux/actions/ProjectActions'
moment.locale('de');
const DesignerTable =
  ({ projects, changePage, page, limit, totalPages, total, languageID }) => {
    const header = {
      projectID: 'ProjektID',
      title: 'Projekttitel',
      category: 'Kategorie',
      status: 'Status',
      startDate: 'Gestartet',
      endDate: 'Ende',
      concept: 'Konzepte',
      interest: 'Interesse',
      prize: 'Preisgeld',
      location: 'Standort'
    };

    const addZero = (number, maxLength) => {
      number = number.toString();
      return number.length < maxLength
        ? addZero('0' + number, maxLength)
        : number;
    };

    const handlePageChange = ({ selected }) => {
      changePage(selected + 1);
    };

    console.log(projects)

    const checkCategory = category => {
      switch (category) {
        case 'BUILDING':
          return 'Neubau';
        case 'RENOVATION':
          return 'Anbau & Aufbau';
        case 'GARDEN':
          return 'Garten- & Landschaftsbau';
        case 'SPECIAL':
          return 'Spezialprojekt';
        default:
          break;
      }
    };
    const dispatch = useDispatch()
    return (
      <div className="projectWrapper">
        <h1>Projekt finden</h1>
        <div className="table-wrapper">
          <ul>
            <li className="tableHeader">
              <span></span>
              <span>{header.startDate}</span>
              <span>{header.endDate}</span>
              <span>{header.location}</span>
              <span>{header.category}</span>
              <span>{header.projectID}</span>
              <span>{header.title}</span>
              <span>{header.interest}</span>
              <span>{header.prize}</span>
              {/* <span>{header.concept}</span> */}
            </li>
            {projects.map(item => (
              <li key={item._id} className="table-item">
                <span className="favorite-img">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 8.90613L11.1214 7.28938C10.862 6.81216 10.4378 6.18982 9.82843 5.7153L10.4428 4.9263C10.8187 5.21904 11.1323 5.55156 11.389 5.87901C11.649 6.21072 11.8507 6.53723 12 6.81189C12.1493 6.53724 12.351 6.21073 12.611 5.87902C12.8677 5.55156 13.1813 5.21903 13.5572 4.9263C14.3465 4.31165 15.242 4 16.2188 4C18.9445 4 21 6.24485 21 9.22173C21 12.4378 18.4356 14.6382 14.5539 17.9689C13.8942 18.5349 13.147 19.1761 12.3703 19.8601C12.2679 19.9503 12.1364 20 12 20C11.8636 20 11.7321 19.9503 11.6297 19.86C10.8533 19.1764 10.1062 18.5353 9.44713 17.9698L9.44657 17.9693C5.5644 14.6382 3 12.4378 3 9.22173C3 6.24485 5.05552 4 7.78125 4C8.758 4 9.6535 4.31165 10.4428 4.9263L9.82843 5.7153C9.21012 5.23382 8.52967 5 7.78125 5C5.68892 5 4 6.71236 4 9.22173C4 10.5243 4.50577 11.6609 5.53957 12.9169C6.59873 14.2037 8.13372 15.5251 10.0978 17.2104L10.0978 17.2104L10.1001 17.2123C10.6763 17.7068 11.3236 18.2622 12 18.8544C12.677 18.2617 13.3248 17.7059 13.9016 17.2109L13.9027 17.21L13.9027 17.21C15.8665 15.525 17.4014 14.2036 18.4605 12.9168C19.4942 11.6609 20 10.5243 20 9.22173C20 6.71236 18.3111 5 16.2188 5C15.4703 5 14.7899 5.23382 14.1716 5.7153C13.5622 6.18982 13.138 6.81218 12.8786 7.28941L12 8.90613ZM11.7111 19.1081C11.7104 19.1087 11.7097 19.1093 11.7091 19.1099L11.7094 19.1096C11.71 19.1091 11.7105 19.1086 11.7111 19.1081Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <div className="order-fourth">
                  <span className="mobile-view">{header.startDate}</span>
                  <span>{moment(item.startDate).format('DD. MMM YYYY')}</span>
                </div>
                <div className="order-fifth">
                  <span className="mobile-view">{header.endDate}</span>
                  <span>
                    {moment(item.endDate).format('DD. MMM YYYY, HH:mm')}
                  </span>
                </div>
                <div className="order-seventh">
                  <span className="mobile-view">{header.location}</span>
                  <span>{item.currency === 'CHF' ? 'Schweiz' : 'Deutschland'}</span>
                </div>
                <div className="order-second">
                  <span className="mobile-view">{header.category}</span>
                  <span className="bold">{checkCategory(item.category)}</span>
                </div>
                <div className="order-first">
                  <span className="mobile-view">{header.projectID}</span>
                  <span>#{addZero(item._id, 6)}</span>
                </div>
                <div className="order-third">
                  <span className="mobile-view">{header.title}</span>
                  <span>{item.name}</span>
                </div>
                <span className="rating">
                  <Rating
                    value={item.rating}
                    isActive={true}
                    star={
                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14.339 11.9443L15.151 16.6789L10.899 14.4435L10.6663 14.3211L10.4337 14.4435L6.18166 16.6789L6.9937 11.9443L7.03815 11.6851L6.8498 11.5016L3.4079 8.14849L8.16206 7.45795L8.42403 7.4199L8.53982 7.18185L10.6663 2.81007L12.7929 7.18185L12.9087 7.4199L13.1706 7.45795L17.9248 8.14849L14.4829 11.5016L14.2945 11.6851L14.339 11.9443Z"
                          stroke="black"
                        />
                      </svg>
                    }
                    setRating={rating => dispatch(setRating(item._id, rating))}
                  />
                </span>
                <div className="order-sixth">
                  <span className="mobile-view">{header.prize}</span>
                  <span className="bold">{item.tariffId.amount / 100} {item.currency}</span>
                </div>
                <Link to={`/${languageID}/profile/project/${item._id}/briefing`}>
                  <span className="img">
                    <img src={arrow_right} alt="arrow_right" />
                  </span>
                </Link>
                <div className="mobile-view amount-block">
                  <span className="favorite-img">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 8.90613L11.1214 7.28938C10.862 6.81216 10.4378 6.18982 9.82843 5.7153L10.4428 4.9263C10.8187 5.21904 11.1323 5.55156 11.389 5.87901C11.649 6.21072 11.8507 6.53723 12 6.81189C12.1493 6.53724 12.351 6.21073 12.611 5.87902C12.8677 5.55156 13.1813 5.21903 13.5572 4.9263C14.3465 4.31165 15.242 4 16.2188 4C18.9445 4 21 6.24485 21 9.22173C21 12.4378 18.4356 14.6382 14.5539 17.9689C13.8942 18.5349 13.147 19.1761 12.3703 19.8601C12.2679 19.9503 12.1364 20 12 20C11.8636 20 11.7321 19.9503 11.6297 19.86C10.8533 19.1764 10.1062 18.5353 9.44713 17.9698L9.44657 17.9693C5.5644 14.6382 3 12.4378 3 9.22173C3 6.24485 5.05552 4 7.78125 4C8.758 4 9.6535 4.31165 10.4428 4.9263L9.82843 5.7153C9.21012 5.23382 8.52967 5 7.78125 5C5.68892 5 4 6.71236 4 9.22173C4 10.5243 4.50577 11.6609 5.53957 12.9169C6.59873 14.2037 8.13372 15.5251 10.0978 17.2104L10.0978 17.2104L10.1001 17.2123C10.6763 17.7068 11.3236 18.2622 12 18.8544C12.677 18.2617 13.3248 17.7059 13.9016 17.2109L13.9027 17.21L13.9027 17.21C15.8665 15.525 17.4014 14.2036 18.4605 12.9168C19.4942 11.6609 20 10.5243 20 9.22173C20 6.71236 18.3111 5 16.2188 5C15.4703 5 14.7899 5.23382 14.1716 5.7153C13.5622 6.18982 13.138 6.81218 12.8786 7.28941L12 8.90613ZM11.7111 19.1081C11.7104 19.1087 11.7097 19.1093 11.7091 19.1099L11.7094 19.1096C11.71 19.1091 11.7105 19.1086 11.7111 19.1081Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <span className="rating">
                    <Rating
                      value={item.rating}
                      isActive={true}
                      star={
                        <svg
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M14.339 11.9443L15.151 16.6789L10.899 14.4435L10.6663 14.3211L10.4337 14.4435L6.18166 16.6789L6.9937 11.9443L7.03815 11.6851L6.8498 11.5016L3.4079 8.14849L8.16206 7.45795L8.42403 7.4199L8.53982 7.18185L10.6663 2.81007L12.7929 7.18185L12.9087 7.4199L13.1706 7.45795L17.9248 8.14849L14.4829 11.5016L14.2945 11.6851L14.339 11.9443Z"
                            stroke="black"
                          />
                        </svg>
                      }
                      setRating={rating => dispatch(setRating(item._id, rating))}
                    />
                  </span>
                  <Link to={`/${languageID}/profile/project/${item._id}/briefing`}>
                    <span className="img">
                      <img src={arrow_right} alt="arrow_right" />
                    </span>
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        </div>
        {total > 10 && (
          <Pagination
            currentPage={page}
            postsPerPage={limit}
            totalPages={totalPages}
            paginate={handlePageChange}
            pageRangeDisplayed={2}
            nextLabel={<img src={small_right} alt="arrow_right" />}
            previousLabel={<img src={small_left} alt="arrow_left" />}
            marginPagesDisplayed={1}
            breakLabel={'...'}
          />
        )}
      </div>
    );
  }

export default DesignerTable;

DesignerTable.propTypes = {
  projects: PropTypes.array,
};
