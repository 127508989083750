import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import DesktopViewRoute from '../shared/DesktopViewRoute';
import NotFound from '../pages/NotFound';
import MyProject from '../pages/MyProjects/MyProjects';
import ProfilePage from '../pages/ProfilePage/ProfilePage';
import TransactionsPage from '../pages/TransactionsPage';
import FAQPage from '../pages/FAQpage';
import DetailedInfoProjectRouter from '../router/DetailedInfoProjectRouter';

class ProfileRouter extends Component {
  render() {
    const { url } = this.props.match;
    return (
      <div className="profile">
        <Switch>
          <Route path={`${url}/my-projects`} exact component={MyProject} />
          <Route
            path={`${url}/project/:id`}
            render={props => <DetailedInfoProjectRouter {...props} />}
          />
          <Route
            path={`${url}/transactions`}
            exact
            component={TransactionsPage}
          />
          <DesktopViewRoute 
            exact 
            path={`${url}/faq`} 
            component={FAQPage} 
            isMobile={window.innerWidth<769} 
            url={'/basic-info'} 
          />
          <Route path={`${url}/me`} exact component={ProfilePage} />
          <Route component={NotFound} />
        </Switch>
      </div>
    );
  }
}

export default ProfileRouter;
