import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { basicInfoTitles } from '../../../../constants/titles';
import LoginIcon from '../../../../assets/login.svg';
import resolvePath from '../../../../helpers/validator/resolvePath';
const NotAuthUser = ({ location }) => {
  
  return (
    <Fragment>
      {basicInfoTitles.map(item => (
        <div key={item.value} className="title">
          <a href={`${process.env.REACT_APP_WP_URL + resolvePath(item.value)}`}>{item.title}</a>
        </div>
      ))}
      <div className="menu-footer">
        <div className="sign-in">
          <Link
            to={{
              pathname: resolvePath('/login'),
              state: { prevPath: location.pathname },
            }}>
            <p>Einloggen</p>
            <img src={LoginIcon} alt="LoginIcon" />
          </Link>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(NotAuthUser);
