import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

export default function SingleRangeSlider({
    min,
    max,
    defaultValue, //int
    onChange,
    location
}) {
    const { pathname } = location
    const languageID = pathname.split('/')[1]
    const currencyFilter = {
        ch: 'CHF',
        de: 'EUR',
    }
    // console.log(languageID)

    return (
        <section className="page-block roof-type">
            <h2>Wie hoch ist Ihr Kostenrahmen (ohne Grundstück)?</h2>
            <div className="selectable-blocks-item column-direction livingSpace">
                <div className="selected-double-range">
                    <div className="selected-double-range-min">{defaultValue == 50000
                        ? `< ${currencyFilter[languageID]} ${defaultValue.toLocaleString('de-CH')}`
                        : defaultValue == 5000000
                            ? `> ${currencyFilter[languageID]} ${defaultValue.toLocaleString('de-CH')}`
                            : `${currencyFilter[languageID]} ${defaultValue.toLocaleString('de-CH')}`}</div>
                </div>

                <div className="single-slider-wrapper">
                    <Slider
                        min={min}
                        max={max}
                        defaultValue={defaultValue}
                        step={50000}
                        onChange={(value) => {
                            return onChange(value);
                        }}
                    />
                </div>
            </div>
        </section>
    );
};