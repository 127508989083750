import { CHANGE_FILE, LOGOUT, RESET_UPLOAD_DOC, UPDATE_STRIPE_ID, UPLOAD_DOC_FILE_DOWNLOADING, UPLOAD_DOC_FILE_SIZE_VALID } from '../types'

function initialState() {
    return {
        stripeId: {
            identity_document: '',
            dispute_evidence: '',
        },
        success: false,
        isDocValid: {
            identity_document: {
                isSizeValid: true,
                isFileValid: true,
                download: false,
            },
            dispute_evidence: {
                isSizeValid: true,
                isFileValid: true,
                download: false,
            },
        },
    };
}

export default function (state = initialState(), action) {
    const { type, payload } = action;
    switch (type) {
        case UPDATE_STRIPE_ID:
            return {
                ...state,
                stripeIdL: { ...state.stripeId, [payload.name]: payload.id }
            }
        case UPLOAD_DOC_FILE_DOWNLOADING:
            var isDocValid = { ...state.isDocValid }
            isDocValid[payload.name].download = payload.state
            return {
                ...state,
                isDocValid,
            }
        case UPLOAD_DOC_FILE_SIZE_VALID:
            var isDocValid = { ...state.isDocValid }
            isDocValid[payload.name].isSizeValid = payload.state;
            return {
                ...state,
                isDocValid
            }
        case CHANGE_FILE:
            var stripeId = state.stripeId
            stripeId[payload] = ''
            return {
                ...state,
                stripeId
            }
        case RESET_UPLOAD_DOC:
        case LOGOUT:
            return initialState()
        default:
            return {
                ...state,
            }
    }
}
