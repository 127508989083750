import React from 'react';
import PropTypes from 'prop-types';
import info from '../../assets/info_black.svg';
import HasInfo from '../InfoIcon/HasInfo';
export default function ListItemContainer({
  title,
  children,
  classname,
  comment,
  imgSrc = [],
  onClick = () => {},
  titleHasInfo,
  titleInfoBlock,
  titleClassName
}) {
  let titleBlock;
  let commentBlock;

  if (comment) {
    commentBlock = <div className="comment">{comment}</div>;
  }
  if (title) {
    titleBlock = <div className={`title ${titleClassName}`}>{title} {titleHasInfo && (
      <HasInfo
        className="checkBoxHasInfo"
        src={info}
        infoBlock={titleInfoBlock}
      />
    )}
    </div>;
  }
  return (
    <div className={`list-item-container ${classname}`} onClick={onClick}>
      {titleBlock}
      {imgSrc.length > 0 && (
        <div className="images">
          {imgSrc.map((item, i) => (
            <img src={item.src} alt={item.title} key={i} />
          ))}
        </div>
      )}
      {commentBlock}
      <div className="children">{children}</div>
    </div>
  );
}

ListItemContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
  classname: PropTypes.string,
};
