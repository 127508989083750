import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../Button';
import mastercard from '../../../../assets/mastercard.svg';
import visa from '../../../../assets/visa.svg';
import instagram from '../../../../assets/instagram.svg';
import facebook from '../../../../assets/facebook.svg';
import { pages } from '../../../../constants/basicInfo';
import resolvePath from '../../../../helpers/validator/resolvePath';

const BasicFooter = ({pathname}) => {
  var languageID = pathname.split('/')[1]
  return null
  return (
    <footer className="basicFooter">
      <div className="column left">
        <div className="contactTitle item">
          <p>Kontaktieren Sie uns</p>
        </div>
        <div className="contactDetails item">
          <a href="tel:0800777078">0800 77 70 78</a>
        </div>
        <div className="contactDetails item">
          <a href="mailto:frage@faberling.com">frage@faberling.com</a>
        </div>
        <div className="social-links-container">
          <a href="https://www.instagram.com/faber.ling/">
            <img src={instagram} alt="instagram" />
          </a>
          <a href="https://www.facebook.com/faberling.ch">
            <img src={facebook} alt="facebook" />
          </a>
        </div>
        <a className="btnFooter" href="mailto:frage@faberling.com">
          <Button isDisabled={false} text="SCHREIBEN SIE UNS" />
        </a>
      </div>
      <div className="center">
        <div className="nav">
          <div className="item">
            <ul>
              <a href={`${process.env.REACT_APP_WP_URL}${resolvePath('')}`}>Home</a>
              <a href={`${process.env.REACT_APP_WP_URL}${resolvePath('/ueber-faberling')}`}>Über Faberling</a>
              <a href={`${process.env.REACT_APP_WP_URL}${resolvePath('/haeufige-fragen')}`}>Häufige Fragen</a>
              <a href={`${process.env.REACT_APP_WP_URL}${resolvePath('/impressum')}`}>Impressum</a>
            </ul>
          </div>
          <div className="item">
            <ul>
              <a href={`${process.env.REACT_APP_WP_URL}${resolvePath('/so-funktionierts')}`}>
                {pages.process.TITLE}
              </a>
              <a href={`${process.env.REACT_APP_WP_URL}${resolvePath('/preise')}`}>{pages.price.TITLE}</a>
              <a href={`${process.env.REACT_APP_WP_URL}${resolvePath('/als-architekt-auftraege-erhalten')}`}>Architekten</a>
              <a href={`${process.env.REACT_APP_WP_URL}${resolvePath('/blog')}`}>{pages.blog.TITLE}</a>
            </ul>
          </div>
        </div>
        <div className="links">
          <Link to={`/${languageID}/create-project/style`}>
            <span className="footerLink">Projekt starten</span>
          </Link>
          <a href={`${process.env.REACT_APP_WP_URL}${resolvePath('/als-architekt-auftraege-erhalten')}`}>
            <span className="footerLink">ALS ARCHITEKT BEWERBEN</span>
          </a>
        </div>
      </div>
      <div className="column right">
        <div className="paymentSystem item">
          {window.innerWidth > 768 && <p>Unsere Zahlungsarten:</p>}
          <div className="paymentImg">
            <img src={mastercard} alt="mastercard" />
            <img src={visa} alt="visa" />
          </div>
        </div>
        <p className="date item">
          {`© ` + new Date().getFullYear() + ` Faberling`}
        </p>
      </div>
    </footer>
  );
};

export default BasicFooter;
