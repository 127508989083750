export const defaultValuesCreateProject = (CategoryType) => {
  switch (CategoryType) {
    case 'BUILDING-Einfamilienhaus':
      return {
        category: 'BUILDING',
        type: 'Einfamilienhaus',
        style: [],
        details: {
          numOfSquares: {
            start: 40,
            end: 500,
          },
          hasAtrium: false,
          hasGallery: false,
          numOfFloors: 0,
          numOfRooms: 0,
          numOfBathrooms: 0,
          numOfGuestWCBat: 0,
          numOfParkings: 0,
          kitchenType: 'CLOSED',
          roomDesign: 'CLOSED',
          hasUndergroundParking: false,
          hasBasement: false,
          hasTerrace: false,
          hasWinterGarden: false,
          skeletonType: 'Massivbau',
          roofType: 'Pultdach/ Scheddach',
          requirementsForArchitect: [{
            value: ''
          }],
          budget: 2000000,
          projectDuration: 30
        },
        email: '',
        name: '',
        projectOptions: [2],
        paymentProcessing: 'IS_PRICE_FUND',
        // tariffId: 8,
        tariffId: 18,
        extra: [],
      }

    case 'BUILDING-Mehrfamilienhaus':
      return {
        category: 'BUILDING',
        type: 'Mehrfamilienhaus',
        style: [],
        details: {
          numOfSquares: {
            start: 40,
            end: 500,
          },
          hasAtrium: false,
          hasGallery: false,
          numberOfApartments: 0, //new field
          numOfFloors: 0,
          numOfParkings: 0,
          hasUndergroundParking: false,
          hasBasement: false,
          hasTerrace: false,
          hasWinterGarden: false,
          kitchenType: 'CLOSED',
          roomDesign: 'CLOSED',
          skeletonType: 'Massivbau',
          roofType: 'Pultdach/ Scheddach',
          requirementsForArchitect: [{
            value: ''
          }],
          budget: 2000000,
          roomApartmentDetails: {
            0: { size: null, number: 0 },   // 1.5
            1: { size: null, number: 0 },   // 2.5
            2: { size: null, number: 0 },   // 3.5
            3: { size: null, number: 0 },   // 4.5
            4: { size: null, number: 0 },   // 5.5
            5: { size: null, number: 0 },   // 6.5
            6: { size: null, number: 0 }  // 7.5
          }
        },
        email: '',
        name: '',
        projectOptions: [2],
        paymentProcessing: 'IS_PRICE_FUND',
        tariffId: 0,
        extra: []
      }

    case 'RENOVATION-Einfamilienhaus':
      return {
        category: 'RENOVATION',
        type: 'Einfamilienhaus',
        style: [],
        details: {
          constructionType: ["Umbau"], //new field
          annotations: '', //new field
          area: '', //new field
          hasAtrium: false,
          hasGallery: false,
          hasTerrace: false,
          hasWinterGarden: false,
          skeletonType: 'Massivbau',
          roofType: 'Pultdach/ Scheddach',
          requirementsForArchitect: [{
            value: ''
          }],
          files: [], //new field
          budget: 2000000,
          projectDuration: 30
        },
        email: '',
        name: '',
        projectOptions: [2],
        paymentProcessing: 'IS_PRICE_FUND',
        // tariffId: 8,
        tariffId: 18,
        extra: [2,3],
      }

    case 'RENOVATION-Mehrfamilienhaus':
      return {
        category: 'RENOVATION',
        type: 'Mehrfamilienhaus',
        style: [],
        details: {
          constructionType: ["Umbau"], //new field
          annotations: '', //new field
          area: '', //new field
          hasAtrium: false,
          hasGallery: false,
          hasTerrace: false,
          hasWinterGarden: false,
          kitchenType: 'CLOSED',
          roomDesign: 'CLOSED',
          skeletonType: 'Massivbau',
          roofType: 'Pultdach/ Scheddach',
          requirementsForArchitect: [{
            value: ''
          }],
          files: [], //new field
          budget: 2000000
        },
        email: '',
        name: '',
        projectOptions: [2],
        paymentProcessing: 'IS_PRICE_FUND',
        tariffId: 0,
        extra: []
      }

    case 'GARDEN-Garten- & Landschaftsbau':
      return {
        category: 'GARDEN',
        type: 'Garten- & Landschaftsbau',
        style: [],
        details: {
          requirements: [{
            value: ''
          }], //new field
          files: [], //new field
          budget: 2000000,
          projectDuration: 30
        },
        email: '',
        name: '',
        projectOptions: [2],
        paymentProcessing: 'IS_PRICE_FUND',
        tariffId: 4,
        extra: [],
      }

    case 'SPECIAL-Spezialprojekt':
      return {
        category: 'SPECIAL',
        type: 'Spezialprojekt',
        details: {
          requirements: [{
            value: ''
          }], //new field
          files: [], //new field
          budget: 2000000,
          projectDuration: 30
        },
        email: '',
        name: '',
        projectOptions: [2],
        paymentProcessing: 'IS_PRICE_FUND',
        // tariffId: 8,
        tariffId: 18,
        extra: [2,3],
      }
    
    case 'PRODUKT-Plan-Digitalisierung':
      return {
        category: 'PRODUKT',
        type: 'Plan-Digitalisierung',
        details: {
          requirements: [{
            value: ''
          }],
          files: [],
          budget: 2000000
        },
        email: '',
        name: 'Plan-Digitalisierung',
        paymentProcessing: 'IS_PRICE_FUND',
        tariffId: 10,
      }

    default:
      return {
        category: 'BUILDING',
        type: 'Einfamilienhaus',
        style: [],
        details: {
          numOfSquares: {
            start: 40,
            end: 500,
          },
          hasAtrium: false,
          hasGallery: false,
          numOfFloors: 0,
          numOfRooms: 0,
          numOfBathrooms: 0,
          numOfGuestWCBat: 0,
          numOfParkings: 0,
          kitchenType: 'CLOSED',
          roomDesign: 'CLOSED',
          hasUndergroundParking: false,
          hasBasement: false,
          hasTerrace: false,
          hasWinterGarden: false,
          skeletonType: 'Massivbau',
          roofType: 'Pultdach/ Scheddach',
          requirementsForArchitect: [{
            value: ''
          }],
          budget: 2000000
        },
        email: '',
        name: '',
        projectOptions: [2],
        paymentProcessing: 'IS_PRICE_FUND',
        // tariffId: 8,
        tariffId: 18,
        extra: [2,3],
      }
  }
};
