import React, { Component } from "react";
import FaqsItem from "../../shared/FaqsItem";
import FormInput from "../../shared/FormInput";
import { faq_search, faqsItems } from "../../constants/basicInfo";
import Button from "../../shared/Button";
import faqService from "../../services/faqService";
import edit_helpers from "../../helpers/validator/edit_helpers";

class FAQPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      faqsArchitects: [],
      faqsBuilders: [],
      faqsTipps: [],
      initialContent: {
        architects: [],
        builders: []
      },
      selected: 'architects'
    }
    this.architects = React.createRef();
    this.builders = React.createRef();
    this.tips = React.createRef();
  }
  
  async componentDidMount() {
    try {
      const responseArchitects = await faqService.getAllQuestionsForDesigner();
      const responseBuilders = await faqService.getAllQuestionsForCustomer();
      const responseTipps = await faqService.getAllTipps();
      const faqsArchitects = edit_helpers.formatFaqs(responseArchitects.data);
      const faqsBuilders = edit_helpers.formatFaqs(responseBuilders.data);
      const faqsTipps = edit_helpers.formatTipps(responseTipps.data);
      this.setState({
        faqsArchitects,
        faqsBuilders,
        faqsTipps,
        initialContent: {
          architects: faqsArchitects,
          builders: faqsBuilders,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  onFilter = (value, list) => {
    return list.filter((filteredItem) => {
      return filteredItem.question.toLowerCase().includes(value.toLowerCase()) || 
             filteredItem.answer.toLowerCase().includes(value.toLowerCase());
    })
  }

  onChange = (e) => {
    const { value } = e.target;
    const { architects, builders } = this.state.initialContent;
    const filteredFaqsArchitects = this.onFilter(value, architects);
    const filteredFaqsBuilders = this.onFilter(value, builders);

    this.setState({
      faqsArchitects: filteredFaqsArchitects,
      faqsBuilders: filteredFaqsBuilders,
    })
  }

  scrollToRef = (ref) => {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'center',
    });
  }

  onClick = (e) => {
    switch (e.target.id) {
      case 'architects': 
        this.scrollToRef(this.architects);
        this.setState({selected: e.target.id})
        break;
      case 'builders':
        this.scrollToRef(this.builders);
        this.setState({selected: e.target.id})
        break;
      case 'tips':
        this.scrollToRef(this.tips);
        this.setState({selected: e.target.id})
        break;
      default:
        break;
    }    
  }
  
  render() {
    const { architects, builders } = faqsItems;
    const { faqsArchitects, faqsBuilders, selected } = this.state;
    const { isMobile } = this.props;
    console.log(this.state)
    return (
      <div className="faqWrapper">
        {!isMobile && (
          <div className="navigation-container">
            <div className="nav-list">
              <h3 className={`${selected === 'builders' ? 'selected' : ''}`}>
                <span className="nav-elem" id="builders" onClick={this.onClick}>
                  {builders.title}
                </span>
              </h3>
              <h3 className={`${selected === 'architects' ? 'selected' : ''}`}>
                <span
                  className="nav-elem"
                  id="architects"
                  onClick={this.onClick}>
                  {architects.title}
                </span>
              </h3>
              <a className="support" href="mailto:frage@faberling.com">
                <Button text="Fragen Sie uns" />
              </a>
            </div>
          </div>
        )}
        <div className="questions-container">
          <h1>Häufige Fragen</h1>
          <div className="search">
            <FormInput
              name="search"
              className="search"
              handleChange={this.onChange}
              placeholder="Search"
              type="text"
              img={faq_search}
            />
          </div>
          {isMobile && (
            <a href="mailto:frage@faberling.com">
              <Button text="Fragen Sie uns" />
            </a>
          )}
          {!isMobile && (
            <div ref={this.builders}>
              <FaqsItem faqs={faqsBuilders} title={builders.title} />
            </div>
          )}
          <div ref={this.architects}>
            <FaqsItem faqs={faqsArchitects} title={architects.title} />
          </div>
        </div>
      </div>
    );
  }
}

export default FAQPage;
