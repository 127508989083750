import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import Button from '../../../../../../../shared/Button';
import PersonData from './components/PersonData/PersonData';
import CompanyData from './components/CompanyData/CompanyData';
import IndividualPersonData from './components/IndividualPersonData/IndividualPersonData';
import { clearCompanyStore, onSubmitCompany } from '../../../../../../../redux/actions/registrationCompanyActions'
import { clearIndividualStore, onSubmitIndividual } from '../../../../../../../redux/actions/registrationIndividualActions'
import { clearDocStore } from '../../../../../../../redux/actions/uploadDocActions'

class SecondStep extends Component {
  state = {
    // change forms
    isCompany: false,
  };

  handleClick = status => {
    this.setState({
      isCompany: status,
    });
    this.props.clearCompanyStore();
    this.props.clearIndividualStore();
    this.props.clearDocStore();
  };

  checkStatus(status) {
    if (status) return <Redirect to="profile/my-projects" />;
  }
  render() {
    const { registrationCompanyReducer, registrationIndividualReducer } = this.props
    return (
      <Fragment>
        <div className="page-switcher">
          <button
            className={this.state.isCompany ? '' : 'active'}
            onClick={() => this.handleClick(false)}>
            Als Einzelperson
          </button>
          <button
            className={this.state.isCompany ? 'active' : ''}
            onClick={() => this.handleClick(true)}>
            Als Firma
          </button>
        </div>
        {this.state.isCompany ? (
          <form onSubmit={this.props.onSubmitCompany}>
            <CompanyData />
            <PersonData />
            <Button
              type="submit"
              text="registrieren"
              isLoading={registrationCompanyReducer.formState.isLoading}
            />
            {this.checkStatus(registrationCompanyReducer.success)}
          </form>
        ) : (
            <form onSubmit={this.props.onSubmitIndividual}>
              <IndividualPersonData />
              <Button
                type="submit"
                text="registrieren"
                isLoading={registrationIndividualReducer.formState.isLoading}
              />
              {this.checkStatus(registrationIndividualReducer.success)}
            </form>
          )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { registrationCompanyReducer, registrationIndividualReducer } = state
  return {
    registrationCompanyReducer,
    registrationIndividualReducer,
  }
};

export default connect(mapStateToProps, {
  clearCompanyStore,
  onSubmitCompany,
  clearIndividualStore,
  onSubmitIndividual,
  clearDocStore,
})(SecondStep);
