import React from 'react';
import PropTypes from 'prop-types';

export default function Button({
  isLoading,
  text,
  handleClick,
  handleBlur,
  handleFocus,
  isDisabled,
  isDisabledClass,
  className = '',
  type,
  icon,
  id,
}) {
  return (
    <div className={`btnWrapper `}>
      <button
        type={type}
        className={`${isDisabledClass ? 'button btnDisabled' : 'button btnActive'} ${className}`}
        onClick={handleClick}
        id={id}
        onBlur={handleBlur}
        onFocus={handleFocus}
        disabled={isDisabled}>
        {isLoading ? 'Laden...' : text}
        {icon}
      </button>
    </div>
  );
}

Button.propTypes = {
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  text: PropTypes.string,
  handleClick: PropTypes.func,
  className: PropTypes.string,
};
