import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import errorsString from '../../helpers/validator/errorsString';

const ErrorSection = props => {
  const { document, isReq, isFileValid, isSizeValid } = props;
  return (
    <Fragment>
      {!document && isReq && (
        <p className="error">{errorsString.COMMON_ERRORS.IS_REQ}</p>
      )}
      {!isFileValid && (
        <p className="error">{errorsString.ID_DOC_ERRORS.DOC_FORMAT}</p>
      )}
      {!isSizeValid && (
        <p className="error">{errorsString.ID_DOC_ERRORS.DOC_SIZE}</p>
      )}
    </Fragment>
  );
};

export default ErrorSection;

ErrorSection.propTypes = {
  document: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isReq: PropTypes.string,
  isFileValid: PropTypes.bool,
  isSizeValid: PropTypes.bool,
};
