import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BuildingEinfa from './pages/Building/BuildingEinfa';
import BuildingMehr from './pages/Building/BuildingMehr';
import RenovationEinfa from './pages/Renovation/RenovationEinfa';
import RenovationMehr from './pages/Renovation/RenovationMehr';
import Special from './pages/Special';
import Garden from './pages/Garden';
import { withRouter } from 'react-router-dom';

// Import Redux Actins
import { setEmail } from '../../../../redux/actions/createProjectActions'

const SecondStep = props => {
  const dispatch = useDispatch()
  const { User } = useSelector(state => state.globalReducer)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (User) dispatch(setEmail(User.email))
  }, [User])

  const typesPages = {
    'BUILDING-Einfamilienhaus': <BuildingEinfa location={props.location} />,
    'BUILDING-Mehrfamilienhaus': <BuildingMehr location={props.location} />,
    'RENOVATION-Einfamilienhaus': <RenovationEinfa location={props.location} />,
    'RENOVATION-Mehrfamilienhaus': <RenovationMehr location={props.location} />,
    'GARDEN-Garten- & Landschaftsbau': <Garden location={props.location} />,
    'SPECIAL-Spezialprojekt': <Special location={props.location} />,
  };


  const { category, type } = useSelector(state => state.createProjectReducer)
  const projectType = `${category}-${type}`;
  const component = typesPages[projectType];

  return <>{component}</>;

}

export default withRouter(SecondStep);
