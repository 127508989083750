import { PROFILE_CHANGE, PROFILE_CHANGE_FIELD, PROFILE_CLEAR, PROFILE_FORM_LOADING, PROFILE_FORM_VALIDITY, PROFILE_BLUR, PROFILE_FOCUS, PROFILE_SET_ERROR_BE, LOGOUT, PROFILE_PASSWORD_CHANGE } from '../types'
import validator from '../../helpers/validator/validation';
import errorsString from '../../helpers/validator/errorsString';

const defaultFields = () => {
    return {
        email: '',
        password: '',
        login: '',
        firstName: '',
        lastName: '',
        companyName: '',
    }
};

function initialState() {
    return {
        isShowBtn: false,
        profile: null,
        profileById: null,
        fields: defaultFields(),
        formState: {
            isLoading: false,
            isFormValid: true,
            errors: {
                email: '',
                password: '',
                login: '',
                firstName: '',
                lastName: '',
                fromBE: '',
            },
        }
    };
}

export default function (state = initialState(), action) {
    const { type, payload } = action;
    switch (type) {
        case PROFILE_SET_ERROR_BE:
            var formState = { ...state.formState }
            formState.errors.fromBE = payload;
            return {
                ...state,
                formState
            }
        case PROFILE_FORM_LOADING:
            var formState = { ...state.formState }
            formState.isLoading = payload;
            return {
                ...state,
                formState
            }
        case PROFILE_CHANGE_FIELD:
            var fields = { ...state.fields }
            fields[payload.name] = payload.value;
            var formState = { ...state.formState, isLoading: false }
            const validatorObj = validator.validate(payload.name, payload.value);
            formState.errors[payload.name] = validatorObj.error;
            return {
                ...state,
                fields,
                formState,
                isShowBtn: true,
            }
        case PROFILE_CHANGE:
            return {
                ...state,
                profileById: action.payload,
                fields: { ...action.payload }
            }
        case PROFILE_FOCUS:
            var formState = { ...state.formState }
            formState.errors[payload.name] = '';
            return {
                ...state,
                formState
            }
        case PROFILE_BLUR:
            var formState = { ...state.formState }
            if (!payload.value) {
                formState.errors[payload.name] = errorsString.COMMON_ERRORS.IS_REQ;
            }
            return {
                ...state,
                formState
            }
        case PROFILE_FORM_VALIDITY:
            return {
                ...state,
                ...payload
            }
        case LOGOUT:
        case PROFILE_CLEAR:
            return initialState()
        default:
            return {
                ...state,
            }
    }
}
