export const setItem = (key, value) => {
    try {
        localStorage.setItem(key, value);
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const getItem = key => {
    try {
        return localStorage.getItem(key);
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const removeItem = key => {
    localStorage.removeItem(key);
};