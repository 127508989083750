import React from 'react';
import DesignerTable from './ProjectTable/DesignerTable';

export default function DesignerPage({ projects, handleRequestPage, languageID }) {
  return (
    <div className="designer-page">
      <DesignerTable
        limit={projects.limit}
        totalPages={projects.totalPages}
        page={projects.page}
        projects={projects.result}
        total={projects.total}
        changePage={handleRequestPage}
        languageID={languageID}
      />
    </div>
  );
}
