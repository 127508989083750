import { SHOW_SNACK, CLOSE_SNACK, LOGOUT } from '../types'

const initialState = () => {
    return {
        isShown: false,
        alert: {
            title: '',
            type: ''
        },
        messageText: null
    }
}

export default function (state = initialState(), action) {
    const { type, payload } = action;
    switch (type) {
        case SHOW_SNACK:
            return {
                ...state,
                isShown: true,
                alert: payload.alert,
                messageText: payload.messageText,
            }
        case CLOSE_SNACK:
            return {
                ...state,
                isShown: false,
                alert: { title: '', type: '' },
                messageText: null,
            }
        case LOGOUT:
            return initialState()
        default:
            return state

    }
}