import axios from 'axios';

export const instance = axios.create({
  baseURL: 'https://faberling.com/wp-json/wp/v2',
});

instance.interceptors.response.use(
  (response) =>  {
    console.log(response)
    return { data: response.data, pages: response.headers['x-wp-totalpages`'] };
  },
  (error) => Promise.reject(error)
);
