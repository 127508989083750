import React from 'react';
import PropTypes from 'prop-types';

export default function Button({ text, handleClick, type, icon }) {
  return (
    <button type={type} className="smallButton" onClick={handleClick}>
      {text} {icon}
    </button>
  );
}

Button.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  handleClick: PropTypes.func,
};
