import {
    COMPANY_SET_ERROR_BE,
    COMPANY_FORM_LOADING,
    COMPANY_CHANGE_REGISTRATION_FIELD,
    COMPANY_CHANGE_BOXES,
    COMPANY_REGISTER_FOCUS,
    COMPANY_REGISTER_BLUR,
    COMPANY_FORM_VALIDITY,
    COMPANY_REGISTER_SUCCESS,
    COMPANY_CLEAR_REGISTRATION
} from '../types'
import { instance } from "../http";
import { loginAuth } from './authActions'

export const setErrorBE = message => dispatch => {
    dispatch({
        type: COMPANY_SET_ERROR_BE,
        payload: message
    })
};

export const setLoading = status => dispatch => {
    dispatch({
        type: COMPANY_FORM_LOADING,
        payload: status
    })
};

export const onChangeSecondStep = ({ target }) => dispatch => {
    dispatch({
        type: COMPANY_CHANGE_REGISTRATION_FIELD,
        payload: target
    })
};

export const changeBoxes = (boxesName, fieldName) => dispatch => {
    dispatch({
        type: COMPANY_CHANGE_BOXES,
        payload: { boxesName, fieldName }
    })
};

export const onFocus = ({ target }) => dispatch => {
    dispatch({
        type: COMPANY_REGISTER_FOCUS,
        payload: target
    })
};
export const onBlur = ({ target }) => dispatch => {
    dispatch({
        type: COMPANY_REGISTER_BLUR,
        payload: target
    })
};

export const onSubmitCompany = event => (dispatch, getState) => {
    event.preventDefault();
    dispatch(checkSecondStep())
};

export const checkSecondStep = () => (dispatch, getState) => {
    var { formState, fieldsSecondStep } = getState().registrationCompanyReducer
    const isAllFieldsValid = Object.values(formState.errors).every(
        field => !field
    );

    console.log(isAllFieldsValid)
    if (!isAllFieldsValid) {
        formState.isFormValid = false;
        dispatch({
            type: COMPANY_FORM_VALIDITY,
            payload: {
                formState,
            }
        })
        return;
    }
    formState.isFormValid = true;
    dispatch({
        type: COMPANY_FORM_VALIDITY,
        payload: {
            formState,
            fieldsSecondStep
        }
    })
    var { fieldsFirstStep, confirmationBoxes } = getState().registrationFirstStepReducer
    const dob = fieldsSecondStep.personDataDob.split('.');
    const data = {
        login: fieldsFirstStep.login,
        password: fieldsFirstStep.password,
        iban: fieldsSecondStep.iban,
        address: {
            country: fieldsSecondStep.iban.substring(0, 2) ? fieldsSecondStep.iban.substring(0, 2) : 'CH',
        },
        companyData: {
            name: fieldsSecondStep.companyName,
        },
        dob: {
            day: dob[0],
            month: dob[1],
            year: dob[2],
        },
        firstName: fieldsSecondStep.firstName,
        lastName: fieldsSecondStep.lastName,
        email: fieldsFirstStep.email,
        additional: {
            emailSubscribe:
                confirmationBoxes.emailSubscribe,
            infoSource: fieldsFirstStep.additional
                .infoSource
                ? fieldsFirstStep.additional.infoSource
                : undefined,
        },
        businessType: 'company',
    };
    dispatch(registration(data, fieldsFirstStep));
}


export const registration = (data, fieldsFirstStep) => dispatch => {
    dispatch(setLoading(true))
    instance.post('/auth/registration/company-alt', data)
        .then(response => {
            dispatch({
                type: COMPANY_REGISTER_SUCCESS,
                payload: response.data
            })
            const user = {
                login: response.data.login,
                password: fieldsFirstStep.password,
            };
            dispatch(setLoading(false))
            dispatch(loginAuth(user))
        })
        .catch(error => {
            if (error.response.status >= 400 && error.response.status <= 500) {
                dispatch(setErrorBE(error.response.data.message))
            }
            dispatch(setLoading(false))
            throw error;
        })
};

export const clearCompanyStore = () => dispatch => {
    dispatch({
        type: COMPANY_CLEAR_REGISTRATION
    })
}