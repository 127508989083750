import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import LoginIcon from "../../../../../assets/login.svg";
export default function NotAuthUser({ location, languageID }) {
  return (
    <Fragment>
      <p>Um Ihren Fortschritt zu speichern</p>
      <div className="sign-in">
        <Link to={{ pathname: `/${languageID}/login`, state: { prevPath: location.pathname } }}>
          <p>Einloggen</p>
          <img src={LoginIcon} alt="LoginIcon" />
        </Link>
      </div>
    </Fragment>
  );
}
