import React, { Component } from "react";
import open from '../../assets/open_faq.svg';
import close from '../../assets/close_faq.svg';

class FaqsItem extends Component {

  state = {
    faqs: []
  }

  componentDidMount() {
    this.setState({faqs: this.props.faqs})
  }

  componentDidUpdate(prevProps) {
    if(prevProps.faqs !== this.props.faqs) {
      this.setState({faqs: this.props.faqs})
    }
  }

  onToggle = (index) => {
    const updated = this.state.faqs.map((faq, i) => {
      if (i === index) {
        return {
          ...faq,
          isAnswerOpen: !faq.isAnswerOpen,
        }
      } else return {...faq}
    });

    this.setState({faqs: updated})
  }

  render() {
    return (
      <div className="faq">
        <div className="item-title uppercase">{this.props.title}</div>
        {this.state.faqs.map((item, index) => {
          return (
            <div
              className={`question-container ${
                item.isAnswerOpen ? 'opened' : ''
              }`}
              key={index}
              onClick={() => this.onToggle(index)}>
              <div className="question">
                <div className="content">
                  <span className="no">{index + 1}</span>
                  <div className="text">{item.question}</div>
                </div>
                {!item.isAnswerOpen ? (
                  <img src={open} alt="open" />
                ) : (
                  <img src={close} alt="close" />
                )}
              </div>
              {item.isAnswerOpen && (
                <div className="answer" dangerouslySetInnerHTML={{ __html: item.answer}}>
                  
                </div>
              )}
            </div>
          );})
        }
      </div>
    )
  }
}

export default FaqsItem;