import { CHANGE_PAGE, CLEAR_INTERVAL, CLEAR_TRANSACTIONS, GET_TRANSACTIONS, GET_TRANSACTION_ID, LOGOUT, POST_TRANSACTION, RESET_TRANSACTION, SET_INTERVAL, TRANSACTION_CHANGE, TRANSACTION_ERROR, TRANSACTION_LOADING, TRANSACTION_MODAL, APPLY_VOUCHER, CLEAR_CARD_DETAILS } from '../types'

function defaultParams() {
    return {
        page: 1,
        limit: 5,
        sort: 'desc',
        sort_by: '_id',
    }
}

function defaultCardDetails() {
    return {
        projectId: null,
        afterConfirmUrl: 'http://localhost:3000/create-project/finish',
        card: {
            number: '',
            exp_month: 10,
            exp_year: 2050,
            cvc: '',
            // name: '',
        },
        voucherCodeId: null
    }
}

function defaultErrors() {
    return {
        fromBe: '',
    }
};

function initialState() {
    return {
        payment: '',
        transactions: { result: [] },
        params: defaultParams(),
        transaction: null,
        cardDetails: defaultCardDetails(),
        transactionAnswer: {},
        isShow: null,
        isLoading: false,
        interval: null,
        errors: { ...defaultErrors() },
    };
}

export default function (state = initialState(), action) {
    const { type, payload } = action;
    switch (type) {
        case TRANSACTION_CHANGE:
            var cardDetails = state.cardDetails
            cardDetails.card[payload.name] = payload.value
            return {
                ...state,
                cardDetails
            }
        case APPLY_VOUCHER:
            var cardDetails = state.cardDetails
            cardDetails[payload.name] = payload.value
            return {
                ...state,
                cardDetails
            }
        case TRANSACTION_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case POST_TRANSACTION:
            return {
                ...state,
                transactionAnswer: payload,
                isLoading: false,
                isShow: true,
                success: true
            }
        case TRANSACTION_ERROR:
            var errors = state.errors
            errors.fromBe = payload
            return {
                ...state,
                isLoading: false,
                errors
            }
        case GET_TRANSACTION_ID:
            return {
                ...state,
                payment: payload.status
            }
        case GET_TRANSACTIONS:
            return {
                ...state,
                transactions: payload
            }
        case CLEAR_TRANSACTIONS:
            return {
                ...state,
                transactions: null,
                params: defaultParams()
            }
        case CLEAR_CARD_DETAILS:
            return {
                ...state,
                cardDetails: defaultCardDetails()
            }
        case CHANGE_PAGE:
            var params = state.params
            params.page = payload;
            return {
                ...state,
                params
            }
        case TRANSACTION_MODAL:
            return {
                ...state,
                isShow: payload
            }
        case SET_INTERVAL:
            return {
                ...state,
                interval: payload,
            }
        // case CLEAR_INTERVAL:
        //     return { 
        //         ...state,
        //         interval: null,
        //     }
        case RESET_TRANSACTION:
            return {
                ...state,
                errors: defaultErrors()

            }
        case LOGOUT:
            return initialState()
        default:
            return {
                ...state,
            }
    }
}
