import React from "react";

const IncomingMessage = ({icon, name, creationDate, text}) => {
  return (
    <div className="company-message">
      <div className="message-header">
        <img src={icon} alt="icon" className='avatar' />
        <span className='name'>{name}</span>
        <span className='date'>{creationDate}</span>
      </div>
      <div className="message-text">
        {text}
      </div>
    </div>
  )
}

export default IncomingMessage;