import React from 'react';
import email from '../../../../assets/email.svg';

const CallUsBlock = () => {
  return (
    <div className="callUsWrapper">
      <p className="title">
        Haben Sie <br /> Fragen?
      </p>
      <p className="phone">Wir beraten Sie gerne.</p>
      <a href="mailto:frage@faberling.com">
        <img src={email} alt="email" />
      </a>
      <p className="subTitle">frage@faberling.com</p>
    </div>
  );
};

export default CallUsBlock;
