import { CHANGE_BOXES, CHANGE_REGISTRATION_FIELD, CLEAR_REGISTRATION, FORM_LOADING, FORM_VALIDITY, REGISTER_BLUR, REGISTER_FOCUS, REGISTER_SUCCESS, REGISTRATION_UPDATE_EMAIL, SET_ERROR_BE } from '../types'
import registrationFields from '../../constants/registrationFields';
import validator from '../../helpers/validator/validation';
import errorsString from '../../helpers/validator/errorsString';

function initialState() {
    return {
        success: false,
        confirmationBoxes: {
            rules: false,
            emailSubscribe: false,
        },
        fields: { ...registrationFields().customer },
        formState: {
            isLoading: false,
            isFormValid: true,
            errors: {
                email: '',
                password: '',
                login: '',
                firstName: '',
                lastName: '',
                phoneNumber: '',
                fromBE: '',
            },
        },
    }
}

export default function (state = initialState(), action) {
    const { type, payload } = action;
    switch (type) {
        case SET_ERROR_BE:
            var formState = { ...state.formState }
            formState.errors.fromBE = payload;
            return {
                ...state,
                formState
            }
        case FORM_LOADING:
            var formState = { ...state.formState }
            formState.isLoading = payload;
            return {
                ...state,
                formState
            }
        case REGISTRATION_UPDATE_EMAIL:
            var fields = {
                ...state.fields,
                email: payload
            }
            return {
                ...state,
                fields
            }
        case CHANGE_REGISTRATION_FIELD:
            var formState = { ...state.formState }
            formState.errors[payload.name] = "";

            var fields = { ...state.fields }
            if (payload.name !== 'infoSource') {
                fields[payload.name] = payload.value;
            } else {
                fields.additional[payload.name] = payload.value;
            }
            if (payload.name !== 'infoSource') {
                var formState = { ...state.formState }
                const validatorObj = validator.validate(payload.name, payload.value);
                formState.errors[payload.name] = validatorObj.error;
                // return validatorObj.isValid;
            }
            return {
                ...state,
                fields,
                formState,
            }
        case CHANGE_BOXES:
            const { boxesName, fieldName } = payload
            var obj = { ...state[boxesName] }
            obj[fieldName] = !state[boxesName][fieldName];
            return {
                ...state,
                [boxesName]: obj
            }
        case REGISTER_FOCUS:
            var formState = { ...state.formState }
            formState.errors[payload.name] = '';
            formState.errors.fromBE = '';
            return {
                ...state,
                formState
            }
        case REGISTER_BLUR:
            var formState = { ...state.formState }
            if (!payload.value) {
                formState.errors[payload.name] = errorsString.COMMON_ERRORS.IS_REQ;
            }
            return {
                ...state,
                formState
            }
        case FORM_VALIDITY:
            return {
                ...state,
                ...payload
            }
        case REGISTER_SUCCESS:
            return {
                ...state,
                success: payload ? true : false,
                loading: false
            }
        case CLEAR_REGISTRATION:
            return initialState()
        default:
            return state
    }
}
