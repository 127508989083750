import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import FormInput from '../FormInput';
import upload from '../../assets/upload.svg';
import Button from '../Button';
import FileItem from '../FileItem';
import close from '../../assets/close.svg';
import LinearProgressWithLabel from '../LinearProgressWithLabel';

import { resetProjectData } from '../../redux/actions/createProjectActions'
import {
  deleteFile,
  onChange,
  onFocus,
  onUpload,
  onSubmit,
  clearAdditionalStore,
} from '../../redux/actions/additionalInfoProjectActions'

class AdditionalInfoProject extends Component {

  componentWillUnmount() {
    this.props.clearAdditionalStore();
  }

  close = () => {
    this.props.onClose();
  }
  render() {
    const {
      projectId,
      projectName,
      additionalInfoProjectReducer,
      deleteFile,
      onChange,
      onFocus,
      onUpload,
      onSubmit,
      currency
    } = this.props;
    const {
      formFields,
      files,
      errors,
      isDocumentOnly,
      loadingPercentage
    } = additionalInfoProjectReducer;
    return (
      <div className="additional-info-project-wrapper">
        <div className="close" onClick={this.close}>
          <img src={close} alt="close" />
        </div>
        {!isDocumentOnly ? (
          <>
            <p className="main-title">Wo planen Sie Ihr Projekt</p>
            <p className="project-name">
              #{projectId} &#8220;{projectName}&#8221; ?
            </p>
            {currency === 'EUR' ?
              <p className="sub-title">{ }
            Sodass unsere Architekten gemäss dem Bebauungsplan und auf Ihrem
            Grundstück individuell gestaltete Konzeptentwürfe erstellen können…{' '}
              </p>
              : <p className="sub-title">{ }
              Sodass unsere Architekten zonenkonforme und auf Ihre Parzelle
              individuell gestaltete Konzeptentwürfe erstellen können, brauchen
              wir untenstehende Angaben als Arbeitsgrundlage. Falls Sie diese
              Informationen nicht zur Hand haben, können Sie diese auch später
              nachreichen.{' '}
              </p>}
            <form className="additional-info-project-form">
              {currency === 'EUR' ? <div className="small-inputs-additional-three">
                <FormInput
                  value={formFields.postalCode}
                  handleChange={(e) => onChange(e)}
                  onFocus={() => onFocus()}
                  name="postalCodeDE"
                  placeholder="Postleitzahl"
                  error={errors.postalCode}
                />
                <FormInput
                  value={formFields.plot}
                  handleChange={(e) => onChange(e)}
                  onFocus={() => onFocus()}
                  name="plot"
                  placeholder="Flur"
                  error={errors.plot}
                />
                <FormInput
                  value={formFields.plotNo}
                  handleChange={(e) => onChange(e)}
                  onFocus={() => onFocus()}
                  name="plotNo"
                  placeholder="Flurstück"
                  error={errors.plotNo}
                />
              </div>
                : <div className="small-inputs-additional">
                  <FormInput
                    value={formFields.postalCode}
                    handleChange={(e) => onChange(e)}
                    onFocus={() => onFocus()}
                    name="postalCode"
                    placeholder="Postleitzahl"
                    error={errors.postalCode}
                  />
                  <FormInput
                    value={formFields.postOfficeNumber}
                    handleChange={(e) => onChange(e)}
                    onFocus={() => onFocus()}
                    name="postOfficeNumber"
                    placeholder="Parzellen-Nummer"
                    error={errors.postOfficeNumber}
                  />
                </div>}
            </form>
          </>
        ) : null}
        <form className="additional-info-project-form">
          <p className="title">Bereits vorhandene Unterlagen/Pläne</p>
          <p className="sub-title">
            Laden Sie hier ggf. Daten, wie z. B. Bilder und Dokumente,
            Dateiupload (max. 512 MB).
          </p>
          {loadingPercentage ? <LinearProgressWithLabel value={loadingPercentage} /> : null}
          <div className="file-list">
            {files &&
              files.map((item, index) => {
                return (
                  <FileItem
                    key={item.id}
                    name={item.name}
                    link={item.url}
                    path={item.path}
                    id={index}
                    deleteFile={deleteFile}
                  />
                );
              })}
          </div>
          <div className="file-wrapper">
            <input
              name="files"
              id="loading_doc"
              type="file"
              onChange={(event) => {
                onUpload(event.target.files[0]);
              }}
            />
            <label htmlFor="loading_doc">
              <span>DATEI HINZUFÜGEN</span>
              <img src={upload} alt="upload"></img>
            </label>
          </div>
          <Button text="Speichern" handleClick={(e) => {
            onSubmit(e)
            this.close()
          }} />
        </form>
        {!isDocumentOnly ? (
          <Button
            id="additional-button"
            text="Später ausfüllen"
            handleClick={this.close}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { additionalInfoProjectReducer } = state
  return {
    additionalInfoProjectReducer,
  }
};

export default connect(mapStateToProps, {
  resetProjectData,
  deleteFile,
  onChange,
  onFocus,
  onUpload,
  onSubmit,
  clearAdditionalStore,
})(AdditionalInfoProject);

AdditionalInfoProject.propTypes = {
  projectId: PropTypes.any,
  projectName: PropTypes.string,
  onClose: PropTypes.func,
};
