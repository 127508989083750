import { SHOW_SNACK, CLOSE_SNACK } from '../types'

export const showSnack = (alert, messageText) => dispatch => {
    dispatch({
        type: SHOW_SNACK,
        payload: {
            alert,
            messageText,
        }
    })
    setTimeout(() => dispatch({
        type: CLOSE_SNACK
    }), 5200);
}

export const closeSnack = () => dispatch => {
    dispatch({
        type: CLOSE_SNACK,
    })
}