import React from 'react';
import AuthUser from './components/AuthUser';
import NotAuthUser from './components/NotAuthUser';

export default function Menu({ isShow, user, languageID }) {
  return (
    <div className="menu">
      {user ? <AuthUser role={user.role} languageID={languageID} /> : <NotAuthUser />}
    </div>
  );
}
