import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';

import FormInput from '../../../shared/FormInput';
import Button from '../../../shared/Button';

// Import Redux Actions
import { onSubmit, onBlur, onFocus, onChange } from '../../../redux/actions/authActions'

const Authorization = ({ location, languageID }) => {
  const dispatch = useDispatch()
  const authReducer = useSelector(state => state.authReducer)
  const { formState, fields } = authReducer;
  return (
    <div className="form-wrapper">
      <p className="title">Anmelden</p>
      <p className="subTitle">
        Wenn Sie ein Konto haben, melden Sie sich mit Ihrer E-Mail-Adresse an
      </p>
      <form className="auth-form" onSubmit={e => dispatch(onSubmit(e))}>
        <p className="error-be">{formState.errors.fromBE}</p>
        <FormInput
          name="login"
          placeholder="E-Mail-Adresse oder Benutzername"
          error={formState.errors.login}
          value={fields.login}
          handleChange={e => dispatch(onChange(e))}
          handleBlur={e => dispatch(onBlur(e))}
          handleFocus={e => dispatch(onFocus(e))}
        />
        <FormInput
          name="password"
          type="password"
          placeholder="Passwort"
          error={formState.errors.password}
          value={fields.password}
          handleChange={e => dispatch(onChange(e))}
          handleBlur={e => dispatch(onBlur(e))}
          handleFocus={e => dispatch(onFocus(e))}
        />
        <div className="helper">
          <Link to={`/${languageID}/forgot-password`}>
            Benutzername oder Passwort vergessen?
          </Link>
        </div>
        <Button type="submit" text="Log In" isLoading={formState.isLoading} />
      </form>
    </div>
  );
};

export default Authorization;
