import React from 'react';
import Button from '../../../shared/Button';

const Further = React.forwardRef(({ id, handleShow }, ref) => {
  return (
    <div className="further-information" id={id} ref={ref}>
      <h2>PLZ, Parzellen-Nr.</h2>
      <Button handleClick={handleShow} text="Informationen angeben" />
    </div>
  );
});

export default Further;
