import {
    PROFILE_SET_ERROR_BE,
    PROFILE_FORM_LOADING,
    PROFILE_CHANGE_FIELD,
    PROFILE_CHANGE,
    PROFILE_FOCUS,
    PROFILE_BLUR,
    PROFILE_FORM_VALIDITY,
    PROFILE_CLEAR,
    VALIDATE_RESET_PASS
} from '../types'
import { instance } from "../http";
import Axios from 'axios';
import errorsString from "../../helpers/validator/errorsString";

export const setErrorBE = message => dispatch => {
    dispatch({
        type: PROFILE_SET_ERROR_BE,
        payload: message
    })
};

export const setLoading = status => dispatch => {
    dispatch({
        type: PROFILE_FORM_LOADING,
        payload: status
    })
};

export const onProfileChange = ({ target }) => dispatch => {
    dispatch({
        type: PROFILE_CHANGE_FIELD,
        payload: target
    })
};

export const setProfile = (profile) => dispatch => {
    dispatch({
        type: PROFILE_CHANGE,
        payload: profile
    })
};

export const setProfileById = (profile) => dispatch => {
    dispatch({
        type: PROFILE_CHANGE,
        payload: profile
    })
};

export const getProfileDetails = () => dispatch => {
    dispatch(setLoading(true))
    instance.get('/accounts/me')
        .then(response => dispatch(setProfileById(response.data)))
        .catch(error => {
            dispatch(setErrorBE(error.response.data.message))
            dispatch(setLoading(false))
        })
}

export const getProfileById = id => dispatch => {
    dispatch(setLoading(true))
    instance.get(`/accounts/${id}`)
        .then(response => dispatch(setProfileById(response.data)))
        .catch(error => {
            dispatch(setErrorBE(error.response.data.message))
            dispatch(setLoading(false))
        })
}

export const onFocus = ({ target }) => dispatch => {
    dispatch({
        type: PROFILE_FOCUS,
        payload: target
    })
};
export const onBlur = ({ target }) => dispatch => {
    dispatch({
        type: PROFILE_BLUR,
        payload: target
    })
};

export const onSubmitProfile = event => (dispatch, getState) => {
    event.preventDefault();
    dispatch(checkForm())
};

export const checkForm = () => (dispatch, getState) => {
    var { formState, fields } = getState().registrationCompanyReducer
    const isAllFieldsValid = Object.values(formState.errors).every(
        field => !field
    );
    console.log(isAllFieldsValid)
    if (!isAllFieldsValid) {
        formState.isFormValid = false;
        dispatch({
            type: PROFILE_FORM_VALIDITY,
            payload: {
                formState,
            }
        })
        return;
    }
    formState.isFormValid = true;
    dispatch({
        type: PROFILE_FORM_VALIDITY,
        payload: {
            formState,
            isShowBtn: false
        }
    })
    dispatch(updateProfile());
}

export const updateProfile = () => (dispatch, getState) => {
    const { fields } = getState().profileReducer
    const { resetPasswordFields } = getState().forgotPassReducer
    const id = fields._id;
    const data = {
        ...fields,
        password: resetPasswordFields.new_pass
    }
    instance.put(`/auth/${id}`, data)
        .then(response => {
            window.alert('Profil aktualisiert.')
            window.location.reload();
        })
        .catch(error => {
            console.log(error.response);
        })
}

const validateResetForm = (dispatch, getState) => {
    const { resetPasswordFields, formState, resetToken } = getState().forgotPassReducer
    if (resetPasswordFields.old_pass.length < 4)
        formState.errors.old_pass = errorsString.RESET_PASS.OLD_PASS
    if (
        resetPasswordFields.old_pass !==
        resetPasswordFields.new_pass
    )
        formState.errors.new_pass = errorsString.RESET_PASS.NEW_PASS;
    dispatch({
        type: VALIDATE_RESET_PASS,
        payload: formState
    })
    return resetToken && !formState.errors.new_pass && !formState.errors.old_pass
}

export const onChangePass = (event) => (dispatch, getState) => {
    event.preventDefault();
    var proceed = validateResetForm(dispatch, getState);
    const { formState } = getState().forgotPassReducer
    if (formState.isFormValid) {
        dispatch(updateProfile())
    }
};

export const clearProfileStore = () => dispatch => {
    dispatch({
        type: PROFILE_CLEAR
    })
}