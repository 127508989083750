import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import plus from '../../assets/plus.svg';
import yes from '../../assets/yes.svg';
import no from '../../assets/no.svg';
import checked from '../../assets/checked.svg';
import Button from '../Button';



export default function Tariff({
  id,
  name,
  amount,
  advantageTitle,
  advantages,
  description,
  planType,
  onClick,
  selectedTariff,
  basicInfoTariffs,
  currency,
  scale
}) {
  const getAdvantagesbyType = type => {
    const elements = advantages.filter(
      advantage => advantage.description === type
    );
    if (type === 'Feature') {
      return <Fragment>
        {elements.map(item => (
          <div className="item-advantage" key={item.name}>
            <img src={plus} alt="plus" />
            <p className={`advantage-name`}>
              {item.name}
            </p>
          </div>
        ))}
      </Fragment>
    } else {
      return <Fragment>
        {elements.map(item => (
          <div className="item-advantage" key={item.name}>
            {JSON.parse(item.value) ? (
              <img src={yes} alt="yes" />
            ) : (
                <img src={no} alt="no" />
              )}
            <p className={`advantage-name ${JSON.parse(item.value)}`}>
              {item.name}
            </p>
          </div>
        ))}
      </Fragment>
    }
  };

  const isSelect = selectedTariff === id;
  return (
    <div
      className={`tariffWrapper ${isSelect ? 'checked' : ''}`}
      onClick={() => onClick(id)}>
      {isSelect && <img className="checked" src={checked} alt="checked" />}
      <div className="item top">
        <p className="item-title">{name}</p>
        <p className="amount">{currency} {(amount / 100).toLocaleString('de-CH')}</p>
        <p className="tax">inkl. {currency === 'CHF' ? '7,7%' : '19%'} MwSt.</p>
      </div>
      <div className="item desc">
        <p className="item-description">{description}</p>
      </div>
      {/* <div className="item planType">
        <p className="item-description">{planType}</p>
      </div> */}
      <div className="item bottom">
        <div className="item-container">
          {/* <p className="item-title">{`${name} enthält`}</p> */}
          {/* <div className='subtitle'>
            <p className="item-advantageTitle">{advantageTitle}</p>
          </div> */}
          {getAdvantagesbyType('Feature')}
        </div>
        <div style={{ width: '100%' }}>{scale ? 'Massstab '+scale : ''}</div>

        {/* {!basicInfoTariffs && (
          <Button
            isDisabledClass={!isSelect}
            text={isSelect ? 'gewählt' : 'Weiter'}
          />
        )} */}
      </div>
    </div>
  );
}

Tariff.propTypes = {
  isSelect: PropTypes.bool,
  onSelect: PropTypes.func,
  name: PropTypes.string,
  amount: PropTypes.number,
};
