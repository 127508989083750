import React, { Component } from 'react';
import { connect } from 'react-redux'
import ListItemContainer from '../../../../shared/ListItemContainer';
import CardInfoForm from '../../../../shared/CardInfoForm';
import { icons } from '../../../../constants/icons';
import fourthStepUnion from '../../../../assets/fourthStepUnion.svg';
import FormInput from '../../../../shared/FormInput';
import Button from '../../../../shared/Button';
import errorMessagesBE from '../../../../localization/errorMessagesBE';

import { changePaymentMethod } from '../../../../redux/actions/createProjectActions'
import { applyVoucher } from '../../../../redux/actions/transactionActions'

class FourthStep extends Component {
  state = {
    voucherCodeToRedeem: '',
    totalAmount: 0,
    invoiceAmount: 0,
    paymentAmount: 0,
    isAfterDiscount: false,
    voucherDisabled: false,
    textBtn: "Einlösen",
    voucher: null,
    invalidText: '',
    hideCard: false
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onHandleVoucherCode = (event) => {
    this.setState({
      voucherCodeToRedeem: event.target.value.toUpperCase()
    })
  }

  recalculateAmount(totalAmount, invoiceAmount, paymentAmount, voucher) {
    const voucherAmount = voucher.amount / 100;
    if (voucher.voucherType === 'INVOICE') {
      this.setState({
        invoiceAmount: invoiceAmount + voucherAmount,
        paymentAmount: paymentAmount
      })
    } else {  // CREDIT_CARD
      const payAmount = paymentAmount + voucherAmount;
      this.setState({
        invoiceAmount: invoiceAmount,
        paymentAmount: payAmount
      })
      if (payAmount == 0) {
        this.setState({ hideCard: true })
      }
    }
    this.setState({
      isAfterDiscount: true,
      totalAmount: totalAmount + voucherAmount,
      voucherDisabled: true,
      textBtn: "Entfernen"
    })
    this.props.applyVoucher({
      name: 'voucherCodeId',
      value: voucher._id
    })
  }

  render() {
    const {
      createProjectReducer,
      projectReducer,
      transactionReducer,
      tariffReducer,
      extraTariffReducer,
      changePaymentMethod,
      voucherCodeReducer,
      location,
      applyVoucher
    } = this.props
    const { options, paymentMethod } = createProjectReducer;
    const { createdProjectData } = projectReducer;
    const { tariffs } = tariffReducer
    const { extraTariffs } = extraTariffReducer
    const { voucherCode } = voucherCodeReducer;
    const isMoneyBack = createdProjectData.paymentProcessing === 'MONEY_BACK';
    const isProduct = createdProjectData.category === 'PRODUKT'

    const pathname = location.pathname
    const languageID = pathname.split('/')[1]
    const currencyFilter = {
      ch: 'CHF',
      de: 'EUR',
    }

    const taxAmount = {
      ch: '7.7',
      de: '19',
    }

    var totalAmount = this.state.isAfterDiscount ? this.state.totalAmount : createdProjectData.totalAmount / 100;
    var invoiceAmount = this.state.isAfterDiscount ? this.state.invoiceAmount : createdProjectData.invoiceAmount / 100;
    var paymentAmount = this.state.isAfterDiscount ? this.state.paymentAmount : createdProjectData.paymentAmount / 100;
    var taxRate = totalAmount * (currencyFilter[languageID] === 'CHF' ? 0.077 : 0.19);
    var totalBeforeTax = totalAmount - taxRate;
    var isValid = false;
    voucherHandler = voucherHandler.bind(this)

    function renderExtraTariff() {
      let selectedTariff = tariffs.find(tariff => tariff._id == createdProjectData.tariffId)
      let relatedExtra = selectedTariff ? selectedTariff.extra : [];
      let arr = [];

      if (createdProjectData.extra && createdProjectData.extra.length && extraTariffs && extraTariffs.length) {
        return extraTariffs.map((extra, i) => {
          if (createdProjectData.extra.includes(extra._id)) {
            let isIncluded;
            if (createdProjectData.extra.includes(languageID === 'ch' ? 14 : 18)) {
              const includedArr = languageID === 'ch' ? [11, 12, 13] : [15, 16, 17]
              if (includedArr.includes(extra._id)) {
                isIncluded = true;
              }
            } else if (createdProjectData.extra.includes(languageID === 'ch' ? 13 : 17)) {
              const includedArr = languageID === 'ch' ? [11, 12] : [15, 16]
              if (includedArr.includes(extra._id)) {
                isIncluded = true;
              }
            } else if (createdProjectData.extra.includes(languageID === 'ch' ? 12 : 16)) {
              const includedArr = languageID === 'ch' ? [11] : [15]
              if (includedArr.includes(extra._id)) {
                isIncluded = true;
              }
            } else {
              isIncluded = false;
            }
            return (
              <div key={i} className="info-block-item extra-tariff">
                <span>{extra.name}</span>
                <span>
                  {isIncluded ? 'inbegriffen' : extra.currency + ' ' + (extra.amount / 100).toLocaleString('de-CH')}
                </span>
              </div>
            )
          }
        })
      }

      // -- old structure :: 18-06-2021 --
      /*extraTariffs.forEach((extra) => {
        let extraData = relatedExtra.find(_extra => _extra.extraTariffId == extra._id);
        arr = [...arr, {
          ...extra,
          selectableType: extraData ? extraData.selectableType : 0
        }];
      })

      let projectExtra = createdProjectData.extra ? createdProjectData.extra : [];
      let extra = arr.map((item, i) => {
        let isSelected = projectExtra.find(id => id == item._id ? true : false);
        if (isSelected) {
          if (item.selectableType == 1) {
            return (
              <div key={i} className="info-block-item extra-tariff">
                <span>{item.name}</span>
                <span>{(item.amount / 100).toLocaleString('de-CH', { style: 'currency', currency: currencyFilter[languageID] })}</span>
              </div>
            )
          }
          if (item.selectableType == 2) {
            return (
              <div key={i} className="info-block-item extra-tariff">
                <span>{item.name}</span>
                <span>inbegriffen</span>
              </div>
            )
          }
        }
      })*/
      // -- end of old structure :: 18-06-2021 --

      // return (<div>{extra}</div>)
    }

    function voucherHandler() {
      this.setState({ invalidText: '' })
      if (!this.state.isAfterDiscount) {
        const selectedTariffId = createdProjectData.tariffId;
        const voucher = voucherCode.find(item => item.name === this.state.voucherCodeToRedeem)
        if (voucher != null) {
          this.setState({ voucher: voucher })
          const voucherValidTariff = voucher.validTariff;
          const currentDate = new Date().getTime();

          if (
            voucherValidTariff.includes(selectedTariffId) &&
            (currentDate <= voucher.expiryDate) &&
            (voucher.totalOfferRemaining > 0)
          ) {
            isValid = true;
          }

          if (isValid) {
            this.recalculateAmount(totalAmount, invoiceAmount, paymentAmount, voucher)
          } else {
            this.setState({
              invalidText: errorMessagesBE['Invalid Voucher'],
              voucher: null
            })
          }
        } else {
          this.setState({
            invalidText: errorMessagesBE['Invalid Voucher']
          })
        }
      } else {
        this.setState({
          isAfterDiscount: false,
          voucherDisabled: false,
          textBtn: "Einlösen",
          voucher: null,
          voucherCodeToRedeem: '',
          hideCard: false
        })
        this.props.applyVoucher({
          name: 'voucherCodeId',
          value: null
        })
      }
    }

    return (
      <div className="fourth-step page-wrapper">
        <section className="page-block payment-data">
          <h2>{isProduct ? 'Ihre Bestellung' : 'Ihre gewählten Leistungen'}</h2>
          <div className="info">
            <div className="info-block">
              <div className="info-block-item block-header">
                <span>Ihre gewählten Leistungen</span>
                <span>Kosten</span>
              </div>
              {
                isProduct ? null : (
                  <div className="info-block-item entry-fee">
                    <span>Einstellgebühr</span>
                    {options ? options.map((item, i) => {
                      return (
                        item.group === 'init' && (
                          <span key={i}>{(item.amount / 100).toLocaleString('de-CH', { style: 'currency', currency: currencyFilter[languageID] })}</span>
                        )
                      );
                    }) : null}
                  </div>
                )
              }
              {/* {isProduct ? null : createdProjectData.projectOptions &&
                options.map(
                  (item, i) =>
                    item._id === createdProjectData.projectOptions[1]._id && !item.isDeleted && (
                      <div className="info-block-item days-included" key={i}>
                        <span>{item.title}</span>
                        <span>{item.description}</span>
                      </div>
                    )
                )} */}
              {tariffs.map(
                (item, i) =>
                  item._id === createdProjectData.tariffId && (
                    <div className="info-block-item prize-money" key={i}>
                      {/* <span>{item.name}</span> */}
                      <span>{item.name}</span>
                      <span>{(item.amount / 100).toLocaleString('de-CH', { style: 'currency', currency: currencyFilter[languageID] })}</span>
                    </div>
                  )
              )}
              {
                isProduct ? null : (
                  <div className="info-block-item">
                    <span>Extras</span>
                    <span></span>
                  </div>
                )
              }
              {isProduct ? null : renderExtraTariff()}
              <br></br>
              <div className="info-block-item">
                <span>Gutschein</span>
              </div>
              {
                this.state.voucher != null ? (
                  <div className="info-block-item extra-tariff">
                    <span>{this.state.voucher.description}</span>
                    <span>{currencyFilter[languageID]} {(this.state.voucher.amount / 100).toLocaleString('de-CH')}</span>
                  </div>
                ) : null
              }
              <hr />
              <div className="info-block-item total-sum">
                <span>Total netto</span>
                <span>{totalBeforeTax.toLocaleString('de-CH', { style: 'currency', currency: currencyFilter[languageID] })}</span>
              </div>
              <div className="info-block-item total-sum">
                <span>MwSt {taxAmount[languageID]}%</span>
                <span>{taxRate.toLocaleString('de-CH', { style: 'currency', currency: currencyFilter[languageID] })}</span>
              </div>
              <div className="info-block-item total-sum">
                <span>Total inkl. MwSt.</span>
                <span>{(createdProjectData.totalAmount / 100).toLocaleString('de-CH', { style: 'currency', currency: currencyFilter[languageID] })}</span>
              </div>
              <hr />
              {isProduct ? null : (
                <div>
                  <div className="info-block-item">
                    <span>in Rechnung gestellter Betrag (Rechnung erhalten Sie per Mail)</span>
                    <span>{invoiceAmount.toLocaleString('de-CH', { style: 'currency', currency: currencyFilter[languageID] })}</span>
                  </div>
                  <div className="info-block-item" style={{ fontWeight: 'bold' }}>
                    <span>sofort fällig per Kreditkarte</span>
                    <span>{paymentAmount.toLocaleString('de-CH', { style: 'currency', currency: currencyFilter[languageID] })}</span>
                  </div>
                </div>
              )}
              <br /><br />
              <div className="info-block-item voucher-wrapper" >
                  <FormInput
                    label="Gutscheincode"
                    name="voucherCode"
                    type="text"
                    disabled={this.state.voucherDisabled}
                    value={this.state.voucherCodeToRedeem}
                    handleChange={this.onHandleVoucherCode}
                    error={this.state.invalidText}
                  />
                <Button
                  handleClick={() => voucherHandler()}
                  text={this.state.textBtn}
                  className='voucher-button'
                />
              </div>
            </div>
            {isMoneyBack ? (
              <div className="info-block-money_back">
                <img src={fourthStepUnion} alt="Union" />
                <h3>Zufriedenheits-Garantie</h3>
                <p>Bei Nicht-Gefallen erhalten Sie Ihr Preisgeld zurück.</p>
              </div>
            ) : null}
          </div>
        </section>
        {!this.state.hideCard ? (
          <section className="page-block payment-method">
            <h2>Wählen Sie Ihre Zahlungsart</h2>
            <div className="payment-block">
              <div className="payment-block-card">
                <ListItemContainer
                  children={
                   <span>Visa / Mastercard</span>
                  }
                  imgSrc={[icons[0], icons[1]]}
                  comment="* Bitte geben Sie Ihre Karteninformationen ein. Es ist 100% sicher."
                  classname="two-items-left "
                />
                <div className="line">
                  <hr />
                </div>

                <CardInfoForm />
              </div>
            </div>
          </section>
        ) : null}
        <p className="error-be">{transactionReducer.errors.fromBe}</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { createProjectReducer, projectReducer, transactionReducer, tariffReducer, extraTariffReducer, voucherCodeReducer } = state
  return {
    createProjectReducer,
    projectReducer,
    transactionReducer,
    tariffReducer,
    extraTariffReducer,
    voucherCodeReducer,
  }
};

export default connect(mapStateToProps, {
  changePaymentMethod,
  applyVoucher
})(FourthStep);