import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap';
import IFrame from '../../../../shared/IFrame';
import { openModal, closeModal } from '../../../../redux/actions/transactionActions'

class FifthStep extends Component {
  componentDidMount() {
    this.props.openModal();
  }
  render() {
    const { transactionReducer, closeModal } = this.props
    const { isShow, isLoading } = transactionReducer;
    return (
      <>
        <Modal
          centered
          size="xl"
          show={isShow, isLoading}
          onHide={closeModal}>
          <IFrame />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { transactionReducer } = state
  return {
    transactionReducer,
  }
};

export default connect(mapStateToProps, {
  openModal, closeModal
})(FifthStep);