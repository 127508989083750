import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
export default function Designer({languageID}) {
  return (
    <Fragment>
      <Link to={`/${languageID}`}>
        <p className="designer">Faberling</p>
      </Link>
    </Fragment>
  );
}
