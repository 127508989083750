import React, { Fragment, useEffect, useState } from 'react';
import ListItemContainer from '../../../../shared/ListItemContainer';
import Checkbox from '../../../../shared/CheckBox';
import Tariff from '../../../../shared/Tariff';
import TariffGarden from '../../../../shared/TariffGarden';
import UpsellCard from '../../../../shared/UpsellCard/';
import { paymentsProcessing } from '../../../../constants/thirdStep';
import { useSelector, useDispatch } from 'react-redux'
import CounterBox from '../../../../shared/CounterBox';
import { titles } from '../../../../constants/createProjectTitles';
import summaryIcon from '../../../../assets/summary.png';
import closeIcon from '../../../../assets/close.svg';

// Import Redux Actions 
import {
  changeValue,
  changeValueInProjectOption,
  changeValueInDetails
} from '../../../../redux/actions/createProjectActions'
import { SUBMIT_CONCEPT_SET_PREVIEW } from '../../../../redux/types';

const ThirdStep = props => {
  useEffect(() => window.scrollTo(0, 0), []);
  const dispatch = useDispatch()

  // Adding Redux features
  const { valuesCreateProject, category, type, options, step } = useSelector(state => state.createProjectReducer)
  const { paymentProcessing, tariffId, extra, projectOptions } = valuesCreateProject;
  const tariffGroup = category === 'GARDEN' ? 'garden' : 'basic';
  const isMehr = type === 'Mehrfamilienhaus' ? true : false;
  const { tariffs } = useSelector(state => state.tariffReducer)
  const { extraTariffs } = useSelector(state => state.extraTariffReducer)
  const [selectedExtraTariff, setSelectedExtraTariff] = useState(extra);
  const [isExtraUpdated, setIsExtraUpdated] = useState(false);
  // const [preAmount, setPreAmount] = useState(0)
  const pathname = props.location.pathname
  const languageID = pathname.split('/')[1]
  const currencyFilter = {
    ch: 'CHF',
    de: 'EUR',
  }

  let preAmount = 0;
  let selectedTariff = getSelectedTariffData(tariffId);
  let relatedExtra = selectedTariff && selectedTariff.length ? selectedTariff[0].extra : [];
  let arr = extraTariffs

  // console.log(extraTariffs)

  // transfer to state and useeffect for improvement
  // -- old structure :: 16-06-2021 --
  // extraTariffs && extraTariffs.forEach((extra) => {
  //   let extraData = relatedExtra.find(_extra => _extra.extraTariffId == extra._id);
  //   arr = [...arr, {
  //     ...extra,
  //     selectableType: extraData ? extraData.selectableType : 0
  //   }];
  // })

  // Provide the tariff number for the tariff that has a money guarantee selected
  const tariffForMoneyGuarantee = tariffId == 9 || tariffId == 13

  if (valuesCreateProject) {
    let preTotalAmount = 0;
    let selectedTariff = tariffs && valuesCreateProject.tariffId ? tariffs.find(item => item._id == valuesCreateProject.tariffId) : null;

    options.map((item, i) => {
      if (item.group === 'init') {
        preTotalAmount = preTotalAmount + item.amount
      }
    })

    if (selectedTariff) {
      preTotalAmount = preTotalAmount + selectedTariff.amount
      if (valuesCreateProject.extra && valuesCreateProject.extra.length) {
        extraTariffs.map((extra, i) => {
          if (valuesCreateProject.extra.includes(extra._id)) {
            let isIncluded;
            if (valuesCreateProject.extra.includes(languageID === 'ch' ? 14 : 18)) {
              const includedArr = languageID === 'ch' ? [11, 12, 13] : [15, 16, 17]
              if (includedArr.includes(extra._id)) {
                isIncluded = true;
              }
            } else if (valuesCreateProject.extra.includes(languageID === 'ch' ? 13 : 17)) {
              const includedArr = languageID === 'ch' ? [11, 12] : [15, 16]
              if (includedArr.includes(extra._id)) {
                isIncluded = true;
              }
            } else if (valuesCreateProject.extra.includes(languageID === 'ch' ? 12 : 16)) {
              const includedArr = languageID === 'ch' ? [11] : [15]
              if (includedArr.includes(extra._id)) {
                isIncluded = true;
              }
            } else {
              isIncluded = false;
            }
            if (!isIncluded) {
              preTotalAmount = preTotalAmount + extra.amount
            }
          }
        })
      }
      // setPreAmount(preTotalAmount)
      preAmount = preTotalAmount
    }
  }

  useEffect(() => {
    if (isMehr) {
      updateSelectedTariff(16)
    }
  }, [])

  useEffect(() => {
    // updateSelectedTariff(tariffId)
    // Logic Added to manage display of checklist item for tariff option 3
    if (tariffForMoneyGuarantee) {
      dispatch(changeValue('paymentProcessing', "IS_PRICE_FUND"))
    }
  }, [tariffId, isExtraUpdated])

  // For setting tariffID to valid tariff for country code
  useEffect(() => {
    if (tariffId && step === 'tariffs') {
      switch (category) {
        case 'BUILDING':
          if (languageID === 'de') {
            updateSelectedTariff(19)
          }
        case 'RENOVATION':
        case 'SPECIAL':
          if (languageID === 'de') {
            if (!([11, 12, 13].includes(tariffId))) {
              updateSelectedTariff(12)
            }
          } else {
            if (!([7, 8, 9].includes(tariffId))) {
              updateSelectedTariff(18)
            }
          }
          break;
        case 'GARDEN':
          if (languageID === 'de') {
            if (!([4, 5, 6].includes(tariffId))) {
              updateSelectedTariff(5)
            }
          } else {
            if (!([4, 5, 6].includes(tariffId))) {
              updateSelectedTariff(5)
            }
          }
          break;
        case 'PRODUKT':
          break;
        default:
          break;
      }

    }
  }, [step, category])

  useEffect(() => {
    if (valuesCreateProject) {
      setSelectedExtraTariff(valuesCreateProject.extra)
    }
  }, [valuesCreateProject])

  function updateSelectedTariff(value) {
    let selectedTariff = getSelectedTariffData(value);
    let defaultExtra = [];
    let defaultSelectedExtra = [];
    // get the default selected extra which is not removable
    if (selectedTariff.length && (typeof selectedTariff[0].extra !== 'undefined')) {
      defaultExtra = selectedTariff[0].extra.filter(extra => extra.selectableType == 2);
    }
    for (let i = 0; i < defaultExtra.length; i++) {
      // get only extra id, eg: [1, 2]
      defaultSelectedExtra.push(defaultExtra[i].extraTariffId)
    }
    dispatch(changeValue('tariffId', value))
    // dispatch(changeValue('extra', defaultSelectedExtra));
  }

  function updateSelectedExtraTariff(value) {
    const extra = extraTariffs.find(extra => extra._id == value)
    const extraIncluded = extra && extra.extraIncluded ? extra.extraIncluded : []
    // setIsExtraUpdated(!isExtraUpdated)
    let selectedExtra = [];
    let selectedExtraWithoutPackage = [];
    let removeAllAdditionalExtra = false;

    if (selectedExtraTariff.includes(value)) {
      selectedExtra = selectedExtraTariff.filter((val) => val !== value)
      removeAllAdditionalExtra = true
    } else {
      selectedExtra = selectedExtraTariff;
      selectedExtra.push(value)
    }

    if (selectedExtra && selectedExtra.length) {
      // get the selected but non included in the extra package
      selectedExtraWithoutPackage = selectedExtra.filter(selectedExtraId => extraIncluded && !extraIncluded.includes(selectedExtraId))
    }

    // combine the non additional extra with the extra package
    if (removeAllAdditionalExtra) {
      selectedExtra = selectedExtraWithoutPackage
    } else {
      selectedExtra = [...selectedExtraWithoutPackage, ...extraIncluded]
    }

    setSelectedExtraTariff(selectedExtra)
    dispatch(changeValue('extra', selectedExtra));
  }

  function getSelectedTariffData(tariffId) {
    return tariffs && tariffs.filter(tariff => tariff._id == tariffId)
  }

  function renderTariff() {
    if (tariffs) {
      var filteredTariff = tariffs
        .filter((tariff) => tariffGroup === tariff.group && tariff.currency === currencyFilter[languageID])
        .sort(function (a, b) {
          if (a.amount < b.amount) { return -1; }
          if (a.amount > b.amount) { return 1; }
          return 0;
        });

      const mehrTariff = [15, 16, 17];

      if (isMehr) {
        filteredTariff = filteredTariff.filter((tariff) => mehrTariff.includes(tariff._id))
      } else {
        filteredTariff = filteredTariff.filter((tariff) => !mehrTariff.includes(tariff._id))
      }

      return filteredTariff.map((item) => (
        tariffGroup === 'basic'
          ? <Tariff
            key={item._id}
            id={item._id}
            amount={item.amount}
            name={item.name}
            advantages={item.advantages}
            onClick={(value) => updateSelectedTariff(value)}
            advantageTitle={item.advantageTitle}
            description={item.description}
            planType={item.planType}
            selectedTariff={tariffId}
            currency={currencyFilter[languageID]}
            scale={item.scale}
          />
          : <TariffGarden
            key={item._id}
            id={item._id}
            amount={item.amount}
            name={item.name}
            advantages={item.advantages}
            onClick={(value) => updateSelectedTariff(value)}
            advantageTitle={item.advantageTitle}
            description={item.description}
            planType={item.planType}
            selectedTariff={tariffId}
            currency={currencyFilter[languageID]}
          />
      ))
    }
  }

  function renderExtras() {
    const mehrExtra = [9, 2, 3, 10];
    const mehrExtraToRemove = [9, 10];
    var filteredExtra = [];

    if (tariffGroup === 'basic' && arr.length > 0 && selectedExtraTariff) {
      if (isMehr) {
        filteredExtra = arr.filter((extra) => mehrExtra.includes(extra._id))
      } else {
        filteredExtra = arr.filter((extra) => !mehrExtraToRemove.includes(extra._id))
      }
    }

    return filteredExtra.map((item) => {
      if (item.currency === currencyFilter[languageID]) {
        return (
          <UpsellCard
            key={item._id}
            id={item._id}
            selectedGroup={selectedExtraTariff}
            selectableType={item.selectableType}
            amount={item.amount}
            name={item.name}
            description={item.description}
            onClick={(value) => updateSelectedExtraTariff(value)}
            currency={currencyFilter[languageID]}
            additionalInfo={item.additionalInfo}
            isHypoReady={item.isHypoReady}
            languageID={languageID}
          />
        )
      }
    })
  }

  return (
    <div className="third-step">
      {/* 
      Removed based on requirements. Keeping the code in case we will require selectable options in the future

      <h2>Laufzeit Ihres Projektes</h2>
      <div className="days">
        {options.slice(1).map((projectOption) => (
          <ListItemContainer
            key={projectOption._id}
            title={projectOption.description}
            children={
              <Checkbox
                value={projectOption._id}
                title={projectOption.title}
                isBig={true}
                onClick={(value) => dispatch(changeValueInProjectOption(value))}
                checkedField={projectOptions[0]}
              />
            }
            classname="two-items-left "
          />
        ))}
      </div> */}

      <h2>Wählen Sie die Projektlaufzeit</h2><br /><br />
      <div className="selectable-blocks">
        <div className="selectable-blocks-wrapper">
          <ListItemContainer
            title={titles.thirdStepCounterTitles[0].title}
            titleHasInfo={true}
            titleInfoBlock="Für Ihr Projekt empfehlen wir eine Laufzeit von 30 Tagen. Kürzere Laufzeiten bis 7 Tage sind möglich können jedoch einen Einfluss auf die Quantität und Qualität der eingereichten Konzepte haben."
            children={
              <Fragment>
                <CounterBox
                  counter={
                    valuesCreateProject.details[
                    titles.thirdStepCounterTitles[0].value
                    ]
                  }
                  onClick={(value) =>
                    dispatch(
                      changeValueInDetails(
                        titles.thirdStepCounterTitles[0].value,
                        value
                      )
                    )
                  }
                  title={titles.thirdStepCounterTitles[0].value}
                />
              </Fragment>
            }
            classname="two-items"
            titleClassName="with-info"
          />
        </div>
      </div>
      <br /><br /><br />

      <h2>Ihr Kreativ-Wettbewerb enthält:</h2>
      <div className="tariffs">
        {renderTariff()}
        <div className="summary-mobile" onClick={() => {
          document.getElementById("project-summary").classList.add("show")
        }}>
          <div><img src={summaryIcon} /></div>
        </div>
        <div id="project-summary" className="summary">
          <div className="info">
            <div className="info-block">
              <h3 className="summary-header">
                Zusammenfassung
                <span className="close-summary" onClick={() => {
                  document.getElementById("project-summary").classList.remove("show")
                }}><img src={closeIcon} /></span>
              </h3>
              <div className="info-block-item block-header">
                <span style={{ textAlign: 'left' }}>Ihre gewählten Leistungen</span>
                <span>Kosten</span>
              </div>
              <div className="info-block-item entry-fee">
                <span><strong>Einstellgebühr</strong></span>
                {options && options.length ? options.map((item, i) => {
                  return (
                    item.group === 'init' && (
                      <span key={i}>{(item.amount / 100).toLocaleString('de-CH', { style: 'currency', currency: currencyFilter[languageID] })}</span>
                    )
                  );
                }) : null}
              </div>
              {tariffs && tariffs.length ? tariffs.map(
                (item, i) =>
                  item._id === valuesCreateProject.tariffId && (
                    <div className="info-block-item">
                      <span><strong>{item.name}</strong></span>
                      <span key={i}>{(item.amount / 100).toLocaleString('de-CH', {
                        style: 'currency', currency: currencyFilter
                        [languageID]
                      })}</span>
                    </div>
                  )
              ) : null}
              {valuesCreateProject.extra && valuesCreateProject.extra.length && extraTariffs && extraTariffs.length
                ? <div className="info-block-item">
                  <span><strong>Extras</strong></span>
                  <span></span>
                </div>
                : null
              }
              <div className="info-block-item">
                <div className="block-child">
                  {valuesCreateProject.extra && valuesCreateProject.extra.length ? extraTariffs && extraTariffs.length ? extraTariffs.map((extra, i) => {
                    if (valuesCreateProject.extra.includes(extra._id)) {
                      let isIncluded;
                      if (valuesCreateProject.extra.includes(languageID === 'ch' ? 14 : 18)) {
                        const includedArr = languageID === 'ch' ? [11, 12, 13] : [15, 16, 17]
                        if (includedArr.includes(extra._id)) {
                          isIncluded = true;
                        }
                      } else if (valuesCreateProject.extra.includes(languageID === 'ch' ? 13 : 17)) {
                        const includedArr = languageID === 'ch' ? [11, 12] : [15, 16]
                        if (includedArr.includes(extra._id)) {
                          isIncluded = true;
                        }
                      } else if (valuesCreateProject.extra.includes(languageID === 'ch' ? 12 : 16)) {
                        const includedArr = languageID === 'ch' ? [11] : [15]
                        if (includedArr.includes(extra._id)) {
                          isIncluded = true;
                        }
                      } else {
                        isIncluded = false;
                      }
                      return (
                        <div className='info-row-item' key={i}>
                          <span>{extra.name}</span>
                          <span style={{ marginLeft: '0.5rem' }}>
                            {isIncluded ? 'inbegriffen' : extra.currency + ' ' + (extra.amount / 100).toLocaleString('de-CH')}
                          </span>
                        </div>
                      )
                    }
                  }) : null : null}
                </div>
              </div>
              <hr style={{ marginTop: 0, marginBottom: 15 }} />
              <div className="info-block-item">
                <span><strong>Total inkl. MwSt.</strong></span>
                <span style={{ marginLeft: '0.5rem' }}>{currencyFilter[languageID] + ' ' + (preAmount / 100).toLocaleString('de-CH')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {tariffGroup === 'basic' && (
        <React.Fragment>
          <h2>Benötigen Sie zusätzliche Leistungen?</h2>
          <p>(Erstellt nur der Gewinnerarchitekt Ihres Wettbewerbs)</p>
        </React.Fragment>
      )} <br /><br /><br />
      <div className="upsellContainer">
        {renderExtras()}
      </div>
      {/* <ListItemContainer
        title="Zahlungsabwicklung"
        hasInfo={true}
        infoBlock="Für Ihr Projekt empfehlen wir eine Laufzeit von 30 Tagen. Kürzere Laufzeiten bis 7 Tage sind möglich können jedoch einen Einfluss auf die Quantität und Qualität der eingereichten Konzepte haben."
        children={
          <Fragment>
            {paymentsProcessing.map((item, i) => {
              // Logic Added to manage display of checklist item for tariff option 3
              var disabled = false
              if (tariffForMoneyGuarantee && item.value === "MONEY_BACK") {
                disabled = true
              }
              var displayCheckbox = true;
              if (isMehr) {
                displayCheckbox = i == 0 ? false : true
              }

              if (displayCheckbox) {
                return (
                  <Checkbox
                    key={item.value}
                    hasInfo={true}
                    value={item.value}
                    title={item.title}
                    onClick={(value) => disabled ? null : dispatch(changeValue('paymentProcessing', value))}
                    checkedField={paymentProcessing}
                    infoBlock={item.infoBlock}
                    disabled={disabled}
                    checkmarkClassName="round-checkmark"
                  />
                )
              }
            })}
          </Fragment>
        }
        classname="three-items"
      /> */}
    </div>
  );
};

export default ThirdStep;
