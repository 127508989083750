export const communicationTitles = {
  PUBLIC_COMMENTS_TITLE: "Öffentliche Kommentare",
  PRIVATE_COMMENTS_GROUP_TITLE: "Teilnehmer",
  REFRESH: "Aktualisierung",
  PUBLIC_DESCRIPTION: "* Nachrichten in diesem Chat sind für alle Projektteilnehmer sichtbar",
  PRIVATE_DESCRIPTION: "* Privater Chat",
  SEND_COMMENT: "Kommentar Senden",
  PLACEHOLDER: "Schreiben öffentliche kommentare",
  CONFIRMATION_WINNER: "Bestätigung Gewinner",
  EMPTY_COMMENTLIST: "Noch keine Kommentare",
};