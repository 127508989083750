import React from 'react';
import { Link } from 'react-router-dom';
import resolvePath from '../../../../helpers/validator/resolvePath';

export default function NoProjects() {
  return (
    <div className="projectWrapper">
      <h1>
        Sie haben noch keine Projekte. Bitte fangen sie beim Projekt Anlegen an!
      </h1>
      <div className="create-project">
        <Link to={resolvePath('/create-project/style')}>
          <p>Neues Projekt</p>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13 7.5C13 7.22386 12.7761 7 12.5 7C12.2239 7 12 7.22386 12 7.5V11H8.5C8.22386 11 8 11.2239 8 11.5C8 11.7761 8.22386 12 8.5 12H12V15.5C12 15.7761 12.2239 16 12.5 16C12.7761 16 13 15.7761 13 15.5V12H16.5C16.7761 12 17 11.7761 17 11.5C17 11.2239 16.7761 11 16.5 11H13V7.5Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22 11.5C22 16.7467 17.7467 21 12.5 21C7.25329 21 3 16.7467 3 11.5C3 6.25329 7.25329 2 12.5 2C17.7467 2 22 6.25329 22 11.5ZM21 11.5C21 16.1944 17.1944 20 12.5 20C7.80558 20 4 16.1944 4 11.5C4 6.80558 7.80558 3 12.5 3C17.1944 3 21 6.80558 21 11.5Z"
              fill="white"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
}
