import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import { userRole } from '../../constants/userRole';
import { postProject } from '../../redux/actions/ProjectActions'
import { saveProjectData, changeValue } from '../../redux/actions/createProjectActions'

export default function Auth(WrappedComponent) {
  class Wrapper extends Component {
    render() {
      const {
        globalReducer,
        projectReducer,
        createProjectReducer,
        transactionReducer,
      } = this.props

      const { pathname, state } = this.props.location;
      var languageID = pathname.split('/')[1]
      const noLanguageID = pathname === '/' || pathname === ''
      // Add default language
      if (noLanguageID) { languageID = 'ch' }
      const isLoginPage = pathname === `/${languageID}/login`;
      const isProfilePage = pathname.split('/')[2] === 'profile';
      const isCreateProjectPage = pathname.split('/')[2] === 'create-project';
      const isPayPage = pathname.split('/')[3] === 'pay';

      const isHomePage = pathname === `/ch` || pathname === `/de` || pathname === '/' || pathname === ''
      const inDesigner =
        globalReducer.User && globalReducer.User.role === userRole.COMPANY;
      const isRedirectToCreateProject =
        createProjectReducer.redirectToCreateProject;
      const { payment } = transactionReducer;


      if (isPayPage && isRedirectToCreateProject && payment === 'SUCCEEDED')
        return <Redirect to={`/${languageID}/profile/my-projects`} />;

      if (inDesigner && isCreateProjectPage)
        return <Redirect to={`/${languageID}/profile/my-projects`} />;

      if (globalReducer.User && isLoginPage && isRedirectToCreateProject) {
        let newProjectData = createProjectReducer.valuesCreateProject;
        let place = 'pay';
        let slugUrl = 'create-project/pay';
        if (newProjectData.category === 'PRODUKT') {
          newProjectData = {
            ...createProjectReducer.valuesCreateProject,
            email: globalReducer.User.email,
            name: 'Plan-Digitalisierung'
          }
        }
        if (newProjectData.type === 'Mehrfamilienhaus') {
          place = 'success';
          slugUrl = 'create-project/success'
          newProjectData = {
            ...createProjectReducer.valuesCreateProject,
            email: globalReducer.User.email
          }
        }
        this.props.postProject(newProjectData);
        this.props.saveProjectData(place);
        return <Redirect to={`/${languageID}/${slugUrl}`} />;
      }

      if (!globalReducer.User && isProfilePage) {
        return <Redirect to={`/${languageID}/login`} />;
      }

      if (globalReducer.User && isLoginPage && !state) {
        return <Redirect to={`/${languageID}/profile/my-projects`} />;
      }

      if (globalReducer.User && isLoginPage && state) {
        return <Redirect to={state.prevPath} />;
      }

      if (globalReducer.User && isHomePage) {
        return <Redirect to={`/${languageID}/profile/my-projects`} />;
      }

      if (!globalReducer.User && isHomePage) {
        return <Redirect to={`/${languageID}/create-project/style`} />;
      }
      return <WrappedComponent {...this.props} />;
    }
  }
  const mapStateToProps = (state) => {
    const { globalReducer, projectReducer, createProjectReducer, transactionReducer } = state
    return {
      globalReducer,
      projectReducer,
      createProjectReducer,
      transactionReducer,
    }
  };

  return connect(mapStateToProps, { postProject, saveProjectData, changeValue })(Wrapper);
  ;
}
