import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { changeValueInDetails, changeType, changeValue } from '../../redux/actions/createProjectActions'

class SelectedCardGroup extends Component {
  getChildContext() {
    return {
      selectedCardGroup: {
        onChange: this.onChange.bind(this),
        fieldName: this.props.fieldName,
      },
    };
  }

  onChange(value) {
    const { fieldName } = this.props;
    const findInDetails = Object.keys(
      this.props.createProjectReducer.valuesCreateProject.details
    ).find(key => key === fieldName);
    if (findInDetails) {
      this.props.changeValueInDetails(fieldName, value);
    } else {
      if (fieldName === 'type') {
        this.props.changeType(value);
      } else {
        this.props.changeValue(fieldName, value);
      }
    }
  }

  render() {
    return <div className="radio-group">{this.props.children}</div>;
  }
}

SelectedCardGroup.childContextTypes = {
  selectedCardGroup: PropTypes.object,
};

SelectedCardGroup.propTypes = {
  fieldName: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { createProjectReducer } = state
  return {
    createProjectReducer,
  }
};

export default connect(mapStateToProps, { changeValueInDetails, changeType, changeValue })(SelectedCardGroup);
