import { CREATE_COMMENT, GET_COMMENTS, GET_PRIVATE_CONTACTS, OPEN_CONVERSATION_OFFER, SET_CHAT_MESSAGES, TOGGLE_CHAT } from '../types';
import { instance } from '../http'

export const toggleChat = (id) => dispatch => {
    dispatch({
        type: TOGGLE_CHAT,
        payload: id,
    })
}

export const createComment = (id, data) => dispatch => {
    instance.post(`/conversations/project/${id}`, data)
        .then(response => {
            dispatch({
                type: CREATE_COMMENT,
                payload: response.data
            })
        })
        .catch(error => {
            console.log(error)
            throw error;
        })
};

export const getPublicComments = (id, chatID) => (dispatch, getState) => {
    // Need to be fixed. getPublic comments is being called in the concept page leading to errors
    if (chatID&&chatID.length < 4) {
        return null
    }
    const user = getState().globalReducer.User
    instance.get(`/conversations/project/${id}`)
        .then(response => {
            dispatch({
                type: GET_COMMENTS,
                payload: { response: response.data, chatID, user }
            })
        })
        .catch(error => {
            console.log(error)
            throw error;
        })
};

export const setChatMessages = (id) => (dispatch, getState) => {
    const { conversations } = getState().communicationReducer
    if (!conversations.length) return;
    const user = getState().globalReducer.User
    dispatch({
        type: SET_CHAT_MESSAGES,
        payload: {
            id,
            user
        }
    })
};

export const getPrivateContacts = () => (dispatch, getState) => {
    const user = getState().globalReducer.User
    dispatch({
        type: GET_PRIVATE_CONTACTS,
        payload: user
    })
};

export const openConversationOnOffer = (id, offerId) => (dispatch, getState) => {
    instance.get(`/conversations/project/${id}`)
        .then(response => {
            const user = getState().globalReducer.User
            dispatch({
                type: OPEN_CONVERSATION_OFFER,
                payload: {
                    id,
                    user,
                    comments: response.data,
                    offerId
                }
            })
        })
        .catch(error => {
            console.log(error)
            throw error;
        })
}

// Not in use
// export const getOfferComments = (id) => dispatch => {
//     instance.get(`/comments/offer/${id}`)
//         .then(response => {
//             dispatch({
//                 type: GET_OFFER_COMMENTS,
//                 payload: response.data
//             })
//         })
//         .catch(error => {
//             console.log(error)
//             throw error;
//         })
// }

