import { CHANGE_AUTH_FIELD, CLEAR_AUTH, AUTH_LOADING, AUTH_VALIDITY, AUTH_BLUR, AUTH_FOCUS, AUTH_SUCCESS, AUTH_SET_ERROR_BE } from '../types';
import { instance } from "../http";
import validator from '../../helpers/validator/validation';
import { login } from './globalActions'
import errorsString from '../../helpers/validator/errorsString';

export const setErrorBE = message => dispatch => {
    dispatch({
        type: AUTH_SET_ERROR_BE,
        payload: message
    })
};

export const setLoading = status => dispatch => {
    dispatch({
        type: AUTH_LOADING,
        payload: status
    })
};

export const onChange = ({ target }) => dispatch => {
    dispatch({
        type: CHANGE_AUTH_FIELD,
        payload: target
    })

};

export const onFocus = ({ target }) => dispatch => {
    dispatch({
        type: AUTH_FOCUS,
        payload: target
    })
};

export const onBlur = ({ target }) => dispatch => {
    dispatch({
        type: AUTH_BLUR,
        payload: target
    })
};

export const onSubmit = event => (dispatch, getState) => {
    event.preventDefault();
    const { fields, formState } = getState().authReducer
    //checking which field (email OR login) will be sent to backend
    const dataKey = validator.validate('email', fields.login).isValid
        ? 'email'
        : 'login';
    const data = {
        password: fields.password,
        [dataKey]: fields.login,
    };
    dispatch(checkForm(data))
};

export const checkForm = (data) => (dispatch, getState) => {
    const { fields, formState } = getState().authReducer
    Object.keys(fields).forEach(item => {
        if (!fields[item]) {
            formState.errors[item] = errorsString.COMMON_ERRORS.IS_REQ;
        }
    });
    const isAllFieldsValid = Object.values(formState.errors).every(
        field => !field
    );
    dispatch({
        type: AUTH_VALIDITY,
        payload: {
            formState,
            fields
        }
    })
    if (!isAllFieldsValid) {
        return;
    }
    dispatch(loginAuth(data))
};

export const loginAuth = data => dispatch => {
    dispatch(setLoading(true))
    instance.post('/auth/authorize', data)
        .then(response => {
            dispatch({
                type: AUTH_SUCCESS,
                payload: response.data
            })
            const { account, status } = response.data
            dispatch(login(account, status, account.role));
            dispatch(setLoading(false))
        })
        .catch(error => {
            if (error.response.status === 403 || error.response.status === 400) {
                dispatch(setErrorBE('Login/ E-mail oder Passwort ist falsch'))
            }
            dispatch(setLoading(false))
            throw error;
        })
};

export const clearAuthStore = () => dispatch => {
    dispatch({
        type: CLEAR_AUTH
    })
}