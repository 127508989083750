import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import FormInput from '../../../../../../shared/FormInput';
import Switch from '../../../../../../shared/Switch';
import CheckBox from '../../../../../../shared/CheckBox';
import SelectedCardGroup from '../../../../../../shared/SelectedCardGroup';
import SelectableCard from '../../../../../../shared/SelectedCardGroup/components/SelectedCard';
import { titles } from '../../../../../../constants/createProjectTitles';
import { constrMethods, roofType } from '../../../../../../constants/firstStep';
import ListItemContainer from '../../../../../../shared/ListItemContainer';
import FileItem from '../../../../../../shared/FileItem';
import upload from '../../../../../../assets/upload.svg';
import SingleRangeSlider from '../../../../../../shared/Slider';
import RadioGroup from '../../../../../../shared/RadioGroup';
import Radio from '../../../../../../shared/RadioGroup/components/Radio';

// Import Redux Actions 
import {
  changeValue,
  changeBoxes,
  changeValueInDetails,
  extractBudgetData,
  onChange,
  onUpload,
  deleteFile,
  saveProjectDataProgress
} from '../../../../../../redux/actions/createProjectActions'

class RenovationMehr extends Component {
  render() {
    const {
      changeValue,
      changeBoxes,
      changeValueInDetails,
      extractBudgetData,
      onUpload,
      onChange,
      deleteFile,
      location,
      saveProjectDataProgress
    } = this.props

    const {
      emailState,
      files,
      valuesCreateProject
    } = this.props.createProjectReducer

    const { name, email } = valuesCreateProject;

    const {
      requirementsForArchitect,
      constructionType,
      budget,
      hasAtrium,
      hasGallery,
      area,
      kitchenType,
      roomDesign,
    } = valuesCreateProject.details;

    function handleBlur(e) {
      saveProjectDataProgress()
    }

    return (
      <div className="second-step">
        <section className="page-block personal-info">
          <h2>Beschreiben Sie Ihr Projekt</h2>
          <div className="personal-info-inputs">
            <FormInput
              label="Vergeben Sie einen Projektnamen"
              name="projectName"
              additionalText="Architekten können den Projektnamen sehen."
              value={name}
              handleChange={(event) => changeValue('name', event.target.value)}
              handleBlur={handleBlur}
            />
            <FormInput
              label="Email-Adresse"
              type="email"
              name="userEmail"
              additionalText="Zur Speicherung Ihres Briefings."
              value={email}
              error={emailState.error}
              handleChange={(event) => {
                onChange(event.target.value);
                changeValue('email', event.target.value);
              }}
              handleBlur={handleBlur}
            />
          </div>
        </section>
        <section className="page-block room-program">
          <h2>Fragen zum Raumprogramm</h2>
          <div className="selectable-blocks">
            <div className="selectable-blocks-wrapper">
              <ListItemContainer
                title="Welche Baumassnahmen möchten Sie umsetzen? "
                children={
                  <Fragment>
                    <CheckBox
                      value={titles.secondStepCheckboxTitles_AnbauAufbau[0]}
                      onClick={() =>
                        changeValueInDetails(
                          'constructionType',
                          titles.secondStepCheckboxTitles_AnbauAufbau[0]
                        )
                      }
                      checkedField={constructionType}
                      title={titles.secondStepCheckboxTitles_AnbauAufbau[0]}
                      isMultiply={true}
                    />
                    <CheckBox
                      value={titles.secondStepCheckboxTitles_AnbauAufbau[1]}
                      onClick={() =>
                        changeValueInDetails(
                          'constructionType',
                          titles.secondStepCheckboxTitles_AnbauAufbau[1]
                        )
                      }
                      checkedField={constructionType}
                      title={titles.secondStepCheckboxTitles_AnbauAufbau[1]}
                      isMultiply={true}
                    />

                    <CheckBox
                      value={titles.secondStepCheckboxTitles_AnbauAufbau[2]}
                      onClick={() =>
                        changeValueInDetails(
                          'constructionType',
                          titles.secondStepCheckboxTitles_AnbauAufbau[2]
                        )
                      }
                      checkedField={constructionType}
                      title={titles.secondStepCheckboxTitles_AnbauAufbau[2]}
                      isMultiply={true}
                    />
                  </Fragment>
                }
                classname="three-items"
              />
              <ListItemContainer
                title="Wieviel m2 möchten Sie total gerne dazugewinnen?"
                classname="two-items"
                children={
                  <Fragment>
                    <FormInput
                      placeholder="Eingabe"
                      measurementUnit="&#13217;"
                      value={area}
                      handleChange={(event) =>
                        changeValueInDetails('area', event.target.value)
                      }
                    />
                  </Fragment>
                }
              />
            </div>
            <div className="selectable-blocks-wrapper">
              {titles.secondStepSwitcherTitles_AnbauAufbau.map((element) => (
                <ListItemContainer
                  key={element.value}
                  title={element.title}
                  onClick={() =>
                    changeValueInDetails(
                      element.value,
                      !valuesCreateProject.details[
                      element.value
                      ]
                    )
                  }
                  children={
                    <Fragment>
                      <Switch
                        value={
                          valuesCreateProject.details[
                          element.value
                          ]
                        }
                        onClick={(value) => {
                          // Removed cause it will double toggle
                          // changeValueInDetails(element.value, value)
                        }}
                      />
                    </Fragment>
                  }
                  classname="two-items"
                />
              ))}
            </div>
          </div>
        </section>

        <section className="page-block living-space">
          <h2>Fragen zum Wohnraumstil?</h2>
          <div className="selectable-blocks">
            <div className="selectable-blocks-wrapper">
              <ListItemContainer
                title={titles.secondStepRadioTitles[0]}
                children={
                  <Fragment>
                    <RadioGroup
                      changeRadio={(value) =>
                        changeValueInDetails('kitchenType', value)
                      }>
                      <Radio
                        selected={kitchenType}
                        value="CLOSED"
                        title="Geschlosse"
                      />
                      <Radio
                        selected={kitchenType}
                        value="WHATEVER"
                        title="Egal"
                      />
                      <Radio
                        selected={kitchenType}
                        value="OPENED"
                        title="Offene"
                      />
                    </RadioGroup>
                  </Fragment>
                }
                classname="two-items"
              />
              <ListItemContainer
                title={titles.secondStepRadioTitles[1]}
                children={
                  <Fragment>
                    <RadioGroup
                      changeRadio={(value) =>
                        changeValueInDetails('roomDesign', value)
                      }>
                      <Radio
                        selected={roomDesign}
                        value="CLOSED"
                        title="Geschlosse"
                      />
                      <Radio
                        selected={roomDesign}
                        value="WHATEVER"
                        title="Egal"
                      />
                      <Radio
                        selected={roomDesign}
                        value="OPENED"
                        title="Offene"
                      />
                    </RadioGroup>
                  </Fragment>
                }
                classname="two-items"
              />
            </div>
            <div className="selectable-blocks-wrapper">
              <ListItemContainer
                title="Wählen Sie zusätzliche Optionen"
                children={
                  <Fragment>
                    <CheckBox
                      value={true}
                      onClick={() => changeBoxes('details', 'hasAtrium')}
                      checkedField={hasAtrium}
                      title={titles.secondStepCheckboxTitles[0]}
                    />
                    <CheckBox
                      value={true}
                      onClick={() => changeBoxes('details', 'hasGallery')}
                      checkedField={hasGallery}
                      title={titles.secondStepCheckboxTitles[1]}
                    />
                  </Fragment>
                }
                classname="two-items children-flex"
              />
            </div>
          </div>
        </section>

        <section className="page-block construction-method">
          <h2>Welche Bauweise bevorzugen Sie?</h2>
          <div className="style">
            <SelectedCardGroup fieldName="skeletonType">
              {constrMethods.map((method, index) => (
                <SelectableCard
                  key={index}
                  path={valuesCreateProject.details}
                  isChangesColor={true}
                  value={method.value}
                  src={method.src}
                  title={method.title}
                />
              ))}
            </SelectedCardGroup>
          </div>
        </section>
        
        <section className="page-block roof-type">
          <h2>Welchen Dachtyp bevorzugen Sie?</h2>
          <div className="style">
            <SelectedCardGroup fieldName="roofType">
              {roofType.map((type, index) => (
                <SelectableCard
                  key={index}
                  path={valuesCreateProject.details}
                  isChangesColor={true}
                  value={type.value}
                  src={type.src}
                  innerTitle={type.title}
                />
              ))}
            </SelectedCardGroup>
          </div>
        </section>

        <SingleRangeSlider
          fieldName="budget"
          min={50000}
          max={5000000}
          defaultValue={budget}
          onChange={extractBudgetData}
          location={location}
        />
        <section className="page-block further-specifications">
          <h2>Haben Sie weitere Vorgaben für die Architekten?</h2>
          <textarea
            value={requirementsForArchitect[0].value}
            onChange={(event) =>
              changeValueInDetails(
                'requirementsForArchitect',
                event.target.value
              )
            }
            className="textarea-for-specifications"
            placeholder="Geben Sie hier Ihre individuellen Wünsche und Anforderung in Textform ein. Umso genauer Sie Ihr Projekt beschreiben, desto exakter können die Architekten in den Konzeptentwürfen darauf eingehen. Sie haben später auch die Möglichkeit, diese Angaben zu ergänzen und der gesamten Architekten-Community mit einem Klick mitzuteilen."
          />
        </section>
        <section className="page-block further-specifications">
          <h2>Zusätzliche Dateien</h2>
          {this.props.globalReducer.User ? (
            <>
              <p className="title">
                Bitte laden Sie vorhandene Fotos, Pläne oder Skizzen etc. hoch
              </p>
              <p className="sub-title">
                Laden Sie hier ggf. Daten, wie z. B. Bilder und Dokumente,
                Dateiupload (max. 200 MB).
              </p>
              <div className="file-list">
                {files &&
                  files.map((item, index) => {
                    return (
                      <FileItem
                        key={item.id}
                        name={item.name}
                        link={item.url}
                        path={item.path}
                        id={index}
                        deleteFile={deleteFile}
                      />
                    );
                  })}
              </div>
              <div className="file-wrapper">
                <input
                  name="files"
                  id="loading_file"
                  type="file"
                  onChange={(event) => {
                    onUpload(event.target.files[0]);
                  }}
                />
                <label htmlFor="loading_file">
                  <span>DATEI HINZUFÜGEN</span>
                  <img src={upload} alt="upload"></img>
                </label>
              </div>
            </>
          ) : (
              <p className="title">
                Wenn Sie Ihr Projekt gestartet haben oder auf Faberling.ch
                registriert sind, können Sie bereits vorhandene Fotos, Pläne oder
                Skizzen Ihrem Briefing hinzufügen.
              </p>
            )}
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { createProjectReducer, globalReducer } = state
  return {
    createProjectReducer,
    globalReducer
  }
};

export default connect(mapStateToProps, {
  changeValue,
  changeBoxes,
  changeValueInDetails,
  extractBudgetData,
  onChange,
  onUpload,
  deleteFile,
  saveProjectDataProgress
})(RenovationMehr);
