import React, { Component } from 'react';
import { connect } from 'react-redux'
import FormInput from '../../../shared/FormInput';
import Button from '../../../shared/Button';
import { Modal } from 'react-bootstrap';
import ForgotPassSuccess from './modals/ForgotPassSuccess';

import { onSubmit, onChange, onBlur, onFocus } from '../../../redux/actions/forgotPassActions'
import { toggleModal } from '../../../redux/actions/modalActions'


class ForgotPassword extends Component {
  render() {
    const {
      onSubmit, onChange, onBlur, onFocus,
      forgotPassReducer,
      toggleModal,
      modalReducer,
      languageID
    } = this.props
    return (
      <>
        <div className="form-wrapper">
          <p className="title">Benutzername oder Passwort vergessen?</p>
          <p className="subTitle">
            Bitte geben Sie unten Ihre E-Mail-Adresse ein, um einen Link zum
            Zurücksetzen des Passworts zu erhalten.
          </p>
          <form className="forgotPassForm" onSubmit={onSubmit}>
            <FormInput
              name="email"
              placeholder="E-Mail-Adresse"
              error={forgotPassReducer.formState.errors.fromBE ? forgotPassReducer.formState.errors.fromBE : forgotPassReducer.formState.errors.email}
              value={forgotPassReducer.fields.email}
              handleChange={(e) => onChange(e)}
              handleBlur={(e) => onBlur(e)}
              handleFocus={(e) => onFocus(e)}
            />
            <Button
              type="submit"
              text="NEUES PASSWORT ZUSENDEN"
              isLoading={forgotPassReducer.formState.isLoading}
            />
          </form>
        </div>
        <Modal
          centered
          size="xl"
          show={modalReducer.componentName === 'forgotPassSuccess'}
          onHide={() => toggleModal('', false)}>
          <ForgotPassSuccess
            onClose={toggleModal}
            text="Wir haben Ihnen soeben eine Nachricht zum Zurücksetzen Ihres
            Passwortes an Ihre E-Mail-Adresse gesendet (schauen Sie auch im
            Spam-Ordner)."
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { forgotPassReducer, modalReducer } = state
  return {
    forgotPassReducer,
    modalReducer
  }
};

export default connect(mapStateToProps, {
  onSubmit, onChange, onBlur, onFocus,
  toggleModal,
})(ForgotPassword);