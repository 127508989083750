import React from 'react';
import Union from '../../../../assets/Union.png';
import Button from '../../../../shared/Button';
import close from '../../../../assets/close.svg';

const ForgotPassSuccess = ({ onClose, text }) => {
    return (
      <div className="submit-winner-concept-wrapper forgot-pass-modal">
        <div className="close" onClick={onClose}>
          <img src={close} alt="close" />
        </div>
        <div className="submit-winner-concept-wrapper__content">
          <img src={Union} alt="InfoIcon" />
            <p>{text}</p>
          <Button text="Okay" handleClick={onClose} />
        </div>
      </div>
    );
}

export default ForgotPassSuccess;
