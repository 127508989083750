import { CHANGE_PAGE, CLEAR_TRANSACTIONS, GET_TRANSACTIONS, GET_TRANSACTION_ID, POST_TRANSACTION, RESET_TRANSACTION, SET_INTERVAL, TRANSACTION_ERROR, TRANSACTION_LOADING, TRANSACTION_MODAL, CLEAR_INTERVAL, TRANSACTION_CHANGE, APPLY_VOUCHER, CLEAR_CARD_DETAILS } from '../types';
import { instance } from "../http";
import { userRole } from '../../constants/userRole';
import { setDocumentOnly } from './additionalInfoProjectActions'

// set transaction

export const setLoading = (state) => dispatch => {
    dispatch({
        type: TRANSACTION_LOADING,
        payload: state
    })
}

export const postTransaction = (projectId) => (dispatch, getState) => {
    dispatch({
        type: RESET_TRANSACTION
    })
    dispatch(setLoading(true))
    const cardDetails = getState().transactionReducer.cardDetails
    cardDetails.projectId = projectId
    // console.log(cardDetails)
    instance.post('/transactions', cardDetails)
        .then(response => {
            dispatch({
                type: POST_TRANSACTION,
                payload: response.data
            })
            dispatch(setLoading(false))
            dispatch({
                type: RESET_TRANSACTION
            })
        })
        .catch(error => {
            dispatch({
                type: TRANSACTION_ERROR,
                payload: error.response.data.message
            })
            console.log(error)
            dispatch(setLoading(false))
        })
};


// get transaction by id

export const getOneTransaction = (transactionId) => dispatch => {
    instance.get(`/transactions/${transactionId}`)
        .then(response => {
            dispatch({
                type: GET_TRANSACTION_ID,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: TRANSACTION_ERROR,
                payload: error.response.data.message
            })
            console.log(error)
        })
};

export const getTransactions = () => (dispatch, getState) => {
    const { limit, page, sort, sort_by } = getState().transactionReducer.params
    instance.get(
        `/transactions?limit=${limit}&sort=${sort}&page=${page}`
    )
        .then(response => {
            dispatch({
                type: GET_TRANSACTIONS,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: TRANSACTION_ERROR,
                payload: error.response.data.message
            })
            console.log(error)
        })
};

export const getTransactionsForAdmin = () => (dispatch, getState) => {
    const { limit, page, sort, sort_by } = getState().transactionReducer.params
    instance.get(
        `/payout-requests?limit=${limit}&sort=${sort}&page=${page}`
    )
        .then(response => {
            dispatch({
                type: GET_TRANSACTIONS,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: TRANSACTION_ERROR,
                payload: error.response.data.message
            })
            console.log(error)
        })
};

export const payout = (id) => dispatch => {
    instance.put(`/payout-requests/${id}`, { status: 'PAID' })
        .then(response => {
            dispatch(getTransactionsForAdmin())
        })
        .catch(error => {
            dispatch({
                type: TRANSACTION_ERROR,
                payload: error.response.data.message
            })
            console.log(error)
        })

};

export const clearTransactions = () => dispatch => {
    dispatch({
        type: CLEAR_TRANSACTIONS
    })
};

export const clearCardDetails = () => dispatch => {
    dispatch({
        type: CLEAR_CARD_DETAILS
    })
};

export const handleRequestPage = (page) => async (dispatch, getState) => {
    await dispatch({
        type: CHANGE_PAGE,
        payload: page,
    })

    getState().globalReducer.User.role !== userRole.ADMIN
        ? dispatch(getTransactions())
        : dispatch(getTransactionsForAdmin())
};

export const openModal = (evt, isDocumentOnly = false) => dispatch => {
    dispatch({
        type: TRANSACTION_MODAL,
        payload: true
    })
    dispatch(setDocumentOnly(isDocumentOnly))
};

export const closeModal = () => dispatch => {
    dispatch({
        type: TRANSACTION_MODAL,
        payload: false
    })
};

export const setLinkInterval = (interval) => dispatch => {
    dispatch({
        type: SET_INTERVAL,
        payload: interval
    })
};

export const onTransactionChange = target => dispatch => {
    dispatch({
        type: TRANSACTION_CHANGE,
        payload: target
    })
}

export const applyVoucher = target => dispatch => {
    dispatch({
        type: APPLY_VOUCHER,
        payload: target
    })
}

// export const clearInterval = (interval) => dispatch => {
//     dispatch({
//         type: CLEAR_INTERVAL,
//         payload: interval
//     })
// }

export const reset = () => dispatch => {
    dispatch({
        type: RESET_TRANSACTION,
    })
}