import React from "react";
import { withRouter } from "react-router";
import { useSelector } from 'react-redux'
import NotAuthUser from "./components/NotAuthUser";
import AuthUser from "./components/AuthUser";

const RightSide = ({ location, languageID }) => {
  const User = useSelector(state => state.globalReducer.User)
  return (
    <div className="right-side">
      {User ? <AuthUser location={location} /> : <NotAuthUser location={location} languageID={languageID} />}
    </div>
  );
};

export default withRouter(RightSide);
