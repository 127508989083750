import { CHANGE_SUBMIT_CONCEPT_VALUES, SUBMIT_CONCEPT_LOADING, RESET_SUBMIT_CONCEPT, SUBMIT_CONCEPT_DELETE_FILE, SUBMIT_CONCEPT_ERRORS, SUBMIT_CONCEPT_FILE_DOWNLOADING, SUBMIT_CONCEPT_FOCUS, SUBMIT_CONCEPT_FORM_VALIDITY, SUBMIT_CONCEPT_LOADING_PERCENTAGE, SUBMIT_CONCEPT_SET_FILES, SUBMIT_CONCEPT_SET_PREVIEW, SUBMIT_CONCEPT_SUCCESS, UPLOAD_FAIL, SUBMIT_CONCEPT_VALID_DOC } from '../types'
import { instance } from "../http";
import errorsString from '../../helpers/validator/errorsString'

export const setLoading = (state) => dispatch => {
    dispatch({
        type: SUBMIT_CONCEPT_LOADING,
        payload: state
    })
}

export const setDownloading = (state, name) => dispatch => {
    dispatch({
        type: SUBMIT_CONCEPT_FILE_DOWNLOADING,
        payload: { state, name }
    })
}

const checkFile = (file, name, getState, dispatch) => {
    const supportedPreviewTypes = ['image/png', 'image/jpeg'];
    const supportedFileTypes = ['image/png', 'image/jpeg', 'application/pdf'];
    const { isDocValid } = getState().submitConceptReducer
    if (file.size < 512000000) { dispatch({ type: SUBMIT_CONCEPT_VALID_DOC, payload: isDocValid }) }
    if (name === 'files') {
        if (supportedFileTypes.indexOf(file.type) !== -1)
            return (
                supportedFileTypes.indexOf(file.type) !== -1 && file.size < 512000000
            );
    } else if (supportedPreviewTypes.indexOf(file.type) !== -1)
        return (
            supportedPreviewTypes.indexOf(file.type) !== -1 && file.size < 512000000
        );
};

export const setFiles = (name, path, id, url) => dispatch => {
    dispatch({
        type: SUBMIT_CONCEPT_SET_FILES,
        payload: {
            name: name,
            path: path,
            id: id,
            url: url,
        }
    })
};
export const setPreview = (name, path, id, url) => dispatch => {
    dispatch({
        type: SUBMIT_CONCEPT_SET_PREVIEW,
        payload: {
            name: name,
            path: path,
            id: id,
            url: url,
        }
    })
};

export const onUpload = (docFile, name) => (dispatch, getState) => {
    let formData = new FormData();
    let isFileSupported;
    if (docFile) {
        isFileSupported = checkFile(docFile, name, getState, dispatch);
    }
    if (!isFileSupported) return;
    formData.append('file', docFile);
    if (getState().submitConceptReducer.loadingPercentage > 0) return;
    dispatch(setDownloading(true, name))
    dispatch(setLoading(true))
    const config = {
        onUploadProgress: (progressEvent) => {
            dispatch({
                type: SUBMIT_CONCEPT_LOADING_PERCENTAGE,
                payload: Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                )
            })
        },
    }
    instance.post(`/upload`, formData, config)
        .then(response => {
            const { fileName, path, _id, fullURL } = response.data
            console.log(response.data)
            if (name === 'preview') {
                dispatch(setPreview(fileName, path, _id, fullURL))
            } else {
                dispatch(setFiles(fileName, path, _id, fullURL))

            }
            dispatch(setDownloading(false, name))
            dispatch(setLoading(false))
        })
        .catch(error => {
            dispatch({
                type: UPLOAD_FAIL,
                payload: name
            })
            throw error;
        })
};

export const onFocus = () => dispatch => {
    dispatch({
        type: SUBMIT_CONCEPT_FOCUS,
    })
};

export const onChange = ({ target }) => dispatch => {
    dispatch({
        type: CHANGE_SUBMIT_CONCEPT_VALUES,
        payload: target
    })
};

export const deleteFile = (index) => dispatch => {
    dispatch({
        type: SUBMIT_CONCEPT_DELETE_FILE,
        payload: index
    })
};

export const clearAdditionalStore = () => dispatch => {
    dispatch({
        type: RESET_SUBMIT_CONCEPT
    })
};


export const onSubmit = (event) => (dispatch, getState) => {
    event.preventDefault();
    const { formFields, files, errors } = getState().submitConceptReducer

    var proceed = true
    Object.keys(formFields).forEach((item) => {
        if (!formFields[item]) {
            errors[item] = errorsString.COMMON_ERRORS.IS_REQ;
        }
    });
    if (!files.length) {
        errors.files = errorsString.COMMON_ERRORS.IS_REQ;
    }
    if (!files.length) {
        errors.files = errorsString.COMMON_ERRORS.IS_REQ;
    }
    const isAllFieldsValid = Object.values(errors).every(
        (field) => !field
    );
    dispatch({
        type: SUBMIT_CONCEPT_FORM_VALIDITY,
        payload: { errors, state: isAllFieldsValid }
    })


    proceed = isAllFieldsValid
    if (!proceed) return
    const additionalArray = files.map((e) => e.id);
    const { detailedProject } = getState().projectReducer
    const data = {
        projectId: detailedProject._id,
        thumbnailUrl: formFields.preview.url,
        demoPdfUrl: files[0].url,
        description: formFields.comments,
        files: additionalArray.splice(1) || undefined,
    };
    dispatch(setLoading(true))
    console.log(files, 'FILES');

    instance.post('/offers', data)
        .then(response => {
            dispatch(setLoading(false))
            dispatch({
                type: SUBMIT_CONCEPT_SUCCESS,
                payload: response.data
            })
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                    switch (error.response.data.message) {
                        case '"demoPdfUrl" is required':
                            errors.fromBE = 'Konzept is required';
                            break;
                        case '"thumbnailUrl" is required':
                            errors.fromBE = 'Vorschau is required';
                            break;
                        default:
                            break;
                    }
                }
                dispatch({
                    type: SUBMIT_CONCEPT_ERRORS,
                    payload: errors,
                })
            }
            dispatch(setLoading(false))
            throw error;
        })
};
