//For CreateProject
export const GET_TARIFFS = 'GET_TARIFFS'
export const GET_EXTRA_TARIFFS = 'GET_EXTRA_TARIFFS'
export const GET_VOUCHER_CODE = 'GET_VOUCHER_CODE'
export const SET_VALUES = 'SET_VALUES'
export const CHANGE_VALUES = 'CHANGE_VALUES'
export const CHANGE_CREATEPROJECT_VALUES = 'CHANGE_CREATEPROJECT_VALUES'
export const CHANGE_CREATEPROJECT_DETAILS_VALUES = 'CHANGE_CREATEPROJECT_DETAILS_VALUES'
export const CHANGE_CREATEPROJECT_BOXES = 'CHANGE_CREATEPROJECT_BOXES'
export const CHANGE_CONFIRMATIONBOXES = 'CHANGE_CONFIRMATIONBOXES'
export const FILE_UPLOADED = 'FILE_UPLOADED'
export const FILE_DOWNLOADING = 'FILE_DOWNLOADING'
export const DELETE_FILE = 'DELETE_FILE'
export const SAVE_PROJECT = 'SAVE_PROJECT'
export const RESET_PROJECT = 'RESET_PROJECT'
export const SET_PROJECT_DATA = 'SET_PROJECT_DATA'
export const GET_PROJECT_OPTIONS = 'GET_PROJECT_OPTIONS'
export const PROJECT_ERROR = 'PROJECT_ERROR'
export const CHANGE_OPTIONS_VALUES = 'CHANGE_OPTIONS_VALUES'
export const PROJECT_REDIRECT = 'PROJECT_REDIRECT'
export const CHANGE_EMAIL = 'CHANGE_EMAIL'
export const RESET_CONFIRMATIONBOXES = 'RESET_CONFIRMATIONBOXES'
export const UPLOAD_PROJECT_FAIL = 'UPLOAD_PROJECT_FAIL'

//For Projects
export const POST_PROJECT = 'POST_PROJECT'
export const POST_PROJECT_FAIL = 'POST_PROJECT_FAIL'
export const GET_PROJECTS = 'GET_PROJECTS'
export const CLEAR_PROJECTS = 'CLEAR_PROJECTS'
export const PROJECTS_FAIL = 'PROJECTS_FAIL'
export const UPDATE_PAGE = 'UPDATE_PAGE'
export const GET_PROJECT_INFO = 'GET_PROJECT_INFO'
export const CLEAR_PROJECT_INFO = 'CLEAR_PROJECT_INFO'
export const SET_PROJECT_RATING = 'SET_PROJECT_RATING'
export const ADD_PROJECT_COMMENT = 'ADD_PROJECT_COMMENT'

//For Additional Project Information
export const ADDITIONAL_SET_FILES = 'ADDITIONAL_SET_FILES'
export const ADDITIONAL_LOADING = 'ADDITIONAL_LOADING'
export const ADDITIONAL_FILE_DOWNLOADING = 'ADDITIONAL_FILE_DOWNLOADING'
export const ADDITIONAL_LOADING_PERCENTAGE = 'ADDITIONAL_LOADING_PERCENTAGE'
export const ADDITIONAL_DELETE_FILE = 'ADDITIONAL_DELETE_FILE'
export const ADDITIONAL_FOCUS = 'ADDITIONAL_FOCUS'
export const CHANGE_ADDITIONAL_VALUES = 'CHANGE_ADDITIONAL_VALUES'
export const RESET_ADDITIONAL = 'RESET_ADDITIONAL'
export const ADDITIONAL_FORM_VALIDITY = 'ADDITIONAL_FORM_VALIDITY'
export const ADDITIOANAL_RESET_ERRORS = 'ADDITIOANAL_RESET_ERRORS'
export const ADDITIONAL_TOGGLE_DOCUMENT = 'ADDITIONAL_TOGGLE_DOCUMENT'

//For Registration
export const SET_ERROR_BE = 'SET_ERROR_BE'
export const FORM_LOADING = 'FORM_LOADING'
export const CHANGE_REGISTRATION_FIELD = 'CHANGE_REGISTRATION_FIELD'
export const CHANGE_BOXES = 'CHANGE_BOXES'
export const REGISTER_FOCUS = 'REGISTER_FOCUS'
export const REGISTER_BLUR = 'REGISTER_BLUR'
export const FORM_VALIDITY = 'FORM_VALIDITY'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const CLEAR_REGISTRATION = 'CLEAR_REGISTRATION'
export const REGISTRATION_UPDATE_EMAIL = 'REGISTRATION_UPDATE_EMAIL'

//For Architects first Step of registration
export const FIRSTSTEP_SET_ERROR_BE = 'FIRSTSTEP_SET_ERROR_BE'
export const FIRSTSTEP_FORM_LOADING = 'FIRSTSTEP_FORM_LOADING'
export const FIRSTSTEP_CHANGE_REGISTRATION_FIELD = 'FIRSTSTEP_CHANGE_REGISTRATION_FIELD'
export const FIRSTSTEP_CHANGE_BOXES = 'FIRSTSTEP_CHANGE_BOXES'
export const FIRSTSTEP_REGISTER_FOCUS = 'FIRSTSTEP_REGISTER_FOCUS'
export const FIRSTSTEP_REGISTER_BLUR = 'FIRSTSTEP_REGISTER_BLUR'
export const FIRSTSTEP_FORM_VALIDITY = 'FIRSTSTEP_FORM_VALIDITY'
export const FIRSTSTEP_REGISTER_SUCCESS = 'FIRSTSTEP_REGISTER_SUCCESS'
export const FIRSTSTEP_CLEAR_REGISTRATION = 'FIRSTSTEP_CLEAR_REGISTRATION'
export const UNIQUE_FAIL = 'UNIQUE_FAIL'

//For Company Architect Registration
export const COMPANY_SET_ERROR_BE = 'COMPANY_SET_ERROR_BE'
export const COMPANY_FORM_LOADING = 'COMPANY_FORM_LOADING'
export const COMPANY_CHANGE_REGISTRATION_FIELD = 'COMPANY_CHANGE_REGISTRATION_FIELD'
export const COMPANY_CHANGE_BOXES = 'COMPANY_CHANGE_BOXES'
export const COMPANY_REGISTER_FOCUS = 'COMPANY_REGISTER_FOCUS'
export const COMPANY_REGISTER_BLUR = 'COMPANY_REGISTER_BLUR'
export const COMPANY_FORM_VALIDITY = 'COMPANY_FORM_VALIDITY'
export const COMPANY_REGISTER_SUCCESS = 'COMPANY_REGISTER_SUCCESS'
export const COMPANY_CLEAR_REGISTRATION = 'COMPANY_CLEAR_REGISTRATION'

// For Individial Architect Registration
export const INDIVIDUAL_SET_ERROR_BE = 'INDIVIDUAL_SET_ERROR_BE'
export const INDIVIDUAL_FORM_LOADING = 'INDIVIDUAL_FORM_LOADING'
export const INDIVIDUAL_CHANGE_REGISTRATION_FIELD = 'INDIVIDUAL_CHANGE_REGISTRATION_FIELD'
export const INDIVIDUAL_CHANGE_BOXES = 'INDIVIDUAL_CHANGE_BOXES'
export const INDIVIDUAL_REGISTER_FOCUS = 'INDIVIDUAL_REGISTER_FOCUS'
export const INDIVIDUAL_REGISTER_BLUR = 'INDIVIDUAL_REGISTER_BLUR'
export const INDIVIDUAL_FORM_VALIDITY = 'INDIVIDUAL_FORM_VALIDITY'
export const INDIVIDUAL_REGISTER_SUCCESS = 'INDIVIDUAL_REGISTER_SUCCESS'
export const INDIVIDUAL_CLEAR_REGISTRATION = 'INDIVIDUAL_CLEAR_REGISTRATION'

//For AUTH
export const AUTH_SET_ERROR_BE = 'AUTH_SET_ERROR_BE'
export const AUTH_LOADING = 'AUTH_LOADING'
export const CHANGE_AUTH_FIELD = 'CHANGE_AUTH_FIELD'
export const AUTH_FOCUS = 'AUTH_FOCUS'
export const AUTH_BLUR = 'AUTH_BLUR'
export const AUTH_VALIDITY = 'AUTH_VALIDITY'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const CLEAR_AUTH = 'CLEAR_AUTH'

//For Profile Page
export const PROFILE_SET_ERROR_BE = 'PROFILE_SET_ERROR_BE'
export const PROFILE_FORM_LOADING = 'PROFILE_FORM_LOADING'
export const PROFILE_CHANGE_FIELD = 'PROFILE_CHANGE_FIELD'
export const PROFILE_CHANGE = 'PROFILE_CHANGE'
export const PROFILE_FOCUS = 'PROFILE_FOCUS'
export const PROFILE_BLUR = 'PROFILE_BLUR'
export const PROFILE_FORM_VALIDITY = 'PROFILE_FORM_VALIDITY'
export const PROFILE_CLEAR = 'PROFILE_CLEAR'

//For Transactions
export const TRANSACTION_LOADING = 'TRANSACTION_LOADING'
export const POST_TRANSACTION = 'POST_TRANSACTION'
export const TRANSACTION_ERROR = 'TRANSACTION_ERROR'
export const GET_TRANSACTION_ID = 'GET_TRANSACTION_ID'
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS'
export const CLEAR_TRANSACTIONS = 'CLEAR_TRANSACTIONS'
export const CLEAR_CARD_DETAILS = 'CLEAR_CARD_DETAILS'
export const CHANGE_PAGE = 'CHANGE_PAGE'
export const TRANSACTION_MODAL = 'TRANSACTION_MODAL'
export const SET_INTERVAL = 'SET_INTERVAL'
export const CLEAR_INTERVAL = 'CLEAR_INTERVAL'
export const RESET_TRANSACTION = 'RESET_TRANSACTION'
export const TRANSACTION_CHANGE = 'TRANSACTION_CHANGE'
export const APPLY_VOUCHER = 'APPLY_VOUCHER'

//For Chat related Features
export const TOGGLE_CHAT = 'TOGGLE_CHAT'
export const CREATE_COMMENT = 'CREATE_COMMENT'
export const GET_COMMENTS = 'GET_COMMENTS'
export const SET_CHAT_MESSAGES = 'SET_CHAT_MESSAGES'
export const GET_PRIVATE_CONTACTS = 'GET_PRIVATE_CONTACTS'
export const OPEN_CONVERSATION_OFFER = 'OPEN_CONVERSATION_OFFER'
// export const GET_OFFER_COMMENTS = 'GET_OFFER_COMMENTS'

//For Concepts
export const GET_CONCEPT_ID = 'GET_CONCEPT_ID'
export const GET_CONCEPTS = 'GET_CONCEPTS'
export const GET_ALL_CONCEPTS = 'GET_ALL_CONCEPTS'
export const SET_RATING = 'SET_RATING'
export const SET_PROJECT_ID = 'SET_PROJECT_ID'
export const SET_PAGE = 'SET_PAGE'
export const CLEAR_CONCEPTS = 'CLEAR_CONCEPTS'

//For Submitting Concept
export const SUBMIT_CONCEPT_LOADING = 'SUBMIT_CONCEPT_LOADING'
export const SUBMIT_CONCEPT_FILE_DOWNLOADING = 'SUBMIT_CONCEPT_FILE_DOWNLOADING'
export const SUBMIT_CONCEPT_LOADING_PERCENTAGE = 'SUBMIT_CONCEPT_LOADING_PERCENTAGE'
export const SUBMIT_CONCEPT_SET_FILES = 'SUBMIT_CONCEPT_SET_FILES'
export const SUBMIT_CONCEPT_SET_PREVIEW = 'SUBMIT_CONCEPT_SET_PREVIEW'
export const SUBMIT_CONCEPT_FOCUS = 'SUBMIT_CONCEPT_FOCUS'
export const CHANGE_SUBMIT_CONCEPT_VALUES = 'CHANGE_SUBMIT_CONCEPT_VALUES'
export const SUBMIT_CONCEPT_DELETE_FILE = 'SUBMIT_CONCEPT_DELETE_FILE'
export const RESET_SUBMIT_CONCEPT = 'RESET_SUBMIT_CONCEPT'
export const SUBMIT_CONCEPT_FORM_VALIDITY = 'SUBMIT_CONCEPT_FORM_VALIDITY'
export const SUBMIT_CONCEPT_SUCCESS = 'SUBMIT_CONCEPT_SUCCESS'
export const SUBMIT_CONCEPT_ERRORS = 'SUBMIT_CONCEPT_ERRORS'
export const SUBMIT_CONCEPT_VALID_DOC = 'SUBMIT_CONCEPT_VALID_DOC'

//For Updating Concept
export const UPDATE_CONCEPT_LOADING = 'UPDATE_CONCEPT_LOADING'
export const UPDATE_CONCEPT_FILE_DOWNLOADING = 'UPDATE_CONCEPT_FILE_DOWNLOADING'
export const UPDATE_CONCEPT_LOADING_PERCENTAGE = 'UPDATE_CONCEPT_LOADING_PERCENTAGE'
export const UPDATE_CONCEPT_SET_FILES = 'UPDATE_CONCEPT_SET_FILES'
export const UPDATE_CONCEPT_SET_PREVIEW = 'UPDATE_CONCEPT_SET_PREVIEW'
export const UPDATE_CONCEPT_FOCUS = 'UPDATE_CONCEPT_FOCUS'
export const CHANGE_UPDATE_CONCEPT_VALUES = 'CHANGE_UPDATE_CONCEPT_VALUES'
export const UPDATE_CONCEPT_DELETE_FILE = 'UPDATE_CONCEPT_DELETE_FILE'
export const RESET_UPDATE_CONCEPT = 'RESET_UPDATE_CONCEPT'
export const UPDATE_CONCEPT_SUCCESS = 'UPDATE_CONCEPT_SUCCESS'
export const UPDATE_CONCEPT_ERRORS = 'UPDATE_CONCEPT_ERRORS'
export const UPDATE_CONCEPT_FILE_SIZE_VALID = 'UPDATE_CONCEPT_FILE_SIZE_VALID'

//For Doc Upload
export const UPDATE_STRIPE_ID = 'UPDATE_STRIPE_ID'
export const UPLOAD_DOC_FILE_SIZE_VALID = 'UPLOAD_DOC_FILE_SIZE_VALID'
export const UPLOAD_DOC_FILE_DOWNLOADING = 'UPLOAD_DOC_FILE_DOWNLOADING'
export const CHANGE_FILE = 'CHANGE_FILE'
export const RESET_UPLOAD_DOC = 'RESET_UPLOAD_DOC'
export const UPLOAD_FAIL = 'UPLOAD_FAIL'

//For Password Rest
export const FORGOT_PASS_LOADING = 'FORGOT_PASS_LOADING'
export const CHANGE_FORGOT_PASS_FIELD = 'CHANGE_FORGOT_PASS_FIELD'
export const CHANGE_RESET_PASS_FIELD = 'CHANGE_RESET_PASS_FIELD'
export const VALIDATE_RESET_PASS = 'VALIDATE_RESET_PASS'
export const RESET_PASS_FORGOT_FORM = 'RESET_PASS_FORGOT_FORM'
export const RESET_PASS_RESET_FORM = 'RESET_PASS_RESET_FORM'
export const FORGOT_PASS_FOCUS = 'FORGOT_PASS_FOCUS'
export const FORGOT_PASS_BLUR = 'FORGOT_PASS_BLUR'
export const SET_TOKEN = 'SET_TOKEN'

//For Snackbar Management
export const SHOW_SNACK = 'SHOW_SNACK'
export const CLOSE_SNACK = 'CLOSE_SNACK'

//For Authentication
export const LOGOUT = 'LOGOUT'
export const LOGIN = 'LOGIN'

//Additional Types
export const TOGGLE_MODAL = 'TOGGLE_MODAL'
export const GET_POSTS = 'GET_POSTS'
export const BRIEFING_CHANGE_PAGE = "BRIEFING_CHANGE_PAGE"