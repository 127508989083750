export default {
  LOGIN: {
    TO_SHORT: 'Benutzername soll mindestens 4 Zeichen enthalten',
    IN_USE: 'Benutzername schon besetzt',
  },
  PASSWORD: {
    TO_SHORT: 'Passwort soll mindestens 4 Zeichen enthalten',
  },
  PHONE: {
    WRONG_FORMAT:
      'Bitte geben Sie die Telefonnummer im folgenden Format ein: +41XXXXXXXX',
  },
  EMAIL: {
    WRONG_FORMAT:
      'Bitte geben Sie Ihre Email-Adresse im folgenden Format ein: name@email.com',
    NOT_EXIST: 'Email existiert nicht',
    IS_EMPTY: 'Please enter your Email',
  },
  DOB: {
    WRONG_FORMAT:
      'Bitte geben Sie Ihr Geburtstag im folgenden Format ein TT.MM.JJJJ',
  },
  ZIP: { WRONG_FORMAT: 'Bitte geben Sie eine vierstellige Postleitzahl ein' },
  PLOTNO: { WRONG_FORMAT: 'Muss eine Zahl sein' },
  IBAN: {
    WRONG_FORMAT:
      'Bitte geben Sie die IBAN im folgeden Format ein: CH00000000000000000000',
  },
  COMMON_ERRORS: {
    IS_REQ: 'Sie müssen das Feld ausfüllen',
    FORB_CHAR: 'Dieses Feld enthält ungültige Zeichen',
    LESS_2_CHAR: 'Dieses Feld muss mindestens 2 Zeichen enthalten',
  },
  ID_DOC_ERRORS: {
    DOC_SIZE: 'Ungültige Dokumentgröße',
    DOC_FORMAT: 'Ungültiges Dokumentformat',
  },
  RESET_PASS: {
    OLD_PASS: 'Neues passwort soll mindestens 4 Zeichen enthalten',
    NEW_PASS: 'Das neue Passwort muss mit dem alten übereinstimmen',
  },
};
