import { LOGIN, LOGOUT } from '../types'
import { getItem, setItem, removeItem } from '../localStorageActions'

const initialState = () => {
    return {
        User: JSON.parse(getItem('user')) || '',
        appState: {
            isUserAuth: false,
            isUserArchitect: false,
            isUserHasProject: false,
        },
        basicInfoRef: 'process'
    }
}

export default function (state = initialState(), action) {
    const { type, payload } = action;
    switch (type) {
        case LOGIN:
            setItem('user', JSON.stringify(payload.User));
            return {
                ...state,
                User: { ...payload.User },
                appState: {
                    ...state.appState,
                    isUserAuth: payload.status,
                    isUserArchitect: payload.role,
                },
            }
        case LOGOUT:
            removeItem('user');
            removeItem('token')
            return initialState()
        default:
            return state

    }
}

