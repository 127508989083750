import axios from 'axios';
import { getItem } from './localStorageActions';
import { logout} from './actions/globalActions'
import { showSnack } from './actions/snackbarActions'
import { alerts } from '../constants/alerts';
import errorMessagesBE from '../localization/errorMessagesBE';
import store from './store';

const {dispatch} = store
const isShowSnack = process.env.SHOW_SNACKS;

export const instance = axios.create({
  baseURL: process.env.REACT_APP_OUR_SERVER_URL,
});

instance.interceptors.request.use(
  async config => {
    config.headers['Content-Type'] = 'application/json';
    const token = await getItem('token');
    if (token) {
      config.headers['Access-Token'] = token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    if (response.config.method !== 'get' && isShowSnack) {
      showSnack(alerts.success, response.statusText);
    }
    return response;
  },
  error => {
    if (error.response) {
      error.response.data.message = errorMessagesBE[error.response.data.message]
        ? errorMessagesBE[error.response.data.message]
        : error.response.data.message;
      if (error.response.status === 401) {
        if (store.getState().globalReducer.User) {
          dispatch(logout());
        }
        return Promise.reject(error);
      }
      if (error.response.status >= 500 && isShowSnack) {
        dispatch(showSnack(
          alerts.error,
          `Server is unavailable, please check your internet connection or just try later.`
        ));
      }
      const messageText = error.response.data.message || error.response.statusText;
      if (isShowSnack) dispatch(showSnack(alerts.error, messageText));
    }
    return Promise.reject(error);
  }
);
