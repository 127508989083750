import { LOGIN, LOGOUT } from '../types'

export const login = (User, status, role) => dispatch => {
    dispatch({
        type: LOGIN,
        payload: {
            User,
            status,
            role,
        }
    })
}

export const logout = () => dispatch => {
    dispatch({
        type: LOGOUT
    })
}

export const getUserState = () => getState => {
    return getState().globalReducer.User
}