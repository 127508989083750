import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import RadioGroup from '../../../../shared/RadioGroup';
import Radio from '../../../../shared/RadioGroup/components/Radio';
import SelectableCard from '../../../../shared/SelectedCardGroup/components/SelectedCard';
import SelectedCardGroup from '../../../../shared/SelectedCardGroup';
import {
  categories,
  styles,
  typesNeubau,
  typesGarten,
  typesSpezialprojekt,
  typesProduct,
  stylesGarden,
} from '../../../../constants/firstStep';
import { changeCategory, changeValue } from '../../../../redux/actions/createProjectActions'

const FirstStep = (props => {
  const dispatch = useDispatch()
  const { tariffs } = useSelector(state => state.tariffReducer)
  const { valuesCreateProject, step } = useSelector(state => state.createProjectReducer)
  const { category, type, tariffId } = valuesCreateProject
  const pathname = props.location.pathname
  const languageID = pathname.split('/')[1]

  // For setting tariffID to valid tariff for country code
  useEffect(() => {
    switch (category) {
      case 'BUILDING':
        if (languageID === 'de'){
          updateSelectedTariff(19)
        }
      case 'RENOVATION':
      case 'SPECIAL':
        if (languageID === 'de') {
          if (!([11, 12, 13].includes(tariffId))) {
            updateSelectedTariff(12)
          }
        } else {
          if (!([7, 8, 9].includes(tariffId))) {
            // updateSelectedTariff(8)
            updateSelectedTariff(18)
          }
        }
        break;
      case 'GARDEN':
        if (languageID === 'de') {
          if (!([4, 5, 6].includes(tariffId))) {
            updateSelectedTariff(5)
          }
        } else {
          if (!([4, 5, 6].includes(tariffId))) {
            updateSelectedTariff(5)
          }
        }
        break;
      case 'PRODUKT':
        break;
      default:
        break;
    }

  }, [category])

  useEffect(() => {
    if (category === 'PRODUKT') {
      if (languageID === 'de') {
        updateSelectedTariff(14)
      } else {
        updateSelectedTariff(10)
      }
    }
  }, [category])

  const changeRadio = value => {
    dispatch(changeCategory(value))
  }

  function getSelectedTariffData(tariffId) {
    return tariffs && tariffs.filter(tariff => tariff._id == tariffId)
  }

  function updateSelectedTariff(value) {
    let selectedTariff = getSelectedTariffData(value);
    let defaultExtra = [];
    let defaultSelectedExtra = [];
    // get the default selected extra which is not removable
    if (selectedTariff && selectedTariff.length && (typeof selectedTariff[0].extra !== 'undefined')) {
      defaultExtra = selectedTariff[0].extra.filter(extra => extra.selectableType == 2);
    }
    for (let i = 0; i < defaultExtra.length; i++) {
      // get only extra id, eg: [1, 2]
      defaultSelectedExtra.push(defaultExtra[i].extraTariffId)
    }
    dispatch(changeValue('tariffId', value))
    // dispatch(changeValue('extra', defaultSelectedExtra));
  }

  let types = '';
  let projectCategory = ''
  let currentStyles = styles;
  switch (category) {
    case 'BUILDING':
    case 'RENOVATION':
      types = typesNeubau;
      projectCategory = 'Hausstil'
      break;
    case 'GARDEN':
      types = typesGarten;
      currentStyles = stylesGarden;
      projectCategory = 'Gartenstil'
      break;
    case 'SPECIAL':
      types = typesSpezialprojekt;
      break;
    case 'PRODUKT':
      types = typesProduct;
      break;
    default:
      types = typesNeubau;
      break;
  }

  console.log(valuesCreateProject)

  return (
    <div className="first-step">
      <h2>Welche Leistung benötigen Sie?</h2>
      <div className="category">
        {/* <RadioGroup
          changeRadio={(value) => changeRadio(value)}>
          {categories.map((item) => (
            <Radio
              selected={category}
              key={item.title}
              value={item.value}
              title={item.title}
            />
          ))}
        </RadioGroup> */}
      </div>
      <div className="type">
        <SelectedCardGroup fieldName="type">
          {types.map((item) => (
            <SelectableCard
              key={item.value}
              path={valuesCreateProject}
              isChangesColor={false}
              value={item.value}
              src={item.src}
              innerTitle={item.value}
            />
          ))}
        </SelectedCardGroup>
      </div>
      {type === 'Mehrfamilienhaus' ? (
        <div>
          <p style={{ fontSize: 22 }}><b>Bitte kontaktieren Sie uns</b></p><br />
          <p>0800 77 70 78</p><br />
          <div><a href="mailto:frage@faberling.com">frage@faberling.com</a></div>
        </div>
      ) : valuesCreateProject.style && (
        <Fragment>
          <div className="text">
            <h2>{`Welcher ${projectCategory} gefällt Ihnen?`}</h2>
            <p>
              Bitte wählen Sie die Stile, die Sie unten mögen. Dies hilft dem
              Architekten, Ihre Vorlieben zu verstehen.
            </p>
          </div>
          <div className="style">
            <SelectedCardGroup fieldName="style">
              {currentStyles.map((style, index) => (
                <Fragment key={index}>
                  <SelectableCard
                    path={valuesCreateProject}
                    isChangesColor={true}
                    value={style.value}
                    src={style.src}
                    title={style.title}
                    isGarden={true}
                  />
                </Fragment>
              ))}
            </SelectedCardGroup>
          </div>
        </Fragment>
      )}
    </div>
  );
});

export default FirstStep;
