import React from "react";

const OutcomingMessage = ({text}) => {
  return (
    <div className="my-message">
      <div className="message-text">
        {text}
      </div>
    </div>
  )
}

export default OutcomingMessage;