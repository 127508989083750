import { ADDITIONAL_LOADING, CHANGE_ADDITIONAL_VALUES, UPLOAD_FAIL, ADDITIONAL_DELETE_FILE, ADDITIONAL_FILE_DOWNLOADING, ADDITIONAL_LOADING_PERCENTAGE, RESET_ADDITIONAL, ADDITIONAL_FORM_VALIDITY, ADDITIONAL_FOCUS, ADDITIONAL_SET_FILES, ADDITIOANAL_RESET_ERRORS, ADDITIONAL_TOGGLE_DOCUMENT, LOGOUT } from '../types'
import validator from '../../helpers/validator/validation';

const defaultErrors = () => {
    return {
        postalCode: '',
        postOfficeNumber: '',
        plot: '',
        plotNo: '',
        fromBE: '',
    };
}

const initialState = () => {
    return {
        formFields: {
            postalCode: '',
            postOfficeNumber: '',
            plot: '',
            plotNo: '',
        },
        files: [],
        errors: defaultErrors(),
        success: false,
        isLoading: false,
        isFormValid: true,
        isDocValid: {
            files: { isSizeValid: true, isFileValid: true, download: false },
        },
        isDocumentOnly: false,
        loadingPercentage: 0
    }
}

export default function (state = initialState(), action) {
    const { type, payload } = action;
    switch (type) {
        case CHANGE_ADDITIONAL_VALUES:
            var { name, value } = payload
            var errors = state.errors
            var formFields = state.formFields
            if (name === 'postalCode' || name === 'plotNo') {
                const errorObj = validator.validate(name, value);

                errors[name] = errorObj ? errorObj.error : '';
            }
            if(name === 'postalCodeDE'){ name = 'postalCode'}
            formFields[name] = value;
            return {
                ...state,
                errors,
                formFields
            }
        case ADDITIONAL_FILE_DOWNLOADING:
            var isDocValid = { ...state.isDocValid }
            isDocValid.files.download = payload
            return {
                ...state,
                isDocValid,
                loadingPercentage: 0
            }
        case ADDITIONAL_LOADING:
            return {
                ...state,
                isLoading: payload
            }
        case ADDITIONAL_LOADING_PERCENTAGE:
            return {
                ...state,
                loadingPercentage: payload
            }
        case UPLOAD_FAIL:
            var isDocValid = { ...state.isDocValid }
            isDocValid.files.download = false
            return {
                ...state,
                isDocValid,
                isLoading: false,
            }
        case ADDITIONAL_DELETE_FILE:
            var errors = { ...state.errors }
            errors.fromBE = ''
            var indexFile = state.files.includes(state.files[payload]);
            if (indexFile) {
                var files
                files = [
                    ...state.files.slice(0, payload),
                    ...state.files.slice(payload + 1),
                ];
                return {
                    ...state,
                    files,
                    errors,
                }
            }
            return state
        case ADDITIONAL_FOCUS:
            var errors = { ...state.errors }
            errors.fromBE = ''
            return {
                ...state,
                errors
            }
        case ADDITIONAL_SET_FILES:
            var isDocValid = { ...state.isDocValid }
            isDocValid.files.download = false
            return {
                ...state,
                files: [...state.files, payload]
            }
        case ADDITIONAL_FORM_VALIDITY:
            return {
                ...state,
                isFormValid: payload
            }
        case ADDITIOANAL_RESET_ERRORS:
            return {
                ...state,
                errors: defaultErrors(),
                isFormValid: true,
            }
        case ADDITIONAL_TOGGLE_DOCUMENT:
            return {
                ...state,
                isDocumentOnly: payload
            }
        case RESET_ADDITIONAL:
        case LOGOUT:
            return { ...initialState() }
        default:
            return state
    }
}
