import { CHANGE_SUBMIT_CONCEPT_VALUES, LOGOUT, RESET_SUBMIT_CONCEPT, SUBMIT_CONCEPT_DELETE_FILE, SUBMIT_CONCEPT_ERRORS, SUBMIT_CONCEPT_FILE_DOWNLOADING, SUBMIT_CONCEPT_FOCUS, SUBMIT_CONCEPT_FORM_VALIDITY, SUBMIT_CONCEPT_LOADING, SUBMIT_CONCEPT_LOADING_PERCENTAGE, SUBMIT_CONCEPT_SET_FILES, SUBMIT_CONCEPT_SET_PREVIEW, SUBMIT_CONCEPT_SUCCESS, SUBMIT_CONCEPT_VALID_DOC, UPLOAD_FAIL } from '../types'

function initialState() {
    return {
        formFields: {
            preview: '',
            comments: '',
        },
        files: [],
        errors: {
            fromBE: '',
            comments: '',
            files: '',
            preview: '',
        },
        success: false,
        isLoading: false,
        isFormValid: true,
        isDocValid: {
            preview: { isSizeValid: true, isFileValid: true, download: false },
            files: { isSizeValid: true, isFileValid: true, download: false },
        },
        loadingPercentage: 0,
    };
}

export default function (state = initialState(), action) {
    const { type, payload } = action;
    switch (type) {
        case SUBMIT_CONCEPT_FILE_DOWNLOADING:
            var isDocValid = { ...state.isDocValid }
            isDocValid[payload.name].download = payload.state
            return {
                ...state,
                isDocValid,
                loadingPercentage: 0
            }
        case SUBMIT_CONCEPT_VALID_DOC:
            return {
                ...state,
                isDocValid: payload
            }
        case SUBMIT_CONCEPT_LOADING:
            return {
                ...state,
                isLoading: payload
            }
        case SUBMIT_CONCEPT_LOADING_PERCENTAGE:
            return {
                ...state,
                loadingPercentage: payload
            }
        case SUBMIT_CONCEPT_SET_FILES:
            var isDocValid = { ...state.isDocValid }
            isDocValid.files.download = false
            var errors = { ...state.errors }
            errors.files = ''
            return {
                ...state,
                files: [...state.files, payload],
                loadingPercentage: 0,
                errors
            }
        case SUBMIT_CONCEPT_SET_PREVIEW:
            var isDocValid = { ...state.isDocValid }
            isDocValid.preview.download = false
            var formFields = { ...state.formFields }
            formFields.preview = {
                name: payload.name,
                path: payload.path,
                id: payload.id,
                url: payload.url,
            };
            var errors = { ...state.errors }
            errors.preview = ''
            return {
                ...state,
                // files: [...state.files, payload],
                formFields,
                loadingPercentage: 0,
                errors
            }
        case UPLOAD_FAIL:
            var isDocValid = { ...state.isDocValid }
            isDocValid[payload].download = false
            return {
                ...state,
                isDocValid,
                isLoading: false,
                loadingPercentage: 0,
            }
        case SUBMIT_CONCEPT_FOCUS:
            var errors = { ...state.errors }
            errors.fromBE = ''
            return {
                ...state,
                errors
            }
        case CHANGE_SUBMIT_CONCEPT_VALUES:
            var { value } = payload
            var formFields = state.formFields

            formFields.comments = value;
            var errors = { ...state.errors }
            if (errors.comments) {
                errors.comments = ''
            }
            return {
                ...state,
                formFields,
                errors
            }
        case SUBMIT_CONCEPT_DELETE_FILE:
            var errors = { ...state.errors }
            errors.fromBE = ''
            if (payload !== 'preview') {
                var indexFile = state.files.includes(state.files[payload]);
                if (indexFile) {
                    var files
                    files = [
                        ...state.files.slice(0, payload),
                        ...state.files.slice(payload + 1),
                    ];
                    return {
                        ...state,
                        files,
                        errors,
                    }
                }
            } else {
                var formFields = { ...state.formFields }
                formFields.preview = '';
                return {
                    ...state,
                    formFields
                }
            }
        case SUBMIT_CONCEPT_FORM_VALIDITY:
            return {
                ...state,
                isFormValid: payload.state,
                errors: payload.errors,
            }
        case SUBMIT_CONCEPT_SUCCESS:
            return {
                ...state,
                success: payload ? true : false
            }
        case SUBMIT_CONCEPT_ERRORS:
            return {
                ...state,
                errors: payload
            }
        case RESET_SUBMIT_CONCEPT:
        case LOGOUT:
            return { ...initialState() }
        default:
            return {
                ...state,
            }
    }
}
