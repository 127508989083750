import React from 'react';
import PropTypes from 'prop-types';
import HasInfo from '../InfoIcon/HasInfo';
import info from '../../assets/info_black.svg';

const FormInput =
  ({
    name,
    type,
    placeholder,
    handleChange,
    handleBlur,
    handleFocus,
    value,
    label,
    className = '',
    error,
    additionalText,
    hasInfo,
    img,
    onKeyDown,
    maxlength,
    disabled,
    measurementUnit,
    infoBlock,
    min
  }) => {
    return (
      <div className={error ? 'inputError inputWrapper' : 'inputWrapper'}>
        <input
          name={name}
          type={type}
          placeholder={placeholder}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          onBlur={handleBlur}
          onFocus={handleFocus}
          value={value}
          maxLength={maxlength}
          className={error ? 'inputError' : className}
          disabled={disabled ? true : false}
          min={0}
        />
        {label && (
          <label className="label" htmlFor={name}>
            {label}
          </label>
        )}
        <div className="helperMessage">
          {(error && <p>{error}</p>) ||
            (additionalText && <p>{additionalText}</p>)}
        </div>
        {hasInfo && (
          <HasInfo className="inputHasInfo" src={info} infoBlock={infoBlock} />
        )}
        {img}
      </div>
    );
  }

FormInput.defaultProps = {
  type: 'text',
};

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleFocus: PropTypes.func,
  className: PropTypes.string,
  additionalText: PropTypes.string,
  hasInfo: PropTypes.bool,
  onKeyDown: PropTypes.func
};

export default FormInput;
