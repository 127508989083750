import React, { Component } from "react";
import PropTypes from "prop-types";
export default class RadioGroup extends Component {
  getChildContext() {
    return {
      radioGroup: {
        onChange: this.onChange.bind(this),
      }
    };
  }

  onChange(value) {
    const { changeRadio } = this.props;
    changeRadio(value);
  }

  render() {
    return <div className="radio-group">{this.props.children}</div>;
  }
}

RadioGroup.childContextTypes = {
  radioGroup: PropTypes.object
};

