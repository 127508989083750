import React from 'react';
import PropTypes from 'prop-types';
import HasInfo from '../InfoIcon/HasInfo';
import info from '../../assets/info_black.svg';
import { toggleModal } from '../../redux/actions/modalActions'
import { useDispatch } from 'react-redux'

const CheckBox = (
  ({
    name,
    value,
    onClick,
    title,
    isBig,
    hasInfo,
    className,
    checkedField,
    disabled,
    infoBlock,
    isTerms,
    isMultiply,
    checkmarkClassName
  }) => {
    const dispatch = useDispatch()
    let checked = checkedField === value;
    const openTerms = () => {

      if (isTerms) {
        dispatch(toggleModal('termsModal', true));
      }
    }

    if (isMultiply && Array.isArray(checkedField)) {
      checked = checkedField.includes(value);
    }
    return (
      <div className="checkBoxWrapper" onClick={() => !isTerms ? onClick(value) : null}>
        <div className={`container ${className ? className : ''}`}>
          <label>
            <input
              type="checkbox"
              name={name}
              value={value}
              checked={checked}
              disabled={disabled ? true : false}
              onChange={() => onClick(value)}
            />
            <span className={`checkmark ${isBig ? 'big' : ''} ${checkmarkClassName ? checkmarkClassName : ''}`}></span>
          </label>
          <span
            onClick={openTerms}
            dangerouslySetInnerHTML={{ __html: title }}></span>
        </div>

        {hasInfo && (
          <HasInfo
            className="checkBoxHasInfo"
            src={info}
            infoBlock={infoBlock}
          />
        )}
      </div>
    );
  }
);

export default CheckBox;

CheckBox.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  isBig: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
