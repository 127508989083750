// Import Packages
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

// Import Components
import NotFound from '../NotFound';
import ErrorModal from '../../shared/ModalContainer/ErrorModal';
import FirstStep from './components/FirstStep';
import SecondStep from './components/SecondStep';
import ThirdStep from './components/ThirdStep';
import FourthStep from './components/FourthStep';
import FifthStep from './components/FifthStep'
import MehrFinish from './components/MehrFinish';

// Import Redux Actions
import { getTariffs } from '../../redux/actions/tariffActions'
import { getExtraTariffs } from '../../redux/actions/extraTariffActions'
import { setCategory } from '../../redux/actions/createProjectActions'
import { toggleModal } from '../../redux/actions/modalActions'
import { getVoucherCode } from '../../redux/actions/voucherCodeActions'

import {
  getProjectOptions,
  setRedirect,
  resetConfirmationBoxes,
  setType,
  setProjectData,
  resetProjectData,
  changeCategory
} from '../../redux/actions/createProjectActions'
import { getItem } from '../../redux/localStorageActions'

class CreateProject extends Component {
  componentDidMount() {
    const projectInfo = JSON.parse(getItem('projectInfo'));
    const {
      getProjectOptions,
      setRedirect,
      resetConfirmationBoxes,
      setType,
      setProjectData
    } = this.props;
    resetConfirmationBoxes();
    setRedirect(false);
    const { history } = this.props;
    const { pathname } = this.props.location;
    const languageID  = pathname.split('/')[1]
    if (projectInfo) {
      this.props.setCategory(projectInfo.data.category);
      setType(projectInfo.data.type);
      setProjectData(projectInfo.data, projectInfo.step);
      history.push(`/${languageID}/create-project/${projectInfo.step}`);
    } else {
      var category = new URLSearchParams(this.props.location.search).get('category')
      if (category) {
        this.props.resetProjectData()
        history.push(`/${languageID}/create-project/style?category=${category}`)
        this.props.changeCategory(category)
      } else {
        history.push(`/${languageID}/create-project/style`);
      }
    }
    getProjectOptions();
    this.props.getTariffs()
    this.props.getExtraTariffs()
    this.props.getVoucherCode()
  }

  componentDidUpdate() {
    const { history } = this.props;
    const { pathname } = this.props.location;
    const languageID  = pathname.split('/')[1]
    const isStylePage = pathname.split('/')[3] === 'style';
    const projectInfo = JSON.parse(getItem('projectInfo'));
    if (!projectInfo && !isStylePage) history.push(`/${languageID}/create-project/style`);
  }
  render() {
    const { url } = this.props.match;
    const { toggleModal, modalReducer } = this.props;
    return (
      <div className="create-project">
        <Switch>
          <Route path={`${url}/style`} exact component={FirstStep} />
          <Route path={`${url}/instruction`} exact component={SecondStep} />
          <Route path={`${url}/pay`} exact component={FourthStep} />
          <Route path={`${url}/pay/success`} exact component={FifthStep} />
          <Route path={`${url}/success`} exact component={MehrFinish} />
          <Route path={`${url}/tariffs`} exact component={ThirdStep} />
          <Route component={NotFound} />
        </Switch>
        <Modal
          centered
          size="xs"
          show={modalReducer.isShowed}
          onHide={() => toggleModal('errorOnCreateProject', false)}>
          <ErrorModal
            onClose={() =>
              toggleModal('errorOnCreateProject', false)
            }
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { tariffReducer, extraTariffReducer, modalReducer } = state
  return {
    tariffReducer,
    extraTariffReducer,
    modalReducer
  }
};

export default connect(mapStateToProps, {
  getTariffs,
  getExtraTariffs,
  setCategory,
  getItem,
  getProjectOptions,
  setRedirect,
  resetConfirmationBoxes,
  setType,
  setProjectData,
  toggleModal,
  getVoucherCode,
  resetProjectData,
  changeCategory
})(CreateProject);
