import React from "react";

const HasInfo = ({ className, src, infoBlock }) => {
  return (
    <div className="tooltip-block">
      <img className={className} src={src} alt="info" />
      <span className="tooltip-arrow"></span>
      <p className="tooltip-text">{infoBlock}</p>
    </div>
  );
};

export default HasInfo;
