import React from 'react';

const Options = React.forwardRef(
  (
    {
      checkType,
      checkStatus,
      kitchenType,
      roomDesign,
      hasAtrium,
      hasGallery,
      id,
    },
    ref
  ) => {
    return (
      <div className="options" id={id} ref={ref}>
        <div className="space-style" id="living-space-style">
          <h2>Wohnraumstil</h2>
          <div className="table-wrapper">
            <ul>
              <li className="table-item">
                <span>Küche</span>
                <span>{checkType(kitchenType)}</span>
              </li>
              <li className="table-item">
                <span>Raumgestaltung</span>
                <span>{checkType(roomDesign)}</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="additional-options" id="additional-options">
          <h2>Sie zusätzliche Optionen</h2>
          <div className="table-wrapper">
            <ul>
              <li className="table-item">
                <span>{checkStatus(hasAtrium)}</span>
                <span>Atrium</span>
              </li>
              <li className="table-item">
                <span>{checkStatus(hasGallery)}</span>
                <span>Galerie</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
);

export default Options;
