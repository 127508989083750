import React, { useState } from "react";
import triangle from "../../assets/triangle.svg";
import close from "../../assets/close.svg";

const CommonInfo = ({ src, children }) => {
  const [fullInfo, toggleInfo] = useState(false);

  return (
    <div className="fixTriangle">
      <div className={fullInfo ? "animatedEffect wrapper" : "wrapper"}>
        {fullInfo ? (
          <div className="fullInfoWrapper">
            <img
              className="close"
              src={close}
              alt="close"
              onClick={() => toggleInfo(false)}
            />
            <div className="fullInfo">{children}</div>
          </div>
        ) : (
          <div className="preview" onClick={() => toggleInfo(true)}>
            <img src={src} alt="preview" />
          </div>
        )}
      </div>
      <img className="triangle" src={triangle} alt="triangle" />
    </div>
  );
};

export default CommonInfo;
