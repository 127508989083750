import moment from 'moment/min/moment-with-locales';
moment.locale('de');

export default {
  getFileType: (file) => {
    if (!file) return null;
    return file.slice(-3, file.length).toUpperCase();
  },
  sizeToKB: (size) => {
    return Math.round(size / 1000);
  },
  edit_date: (date) => {
    return moment(date).format('DD. MMM YYYY, HH:mm');
  },
  isBool: value => typeof value === "boolean",

  formatFaqs: data => {
    try {
      return data.map((contentItem) => {
        return {
          question: contentItem.title.rendered,
          answer: contentItem.content.rendered,
        };
      }).reverse();
    } catch (error) {
      console.error(error);
      return [];
    }
  },

  formatTipps: data => {
    try {
      return data
        .map((contentItem) => {
          return {
            title: contentItem.title.rendered,
            description: contentItem.content.rendered,
          };
        })
        .reverse();
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};
