import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import {
  detailedInfoTitleWithoutWinner,
  detailedInfoTitleWithWinner,
} from '../../../../../constants/titles';
import { userRole } from '../../../../../constants/userRole';
import { Modal } from 'react-bootstrap';
import SubmitConcept from '../../../../SubmitConcept/SubmitConcept';
import AcceptProject from '../../../../AcceptProject/AcceptProject';
import { updateStatus } from '../../../../../redux/actions/ProjectActions'

const LeftSide = ({ location, detailedProject, role }) => {
  const dispatch = useDispatch()
  const User = useSelector(state => state.globalReducer.User)
  const [show, setShow] = useState(false);
  const [showAcceptProject, setShowAcceptProject] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [priceMoney, setPriceMoney] = useState('')

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { pathname } = location;
  const languageID = pathname.split('/')[1];
  const place = pathname.split('/')[5];

  const id = pathname.split('/')[4];
  const isDesigner = role === userRole.COMPANY;
  const isAdmin = role === userRole.ADMIN;
  const { winnerIds } = detailedProject;
  const winnerId = winnerIds.length > 0 && winnerIds[0].accountId._id;
  const isWinner = winnerId === User._id;

  let titles = detailedProject.hasWinner
    ? isDesigner
      ? isWinner
        ? detailedInfoTitleWithWinner
        : detailedInfoTitleWithoutWinner
      : detailedInfoTitleWithWinner
    : detailedInfoTitleWithoutWinner;

  if (detailedProject.category === 'PRODUKT'){
    titles = titles.filter(item => (item.title !== 'Konzepte') && (item.title !== 'Briefing'));
  }
  
  useEffect(function(){
    setPriceMoney(detailedProject.invoiceAmount/100)
  }, [])
  
  function approve(){
    setShowAcceptProject(true)
  }

  function onSubmit(e){
    e.preventDefault()
    // @id is project id
    const invoiceAmount = priceMoney * 100
    setIsLoading(true)
    dispatch(updateStatus(id, invoiceAmount))
    setTimeout(function(){
      handleCloseAcceptProject()
      window.location.reload()
    }, 3000)
  }

  function handleCloseAcceptProject(){
    setShowAcceptProject(false)
  }

  function onHandleChangeInput(e){
    setPriceMoney(e.target.value)
  }

  function renderAcceptBtn(){
    if (isAdmin && (detailedProject.status === 'REVIEW')){
      return (
        <div className='link not-active customer-role'>
          <Link onClick={() => approve()}>Genehmigen</Link>
        </div>
      )
    }
  }

  return (
    <div className="header-additional-left-side">
      <h1>{detailedProject.name}</h1>
      <div className="links ">
        {isDesigner && !detailedProject.hasWinner && (
          <div className="offer-container">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.5 11.5C13.7761 11.5 14 11.7239 14 12V18C14 18.2761 13.7761 18.5 13.5 18.5C13.2239 18.5 13 18.2761 13 18V12C13 11.7239 13.2239 11.5 13.5 11.5Z"
                fill="black"
              />
              <path
                d="M11 12C11 11.7239 10.7761 11.5 10.5 11.5C10.2239 11.5 10 11.7239 10 12V18C10 18.2761 10.2239 18.5 10.5 18.5C10.7761 18.5 11 18.2761 11 18V12Z"
                fill="black"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 4V3C9 2.44772 9.44772 2 10 2H14C14.5523 2 15 2.44772 15 3V4H19C19.5523 4 20 4.44772 20 5V8C20 8.55228 19.5523 9 19 9V21C19 21.5523 18.5523 22 18 22H6C5.44772 22 5 21.5523 5 21V9C4.44772 9 4 8.55228 4 8V5C4 4.44772 4.44772 4 5 4H9ZM14 3V4H10V3H14ZM6 9V21H18V9H6ZM19 8V5H5V8H19Z"
                fill="black"
              />
            </svg>
            <span onClick={handleShow} className="offer">
              Konzept einreichen
            </span>
          </div>
        )}
        { renderAcceptBtn() }
        {titles.map(item => (
          <div
            key={item.value}
            className={`link ${item.value === place ? 'active' : 'not-active'
              } ${item.value}-title ${role.toLowerCase()}-role`}>
            <Link to={`/${languageID}/profile/project/${id}/${item.value}`}>
              {item.title}
            </Link>
          </div>
        ))}
      </div>
      <Modal centered size="xl" show={show} onHide={handleClose}>
        <SubmitConcept onClose={handleClose} />
      </Modal>
      <Modal centered size="xl" show={showAcceptProject}>
        <AcceptProject 
          onClose={() => handleCloseAcceptProject()} 
          project={detailedProject}
          isLoading={isLoading}
          onSubmit={onSubmit}
          onHandleChangeInput={onHandleChangeInput}
          invoiceAmount={priceMoney} />
      </Modal>
    </div>
  );
};

export default withRouter(LeftSide);
