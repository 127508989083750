import { instance } from "../http";
import { ADD_PROJECT_COMMENT, CLEAR_PROJECTS, CLEAR_PROJECT_INFO, GET_PROJECTS, GET_PROJECT_INFO, POST_PROJECT, POST_PROJECT_FAIL, PROJECTS_FAIL, SET_PROJECT_RATING, TOGGLE_MODAL, UPDATE_PAGE } from '../types'

export const postProject = (data) => dispatch => {
    // // Add timestamp to requirements
    // data.details.requirementsForArchitect
    //     ? data.details.requirementsForArchitect[0].timestamp = new Date()
    //     : data.details.requirements[0].timestamp = new Date()
    instance.post('/projects', data)
        .then(response => {
            dispatch({
                type: POST_PROJECT,
                payload: response.data
            })
        })
        .catch(error => {
            console.log(error.response)
            console.log(error.response.message)
            dispatch({
                type: POST_PROJECT_FAIL,
                payload: error.response
            })
            if (error.response.status !== 401) {
                dispatch({
                    type: TOGGLE_MODAL,
                    payload: {
                        componentName: 'errorOnCreateProject',
                        isShowed: true
                    }
                })
            }

        })
};

// all projects

export const getProjects = (pageValue) => (dispatch, getState) => {
    var { limit, page, sort, sort_by } = getState().projectReducer.params
    // If Page value provided
    if (pageValue) page = pageValue
    instance.get(
        `/projects?limit=${limit}&page=${page}&sort=${sort}&sort_by=${sort_by}`
    )
        .then(response => {
            dispatch({
                type: GET_PROJECTS,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: PROJECTS_FAIL
            })
            console.log(error);
        })
};

export const clearProjects = () => dispatch => {
    dispatch({
        type: CLEAR_PROJECTS,
    })
}

// pagination

export const handleRequestPage = (page) => dispatch => {
    dispatch({
        type: UPDATE_PAGE,
        payload: page
    })
    dispatch(getProjects(page))
};

// one project

export const getDetailedProject = (projectId) => dispatch => {
    instance.get(`/projects/${projectId}`)
        .then(response => {
            dispatch({
                type: GET_PROJECT_INFO,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: PROJECTS_FAIL
            })
            console.log(error);
        })
};

export const setRating = (id, rating) => dispatch => {
    instance.put(`/projects/${id}/rating`, {
        rating: rating,
    })
        .then(response => dispatch({
            type: SET_PROJECT_RATING,
            payload: response.data
        }))
        .catch(error => {
            dispatch({
                type: PROJECTS_FAIL
            })
            console.log(error);
        })
};

export const addComment = (id, requirementsForArchitect, type) => (dispatch, getState) => {
    var arr = getState().projectReducer.detailedProject.details[type]
    arr = [
        ...arr,
        {
            value: requirementsForArchitect,
            timestamp: new Date()
        }
    ]
    var requestBody = {}
    requestBody[type] = arr
    instance.put(`/projects/${id}/addRequirement`, requestBody)
        .then(response => dispatch({
            type: ADD_PROJECT_COMMENT,
            payload: response.data
        }))
        .catch(error => {
            dispatch({
                type: PROJECTS_FAIL
            })
            console.log(error.response);
        })
}

export const clearProject = () => dispatch => {
    dispatch({
        type: CLEAR_PROJECT_INFO,
    })
}

// update project
export const updateStatus = (id, invoiceAmount) => (dispatch) => {
    const data = {
        status: 'ACCEPTED',
        invoiceAmount: invoiceAmount
    }
    // When status updated to ACCEPTED will also trigger email
    instance.put(`/projects/${id}/faberling-coach`, data)
        .then(response => {
            console.log(response)
        })
        .catch(error => {
            dispatch({
                type: PROJECTS_FAIL
            })
            console.log(error.response);
        })
}