import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux'
import Notify from '../../../../../assets/notify.svg';
import UserIcon from '../../../../../assets/user.svg';
import ArrowDown from '../../../../../assets/arrow-down.svg';
import DropDown from '../../../../DropDown';
// import Settings from '../../../../DropDown/components/Settings';

const AuthUser = ({location}) => {
  const User = useSelector(state => state.globalReducer.User)
  const [isDropDownOpen, setOpen] = useState(false);

  useEffect(() => {
    return setOpen(false);
  }, []);

  const handleClickDropDown = () => {
    setOpen(!isDropDownOpen);
  };
  return (
    <Fragment>
      {/* <div className="notify">
        <img src={Notify} alt="Bell" />
        <div className="count">0</div>
      </div> */}

      <div className="profileHeader">
        <Link to="#" onClick={handleClickDropDown}>
          <img src={UserIcon} alt="User Icon" />
          <p>
            {User.firstName} {User.lastName}
          </p>
          <img src={ArrowDown} alt="Arrow Down" />
        </Link>

        <div className="drop-down-container">
          <DropDown
            closeMenu={() => handleClickDropDown()}
            isDropDownOpen={isDropDownOpen}
            location={location}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default AuthUser;
