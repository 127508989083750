import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import noImage from '../../assets/noImage.svg';
import prize from '../../assets/Prize.png';
import ChatBlock from '../CommunicationPage/components/ChatBlock/ChatBlock';
import link_file from '../../constants/link_file';
import download from '../../assets/download.svg';
import edit_helpers from '../../helpers/validator/edit_helpers';
import ConceptFile from '../../shared/ConceptFile/ConceptFile';
import { userRole } from '../../constants/userRole';
import LinearProgressWithLabel from '../../shared/LinearProgressWithLabel';
import { openConversationOnOffer } from '../../redux/actions/communicationActions'
import { getConceptById } from '../../redux/actions/conceptActions'
import { onUpload } from '../../redux/actions/updateConceptActions'


class ConceptWinner extends Component {
  state = {
    comments: [],
  };

  async componentDidMount() {
    const { detailedProject } = this.props;
    if (detailedProject && detailedProject.winnerIds.length > 0) {
      this.props.getConceptById(detailedProject.winnerIds[0]._id);
      this.props.openConversationOnOffer(
        detailedProject._id,
        detailedProject.winnerIds[0]._id
      );
    }
  }

  componentDidUpdate(prevProps) {
    const prevDetailedProject = prevProps.detailedProject;
    const { detailedProject } = this.props;
    if (prevDetailedProject !== detailedProject)
      this.props.getConceptById(detailedProject.winnerIds[0]._id);
  }

  addZero = (number, maxLength) => {
    number = number.toString();
    return number.length < maxLength
      ? this.addZero('0' + number, maxLength)
      : number;
  };

  render() {
    const {
      globalReducer,
      projectReducer,
      conceptReducer,
      updateConceptReducer,
      onUpload,
    } = this.props
    const { url } = this.props.match;
    const projectId = url.split('/')[4];
    const { detailedProject } = projectReducer;
    const { detailedConcept } = conceptReducer;
    const { loadingPercentage } = updateConceptReducer;
    const { User } = globalReducer;
    const isDesigner = User.role === userRole.COMPANY;
    if (!detailedConcept || !detailedProject) return null;

    return (
      <Fragment>
        {isDesigner && (
          <div className="designer-winner">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.6665 29.9241C24.5119 29.4045 27.0501 26.2521 28.8573 21.6074C28.9529 21.6456 29.0572 21.6667 29.1665 21.6667C31.8698 21.6667 34.0185 20.7529 35.4582 18.8333C36.864 16.9588 37.4998 14.2468 37.4998 10.8333V10H31.4374C31.5382 8.90835 31.607 7.79515 31.6412 6.66667C31.658 6.11452 31.6665 5.55872 31.6665 5H9.99984C9.99984 5.55872 10.0084 6.11452 10.0251 6.66667C10.0594 7.79515 10.1281 8.90835 10.2289 10H4.1665V10.8333C4.1665 14.2468 4.8023 16.9588 6.20817 18.8333C7.64786 20.7529 9.79651 21.6667 12.4998 21.6667C12.6091 21.6667 12.7134 21.6456 12.8091 21.6074C14.6163 26.2521 17.1544 29.4045 19.9998 29.9241V35H16.6665V36.6667H24.9998V35H21.6665V29.9241ZM26.9611 21.8437C28.6564 17.8799 29.7908 12.512 29.9738 6.66667H11.6926C11.8756 12.512 13.0099 17.8799 14.7052 21.8437C15.6343 24.016 16.6986 25.6884 17.7977 26.7927C18.8925 27.8927 19.9172 28.3333 20.8332 28.3333C21.7492 28.3333 22.7739 27.8927 23.8687 26.7927C24.9677 25.6884 26.032 24.016 26.9611 21.8437ZM12.2313 19.9965C10.0777 19.9396 8.56 19.1913 7.5415 17.8333C6.54328 16.5024 5.94454 14.4813 5.84724 11.6667H10.4097C10.7869 14.6896 11.4118 17.5138 12.2313 19.9965ZM34.1248 17.8333C33.1063 19.1913 31.5887 19.9396 29.435 19.9965C30.2546 17.5138 30.8795 14.6896 31.2567 11.6667H35.8191C35.7218 14.4813 35.1231 16.5024 34.1248 17.8333Z"
                fill="white"
              />
            </svg>
            Herzliche Glückwünsche! Du bist der Sieger!
          </div>
        )}
        <div className={`concept-winner-wrapper isDesigner-${isDesigner}`}>
          <div className="concept-winner-describe">
            <div className="concept-winner-header">
              <span className="concept-header-name">
                Konzepte #{this.addZero(detailedConcept._id, 3)}
              </span>
              <div className="concept-winner-info">
                <div className="group">
                  <div className="concept-header-architekt winner">
                    <span>Architekt: </span>
                    <span>{detailedConcept.accountId.login}</span>
                  </div>
                  <div className="concept-header-upload-date winner">
                    <span>Datum des Uploads: </span>
                    <span>
                      {edit_helpers.edit_date(detailedConcept.creationDate)}
                    </span>
                  </div>
                </div>
                <div className="concept-winner-badge">
                  <span>Gewinner</span>
                  <img src={prize} alt="" />
                </div>
              </div>
            </div>
            <div className="concept-describe winner">
              <div className="concept-describe-image">
                <img src={detailedConcept.thumbnailUrl || noImage} alt="" />
              </div>
              <div className="concept-describe-description">
                <h2>Beschreibung</h2>
                <p>{detailedConcept.description}</p>
              </div>
              <h2 className="files-title-table">Projektdaten</h2>
              <div className="files-header">
                <p className="format">Format </p>
                <p>Name</p>
                <p className="file-data">Größe</p>
                <p className="file-data">Datum des Uploads</p>
              </div>
              <div className="concept-describe-attached-files">
                {loadingPercentage ? (
                  <LinearProgressWithLabel value={loadingPercentage} />
                ) : null}
                <ConceptFile
                  link={detailedConcept.demoPdfUrl}
                  name="Hochgeladenen Datein"
                  icon={download}
                />
                {detailedConcept.files.map((item) => (
                  <ConceptFile
                    key={item._id}
                    name={item.fileName}
                    type={edit_helpers.getFileType(item.fileName)}
                    link={link_file + item.path}
                    icon={download}
                    size={edit_helpers.sizeToKB(item.size)}
                    date={edit_helpers.edit_date(item.creationDate)}
                  />
                ))}

                {isDesigner ? (
                  <div className="uploadFile">
                    <input
                      type="file"
                      name="file"
                      id="file"
                      onChange={(event) => {
                        onUpload(event.target.files[0]);
                      }}
                    />
                    <label htmlFor="file">
                      Datei hinzufügen
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.8536 7.14652C17.0488 7.34178 17.0488 7.65837 16.8536 7.85363C16.6583 8.04889 16.3417 8.04889 16.1464 7.85363L13 4.70718V16.0001C13 16.2762 12.7761 16.5001 12.5 16.5001C12.2239 16.5001 12 16.2762 12 16.0001V4.70718L8.85355 7.85363C8.65829 8.04889 8.34171 8.04889 8.14645 7.85363C7.95118 7.65837 7.95118 7.34178 8.14645 7.14652L12.5 2.79297L16.8536 7.14652Z" />
                        <path d="M5 15.0001C5 14.7239 4.77614 14.5001 4.5 14.5001C4.22386 14.5001 4 14.7239 4 15.0001V20.0001L21 20.0001V15.0001C21 14.7239 20.7761 14.5001 20.5 14.5001C20.2239 14.5001 20 14.7239 20 15.0001V19.0001L5 19.0001V15.0001Z" />
                      </svg>
                    </label>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="concept-chat-block">
            <ChatBlock
              username={detailedConcept.accountId.login}
              projectId={+projectId}
              offerId={detailedProject.winnerIds[0]._id}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { globalReducer, projectReducer, conceptReducer, updateConceptReducer } = state
  return {
    globalReducer,
    projectReducer,
    conceptReducer,
    updateConceptReducer,
  }
};

export default connect(mapStateToProps, {
  openConversationOnOffer, getConceptById, onUpload
})(ConceptWinner);
