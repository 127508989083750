import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import arrow_right from '../../../../assets/arrow_right.svg';
import Pagination from '../../../../shared/Pagination/Pagination';
import small_right from '../../../../assets/small_right.svg';
import small_left from '../../../../assets/small_left.svg';
import moment from 'moment/min/moment-with-locales';
moment.locale('de');

const ProjectTable =
  ({ projects, changePage, page, limit, total, totalPages, languageID }) => {
    const header = {
      projectID: 'ProjektID',
      title: 'Projekttitel',
      category: 'Kategorie',
      status: 'Status',
      startDate: 'Gestartet',
      endDate: 'Ende',
      concept: 'Konzepte',
    };

    const addZero = (number, maxLength) => {
      number = number.toString();
      return number.length < maxLength
        ? addZero('0' + number, maxLength)
        : number;
    };

    const handlePageChange = ({ selected }) => {
      changePage(selected + 1);
    };

    const checkStatus = status => {
      switch (status) {
        case 'ON_MODERATION':
          return 'entwurf';
        case 'REVIEW':
          return 'faberling coach';
        case 'ACCEPTED':
          return 'laufend';
        case 'DECLINED':
          return 'abgesagt';
        case 'CLOSED':
          return 'genehmigt';
        default:
          break;
      }
    };

    const checkCategory = category => {
      switch (category) {
        case 'BUILDING':
          return 'Neubau';
        case 'RENOVATION':
          return 'Anbau & Aufbau';
        case 'GARDEN':
          return 'Garten- & Landschaftsbau';
        case 'SPECIAL':
          return 'Spezialprojekt';
        case 'PRODUKT':
          return 'Produkt';
        default:
          break;
      }
    };

    return (
      <div className="projectWrapper">
        <h1>Meine Projekte</h1>
        <div className="table-wrapper">
          <ul>
            <li className="tableHeader">
              <span>{header.projectID}</span>
              <span>{header.title}</span>
              <span className="category">{header.category}</span>
              <span>{header.status}</span>
              <span>{header.startDate}</span>
              <span>{header.endDate}</span>
              <span>{header.concept}</span>
            </li>
            {projects.map(item => (
              <li key={item._id} className="table-item">
                <div>
                  <span className="mobile-view">{header.projectID}</span>
                  <span>#{addZero(item._id, 6)}</span>
                </div>
                <div>
                  <span className="mobile-view">{header.title}</span>
                  <span className="bold">{item.name}</span>
                </div>
                <div>
                  <span className="mobile-view">{header.category}</span>
                  <span>{checkCategory(item.category)}</span>
                </div>
                {
                  item.status === 'REVIEW' ? (
                    <span className={`status faberling-coach`}>
                      {checkStatus(item.status)}
                    </span>
                  ) : (
                      <span className={`status ${checkStatus(item.status)}`}>
                        {checkStatus(item.status)}
                      </span>
                    )
                }
                <div>
                  <span className="mobile-view">{header.startDate}</span>
                  {
                    item.status === 'ON_MODERATION' || item.status === 'REVIEW' ? '-' : (
                      <span>{moment(item.startDate).format('DD. MMM YYYY')}</span>
                    )
                  }
                </div>
                <div>
                  <span className="mobile-view">{header.endDate}</span>
                  {
                    item.status === 'ON_MODERATION' || item.status === 'REVIEW' ? '-' : (
                      <span>{moment(item.endDate).format('DD. MMM YYYY')}</span>
                    )
                  }
                </div>
                <div>
                  <span className="mobile-view">{header.concept}</span>
                  <span className={item.totalOffers ? 'bold' : 'gray'}>
                    {item.category === 'PRODUKT' ? '-' : item.totalOffers ? item.totalOffers : 'nichts:('}
                  </span>
                </div>
                <Link to={`/${languageID}/profile/project/${item._id}/${item.category === 'PRODUKT' ? 'communication' : 'concepts'}`}>
                  <span className="img">
                    <img src={arrow_right} alt="arrow_right" />
                  </span>
                </Link>
                <div className="mobile-view amount-block">
                  {
                    item.status === 'REVIEW' ? (
                      <span className={`status faberling-coach`}>
                        {checkStatus(item.status)}
                      </span>
                    ) : (
                        <span className={`status ${checkStatus(item.status)}`}>
                          {checkStatus(item.status)}
                        </span>
                      )
                  }
                  <Link to={`/${languageID}/profile/project/${item._id}/${item.category === 'PRODUKT' ? 'communication' : 'concepts'}`}>
                    <span className="img">
                      <img src={arrow_right} alt="arrow_right" />
                    </span>
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        </div>
        {total > 10 && (
          <Pagination
            currentPage={page}
            postsPerPage={limit}
            totalPages={totalPages}
            paginate={handlePageChange}
            pageRangeDisplayed={2}
            nextLabel={<img src={small_right} alt="arrow_right" />}
            previousLabel={<img src={small_left} alt="arrow_left" />}
            marginPagesDisplayed={1}
            breakLabel={'...'}
          />
        )}
      </div>
    );
  }

export default ProjectTable;

ProjectTable.propTypes = {
  projects: PropTypes.array,
};