import React, { Component } from "react";
import PropTypes from "prop-types";
import { Switch, Route } from 'react-router-dom';
import ContactBar from "./components/ContactBar/ContactBar";
import ChatBlock from './components/ChatBlock/ChatBlock'

import { connect } from 'react-redux'
import { } from '../../redux/actions/communicationActions'

class CommunicationPage extends Component {

  state = {
    comments: [],
    contacts: [],
  }
  //Notse Used
  // setComments = (comments) => {
  //   this.setState({ comments: comments });
  // }

  render() {
    const { url } = this.props.match;
    const { projectId } = this.props;
    const { comments, contacts } = this.state;

    return (
      <div className="communication-container">
        <ContactBar />
        <Switch>
          <Route
            exact
            path={`${url}/`}
            render={(props) => <ChatBlock
              {...props}
              comments={comments}
              contacts={contacts}
              // setComments={this.setComments}
              projectId={projectId}
            />}
          />
          <Route
            exact
            path={`${url}/:id`}
            render={(props) => {
              return <ChatBlock
                {...props}
                comments={this.props.communicationReducer.messages}
                contacts={contacts}
                // setComments={this.setComments}
                projectId={projectId}
              />
            }}
          />
        </Switch>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  const { communicationReducer } = state
  return {
    communicationReducer,
  }
};

export default connect(mapStateToProps, {

})(CommunicationPage);

CommunicationPage.propTypes = {
  projectId: PropTypes.number.isRequired
};