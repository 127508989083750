import { SET_VALUES, CHANGE_VALUES, CHANGE_CREATEPROJECT_VALUES, CHANGE_CREATEPROJECT_DETAILS_VALUES, CHANGE_CREATEPROJECT_BOXES, CHANGE_CONFIRMATIONBOXES, FILE_UPLOADED, FILE_DOWNLOADING, UPLOAD_PROJECT_FAIL, DELETE_FILE, SAVE_PROJECT, RESET_PROJECT, SET_PROJECT_DATA, GET_PROJECT_OPTIONS, PROJECT_ERROR, CHANGE_OPTIONS_VALUES, PROJECT_REDIRECT, CHANGE_EMAIL, RESET_CONFIRMATIONBOXES } from '../types'
import { instance } from "../http";

export const changeCategory = (category) => dispatch => {
    const payload = {
        category
    }
    switch (category) {
        case 'GARDEN':
            payload.type = 'Garten- & Landschaftsbau';
            break;
        case 'SPECIAL':
            payload.type = 'Spezialprojekt';
            break;
        case 'BUILDING':
        case 'RENOVATION':
            payload.type = 'Einfamilienhaus';
            break;
        case 'PRODUKT':
            payload.type = 'Plan-Digitalisierung';
            break;
        default:
            break;
    }
    dispatch({
        type: CHANGE_VALUES,
        payload
    })
};

export const changeType = (type) => dispatch => {
    dispatch({
        type: CHANGE_VALUES,
        payload: { type }
    })
}

export const setCategory = (category) => dispatch => {
    dispatch({
        type: CHANGE_VALUES,
        payload: { category }
    })
}

export const setType = (type) => dispatch => {
    dispatch({
        type: CHANGE_VALUES,
        payload: { type }
    })
}

export const changePaymentMethod = (value) => dispatch => {
    dispatch({
        type: CHANGE_VALUES,
        payload: { value }
    })
};

export const changeValue = (fieldName, value) => dispatch => {
    dispatch({
        type: CHANGE_CREATEPROJECT_VALUES,
        payload: { fieldName, value }
    })
};

export const changeValueInDetails = (fieldName, value) => dispatch => {
    dispatch({
        type: CHANGE_CREATEPROJECT_DETAILS_VALUES,
        payload: { fieldName, value }
    })
};

export const changeBoxes = (boxesName, fieldName) => dispatch => {
    dispatch({
        type: CHANGE_CREATEPROJECT_BOXES,
        payload: { fieldName, boxesName }
    })
};

export const changeconfirmationBoxes = (fieldName) => dispatch => {
    dispatch({
        type: CHANGE_CONFIRMATIONBOXES,
        payload: { fieldName }
    })
};

//upLoad files for special and garden type project
const checkFile = (file) => {
    var isDocValid = {
        isSizeValid: false,
        isFileValid: false,
    }
    var supportedFileTypes = ['image/png', 'image/jpeg', 'application/pdf'];

    if (file.size < 512000000) isDocValid.isSizeValid = true;
    if (supportedFileTypes.indexOf(file.type) !== -1)
        isDocValid.isFileValid = true;
    return (
        supportedFileTypes.indexOf(file.type) !== -1 && file.size < 512000000
    );
};

export const onUpload = (docFile) => dispatch => {
    let formData = new FormData();
    let isFileSupported;
    if (docFile) {
        isFileSupported = checkFile(docFile);
    }
    if (!isFileSupported) return;
    formData.append('file', docFile);
    dispatch({ type: FILE_DOWNLOADING })
    instance({
        method: 'post',
        url: `/upload`,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
    })
        .then(response => dispatch({
            type: FILE_UPLOADED,
            payload: response.data
        }))
        .catch(error => {
            dispatch({
                type: UPLOAD_PROJECT_FAIL,
            })
            throw error;
        })
};

export const deleteFile = (index) => dispatch => {
    dispatch({
        type: DELETE_FILE,
        payload: index
    })
};

export const extractRangeData = (value) => dispatch => {
    dispatch({
        type: CHANGE_CREATEPROJECT_DETAILS_VALUES,
        payload: { fieldName: 'numOfSquares', value }
    })
};

export const extractBudgetData = (value) => dispatch => {
    dispatch({
        type: CHANGE_CREATEPROJECT_DETAILS_VALUES,
        payload: { fieldName: 'budget', value }
    })
};

export const saveProjectData = (place) => dispatch => {
    dispatch({
        type: SAVE_PROJECT,
        payload: place
    })
};

export const saveProjectDataProgress = () => (dispatch, getState) => {
    const { valuesCreateProject, emailState } = getState().createProjectReducer
    if (valuesCreateProject.email !== '' && emailState.isValid){
        const data = {
            email: valuesCreateProject.email,
            name: valuesCreateProject.name
        }
        instance.post('/projects/save-progress', data)
            .then(response => {
                console.log('saved');
            })
            .catch(error => {
                
            })
    }
};

export const resetProjectData = () => dispatch => {
    // transactionStore.reset(); handle when coming to transaction
    dispatch({
        type: RESET_PROJECT
    })
    dispatch(getProjectOptions())
};

export const setProjectData = (valuesCreateProject, step = 'style') => dispatch => {
    dispatch({
        type: SET_PROJECT_DATA,
        payload: {
            valuesCreateProject,
            step
        }
    })
};

export const getProjectOptions = () => dispatch => {
    instance.get('/project/options')
        .then(response => {
            dispatch({
                type: GET_PROJECT_OPTIONS,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: PROJECT_ERROR,
            })
            console.log(error);
        })
};

export const changeValueInProjectOption = (value) => dispatch => {
    dispatch({
        type: CHANGE_OPTIONS_VALUES,
        payload: value
    })
};

export const setRedirect = (value) => dispatch => {
    dispatch({
        type: PROJECT_REDIRECT,
        payload: value
    })
};

export const onChange = (value) => dispatch => {
    dispatch({
        type: CHANGE_EMAIL,
        payload: value
    })
};

export const setEmail = (value) => dispatch => {
    dispatch({
        type: CHANGE_CREATEPROJECT_VALUES,
        payload: {value, fieldName: 'email'}
    })
};

export const resetConfirmationBoxes = () => dispatch => {
    dispatch({
        type: RESET_CONFIRMATIONBOXES,
    })
};