import { CHANGE_FORGOT_PASS_FIELD, CHANGE_RESET_PASS_FIELD, FORGOT_PASS_BLUR, FORGOT_PASS_FOCUS, FORGOT_PASS_LOADING, RESET_PASS_FORGOT_FORM, RESET_PASS_RESET_FORM, SET_TOKEN, VALIDATE_RESET_PASS } from '../types'
import { toggleModal } from './modalActions'
import { instance } from '../http'
import errorsString from "../../helpers/validator/errorsString";

export const setLoading = status => dispatch => {
    dispatch({
        type: FORGOT_PASS_LOADING,
        payload: status
    })
};

export const onChange = ({ target }) => dispatch => {
    dispatch({
        type: CHANGE_FORGOT_PASS_FIELD,
        payload: target
    })
};

export const onChangeReset = ({ target }) => dispatch => {
    dispatch({
        type: CHANGE_RESET_PASS_FIELD,
        payload: target
    })
};

const validateResetForm = (dispatch, getState) => {
    const { resetPasswordFields, formState, resetToken } = getState().forgotPassReducer
    if (resetPasswordFields.old_pass.length < 4)
        formState.errors.old_pass = errorsString.RESET_PASS.OLD_PASS
    if (
        resetPasswordFields.old_pass !==
        resetPasswordFields.new_pass
    )
        formState.errors.new_pass = errorsString.RESET_PASS.NEW_PASS;
    dispatch({
        type: VALIDATE_RESET_PASS,
        payload: formState
    })
    return resetToken && !formState.errors.new_pass && !formState.errors.old_pass
}

export const submitResetForm = event => (dispatch, getState) => {
    event.preventDefault();
    var proceed = validateResetForm(dispatch, getState);
    const { resetPasswordFields, resetToken, formState } = getState().forgotPassReducer
    formState.errors.fromBE = '';
    if (proceed) {
        const data = {
            password: resetPasswordFields.new_pass,
            token: resetToken
        }
        instance.put('auth/password/reset', data)
            .then(response => {
                dispatch(toggleModal('resetPassSuccess', true))
                dispatch(resetForgotPasswordForm())
            })
            .catch(error => {
                formState.errors.fromBE = error.response.data.message;
                dispatch({
                    type: VALIDATE_RESET_PASS,
                    payload: formState
                })
            })
    }
}

export const resetResetPasswordForm = () => dispatch => {
    dispatch({
        type: RESET_PASS_RESET_FORM
    })
}

export const resetForgotPasswordForm = () => dispatch => {
    dispatch({
        type: RESET_PASS_FORGOT_FORM
    })
}

export const onFocus = ({ target }) => dispatch => {
    dispatch({
        type: FORGOT_PASS_FOCUS,
        payload: target
    })
};
export const onBlur = ({ target }) => dispatch => {
    dispatch({
        type: FORGOT_PASS_BLUR,
        payload: target
    })
};

export const onSubmit = (event) => (dispatch, getState) => {
    event.preventDefault();
    var proceed = checkForm(dispatch, getState);
    if (proceed) {
        const { fields, formState } = getState().forgotPassReducer
        instance.post('auth/password/recovery', fields)
            .then(response => {
                dispatch(toggleModal('forgotPassSuccess', true))
                dispatch(resetForgotPasswordForm())
            })
            .catch(error => {
                formState.errors.fromBE = error.response.data.message;
                dispatch({
                    type: VALIDATE_RESET_PASS,
                    payload: formState
                })
            })
    }
};

const checkForm = (dispatch, getState) => {
    const { fields, formState } = getState().forgotPassReducer
    Object.keys(fields).forEach((item) => {
        if (!fields[item]) {
            formState.errors[item] = errorsString.COMMON_ERRORS.IS_REQ;
        }
    });
    const isAllFieldsValid = Object.values(formState.errors).every(
        (field) => !field
    );
    if (!isAllFieldsValid) {
        formState.isFormValid = false;

    }
    formState.isFormValid = true;
    dispatch({
        type: VALIDATE_RESET_PASS,
        payload: formState
    })
    return isAllFieldsValid
};

export const checkToken = token => (dispatch, getState) => {
    instance.get('auth/check/reset-token', {
        params: { token },
    })
        .then(response => {
            dispatch({
                type: SET_TOKEN,
                payload: token
            })
        })
        .catch(error => {
            const { formState } = getState().forgotPassReducer
            formState.errors.fromBE = error.response.data.message;
            dispatch({
                type: VALIDATE_RESET_PASS,
                payload: formState
            })
        })
}
