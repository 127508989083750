import React, { Component } from "react";
import PropTypes from "prop-types";

class Radio extends Component {
  render() {
    const { title, value, selected } = this.props;
    let classname;
    const { onChange } = this.context.radioGroup;
    if (Array.isArray(selected)) {
      classname = selected.includes(value);
    } else {
      classname = selected === value;
    }
    classname = classname ? " active" : "";
    return (
      <button
        type="button"
        className={"radio" + classname}
        onClick={onChange.bind(this, value)}
      >
        {title}
      </button>
    );
  }
}

Radio.contextTypes = {
  radioGroup: PropTypes.object
};

Radio.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string
};

export default Radio;
