import { CHANGE_UPDATE_CONCEPT_VALUES, UPDATE_CONCEPT_LOADING, RESET_UPDATE_CONCEPT, UPDATE_CONCEPT_DELETE_FILE, UPDATE_CONCEPT_ERRORS, UPDATE_CONCEPT_FILE_DOWNLOADING, UPDATE_CONCEPT_FOCUS, UPDATE_CONCEPT_LOADING_PERCENTAGE, UPDATE_CONCEPT_SET_FILES, UPDATE_CONCEPT_SET_PREVIEW, UPLOAD_FAIL, UPDATE_CONCEPT_FILE_SIZE_VALID, UPDATE_CONCEPT_SUCCESS } from '../types'
import { instance } from "../http";
import { getConceptById } from './conceptActions'

export const setLoading = (state) => dispatch => {
    dispatch({
        type: UPDATE_CONCEPT_LOADING,
        payload: state
    })
}

export const setDownloading = (state) => dispatch => {
    dispatch({
        type: UPDATE_CONCEPT_FILE_DOWNLOADING,
        payload: { state, }
    })
}

const checkFile = (file) => dispatch => {
    dispatch({
        type: UPDATE_CONCEPT_FILE_SIZE_VALID,
        payload: false
    })
    const supportedFileTypes = ['image/png', 'image/jpeg', 'application/pdf'];
    if (file.size < 512000000) {
        dispatch({
            type: UPDATE_CONCEPT_FILE_SIZE_VALID,
            payload: true
        })
    }
    if (supportedFileTypes.indexOf(file.type) !== -1)
        return (
            supportedFileTypes.indexOf(file.type) !== -1 && file.size < 512000000
        );
};

export const setFiles = (name, path, id, url) => dispatch => {
    dispatch({
        type: UPDATE_CONCEPT_SET_FILES,
        payload: {
            name,
            path,
            id,
            url,
        }
    })
};

export const onUpload = (docFile,) => (dispatch, getState) => {
    let formData = new FormData();
    let isFileSupported;
    if (docFile) {
        isFileSupported = checkFile(docFile,);
    }
    if (!isFileSupported) return;
    formData.append('file', docFile);
    dispatch(setDownloading(true))
    dispatch(setLoading(true))
    if (getState().updateConceptReducer.loadingPercentage > 0) return;
    const config = {
        onUploadProgress: (progressEvent) => {
            dispatch({
                type: UPDATE_CONCEPT_LOADING_PERCENTAGE,
                payload: Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                )
            })
        },
    }
    instance.post(`/upload`, formData, config)
        .then(response => {
            const { fileName, path, _id, fullURL } = response.data
            dispatch(setFiles(fileName, path, _id, fullURL))
            dispatch(setDownloading(false,))
            dispatch(setLoading(false))
            var { files } = getState().updateConceptReducer
            files = [
                ...files,
                {
                    name: fileName,
                    path,
                    id: _id,
                    url: fullURL,
                }
            ]
            dispatch(updateConcept(files))
        })
        .catch(error => {
            dispatch({
                type: UPLOAD_FAIL,
            })
            throw error;
        })
};

export const onFocus = () => dispatch => {
    dispatch({
        type: UPDATE_CONCEPT_FOCUS,
    })
};

export const onChange = ({ target }) => dispatch => {
    dispatch({
        type: CHANGE_UPDATE_CONCEPT_VALUES,
        payload: target
    })
};

export const deleteFile = (index) => (dispatch, getState) => {
    dispatch({
        type: UPDATE_CONCEPT_DELETE_FILE,
        payload: index
    })
    //To check how to be done
    var { files } = getState().updateConceptReducer

    var indexFile = files.includes(files[index]);
    if (indexFile) {
        files = [
            ...files.slice(0, index),
            ...files.slice(index + 1),
        ];
        dispatch(updateConcept(files))
    }
};

export const clearUpdateConceptStore = () => dispatch => {
    dispatch({
        type: RESET_UPDATE_CONCEPT
    })
};

export const updateConcept = (overwriteFiles) => (dispatch, getState) => {
    const { detailedConcept } = getState().conceptReducer
    var { files, errorsFromBE } = getState().updateConceptReducer
    if (overwriteFiles) files = overwriteFiles
    const idConcept = detailedConcept._id;
    const idArr = files.map((e) => e.id);
    const data = {
        thumbnailUrl: detailedConcept.thumbnailUrl,
        demoPdfUrl: detailedConcept.demoPdfUrl,
        description: detailedConcept.description,
        files: idArr,
    };
    dispatch(setLoading(true))
    instance.put(`/offers/${idConcept}`, data)
        .then(response => {
            dispatch({
                type: UPDATE_CONCEPT_SUCCESS,
                payload: response.data
            })
            dispatch(setLoading(false))
            dispatch(getConceptById(idConcept))
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 400) {
                    errorsFromBE = 'Ungültige Anforderung';
                }
                dispatch({
                    type: UPDATE_CONCEPT_ERRORS,
                    payload: errorsFromBE,
                })
            }
            dispatch(setLoading(false))
            throw error;
        })
}
