import React from 'react';
import CustomerTable from './ProjectTable/CustomerTable';
import NoProjects from './NoProjects/NoProjects';

export default function CustomerPage({ projects, handleRequestPage, languageID }) {
  return (
    <div className="customer-page">
      {projects.result.length <= 0 ? (
        <NoProjects />
      ) : (
          <CustomerTable
            limit={projects.limit}
            totalPages={projects.totalPages}
            page={projects.page}
            projects={projects.result}
            total={projects.total}
            changePage={handleRequestPage}
            languageID={languageID}
          />
        )}
    </div>
  );
}
