import React from 'react';
import edit_helpers from '../../../helpers/validator/edit_helpers';
import { titles } from '../../../constants/createProjectTitles';

const Question = React.forwardRef(
  (
    {
      numberOfApartments,
      numOfFloors,
      numOfSquares,
      numOfRooms,
      numOfBathrooms,
      numOfGuestWCBat,
      roomApartmentDetails,
      numOfParkings,
      checkStatus,
      hasUndergroundParking,
      hasBasement,
      hasTerrace,
      hasWinterGarden,
      constructionType,
      area,
      id,
      budget
    },
    ref
  ) => {
    console.log('budget', budget)
    return (
      <div className="questions-about-the-room-program" id={id} ref={ref}>
        <h2>Fragen zum Raumprogramm</h2>
        <div className="table-wrapper">
          <ul>
            {constructionType && constructionType.length ? (
              <li className="table-item">
                <span>Welche Baumassnahmen möchten Sie umsetzen?</span>
                <span>{constructionType}</span>
              </li>
            ) : null}
            {area ? (
              <li className="table-item">
                <span>Wieviel m2 möchten Sie total gerne dazugewinnen?</span>
                <span>{area}</span>
              </li>
            ) : null}
            {numOfFloors ? (
              <li className="table-item">
                <span>Anzahl Geschosse</span>
                <span>{numOfFloors}</span>
              </li>
            ) : null}
            {numOfSquares && numOfSquares.start ? (
              <li className="table-item">
                <span>Wie viel Wohnfläche benötigen Sie?</span>
                <span>
                  {numOfSquares.start} – {numOfSquares.end} {`m\u00B2`}
                </span>
              </li>
            ) : null}
            {numberOfApartments !== null ? (
              <li className="table-item">
                <span>Anzahl Wohnungen</span>
                <span>{numberOfApartments}</span>
              </li>
            ) : null}
            {numOfRooms ? (
              <li className="table-item">
                <span>Anzahl Zimmer</span>
                <span>{numOfRooms}</span>
              </li>
            ) : null}
            {numOfBathrooms ? (
              <li className="table-item">
                <span>Anzahl Badezimmer</span>
                <span>{numOfBathrooms}</span>
              </li>
            ) : null}
            {numOfGuestWCBat ? (
              <li className="table-item">
                <span>Anzahl Gäste-WC/Bad</span>
                <span>{numOfGuestWCBat}</span>
              </li>
            ) : null}
            {roomApartmentDetails ? (
              <li className="table-item-details">
                {
                  titles.secondStepNumberSizesApartment.map((item, i) => (
                    <div key={i} className="item-listing">
                      <span>{item}</span>
                      <span>{roomApartmentDetails[i].size != null ? roomApartmentDetails[i].size + ` m\u00B2` : ''}</span>
                      <span>{roomApartmentDetails[i].number}</span>
                    </div>
                  ))
                }
              </li>
            ) : null}
            {numOfParkings ? (
              <li className="table-item">
                <span>
                  Wie viele Aussenparkmöglichkeiten (Carport, Parkplatz)
                  brauchen Sie?
                </span>
                <span>{numOfParkings}</span>
              </li>
            ) : null}
            {edit_helpers.isBool(hasUndergroundParking) ? (
              <li className="table-item">
                <span>Möchten Sie eine Tiefgarage?</span>
                <span>{checkStatus(hasUndergroundParking)}</span>
              </li>
            ) : null}
            {edit_helpers.isBool(hasBasement) ? (
              <li className="table-item">
                <span>Möchten Sie einen Keller/ ein Untergeschoss?</span>
                <span>{checkStatus(hasBasement)}</span>
              </li>
            ) : null}
            {edit_helpers.isBool(hasTerrace) ? (
              <li className="table-item">
                <span>
                  Möchten Sie einen Aussenbereich in den oberen Geschossen (wie
                  z.B. eine Terrasse, eine Loggia oder ein Balkon)?
                </span>
                <span>{checkStatus(hasTerrace)}</span>
              </li>
            ) : null}
            {edit_helpers.isBool(hasWinterGarden) ? (
              <li className="table-item">
                <span>Möchten Sie einen Wintergarten?</span>
                <span>{checkStatus(hasWinterGarden)}</span>
              </li>
            ) : null}
            {budget ? (
              <li className="table-item">
                <span>Wie hoch ist lhr Kostenrahmen (ohne. Grundstück)?</span>
                <span>
                  {budget.toLocaleString('de-CH')}
                </span>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    );
  }
);

export default Question;
