import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import Button from '../../../../shared/Button';
import { removeItem } from '../../../../redux/localStorageActions';
import { resetProjectData } from '../../../../redux/actions/createProjectActions';

const MehrFinish = ({ history, route }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { pathname } = location;
  const languageID = pathname.split('/')[1]

  function closeHandler(){
    dispatch(resetProjectData())
    history.push(`/${languageID}/profile/my-projects`)
  }
  
  return (
    <Modal
      centered
      size="xl"
      show={true, true}>
      <div className="finish-message page-wrapper" style={{ paddingBottom: 0 }}>
        <div className="main-content" style={{ maxWidth: 600 }}>
          <p>Nachdem Sie lhr Projekt registriert haben, wird sich unser Faberling-Coach mit lhnen innerhalb von 48h in Verbindung setzen.</p><br />
          <p>Das Preisgeld (Leistungspaket) wird in Absprache mit lhnen projektspezifish definiert.</p><br />
          <p>Anschliessend geht lhr Wettbewerb online.</p>
          <Button text="okay" handleClick={() => closeHandler()} />
        </div>
      </div>
    </Modal>
  );
};

export default MehrFinish;