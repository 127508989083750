import mastercard from "../assets/mastercard.svg";
import paypal from "../assets/paypal.svg";
import visa from "../assets/visa.svg";

export const icons = [
  {
    title: 'Mastercard',
    src: mastercard
  },
  {
    title: 'Visa',
    src: visa
  },
  {
    title: 'PayPal',
    src: paypal
  }
];