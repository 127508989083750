import React, { Component } from 'react';
import { connect } from 'react-redux'
import FormInput from '../../../../../../shared/FormInput';
import upload from '../../../../../../assets/upload.svg';
import FileItem from '../../../../../../shared/FileItem';
import SingleRangeSlider from '../../../../../../shared/Slider';

// Import Redux Actions 
import {
  changeValue,
  changeValueInDetails,
  extractBudgetData,
  onChange,
  onUpload,
  deleteFile,
  saveProjectDataProgress
} from '../../../../../../redux/actions/createProjectActions'

class Special extends Component {
  componentDidUpdate(prevProps) {
    const {
      valuesCreateProject
    } = this.props.createProjectReducer
    const { email } = valuesCreateProject;
    if (prevProps.createProjectReducer.valuesCreateProject.email !== email)
      if (email) onChange(email);
  }

  render() {
    const {
      changeValue,
      changeValueInDetails,
      extractBudgetData,
      onUpload,
      onChange,
      deleteFile,
      location,
      saveProjectDataProgress
    } = this.props

    const {
      emailState,
      files,
      valuesCreateProject
    } = this.props.createProjectReducer

    const { name, email } = valuesCreateProject;

    const {
      requirements,
      budget
    } = valuesCreateProject.details;

    function handleBlur(e) {
      saveProjectDataProgress()
    }

    return (
      <div className="second-step">
        <section className="page-block personal-info">
          <h2>Beschreiben Sie Ihr Projekt</h2>
          <div className="personal-info-inputs">
            <FormInput
              label="Vergeben Sie einen Projektnamen"
              name="projectName"
              additionalText="Architekten können den Projektnamen sehen."
              value={name}
              handleChange={(event) => changeValue('name', event.target.value)}
              handleBlur={handleBlur}
            />
            <FormInput
              label="Email-Adresse"
              type="email"
              name="userEmail"
              additionalText="Zur Speicherung Ihres Briefings."
              value={email}
              error={emailState.error}
              handleChange={(event) => {
                onChange(event.target.value);
                changeValue('email', event.target.value);
              }}
              handleBlur={handleBlur}
            />
          </div>
        </section>

        <SingleRangeSlider
          fieldName="budget"
          min={50000}
          max={5000000}
          defaultValue={budget}
          onChange={extractBudgetData}
          location={location}
        />

        <section className="page-block further-specifications">
          <h2>Erzählen Sie uns von Ihren Anforderungen</h2>
          <textarea
            value={requirements[0].value}
            onChange={(event) =>
              changeValueInDetails('requirements', event.target.value)
            }
            className="textarea-for-specifications"
            placeholder="Geben Sie hier Ihre individuellen Wünsche und Anforderung in Textform ein. Umso genauer Sie Ihr Projekt beschreiben, desto exakter können die Architekten in den Konzeptentwürfen darauf eingehen. Sie haben später auch die Möglichkeit, diese Angaben zu ergänzen und der gesamten Architekten-Community mit einem Klick mitzuteilen."
          />
        </section>
        <section className="page-block further-specifications">
          <h2>Zusätzliche Dateien</h2>
          {this.props.globalReducer.User ? (
            <>
              <p className="title">
                Bitte laden Sie vorhandene Fotos, Pläne oder Skizzen etc. hoch
              </p>
              <p className="sub-title">
                Laden Sie hier ggf. Daten, wie z. B. Bilder und Dokumente,
                Dateiupload (max. 200 MB).
              </p>
              <div className="file-list">
                {files &&
                  files.map((item, index) => {
                    return (
                      <FileItem
                        key={item.id}
                        name={item.name}
                        link={item.url}
                        path={item.path}
                        id={index}
                        deleteFile={deleteFile}
                      />
                    );
                  })}
              </div>
              <div className="file-wrapper">
                <input
                  name="files"
                  id="loading_file"
                  type="file"
                  onChange={(event) => {
                    onUpload(event.target.files[0]);
                  }}
                />
                <label htmlFor="loading_file">
                  <span>DATEI HINZUFÜGEN</span>
                  <img src={upload} alt="upload"></img>
                </label>
              </div>
            </>
          ) : (
              <p className="title">
                Wenn Sie Ihr Projekt gestartet haben oder auf Faberling.ch
                registriert sind, können Sie bereits vorhandene Fotos, Pläne oder
                Skizzen Ihrem Briefing hinzufügen.
              </p>
            )}
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { createProjectReducer, globalReducer } = state
  return {
    createProjectReducer,
    globalReducer
  }
};

export default connect(mapStateToProps, {
  changeValue,
  changeValueInDetails,
  extractBudgetData,
  onChange,
  onUpload,
  deleteFile,
  saveProjectDataProgress
})(Special);