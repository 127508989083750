import { CHANGE_FORGOT_PASS_FIELD, CHANGE_RESET_PASS_FIELD, FORGOT_PASS_BLUR, FORGOT_PASS_FOCUS, FORGOT_PASS_LOADING, RESET_PASS_FORGOT_FORM, RESET_PASS_RESET_FORM, SET_TOKEN, VALIDATE_RESET_PASS } from '../types'
import validator from '../../helpers/validator/validation';
import errorsString from '../../helpers/validator/errorsString';

const defaultFields = () => {
    return {
        email: ""
    };
}

const defaultResetPasswordFields = () => {
    return {
        old_pass: '',
        new_pass: ''
    }
}

const defaultFormState = () => {
    return {
        isLoading: false,
        isFormValid: true,
        errors: {
            email: '',
            fromBE: '',
            old_pass: '',
            new_pass: '',
        },
    }
}

function initialState() {
    return {
        fields: defaultFields(),
        resetPasswordFields: defaultResetPasswordFields(),
        formState: defaultFormState(),
        resetToken: ''
    };
}

export default function (state = initialState(), action) {
    const { type, payload } = action;
    switch (type) {
        case FORGOT_PASS_LOADING:
            var formState = { ...state.formState }
            formState.isLoading = payload;
            return {
                ...state,
                formState
            }
        case CHANGE_FORGOT_PASS_FIELD:
            var formState = { ...state.formState }
            formState.errors[payload.name] = "";
            var fields = { ...state.fields }
            fields[payload.name] = payload.value;
            var formState = { ...state.formState }
            const validatorObj = validator.validate(payload.name, payload.value);
            formState.errors[payload.name] = validatorObj.error;
            return {
                ...state,
                fields,
                formState,
            }
        case CHANGE_RESET_PASS_FIELD:
            var formState = { ...state.formState }
            formState.errors[payload.name] = "";
            var resetPasswordFields = { ...state.resetPasswordFields }
            resetPasswordFields[payload.name] = payload.value;
            return {
                ...state,
                // formState: payload,
                resetPasswordFields
            }
        case VALIDATE_RESET_PASS:
            return {
                ...state,
                formState: payload
            }
        case RESET_PASS_RESET_FORM:
            return {
                ...state,
                formState: defaultFormState(),
                resetPasswordFields: defaultResetPasswordFields(),
            }
        case RESET_PASS_FORGOT_FORM:
            return {
                ...state,
                formState: defaultFormState(),
                fields: defaultFields(),
            }
        case FORGOT_PASS_FOCUS:
            var formState = { ...state.formState }
            formState.errors[payload.name] = '';
            formState.errors.fromBE = '';
            return {
                ...state,
                formState
            }
        case FORGOT_PASS_BLUR:
            var formState = { ...state.formState }
            if (!payload.value) {
                formState.errors[payload.name] = errorsString.COMMON_ERRORS.IS_REQ;
            }
            return {
                ...state,
                formState
            }
        case SET_TOKEN:
            return {
                ...state,
                resetToken: payload
            }
        default:
            return state
    }
}
