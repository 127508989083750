import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.scss";
import App from "./App";
import moment from 'moment/min/moment-with-locales';
import TagManager from 'react-gtm-module'
moment.locale('de');
console.log(moment().format('DD. MMM YYYY'))

const tagManagerArgs = {
    gtmId: 'GTM-WBTFH23'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
