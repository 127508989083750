import React, { Fragment } from 'react';
import { useState } from 'react';
import plus from '../../../assets/plus.svg';
import yes from '../../../assets/yes.svg';
import no from '../../../assets/no.svg';
import { useEffect } from 'react';

const Requirements = React.forwardRef(
  ({ requirementsForArchitect, requirements, id, handleShow, projectID, approvedRole }, ref) => {
    const [display, setDisplay] = useState(false)
    const [newRequirement, setNewRequirement] = useState('')
    const [type, setType] = useState('')
    const toggle = () => {
      setDisplay(!display)
    }
    const addRequirement = () => {
      toggle()
      handleShow(projectID, newRequirement, type)
      setNewRequirement('')
    }

    useEffect(() => {
      if (projectID) {
        setType(requirementsForArchitect ? "requirementsForArchitect" : "requirements")
      }
    }, [projectID])

    const requirementValue = requirementsForArchitect ? requirementsForArchitect : requirements
    if (Array.isArray(requirementValue)) {
      return <Fragment>
        <h2>Weitere Vorgaben für die Architekten</h2>
        {requirementValue.map((requirement, i) => {
          if (typeof requirement === 'object' && requirement !== null) {
            return (
              <div key={requirement + i}>
                <div className="requirements" id={id} ref={ref}>
                  <p className='date'>{`${new Date(requirement.timestamp).toLocaleString('de-CH')}`}</p>
                  <p>{requirement.value}</p>
                </div>
              </div>
            )
          } else {
            return <div className="requirements" id={id} ref={ref}>
              <h2>Weitere Vorgaben für die Architekten</h2>
              <p>{requirement}</p>
            </div>
          }
        })}
        {approvedRole && (display
          ? <Fragment>
            <textarea
              value={newRequirement}
              onChange={(event) =>
                setNewRequirement(event.target.value)
              }
              className="textarea-for-specifications"
              placeholder="Geben Sie hier Ihre individuellen Wünsche und Anforderung in Textform ein. Umso genauer Sie Ihr Projekt beschreiben, desto exakter können die Architekten in den Konzeptentwürfen darauf eingehen."></textarea>
            <img src={yes} alt="yes" /><span className='addRequirement' style={{ color: 'green' }} onClick={addRequirement}> hinzufügen</span> {' '}
            <img src={no} alt="no" /><span className='addRequirement' style={{ color: 'red' }} onClick={toggle}> abbrechen</span>
          </Fragment>
          : <Fragment>
            <br></br>
            <img src={plus} alt="plus" />
            <span className='addRequirement' onClick={toggle}> Vorgabe hinzufügen</span>
          </Fragment>)}
      </Fragment>
    } else {
      return (
        <div className="requirements" id={id} ref={ref}>
          <h2>Weitere Vorgaben für die Architekten</h2>
          <p>{requirementValue}</p>
        </div>
      );
    }
  }
);

export default Requirements;
