import React from 'react';
import { withRouter } from 'react-router';
import {
  createProjectTitle,
  profileTitle,
  profileDisignerTitle,
  basicInfoTitles,
} from '../../../../constants/titles';
import ElementWithLink from './components/ElementWithLink';
import ElementWithoutLink from './components/ElementWithoutLink';
import { userRole } from '../../../../constants/userRole';
import ProjectHeader from './ProjectHeader';
function CentralSide({ location, role }) {
  const { pathname } = location;
  const languageID = pathname.split('/')[1];
  let page = pathname.split('/')[2];
  const place = pathname.split('/')[3];
  let pageTitle, isLink;
  const isProject = page === 'create-project';
  switch (page) {
    case 'create-project':
      pageTitle = createProjectTitle;
      isLink = false;
      break;
    case 'profile':
      if (role === userRole.COMPANY) {
        pageTitle = profileDisignerTitle;
      } else {
        pageTitle = profileTitle;
      }
      isLink = true;
      break;
    case 'basic-info':
      pageTitle = basicInfoTitles;
      page = null;
      isLink = true;
      break;
    default:
      pageTitle = null;
      break;
  }

  return (
    <div className="central-side">
      {isProject ? (
        <ProjectHeader languageID={languageID}/>
      ) : (
        pageTitle &&
        pageTitle.map((item) =>
          isLink ? (
            <ElementWithLink
              key={item.value}
              item={item}
              type={item.type}
              page={page}
              languageID={languageID}
              place={place}
            />
          ) : (
            <ElementWithoutLink key={item.value} item={item} place={place} />
          )
        )
      )}
    </div>
  );
}

export default withRouter(CentralSide);
