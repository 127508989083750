import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux'
import FormInput from '../../../../../../shared/FormInput';
import CounterBox from '../../../../../../shared/CounterBox';
import Switch from '../../../../../../shared/Switch';
import RadioGroup from '../../../../../../shared/RadioGroup';
import Radio from '../../../../../../shared/RadioGroup/components/Radio';
import CheckBox from '../../../../../../shared/CheckBox';
import SelectedCardGroup from '../../../../../../shared/SelectedCardGroup';
import SelectableCard from '../../../../../../shared/SelectedCardGroup/components/SelectedCard';
import DoubleRangeSlider from '../../../../../../shared/RangeSlider';
import SingleRangeSlider from '../../../../../../shared/Slider';
import { titles } from '../../../../../../constants/createProjectTitles';
import { constrMethods, roofType } from '../../../../../../constants/firstStep';
import ListItemContainer from '../../../../../../shared/ListItemContainer';

// Import Redux Actions 
import {
  changeValue,
  changeBoxes,
  extractRangeData,
  extractBudgetData,
  changeValueInDetails,
  onChange,
  saveProjectDataProgress
} from '../../../../../../redux/actions/createProjectActions'
import { isMoment } from 'moment/min/moment-with-locales';

class BuildingEinfa extends Component {
  componentDidUpdate(prevProps) {
    const {
      valuesCreateProject
    } = this.props.createProjectReducer
    const { email } = valuesCreateProject;
    if (prevProps.createProjectReducer.valuesCreateProject.email !== email)
      if (email) onChange(email);
  }
  render() {
    const {
      changeValue,
      changeBoxes,
      extractRangeData,
      extractBudgetData,
      changeValueInDetails,
      onChange,
      saveProjectDataProgress,
      location
    } = this.props
    const {
      emailState,
      valuesCreateProject
    } = this.props.createProjectReducer
    const { name, email } = valuesCreateProject;

    const {
      numOfSquares,
      budget,
      hasAtrium,
      hasGallery,
      kitchenType,
      roomDesign,
      requirementsForArchitect,
    } = valuesCreateProject.details;

    function handleBlur(e){
      saveProjectDataProgress()
    }

    return (
      <div className="second-step">
        <section className="page-block personal-info">
          <h2>Beschreiben Sie Ihr Projekt</h2>
          <div className="personal-info-inputs">
            <FormInput
              label="Vergeben Sie einen Projektnamen"
              name="projectName"
              additionalText="Architekten können den Projektnamen sehen."
              value={name}
              handleChange={(event) => changeValue('name', event.target.value)}
              handleBlur={handleBlur}
            />
            <FormInput
              label="Email-Adresse"
              type="email"
              name="userEmail"
              additionalText="Zur Speicherung Ihres Briefings."
              value={email}
              error={emailState.error}
              handleChange={(event) => {
                onChange(event.target.value);
                changeValue('email', event.target.value);
              }}
              handleBlur={handleBlur}
            />
          </div>
        </section>
        <section className="page-block room-program">
          <h2>Fragen zum Raumprogramm</h2>
          <div className="selectable-blocks">
            <div className="selectable-blocks-wrapper">
              <ListItemContainer
                title={titles.secondStepCounterTitles[0].title}
                children={
                  <Fragment>
                    <CounterBox
                      counter={
                        valuesCreateProject.details[
                        titles.secondStepCounterTitles[0].value
                        ]
                      }
                      onClick={(value) =>
                        changeValueInDetails(
                          titles.secondStepCounterTitles[0].value,
                          value
                        )
                      }
                    />
                  </Fragment>
                }
                classname="two-items"
              />
              <div className="selectable-blocks-item column-direction livingSpace">
                <p>Wie viel Wohnfläche benötigen Sie?</p>
                <DoubleRangeSlider
                  fieldName="livingSpace"
                  min={10}
                  max={1000}
                  defaultValue={Object.values(numOfSquares)}
                  onChange={extractRangeData}
                />
                <div className="selected-double-range">
                  <div className="selected-double-range-min">
                    {numOfSquares.start}
                  </div>
                  <span className="selected-double-range-dash">&ndash;</span>
                  <div className="selected-double-range-max">{`${numOfSquares.end} m\u00B2`}</div>
                </div>
              </div>
              {titles.secondStepCounterTitles.map((element, index) => {
                if (index > 0) {
                  return (
                    <ListItemContainer
                      key={element.value}
                      title={element.title}
                      children={
                        <Fragment>
                          <CounterBox
                            counter={
                              valuesCreateProject.details[
                              element.value
                              ]
                            }
                            onClick={(value) =>
                              changeValueInDetails(element.value, value)
                            }
                          />
                        </Fragment>
                      }
                      classname="two-items"
                    />
                  );
                } else {
                  return null;
                }
              })}
            </div>
            <div className="selectable-blocks-wrapper">
              {titles.secondStepSwitcherTitles.map((element) => (
                <ListItemContainer
                  key={element.value}
                  title={element.title}
                  onClick={() =>
                    changeValueInDetails(
                      element.value,
                      !valuesCreateProject.details[
                      element.value
                      ]
                    )
                  }
                  children={
                    <Fragment>
                      <Switch
                        value={
                          valuesCreateProject.details[
                          element.value
                          ]
                        }
                        onClick={(value) => {
                          // Removed cause it will double toggle
                          // changeValueInDetails(element.value, value)
                        }}
                      />
                    </Fragment>
                  }
                  classname="two-items"
                />
              ))}
            </div>
          </div>
        </section>

        <section className="page-block living-space">
          <h2>Fragen zum Wohnraumstil?</h2>
          <div className="selectable-blocks">
            <div className="selectable-blocks-wrapper">
              <ListItemContainer
                title={titles.secondStepRadioTitles[0]}
                children={
                  <Fragment>
                    <RadioGroup
                      changeRadio={(value) =>
                        changeValueInDetails('kitchenType', value)
                      }>
                      <Radio
                        selected={kitchenType}
                        value="CLOSED"
                        title="Geschlosse"
                      />
                      <Radio
                        selected={kitchenType}
                        value="WHATEVER"
                        title="Egal"
                      />
                      <Radio
                        selected={kitchenType}
                        value="OPENED"
                        title="Offene"
                      />
                    </RadioGroup>
                  </Fragment>
                }
                classname="two-items"
              />
              <ListItemContainer
                title={titles.secondStepRadioTitles[1]}
                children={
                  <Fragment>
                    <RadioGroup
                      changeRadio={(value) =>
                        changeValueInDetails('roomDesign', value)
                      }>
                      <Radio
                        selected={roomDesign}
                        value="CLOSED"
                        title="Geschlosse"
                      />
                      <Radio
                        selected={roomDesign}
                        value="WHATEVER"
                        title="Egal"
                      />
                      <Radio
                        selected={roomDesign}
                        value="OPENED"
                        title="Offene"
                      />
                    </RadioGroup>
                  </Fragment>
                }
                classname="two-items"
              />
            </div>
            <div className="selectable-blocks-wrapper">
              <ListItemContainer
                title="Wählen Sie zusätzliche Optionen"
                children={
                  <Fragment>
                    <CheckBox
                      value={true}
                      onClick={() => changeBoxes('details', 'hasAtrium')}
                      checkedField={hasAtrium}
                      title={titles.secondStepCheckboxTitles[0]}
                    />
                    <CheckBox
                      value={true}
                      onClick={() => changeBoxes('details', 'hasGallery')}
                      checkedField={hasGallery}
                      title={titles.secondStepCheckboxTitles[1]}
                    />
                  </Fragment>
                }
                classname="two-items children-flex"
              />
            </div>
          </div>
        </section>

        <section className="page-block construction-method">
          <h2>Welche Bauweise bevorzugen Sie?</h2>
          <div className="style">
            <SelectedCardGroup fieldName="skeletonType">
              {constrMethods.map((method, index) => (
                <SelectableCard
                  key={index}
                  path={valuesCreateProject.details}
                  isChangesColor={true}
                  value={method.value}
                  src={method.src}
                  title={method.title}
                />
              ))}
            </SelectedCardGroup>
          </div>
        </section>

        <section className="page-block roof-type">
          <h2>Welchen Dachtyp bevorzugen Sie?</h2>
          <div className="style">
            <SelectedCardGroup fieldName="roofType">
              {roofType.map((type, index) => (
                <SelectableCard
                  key={index}
                  path={valuesCreateProject.details}
                  isChangesColor={true}
                  value={type.value}
                  src={type.src}
                  innerTitle={type.title}
                />
              ))}
            </SelectedCardGroup>
          </div>
        </section>

        <SingleRangeSlider
          fieldName="budget"
          min={50000}
          max={5000000}
          defaultValue={budget}
          onChange={extractBudgetData}
          location={location}
        />

        <section className="page-block further-specifications">
          <h2>Haben Sie weitere Vorgaben für die Architekten?</h2>
          <textarea
            value={requirementsForArchitect[0].value}
            onChange={(event) =>
              changeValueInDetails(
                'requirementsForArchitect',
                event.target.value
              )
            }
            className="textarea-for-specifications"
            placeholder="Geben Sie hier Ihre individuellen Wünsche und Anforderung in Textform ein. Umso genauer Sie Ihr Projekt beschreiben, desto exakter können die Architekten in den Konzeptentwürfen darauf eingehen. Sie haben später auch die Möglichkeit, diese Angaben zu ergänzen und der gesamten Architekten-Community mit einem Klick mitzuteilen."></textarea>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { createProjectReducer } = state
  return {
    createProjectReducer,
  }
};

export default connect(mapStateToProps, {
  changeValue,
  changeBoxes,
  extractRangeData,
  extractBudgetData,
  changeValueInDetails,
  onChange,
  saveProjectDataProgress
})(BuildingEinfa);
