import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

const PaginationBlock = ({
  postsPerPage,
  totalPages,
  paginate,
  activePage,
  pageRangeDisplayed,
  breakLabel,
  marginPagesDisplayed,
  previousLabel,
  nextLabel,
}) => {
  return (
    <ReactPaginate
      containerClassName={'pagination'}
      activeClassName={'active'}
      activePage={activePage}
      pageRangeDisplayed={pageRangeDisplayed}
      onPageChange={paginate}
      pageCount={totalPages}
      nextLabel={nextLabel}
      previousLabel={previousLabel}
      marginPagesDisplayed={marginPagesDisplayed}
      breakLabel={breakLabel}
    />
  );
};

export default PaginationBlock;

PaginationBlock.propTypes = {
  postsPerPage: PropTypes.number,
  totalPosts: PropTypes.number,
  activePage: PropTypes.number,
  paginate: PropTypes.func,
};
