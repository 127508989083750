import { CREATE_COMMENT, GET_COMMENTS, GET_PRIVATE_CONTACTS, LOGOUT, OPEN_CONVERSATION_OFFER, SET_CHAT_MESSAGES, TOGGLE_CHAT } from '../types'

function initialState() {
    return {
        totalPublicComments: 0,
        isChatOpened: false,
        conversations: [],
        conversationNav: [],
        currentConversation: { messages: [], username: '', id: '' },
        newMessage: false,
    };
}

export default function (state = initialState(), action) {
    const { type, payload } = action;
    switch (type) {
        case TOGGLE_CHAT:
            var currentUser = JSON.parse(localStorage.getItem('user'))

            if (!payload) {
                var conversation = state.conversations.find((conversation) => conversation.isPublic)
            } else {
                var conversation = state.conversations.find((conversation) => conversation._id == payload)
            }
            var user = conversation.users.find((user) => currentUser._id !== user._id);
            currentConversation = {
                messages: conversation.messages,
                username: user ? user.login : '',
                id: conversation._id
            }
            return {
                ...state,
                currentConversation,
                isChatOpened: true
            }
        case GET_COMMENTS:
            var currentConversation
            var id = payload.chatID ? payload.chatID : payload.response[0]._id
            var conversation = payload.response.find((conversation) => conversation._id == id)
            var user = conversation.users.find((user) => payload.user._id !== user._id);
            currentConversation = {
                messages: conversation.messages,
                username: user ? user.login : '',
                id
            }
            return {
                ...state,
                conversations: payload.response,
                totalPublicComments: payload.response.length,
                newMessage: false,
                currentConversation
            }
        case CREATE_COMMENT:
            return {
                ...state,
                newMessage: true
            }
        case SET_CHAT_MESSAGES:
            var currentConversation
            var id = payload.id ? payload.id : state.conversations[0]._id
            var conversation = state.conversations.find((conversation) => conversation._id == id)
            var user = conversation.users.find((user) => payload.user._id !== user._id);
            currentConversation = {
                messages: conversation.messages,
                username: user ? user.login : '',
                id
            }
            return {
                ...state,
                currentConversation,
            }
        case OPEN_CONVERSATION_OFFER:
            var obj = {
                ...state,
                conversations: payload.comments,
                totalPublicComments: payload.comments.length
            }
            obj.conversations.forEach((conversation) => {
                if (conversation.offer == payload.offerId) {
                    const user = conversation.users.find(
                        (user) => payload.user._id !== user._id
                    );
                    obj.currentConversation = {
                        messages: conversation.messages,
                        username: user ? user.login : '',
                        id: conversation._id,
                    };
                }
            });
            return obj
        case GET_PRIVATE_CONTACTS:
            return {
                ...state,
                conversationNav: state.conversations
                    .filter((conversation) => !conversation.isPublic)
                    .map((conversation) => ({
                        id: conversation._id,
                        login: conversation.users.find(
                            (user) => payload.login !== user.login
                        ).login,
                    }))
            }
        case LOGOUT:
            return initialState()
        default:
            return state
    }
}
