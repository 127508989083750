import { CHANGE_FILE, RESET_UPLOAD_DOC, UPDATE_STRIPE_ID, UPLOAD_DOC_FILE_DOWNLOADING, UPLOAD_DOC_FILE_SIZE_VALID, UPLOAD_FAIL } from '../types';
import { instance } from '../http'

export const onUpload = (docFile, name) => dispatch => {
    let formData = new FormData();
    let isFileSupported;
    if (docFile) {
        isFileSupported = checkFileType(docFile, name, dispatch);
    }

    if (!isFileSupported) return;
    formData.append('file', docFile);
    formData.append('purpose', name);
    dispatch(setDownloading(true, name))
    instance({
        method: 'post',
        url: `/upload/verification`,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
    })
        .then(response => {
            dispatch(setStripeID(response.data.stripeDocId, name))
            dispatch(setDownloading(false, name))
        })
        .catch(error => {
            dispatch({
                type: UPLOAD_FAIL,
            })
            throw error;
        })
};

export const checkFileType = (file, name, dispatch) => {
    dispatch({
        type: UPLOAD_DOC_FILE_SIZE_VALID,
        payload: { state: false, name }
    })
    // registrationCompanyStore.formState.errors[name] = '';
    const supportedFileTypesDE = ['image/png', 'image/jpeg', 'application/pdf'];
    const supportedFileTypesID = ['image/png', 'image/jpeg'];

    if (name === 'dispute_evidence') {
        if (file.size < 512000000) dispatch({
            type: UPLOAD_DOC_FILE_SIZE_VALID,
            payload: { state: true, name }
        })
        if (supportedFileTypesDE.indexOf(file.type) !== -1)
            return (
                supportedFileTypesDE.indexOf(file.type) !== -1 && file.size < 512000000
            );
    } else {
        if (file.size < 512000000) dispatch({
            type: UPLOAD_DOC_FILE_SIZE_VALID,
            payload: { state: true, name }
        })
        if (supportedFileTypesID.indexOf(file.type) !== -1)
            return (
                supportedFileTypesID.indexOf(file.type) !== -1 && file.size < 512000000
            );
    }
};

export const setDownloading = (state, name) => dispatch => {
    dispatch({
        type: UPLOAD_DOC_FILE_DOWNLOADING,
        payload: { state, name }
    })
}

export const setStripeID = (id, name) => dispatch => {
    dispatch({
        type: UPDATE_STRIPE_ID,
        payload: { id, name }
    })
};

export const changeFile = name => dispatch => {
    // registrationCompanyStore.formState.errors.fromBE = '';
    dispatch({
        type: CHANGE_FILE,
        payload: name
    })
};


export const clearDocStore = () => dispatch => {
    dispatch({
        type: RESET_UPLOAD_DOC
    })
};