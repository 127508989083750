import React from 'react';

const Work = React.forwardRef(
  ({ type, category, checkProjectType, checkCategory, id }, ref) => {
    const categoryName =
      checkCategory(category) === type
        ? type
        : `${checkCategory(category)} – ${type}`;
    return (
      <div id={id} ref={ref} className="work">
        <h2>Leistung</h2>
        <div className="card-wrapper">
          <div className="img-container">
            <img src={checkProjectType(type)} alt="project type" alt={category} className={category} />
            <p className={category}>
              {categoryName}
            </p>
          </div>
        </div>
      </div>
    );
  }
);

export default Work;
