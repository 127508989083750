import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../Button';
import deleteAct from '../../../../assets/close_modal.svg';
import save from '../../../../assets/save.svg';
import { steps } from '../../../../constants/steps';
import {
  resetProjectData,
  saveProjectData,
  setRedirect,
  onChange,
  changeValue
} from '../../../../redux/actions/createProjectActions'
import { updateEmail } from '../../../../redux/actions/registrationActions'

//Import Redux actions
import { getItem } from '../../../../redux/localStorageActions'
import { postProject } from '../../../../redux/actions/ProjectActions'
// import { setRedirect, onChange } from '../../../../redux/actions/createProjectActions'
import { postTransaction } from '../../../../redux/actions/transactionActions'

const ProjectFooter = ({ location, history }) => {
  const dispatch = useDispatch()
  const { redirect } = useSelector(state => state.projectReducer)
  const { emailState, valuesCreateProject } = useSelector(state => state.createProjectReducer)
  const { User } = useSelector(state => state.globalReducer)
  const { success, isLoading } = useSelector(state => state.transactionReducer)
  const isProduct = valuesCreateProject.category === 'PRODUKT';
  const isMehr = valuesCreateProject.type === 'Mehrfamilienhaus' ? true : false;
  const { pathname } = location;
  const url = `${pathname.split('/')[1]}/${pathname.split('/')[2]}`;
  const place = pathname.split('/')[3];
  const languageID = pathname.split('/')[1];
  const [buttonPressed, setButtonPressed] = useState(false)
  const [disableBackButton, setDisableBackButton] = useState(true)


  useEffect(() => {
    if (place !== 'style') {
      setDisableBackButton(false)
    } else {
      setDisableBackButton(true)
    }
  }, [place])

  useEffect(() => {
    if (redirect) {
      if (!User && (place === 'tariffs' || place === 'style' || (place === 'instruction' && isMehr))) {
        // if user is not logged in
        dispatch(updateEmail(valuesCreateProject.email))
        dispatch(setRedirect(true))
        // after registration or login the redirection will be determined in /shared/AuthHOC/AuthHOC.jsx
        history.push(`/${languageID}/registration`);
      } else {
        if (place === 'pay' && !success) return
        if (isProduct && place !== 'pay') {
          // if category is product > from 1st step > after weiter, project will be created and skip step 2-3 > redirect to payment page
          history.push(`/${url}/pay`);
        } else {
          if (isMehr) {
            // if type is Mehrfamilienhaus > from 2nd step > after weiter, project will be created and skip the rest process > redirect to Mehrfamilienhaus success page
            history.push(`/${url}/success`);
          } else {
            // normal step flow
            history.push(`/${url}/${steps[place]}`);
          }
        }
      }
    }
  }, [redirect])

  useEffect(() => {
    if (!isLoading) {
      setButtonPressed(false)
    }
  }, [isLoading])

  const resetProjectFields = () => {
    if (window.innerWidth < 421){
      if (window.confirm('Möchten Sie dieses Projekt abbrechen?')){
        confirmResetProjectFields()
      }
    } else {
      confirmResetProjectFields()
    }
  };

  function confirmResetProjectFields(){
    dispatch(resetProjectData())
    history.push(`/${url}/style`);
  }

  const createdProjectData = JSON.parse(getItem('createdProjectData')) || {}

  const nextStep = async () => {
    dispatch(saveProjectData(place))
    let hasError = false;
    switch (place) {
      case 'tariffs':
        dispatch(postProject(valuesCreateProject))
        hasError = true
        break;
      case 'pay':
        setButtonPressed(true)
        dispatch(postTransaction(createdProjectData._id))
        hasError = true
        break;
      default:
        break;
    }
    if (!hasError) {
      if (place === 'style' && isProduct) {
        // from 2nd step > if category is product > create project > redirect value set by redux and useEffect will be triggered to handle the redirection
        let createProjectData = valuesCreateProject;
        if (User) {
          dispatch(changeValue('email', User.email));
          dispatch(changeValue('name', 'Plan-Digitalisierung'));
          createProjectData = {
            ...createProjectData,
            email: User.email,
            name: 'Plan-Digitalisierung'
          }
        }
        dispatch(postProject(createProjectData))
      } else if (place === 'instruction' && isMehr) {
        // from 2nd step > if category is product > create project > redirect value set by redux and useEffect will be triggered to handle the redirection
        let createProjectData = valuesCreateProject;
        createProjectData = {
          ...createProjectData,
          currency: languageID === 'ch' ? 'CHF' : 'EUR'   // set the currency manually cause it doesn't have tariff
        }
        dispatch(postProject(createProjectData))
      } else {
        // redirect to the next step normally
        history.push(`/${url}/${steps[place]}`);
      }
    }
  };

  const checkDisable = (place) => {
    if (buttonPressed) { return true }
    switch (place) {
      case 'instruction':
        const { email, name } = valuesCreateProject;
        return email &&
          emailState.isValid &&
          name
          ? false
          : true;
      default:
        return false;
    }
  };

  const goBack = () => {
    const place = pathname.split('/')[3];
    let step = ''
    if (!place) return;
    switch (place) {
      case 'instruction':
        step = 'style';
        break;
      case 'tariffs':
        step = 'instruction';
        break;
      case 'pay':
        step = 'tariffs';
        break;
      default:
        break;
    }
    if (step) {
      if (place === 'pay' && isProduct) {
        history.push(`/${languageID}/create-project/style`);
      } else {
        history.push(`/${languageID}/create-project/${step}`);
      }
    }
  }

  return (
    isMehr ? <></> :
      place !== 'payment' && (
        <footer className="projectFooter">
          <div onClick={resetProjectFields} className="item left">
            {/* <img src={deleteAct} alt="deleteAct" /> */}
            <i className="fa fa-undo"></i> &nbsp;
            <span>Abbrechen</span>
          </div>
          <div className="btnFooter">
            <Button handleClick={goBack} text="Zurück"
              isDisabled={disableBackButton}
              isDisabledClass={disableBackButton}
            />
            <Button
              handleClick={nextStep}
              isDisabled={checkDisable(place)}
              isDisabledClass={checkDisable(place)}
              text="Weiter"
            />
          </div>
          <div
            className="item"
            onClick={() => dispatch(saveProjectData(place))}>
            <span>Briefing speichern</span>
            <img src={save} alt="save" style={{width:'30px', marginLeft:'4px'}}/>
          </div>
        </footer>
      )
  );
};

export default withRouter(ProjectFooter);
