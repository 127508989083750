import React, { Component } from 'react';
import { connect } from 'react-redux'
import InputMask from 'react-input-mask';
import FormInput from '../../shared/FormInput';

import { onTransactionChange } from '../../redux/actions/transactionActions'

class CardInfoForm extends Component {
  onHandleChangeCardNumber = (event) => {
    let cardNumber = event.target.value.split(' ').join('');
    this.props.onTransactionChange({
      name: 'number',
      value: cardNumber
    })
  }

  onHandleChangeExpiryDate = (event) => {
    let expiryDate = event.target.value.split('/');
    this.props.onTransactionChange({
      name: 'exp_month',
      value: expiryDate[0]
    })
    this.props.onTransactionChange({
      name: 'exp_year',
      value: expiryDate[1] && '20' + expiryDate[1]
    })
  }

  onHandleChangeCvv2 = (event) => {
    this.props.onTransactionChange({
      name: 'cvc',
      value: event.target.value
    })
  }

  onHandleChangeCardName(event) {
    // transactionStore.cardDetails.card.name = event.target.value;
  }

  render() {
    const { transactionReducer } = this.props

    return (
      < div className="card-info-block" >
        <div className={'inputWrapper'}>
          <InputMask
            name="cardNumber"
            type="text"
            placeholder="XXXX XXXX XXXX XXXX"
            mask="9999 9999 9999 9999"
            maskChar={null}
            onChange={this.onHandleChangeCardNumber}>
            {inputProps => <input {...inputProps} />}
          </InputMask>

          <label className="label" htmlFor="cardNumber">
            Kartennummer
          </label>
          <div className="helperMessage">
            {/* {(error && <p>{error}</p>) ||
              (additionalText && <p>{additionalText}</p>)} */}
          </div>
        </div>
        <FormInput
          label="Name auf der Karte"
          name="cardName"
          type="text"
          handleChange={this.onHandleChangeCardName}
        />
        <div className={'inputWrapper'}>
          <InputMask
            name="expiryDate"
            type="text"
            placeholder="MM/YY"
            mask="99/99"
            maskChar={null}
            onChange={this.onHandleChangeExpiryDate}>
            {inputProps => <input {...inputProps} />}
          </InputMask>
          <label className="label" htmlFor="expiryDate">
            Verfallsdatum
          </label>
          <div className="helperMessage">
            {/* {(error && <p>{error}</p>) ||
              (additionalText && <p>{additionalText}</p>)} */}
          </div>
        </div>
        <FormInput
          label="CVV2"
          name="cvv2"
          type="password"
          maxlength={3}
          handleChange={this.onHandleChangeCvv2}
        />
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  const {
    transactionReducer
  } = state
  return {
    transactionReducer
  }
};

export default connect(mapStateToProps, {
  onTransactionChange
})(CardInfoForm);