import React from 'react';
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

export default function DoubleRangeSlider({
  min,
  max,
  defaultValue, //array
  onChange
}) {
  const rangeData = {};

  return (
    <div className="range-wrapper">
      <Range
        min={min}
        max={max}
        defaultValue={defaultValue}
        step={10}
        onChange={(value) => {
          rangeData.start = value[0];
          rangeData.end = value[1];
          return onChange(rangeData);
        }}
      />
    </div>
  );
};