import React, { Component } from 'react';
import { connect } from 'react-redux'
import RegistrationCustomerForm from './components/RegistrationCustomerForm/RegistrationCustomerForm';
import RegistrationCompanyForm from './components/RegistrationCompanyForm/RegistrationCompanyForm';
import { withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import TermsModal from '../../../shared/ModalContainer/TermsModal';

import { clearFirstStepStore } from '../../../redux/actions/registrationFirstStepActions'
import { clearStore } from '../../../redux/actions/registrationActions'
import { toggleModal } from '../../../redux/actions/modalActions'

class Registration extends Component {
  state = {
    // change forms
    isArchitect: false,
  };

  componentDidMount() {
    this.setState({ isArchitect: this.props.location.search === '?designer' });
  }

  handleClick = status => {
    this.setState({
      isArchitect: status,
    });
    this.props.clearFirstStepStore();
    this.props.clearStore();
  };

  render() {
    const isArchitect = this.state.isArchitect;
    const {
      registrationFirstStepReducer,
      createProjectReducer,
      modalReducer,
      toggleModal,
    }
      = this.props
    const { isSecondStep } = registrationFirstStepReducer;
    return (
      <div className="form-wrapper registration">
        <p className="title">Registrierung</p>
        {!isSecondStep ? (
          <p className="subTitle">
            Das Erstellen eines Kontos hat viele Vorteile.
          </p>
        ) : (
            <p className="subTitle">
              Um an Projekten arbeiten zu können, müssen Sie die Registrierung
              abschließen. Sie können dies jetzt oder später jederzeit tun.
            </p>
          )}
        {!isSecondStep && !createProjectReducer.redirectToCreateProject && (
          <div className="page-switcher">
            <button
              className={isArchitect ? '' : 'active'}
              onClick={() => this.handleClick(false)}>
              Ich bin Autraggeber
            </button>
            <button
              className={isArchitect ? 'active' : ''}
              onClick={() => this.handleClick(true)}>
              Ich bin Architekt
            </button>
          </div>
        )}
        {isArchitect ? (
          <RegistrationCompanyForm />
        ) : (
            <RegistrationCustomerForm />
          )}
        <Modal
          centered
          size="xl"
          show={modalReducer.componentName === 'termsModal'}
          onHide={() => toggleModal('', false)}>
          <TermsModal onClose={toggleModal} />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    modalReducer,
    registrationCompanyReducer,
    registrationIndividualReducer,
    registrationFirstStepReducer,
    createProjectReducer,
  } = state
  return {
    modalReducer,
    registrationFirstStepReducer,
    registrationCompanyReducer,
    registrationIndividualReducer,
    createProjectReducer,
  }
};

export default connect(mapStateToProps, {
  clearFirstStepStore,
  clearStore,
  toggleModal,
})(withRouter(Registration));
