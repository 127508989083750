import React, { Component } from 'react';
import { connect } from 'react-redux'
import FormInput from '../../../../shared/FormInput';
import Button from '../../../../shared/Button';
import close from '../../../../assets/close.svg';
import { onChangePass } from '../../../../redux/actions/profileActions'
import { onChangeReset } from '../../../../redux/actions/forgotPassActions'
// import { useSelector } from 'react-redux'

class ChangePassword extends Component {
  render() {
    const {
      closeChangePassword,
      onChangeReset,
      onChangePass
    } = this.props;

    // const { errors } = useSelector(state => state.forgotPassReducer)

    return (
      <div className="position">
        <p className="main-title">Passwort ändern</p>
        <form className="change-pass-form" onSubmit={onChangePass}>
          <FormInput 
            name="old_pass" 
            label="Neues Passwort" 
            type="password"
            handleChange={(e) => onChangeReset(e)}
            // error={errors.old_pass}
          />
          <FormInput
            name="new_pass"
            label="Neues Passwort bestätigen"
            type="password"
            handleChange={(e) => onChangeReset(e)}
            // error={errors.new_pass}
          />
          <div className="btn-width">
            <Button type="submit" text="ABSENDEN" />
            <div
              onClick={() => closeChangePassword(false)}
              className="close-change-pass">
              <img src={close} alt="close" />
              <span>Stornieren</span>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {

  } = state
  return {
  
  }
};

export default connect(mapStateToProps, {
  onChangeReset,
  onChangePass
})(ChangePassword);
