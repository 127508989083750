import { instance } from './httpWP.js';

class faqService {
  static getAllQuestionsForDesigner = async () => {
    return await instance.get('/posts?categories=6&per_page=100');
  };
  static getAllQuestionsForCustomer = async () => {
    return await instance.get('/posts?categories=8&per_page=100');
  };

  static getAllTipps = async () => {
    return await instance.get('/posts?categories=9');
  };

  static getBlogPosts = async () => {
    return await instance.get('/posts?categories=7');
  };

  static getArchitectsContent = async () => {
    return await instance.get('/posts?categories=10');
  };

  static getProcessPosts = async () => {
    return await instance.get('/posts?categories=11');
  };

  static getTermsContent = async (languageID) => {
    const url = languageID === 'ch' ? '/posts?categories=12' : '/posts?categories=42'
    return await instance.get(url);
  };
}

export default faqService;
