import React, { useState, Fragment, useEffect } from 'react';
import infoIcon from '../../../../assets/Union.png';
import close from '../../../../assets/close.svg';
import Button from '../../../../shared/Button';
import AdditionalInfoProject from '../../../../shared/AdditionalInfoProject';
import { useDispatch, useSelector } from 'react-redux';
import { clearTransactions, clearCardDetails } from '../../../../redux/actions/transactionActions';

const FinishMessage = ({ projectId, onClose, projectName, currency }) => {
  const dispatch = useDispatch()
  const { valuesCreateProject } = useSelector(state => state.createProjectReducer)
  const [isAdditionalInfo, setAdditionalInfo] = useState(false);
  const isProduct = valuesCreateProject.category === 'PRODUKT';

  useEffect(function(){
    dispatch(clearTransactions())
    dispatch(clearCardDetails())
  }, [])

  return (
    <Fragment>
      {isAdditionalInfo ? (
        <Fragment>
          <div className="close" onClick={onClose}>
            <img src={close} alt="close" />
          </div>
          <AdditionalInfoProject
            projectId={projectId}
            projectName={projectName}
            onClose={onClose}
            currency={currency}
          />
        </Fragment>
      ) : (
          <div className="finish-message page-wrapper">
            <div className="main-content">
              <img
                src={infoIcon}
                alt="Information icon"
                width="40px"
                height="40px"
              />
              <h1>Glückwunsch!</h1>
              <p>Dein Projekt #{projectId}</p>
              <p className="project-name">&#8220;{projectName}&#8221;</p>
              <p>wurde erfolgreich veröffentlicht</p>
              <Button text="okay" handleClick={() => isProduct ? onClose() : setAdditionalInfo(true)} />
            </div>
          </div>
        )}
    </Fragment>
  );
};

export default FinishMessage;
