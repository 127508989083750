import React, { Component } from 'react';

export default class Rating extends Component {
  state = {
    rating: this.props.value || null,
    temp_rating: null,
  };

  rate(rating) {
    this.setState({
      rating: rating,
      temp_rating: rating,
    });

    this.props.setRating(rating);
  }

  star_over(rating) {
    this.setState({
      rating: rating,
      temp_rating: this.state.rating,
    });
  }

  star_out() {
    this.setState({
      rating: this.state.temp_rating,
      temp_rating: this.state.rating,
    });
  }

  render() {
    var stars = [];
    const { isActive, star } = this.props;
    for (let i = 1; i < 6; i++) {
      var klass = `star-rating__star ${isActive}`;

      if (this.state.rating >= i && this.state.rating != null) {
        klass += ' is-selected';
      }

      stars.push(
        isActive ? (
          <label
            key={i}
            className={klass}
            onClick={() => this.rate(i)}
            onMouseOver={() => this.star_over(i)}
            onMouseOut={() => this.star_out()}>
            {star || (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.5057 14.3158L17.5144 20.1971L12.2327 17.4203L12 17.2979L11.7673 17.4203L6.48557 20.1971L7.49427 14.3159L7.53872 14.0567L7.35037 13.8732L3.07489 9.70798L8.98049 8.85019L9.24246 8.81214L9.35825 8.57409L12 3.14308L14.6417 8.57409L14.7575 8.81214L15.0195 8.85019L20.9251 9.70798L16.6496 13.8732L16.4613 14.0566L16.5057 14.3158Z"
                  stroke="black"
                />
              </svg>
            )}
          </label>
        ) : (
          <label key={i} className={klass}>
            {star}
          </label>
        )
      );
    }

    return <div className="star-rating">{stars}</div>;
  }
}
