import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux'

const DesktopViewRoute = ({ component: Component, isMobile, url, ...rest }) => {
  const globalReducer = useSelector(state => state.globalReducer)
  switch (rest.path) {
    case '/basic-info/process/':
      globalReducer.basicInfoRef = "process";
      break;
    case '/basic-info/price/':
      globalReducer.basicInfoRef = "price";
      break;
    case '/basic-info/blog/':
      globalReducer.basicInfoRef = "blog";
      break;
    case '/profile/faq':
      globalReducer.basicInfoRef = "faq";
      break;
    default:
      globalReducer.basicInfoRef = "process";
      break;
  }

  return (
    <Route
      {...rest}
      render={props => (
        <Component {...props} />
      )}
    />
  );
};

export default DesktopViewRoute;