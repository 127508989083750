import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import ChatHeader from './ChatHeader/ChatHeader';
import MessageList from './MessageList/MessageList';
import CreateMessage from './CreateMessage/CreateMessage';

import { connect } from 'react-redux'
import { getPrivateContacts, getPublicComments, setChatMessages } from '../../../../redux/actions/communicationActions'
import { socket } from "../../../../services/websocket";

class ChatBlock extends Component {
  // Not in use
  // state = {
  //   success: null,
  // }
  componentDidMount() {
    const { currentConversation, conversations, newMessage } = this.props.communicationReducer
    var chatID = currentConversation.id ? currentConversation.id : this.props.match.params.id
    this.props.getPublicComments(
      this.props.projectId, chatID
    );

  }

  componentDidUpdate(prevProps) {
    const { currentConversation, newMessage } = this.props.communicationReducer
    if (prevProps.communicationReducer.conversations.length !== this.props.communicationReducer.conversations.length) {
      this.props.getPrivateContacts();
    }

    if (prevProps.communicationReducer.newMessage !== newMessage) {
     
    }

    if (currentConversation.id && prevProps.communicationReducer.currentConversation.id !== currentConversation.id) {
      socket.off('message').on('message', (e, error) => {
        var chatID = currentConversation.id ? currentConversation.id : this.props.match.params.id
        this.props.getPublicComments(
          this.props.projectId, chatID
        );
      })
    }
  }

  getList = (id) => {
    this.props.getPublicComments(this.props.projectId, id)
  }

  render() {
    const { contacts, projectId, match, offerId } = this.props;
    const { isChatOpened, currentConversation } = this.props.communicationReducer;
    const additionalClass = isChatOpened ? 'open' : ''

    return (
      <div className={`message-container ${additionalClass}`}>
        <ChatHeader
          username={currentConversation.username}
          contacts={contacts}
          offerId={offerId || +match.params.id}
          getList={this.getList}
        />
        <MessageList
          comments={currentConversation.messages.sort((a, b) => {
            return a._id - b._id
          })}
          getList={this.getList}
          projectId={projectId}
        // success={this.state.success}
        />
        <CreateMessage
          offerId={currentConversation.id}
          projectId={projectId}
          onSuccess={this.onSuccess}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { communicationReducer, globalReducer } = state
  return {
    communicationReducer, globalReducer
  }
};

export default connect(mapStateToProps, {
  getPrivateContacts, getPublicComments, setChatMessages
})(withRouter(ChatBlock));