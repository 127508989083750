import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux'
import CustomerConcepts from '../pages/Concepts/CustomerConcepts';
import CommunicationPage from '../pages/CommunicationPage/CommunicationPage';
import NotFound from '../pages/NotFound';
import Briefing from '../pages/Briefing';
import ConceptView from '../pages/ConceptDetails/ConceptView';
import ConceptWinner from '../pages/ConceptDetails/ConceptWinner';
import { userRole } from '../constants/userRole';
import DesignerConcepts from '../pages/Concepts/DesignerConcepts';
import { Modal } from 'react-bootstrap';
import AdditionalInfoProject from '../shared/AdditionalInfoProject';

import { clearProject, getDetailedProject } from '../redux/actions/ProjectActions'
import { openModal, closeModal } from '../redux/actions/transactionActions'
import { setProjectId, clearConcepts } from '../redux/actions/conceptActions'


class DetailedInfoProjectRouter extends Component {
  componentDidMount() {
    const { url } = this.props.match;
    const id = url.split('/')[4];
    // this.props.setProjectId(id);
    this.props.getDetailedProject(id);
  }

  componentDidUpdate(prevProps) {
    const { detailedProject } = this.props.projectReducer;
    if (prevProps.projectReducer.detailedProject !== detailedProject ||
      prevProps.globalReducer.User !== this.props.globalReducer.User) {
      if (
        detailedProject &&
        !detailedProject.additionalInfo &&
        (detailedProject.category !== 'PRODUKT') &&
        this.props.globalReducer.User &&
        this.props.globalReducer.User.role === userRole.CUSTOMER
      ) {
        this.props.openModal();
      }
    }
  }

  componentWillUnmount() {
    this.props.clearConcepts();
    this.props.clearProject();
  }

  handleClose = async () => {
    this.props.closeModal();
  };

  render() {
    const {
      globalReducer,
      projectReducer,
      transactionReducer,
      location,
    } = this.props
    const { url, params } = this.props.match;
    const { detailedProject } = projectReducer;
    if (!detailedProject) return (<p>Laden...</p>)
    let conceptsComponent =
      globalReducer.User.role === userRole.CUSTOMER ? (
        <CustomerConcepts id={+params.id} />
      ) : (
          <DesignerConcepts id={+params.id} />
        );
    return (
      <div className="detailed-info-wrapper">
        <Switch>
          <Route
            path={`${url}/concepts`}
            exact
            render={() => conceptsComponent}
          />

          <Route
            path={`${url}/concepts/:id`}
            render={(props) => <ConceptView {...props} />}
          />

          <Route
            path={`${url}/winner/`}
            render={(props) => (
              <ConceptWinner detailedProject={detailedProject} {...props} />
            )}
          />
          <Route
            path={`${url}/communication`}
            render={(props) => (
              <CommunicationPage {...props} projectId={+params.id} />
            )}
          />

          <Route path={`${url}/briefing`} component={Briefing} />
          <Route component={NotFound} />
        </Switch>
        <Modal
          centered
          size="xl"
          show={transactionReducer.isShow}
          onHide={this.handleClose}>
          <AdditionalInfoProject
            projectId={detailedProject._id}
            projectName={detailedProject.name}
            onClose={this.handleClose}
            currency={detailedProject.currency}
          />
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const { globalReducer, projectReducer, transactionReducer } = state
  return {
    globalReducer,
    projectReducer,
    transactionReducer
  }
};

export default connect(mapStateToProps, {
  clearProject,
  getDetailedProject,
  openModal, closeModal,
  setProjectId, clearConcepts
})(DetailedInfoProjectRouter);