import React from 'react';
import PropTypes from 'prop-types';
import deleteImg from '../../assets/delete.svg';

const FileItem = ({ name, link, deleteFile, id }) => {
  return (
    <div className="item-file">
      <a href={link} target="_blank" rel="noopener noreferrer">
        {name}
      </a>
      <span onClick={() => deleteFile(id)}>
        <img src={deleteImg} alt="delete" />
      </span>
    </div>
  );
}

FileItem.propTypes = {
  deleteFile: PropTypes.func,
  id: PropTypes.any,
  name: PropTypes.string,
  link: PropTypes.string,
};

export default FileItem;
