import React, { Component } from 'react';
import { connect } from 'react-redux'
import FristStep from './components/FristStep';
import SecondStep from './components/SecondStepRegistration/SecondStep';
import {
  onChange,
  onBlur,
  onFocus,
  onSubmit,
  changeBoxes,
} from '../../../../../redux/actions/registrationActions'
import { goToSecondStep } from '../../../../../redux/actions/registrationFirstStepActions'

class RegistrationCompanyForm extends Component {
  render() {
    const {
      goToSecondStep,
      registrationFirstStepReducer,
      registrationCompanyReducer,
      registrationIndividualReducer
    } = this.props;
    const isSecondStep = registrationFirstStepReducer.isSecondStep
    return (
      <div className="registration-form">
        <p className="error-be">
          {registrationCompanyReducer.formState.errors.fromBE ||
            registrationIndividualReducer.formState.errors.fromBE ||
            registrationFirstStepReducer.formState.errors.fromBE}
        </p>
        {isSecondStep ? <SecondStep /> : <FristStep onClick={goToSecondStep} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { registrationCompanyReducer, registrationIndividualReducer, registrationFirstStepReducer } = state
  return {
    registrationFirstStepReducer,
    registrationCompanyReducer,
    registrationIndividualReducer,
  }
};

export default connect(mapStateToProps, {
  onChange,
  onBlur,
  onFocus,
  onSubmit,
  changeBoxes,
  goToSecondStep,
})(RegistrationCompanyForm);