import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import FormInput from '../../../../../shared/FormInput';
import Button from '../../../../../shared/Button';
import Checkbox from '../../../../../shared/CheckBox';
import { checkBoxTitle } from '../../../../../constants/subscribeTitle';
import {
  onChange,
  onBlur,
  onFocus,
  onSubmit,
  changeBoxes,
} from '../../../../../redux/actions/registrationActions'

class RegistrationCustomerForm extends Component {
  checkStatus(status) {
    if (status) return <Redirect to="profile/my-projects" />;
  }
  render() {
    const {
      onChange,
      onBlur,
      onFocus,
      onSubmit,
      changeBoxes,
    } = this.props
    const {
      formState,
      fields,
      success,
      confirmationBoxes,
    } = this.props.registrationReducer;
    console.log(confirmationBoxes)
    return (
      <form className="registration-form" onSubmit={onSubmit}>
        <p className="error-be">{formState.errors.fromBE}</p>
        <FormInput
          name="lastName"
          label="Vorname"
          error={formState.errors.lastName}
          value={fields.lastName}
          handleChange={(e) => onChange(e)}
          handleBlur={(e) => onBlur(e)}
          handleFocus={(e) => onFocus(e)}
          hasInfo={true}
          infoBlock="Ihr Vorname sieht nur der Administrator von Faberling.com. Auf der Plattform sind Sie anonym unterwegs"
        />
        <FormInput
          name="firstName"
          label="Nachname"
          error={formState.errors.firstName}
          value={fields.firstName}
          handleChange={(e) => onChange(e)}
          handleBlur={(e) => onBlur(e)}
          handleFocus={(e) => onFocus(e)}
          hasInfo={true}
          infoBlock="Ihr Nachname sieht nur der Administrator von Faberling.com. Auf der Plattform sind Sie anonym unterwegs."
        />
        <FormInput
          name="email"
          label="E-Mail-Adresse"
          error={formState.errors.email}
          value={fields.email}
          handleChange={(e) => onChange(e)}
          handleBlur={(e) => onBlur(e)}
          handleFocus={(e) => onFocus(e)}
          hasInfo={true}
          infoBlock="Ihre E-Mail-Adresse sieht nur der Administrator von Faberling.com. Auf der Plattform sind Sie anonym unterwegs."
        />
        <FormInput
          name="phoneNumber"
          label="Telefonnummer (Optional)"
          placeholder="+41XXXXXXXXX"
          error={formState.errors.phoneNumber}
          value={fields.phoneNumber}
          handleChange={(e) => onChange(e)}
          handleFocus={(e) => onFocus(e)}
        />
        <FormInput
          name="login"
          label="Benutzername"
          error={formState.errors.login}
          value={fields.login}
          handleChange={(e) => onChange(e)}
          handleBlur={(e) => onBlur(e)}
          handleFocus={(e) => onFocus(e)}
          hasInfo={true}
          infoBlock="Der gewählte Benutzername wird den Architekten angezeigt. Sie können auch einen Fantasienamen wählen"
        />
        <FormInput
          name="password"
          type="password"
          label="Passwort"
          error={formState.errors.password}
          value={fields.password}
          handleChange={(e) => onChange(e)}
          handleBlur={(e) => onBlur(e)}
          handleFocus={(e) => onFocus(e)}
        />
        <FormInput
          name="infoSource"
          placeholder="Woher kennen Sie uns? (Optional)"
          value={fields.additional.infoSource}
          handleChange={(e) => onChange(e)}
        />
        <div className="subscribeBox">
          <Checkbox
            value={true}
            onClick={() =>
              changeBoxes('confirmationBoxes', 'rules')
            }
            checkedField={confirmationBoxes.rules}
            title={checkBoxTitle.agree}
            className="subcsribe"
            isTerms={true}
          />
          <Checkbox
            value={true}
            onClick={() =>
              changeBoxes(
                'confirmationBoxes',
                'emailSubscribe'
              )
            }
            checkedField={confirmationBoxes.emailSubscribe}
            title={checkBoxTitle.subscribe}
            className="subcsribe"
          />
        </div>
        <Button
          type="submit"
          text="registrieren"
          isLoading={formState.isLoading}
          isDisabled={confirmationBoxes.rules ? false : true}
          isDisabledClass={confirmationBoxes.rules ? false : true}
        />
        {this.checkStatus(success)}
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  const { registrationReducer } = state
  return {
    registrationReducer,
  }
};

export default connect(mapStateToProps, {
  onChange,
  onBlur,
  onFocus,
  onSubmit,
  changeBoxes,
})(RegistrationCustomerForm);
