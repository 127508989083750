import React from "react";
import { communicationTitles } from '../../../../../constants/communicationTitles';
import commentIcon from '../../../../../assets/commentIcon.svg';
import closeChat from '../../../../../assets/closeChat.svg';

import { useDispatch } from 'react-redux'
import { toggleChat } from '../../../../../redux/actions/communicationActions'

const ChatHeader = ({ offerId, contacts, getList, username }) => {
  const dispatch = useDispatch()
  const { PUBLIC_COMMENTS_TITLE, REFRESH, PUBLIC_DESCRIPTION, PRIVATE_DESCRIPTION } = communicationTitles;

  let currentContactTitle = '';
  if (username) {
    currentContactTitle = username;
  } else {
    const currentContact = contacts && contacts.find(contact => contact.offerId === offerId);
    currentContactTitle = currentContact && currentContact.login;
  }

  return (
    <div className="chat-header">
      <span className="title">
        <img src={commentIcon} alt="ICON" className="chat-mobile-icon" />
        {offerId ? (
          <span>{currentContactTitle}</span>
        ) : (
            <span>{PUBLIC_COMMENTS_TITLE}</span>
          )}
        <span className="refresh" onClick={() => getList(offerId)}>
          {REFRESH}
        </span>
      </span>
      <img src={closeChat} alt="ICON" className="chat-mobile-icon" onClick={() => dispatch(toggleChat(false))} />
      <span className="description">{`${offerId ? PRIVATE_DESCRIPTION : PUBLIC_DESCRIPTION
        }`}</span>
    </div>
  );
}

export default ChatHeader;