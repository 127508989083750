import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import BasicFooter from "./components/BasicFooter/BasicFooter";
import ProjectFooter from "./components/ProjectFooter/ProjectFooter";

const Footer = ({ location, history }) => {
  const { pathname } = location;
  const page = pathname.split("/")[2];
  let pageComponent;
  switch (page) {
    case "create-project":
      pageComponent = <ProjectFooter history={history} />;
      break;
    case "profile":
      pageComponent = <BasicFooter pathname={pathname} />;
      break;
    case "basic-info":
      pageComponent = <BasicFooter pathname={pathname} />;
      break;
    default:
      pageComponent = null;
      break;
  }

  return <Fragment>{pageComponent}</Fragment>;
};

export default withRouter(Footer);
