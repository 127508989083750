import React from "react";

export default function ElementWithLink({ page, languageID, item, place, type }) {
  const url = type !== 'wp-page' ? `/${languageID}/${page}/${item.value}` : item.value;
  return (
    <div className={item.value === place ? 'active' : 'not-active'}>
      <a href={url}>{item.title}</a>
    </div>
  );
}
