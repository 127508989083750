import { GET_POSTS, LOGOUT } from '../types'
import moment from 'moment/min/moment-with-locales';
moment.locale('de');

function initialState() {
    return {
        posts: [],
    };
}

export default function (state = initialState(), action) {
    const { type, payload } = action;
    switch (type) {
        case GET_POSTS:
            payload.map((item) => ({
                text: item.content.rendered,
                title: item.title.rendered,
                date: moment(item.date).locale('de').format('DD MMMM YYYY'),
            }));
            return {
                ...state,
                posts: payload.map((item) => ({
                    text: item.content.rendered,
                    title: item.title.rendered,
                    date: moment(item.date).locale('de').format('DD MMMM YYYY'),
                }))
            }
        case LOGOUT:
            return initialState()
        default:
            return {
                ...state,
            }
    }
}
