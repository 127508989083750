import { GET_TARIFFS } from '../types'
import { instance } from "../http";

export const getTariffs = () => (dispatch, getState) => {
    instance.get("/tariffs")
    .then(res => dispatch({
        type: GET_TARIFFS,
        payload: res.data
    }))
    .catch(error => console.log(error))
}