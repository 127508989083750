import { LOGOUT, TOGGLE_MODAL } from '../types'

function initialState() {
    return {
        componentName: '',
        isShowed: false,
    };
}

export default function (state = initialState(), action) {
    const { type, payload } = action;
    switch (type) {
        case TOGGLE_MODAL:
            const { componentName, isShowed } = payload
            return {
                componentName,
                isShowed
            }
        case LOGOUT:
            return initialState()
        default:
            return {
                ...state,
            }
    }
}
