export const createProjectTitle = [
  { title: '1. Stile', value: 'style' },
  { title: '2. Briefing', value: 'instruction' },
  { title: '3. Preisgeld', value: 'tariffs' },
  { title: '4. Bezahlen', value: 'pay' },
];

export const profileTitle = [
  { title: 'Meine Projekte', value: 'my-projects' },
  { title: 'Transaktionen', value: 'transactions' },
];

export const basicInfoTitles = [
  { title: "So funktioniert's", value: "/so-funktionierts" },
  { title: "Preise", value: "/preise" },
  { title: "Architekten", value: "/als-architekt-auftraege-erhalten" },
  { title: "Blog", value: "/blog" }
];

export const profileDisignerTitle = [
  { title: 'Projekte', value: 'my-projects' },
  { title: 'Transaktionen', value: 'transactions' },
  // { title: 'FAQs', value: "faq" },
];

export const detailedInfoTitleWithoutWinner = [
  { title: 'Konzepte', value: 'concepts' },
  { title: 'Kommunikation', value: 'communication' },
  { title: 'Briefing', value: 'briefing' },
];

export const detailedInfoTitleWithWinner = [
  { title: 'Datenübergabe', value: 'winner' },
  { title: 'Konzepte', value: 'concepts' },
  { title: 'Kommunikation', value: 'communication' },
  { title: 'Briefing', value: 'briefing' },
];

export const briefingTitle = [
  { title: 'Leistung', value: 'work' },
  { title: 'Hausstil', value: 'style' },
  {
    title: 'Fragen zum Raumprogramm',
    value: 'questions-about-the-room-program',
  },
  { title: 'Wohnraumstil', value: 'options' },
  { title: 'Sie zusätzliche Optionen', value: 'options' },
  { title: 'Bauweise', value: 'roof-type-construction' },
  { title: 'Dachtyp', value: 'roof-type-construction' },
  {
    title: 'Weitere Vorgaben für die Architekten',
    value: 'further-requirements-for-the-architects',
  },
  { title: 'PLZ, Parzellen-Nr', value: 'further-information' },
  { title: 'Unterlagen/Pläne/Fotos', value: 'file-information' },
  { title: 'Daten (nicht veröffentlicht)', value: 'data' },
];

export const briefingGardenTitle = [
  { title: 'Leistung', value: 'work' },
  { title: 'Hausstil', value: 'style' },
  {
    title: 'Weitere Vorgaben für die Architekten',
    value: 'further-requirements-for-the-architects',
  },
  { title: 'PLZ, Parzellen-Nr', value: 'further-information' },
  { title: 'Unterlagen/Pläne/Fotos', value: 'file-information' },
  { title: 'Daten (nicht veröffentlicht)', value: 'data' },
];

export const briefingProductTitle = [
  { title: 'Leistung', value: 'work' },
  {
    title: 'Weitere Vorgaben für die Architekten',
    value: 'further-requirements-for-the-architects',
  },
];

export const briefingSpecialTitle = [
  { title: 'Leistung', value: 'work' },
  {
    title: 'Weitere Vorgaben für die Architekten',
    value: 'further-requirements-for-the-architects',
  },
  { title: 'PLZ, Parzellen-Nr', value: 'further-information' },
  { title: 'Unterlagen/Pläne/Fotos', value: 'file-information' },
  { title: 'Daten (nicht veröffentlicht)', value: 'data' },
];

