import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import FormInput from '../../../../../../../../../shared/FormInput';
import {
  onChangeSecondStep,
  onBlur,
  onFocus,
} from '../../../../../../../../../redux/actions/registrationCompanyActions'

class CompanyData extends Component {
  render() {
    const {
      onChangeSecondStep,
      onBlur,
      onFocus,
    } = this.props
    const {
      fieldsSecondStep,
      formState,
    } = this.props.registrationCompanyReducer;
    return (
      <Fragment>
        <FormInput
          name="companyName"
          label="Firma"
          error={formState.errors.companyName}
          value={fieldsSecondStep.companyName}
          handleChange={(e) => onChangeSecondStep(e)}
          handleBlur={(e) => onBlur(e)}
          handleFocus={(e) => onFocus(e)}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { registrationCompanyReducer } = state
  return {
    registrationCompanyReducer,
  }
};

export default connect(mapStateToProps, {
  onChangeSecondStep,
  onBlur,
  onFocus,
})(CompanyData);