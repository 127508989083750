import { FIRSTSTEP_CHANGE_BOXES, FIRSTSTEP_CHANGE_REGISTRATION_FIELD, FIRSTSTEP_CLEAR_REGISTRATION, FIRSTSTEP_FORM_LOADING, FIRSTSTEP_FORM_VALIDITY, FIRSTSTEP_REGISTER_BLUR, FIRSTSTEP_REGISTER_FOCUS, FIRSTSTEP_REGISTER_SUCCESS, FIRSTSTEP_SET_ERROR_BE, UNIQUE_FAIL } from '../types'
import registrationFields from '../../constants/registrationFields';
import validator from '../../helpers/validator/validation';
import errorsString from '../../helpers/validator/errorsString';
import errorMessagesBE from '../../localization/errorMessagesBE';

function initialState() {
    return {
        isSecondStep: false,
        confirmationBoxes: {
            rules: false,
            emailSubscribe: false,
        },
        fieldsFirstStep: registrationFields().company_first_step,
        formState: {
            isLoading: false,
            isFormValid: true,
            errors: registrationFields().errors_company,
        },
    }
}

export default function (state = initialState(), action) {
    const { type, payload } = action;
    switch (type) {
        case FIRSTSTEP_SET_ERROR_BE:
            var formState = { ...state.formState }
            formState.errors.fromBE = payload;
            return {
                ...state,
                formState
            }
        case FIRSTSTEP_FORM_LOADING:
            var formState = { ...state.formState }
            formState.isLoading = payload;
            return {
                ...state,
                formState
            }
        case FIRSTSTEP_CHANGE_REGISTRATION_FIELD:
            var formState = { ...state.formState }
            formState.errors[payload.name] = "";

            var fieldsFirstStep = { ...state.fieldsFirstStep }
            if (payload.name !== 'infoSource') {
                fieldsFirstStep[payload.name] = payload.value;
            } else {
                fieldsFirstStep.additional[payload.name] = payload.value;
            }
            if (payload.name !== 'infoSource') {
                var formState = { ...state.formState }
                const validatorObj = validator.validate(payload.name, payload.value);
                formState.errors[payload.name] = validatorObj.error;
                // return validatorObj.isValid;
            }
            return {
                ...state,
                fieldsFirstStep,
                formState,
            }
        case FIRSTSTEP_CHANGE_BOXES:
            const { boxesName, fieldName } = payload
            var obj = { ...state[boxesName] }
            obj[fieldName] = !state[boxesName][fieldName];
            return {
                ...state,
                [boxesName]: obj
            }
        case FIRSTSTEP_REGISTER_FOCUS:
            var formState = { ...state.formState }
            formState.errors[payload.name] = '';
            formState.errors.fromBE = '';
            return {
                ...state,
                formState
            }
        case FIRSTSTEP_REGISTER_BLUR:
            var formState = { ...state.formState }
            if (!payload.name === 'infoSource' && !payload.value) {
                formState.errors[payload.name] = errorsString.COMMON_ERRORS.IS_REQ;
            }
            return {
                ...state,
                formState
            }
        case FIRSTSTEP_FORM_VALIDITY:
            return {
                ...state,
                ...payload
            }
        case UNIQUE_FAIL:
            var formState = { ...state.formState }
            formState.errors.fromBE = payload.email === 'used' ? errorMessagesBE['Email Already Exists.'] : errorMessagesBE['Login Already Exists.'];
            return {
                ...state,
                isSecondStep: false,
                formState
            }
        case FIRSTSTEP_REGISTER_SUCCESS:
            return {
                ...state,
                isSecondStep: true,
            }
        case FIRSTSTEP_CLEAR_REGISTRATION:
            return initialState()
        default:
            return state
    }
}
