export const alerts = {
  info: {
    title: "Info alert text.",
    type: "info"
  },
  success: {
    title: "Request completed successfully.",
    type: "success"
  },
  warning: {
    title: "Warning alert text.",
    type: "warning"
  },
  error: {
    title: "Request failed.",
    type: "error"
  }
}