import { GET_VOUCHER_CODE } from '../types'
import { instance } from "../http";

export const getVoucherCode = () => (dispatch, getState) => {
    instance.get("/voucher-code")
    .then(res => dispatch({
        type: GET_VOUCHER_CODE,
        payload: res.data
    }))
    .catch(error => console.log(error))
    // temporary data
    /*dispatch({
        type: GET_VOUCHER_CODE,
        payload: [
            {
                "validTariff": [
                    8,
                    10
                ],
                "validExtraTariff": [],
                "isDeleted": false,
                "_id": 1,
                "name": "ABC123",
                "amount": -3000,
                "description": "Discount",
                "expiryDate": 1608134400000,
                "totalOffer": 10,
                "totalOfferRemaining": 10,
                "voucherType": "CREDIT_CARD",
                "__v": 0
            }
        ]
    })*/
}