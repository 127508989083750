import { CLEAR_CONCEPTS, SET_RATING, SET_PAGE, GET_ALL_CONCEPTS, GET_CONCEPTS, GET_CONCEPT_ID, SET_PROJECT_ID, SUBMIT_CONCEPT_LOADING_PERCENTAGE } from '../types';
import { getDetailedProject } from './ProjectActions'
import { instance } from '../http'

export const getConceptById = id => dispatch => {
    instance.get(`/offers/${id}`)
        .then(response => {
            dispatch({
                type: GET_CONCEPT_ID,
                payload: response.data
            })
        })
        .catch(error => {
            console.log(error);

        })
};

export const getConcepts = (overwriteID, overwritePage) => (dispatch, getState) => {
    var { limit, page, sort, sort_by, id } = getState().conceptReducer.params
    if (overwriteID) id = overwriteID
    if (overwritePage) id = overwritePage
    instance.get(`/offers?projectId=${id}&limit=${limit}&page=${page}&sort=${sort}&sort_by=${sort_by}`)
        .then(response => {
            dispatch({
                type: GET_CONCEPTS,
                payload: response.data
            })
        })
        .catch(error => {
            console.log(error);
        })
};

export const getAllConcepts = (overwriteID) => (dispatch, getState) => {
    var { limit, page, sort, sort_by, id } = getState().conceptReducer.params
    if (overwriteID) id = overwriteID
    instance.get(`offers/project/${id}?limit=${limit}&page=${page}&sort=${sort}&sort_by=${sort_by}`)
        .then(response => {
            dispatch({
                type: GET_ALL_CONCEPTS,
                payload: response.data
            })
        })
        .catch(error => {
            console.log(error);
        })
}

export const setRating = (id, rating) => dispatch => {
    instance.put(`/offers/${id}/rating`, { rating })
        .then(response => {
            dispatch({
                type: SET_RATING,
                payload: response.data
            })
        })
        .catch(error => {
            console.log(error.response);
        })
};

export const setWinner = (id, projectId) => dispatch => {
    instance.put(`/projects/${projectId}/winners`, { winnerId: id, })
        .then(response => {
            dispatch(getDetailedProject(projectId))
            dispatch(getConceptById(id))
        })
        .catch(error => {
            console.log(error);
        })
};

export const setProjectId = id => dispatch => {
    dispatch({
        type: SET_PROJECT_ID,
        payload: id
    })
    dispatch(getConcepts(id))
    dispatch(getAllConcepts(id))
};

export const handleRequestPage = page => dispatch => {
    dispatch({
        type: SET_PAGE,
        payload: page
    })
    dispatch(getConcepts(null, page))
};

export const clearConcepts = () => dispatch => {
    dispatch({
        type: CLEAR_CONCEPTS,
    })
};
