import React from 'react';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux'
import FaberlingLogo from '../../../../assets/logo_icon.png';
import InProject from './components/InProject';
import InProfile from './components/InProfile';
import { userRole } from '../../../../constants/userRole';
import Designer from './components/Designer';

function LeftSide({ location, role }) {
  const User = useSelector(state => state.globalReducer.User)
  const { pathname } = location;
  const languageID = pathname.split('/')[1];
  const page = pathname.split('/')[2];
  const section = pathname.split('/')[3];
  const isDesigner = role === userRole.COMPANY;
  let redirectUrl =
    User && section !== 'my-projects'
      ? `/${languageID}`
      : 'https://faberling.com/';
  let pageComponent;
  switch (page) {
    case 'create-project':
      pageComponent = <InProject languageID={languageID} />;
      break;
    case 'profile':
      if (isDesigner) {
        pageComponent = <Designer languageID={languageID} />;
      } else {
        pageComponent = <InProfile languageID={languageID} />;
      }
      break;
    case 'basic-info':
      pageComponent = <Designer languageID={languageID} />;
      redirectUrl = 'https://faberling.com/';
      break;
    default:
      pageComponent = null;
      break;
  }
  return (
    <div className="left-side">
      <a href={redirectUrl}>
        <img src={FaberlingLogo} alt="FaberlingLogo" className='faberling_icon' />
      </a>
      {pageComponent}
    </div>
  );
}

export default withRouter(LeftSide);
