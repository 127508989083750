import validatorTypes from './validatorTypes';
import errorsString from './errorsString';

class Validator {
  validate = (name, value) => {
    if (!(name in validatorTypes) && validatorTypes.name in this) {
      console.log(
        `validator doesn't have any validation type with name ${name}`
      );
      return null;
    }

    return this[validatorTypes[name]](value);
  };

  validatorPhone = value => {
    return /^[+]{1}[4]{1}[1]{1}[0-9]{9}$/.test(value)
      ? { isValid: true, error: '' }
      : { isValid: false, error: errorsString.PHONE.WRONG_FORMAT };
  };
  validatorEmail = value => {
    if (/^.([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/.test(value)) {
      return { isValid: true, error: '' };
    }
    return { isValid: false, error: errorsString.EMAIL.WRONG_FORMAT };
  };
  validatorPassword = value => {
    return /^.{4,}$/.test(value)
      ? { isValid: true, error: '' }
      : { isValid: false, error: errorsString.PASSWORD.TO_SHORT };
  };
  validatorName = value => {
    return value
      ? /^[a-z\s.'-]+$/iu.test(value)
        ? /^.{2,}$/.test(value)
          ? { isValid: true, error: '' }
          : { isValid: false, error: errorsString.COMMON_ERRORS.LESS_2_CHAR }
        : { isValid: false, error: errorsString.COMMON_ERRORS.FORB_CHAR }
      : { isValid: true, error: '' };
  };
  validatorLogin = value => {
    if (value.length < 4) {
      return { isValid: false, error: errorsString.LOGIN.TO_SHORT };
    }
    return { isValid: true, error: '' };
  };

  validatorStr = value => {
    return /^[a-z\s\d.,;:()""''&-\\#]+$/iu.test(value)
      ? { isValid: true, error: '' }
      : { isValid: false, error: errorsString.COMMON_ERRORS.FORB_CHAR };
  };
  validatorNum = value => {
    return !isNaN(value)
      ? { isValid: true, error: '' }
      : { isValid: false, error: errorsString.COMMON_ERRORS.FORB_CHAR };
  };
  validatorTaxandVat = value => {
    return /^[a-z\d]+$/iu.test(value)
      ? { isValid: true, error: '' }
      : { isValid: false, error: errorsString.COMMON_ERRORS.FORB_CHAR };
  };
  validatorDob = value => {
    return /^([0 1]{1}[0-9]{1})\.([0-9]{2})\.([0-9]{4})$/.test(value)
      ? { isValid: true, error: '' }
      : { isValid: false, error: errorsString.DOB.WRONG_FORMAT };
  };

  validatorZIP = value => {
    return /^[0-9]{4}$/.test(value)
      ? { isValid: true, error: '' }
      : { isValid: false, error: errorsString.ZIP.WRONG_FORMAT };
  };
  validatorIBAN = value => {
    return /(^[C][H]{1}[0-9]{19}$)|(^[D][E]{1}[0-9]{20}$)/.test(value)
      ? { isValid: true, error: '' }
      : { isValid: false, error: errorsString.IBAN.WRONG_FORMAT };
  };
  validatorPlotNo = value => {
    console.log(value)
    return /^[0-9]*$/.test(value)
      ? { isValid: true, error: '' }
      : { isValid: false, error: errorsString.PLOTNO.WRONG_FORMAT }; 
  }
}

/*
/^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/
*/

const validator = new Validator();

export default validator;
