import React, { Fragment } from 'react';

import step_1 from '../assets/step_1.png';
import step_2 from '../assets/step_2.png';
import step_3 from '../assets/step_3.png';
import article_1 from '../assets/article_1.png';
import article_2 from '../assets/article_2.png';
import article_3 from '../assets/article_3.png';

export const pages = {
  process: {
    TITLE: "So funktioniert's",
    DESCRIPTION: "Als Baufrau oder Bauherr erhalten Sie auf Faberling.com anhand von drei einfachen Schritten Ihr individueller Entwurf von Architekten – getreu nach unserem Motto: mehr Architektur zu einem fairen Fixpreis."
  },
  price: {
    TITLE: "Preise",
    DESCRIPTION: "Sie beschreiben in einem kurzen Briefing die Designaufgabe und geben ein Preisgeld vor. Wir geben Ihnen Hilfestellung und sagen Ihnen, mit wie vielen Designs Sie bei dem gewählten Preisgeld rechnen können."
  },
  blog: {
    TITLE: "Blog",
    DESCRIPTION: ""
  },
}

export const steps = [
  {
    image: step_1,
    title: 'Briefing erstellen und Preisgeld festlegen.',
    description: <Fragment>
                  <p className="default-text">Anhand unseres standardisierten und mit unseren Architekten entwickelten Briefings begleiten wir Sie durch Ihr Bauvorhaben. Wir stellen genau diese Fragen, welche Ihnen Ihr Architekt auch stellen würde - alles sehr unkompliziert, digital und nur einmal für mehrere Architekten.</p>
                  <p className="default-text">Vielleicht haben Sie noch ein paar individuelle Ideen für Ihr Traumhaus? Um so genauer Sie Ihre Bedürfnisse und Ideen den Architekten mitteilen, desto treffendere Konzepte werden Sie erhalten.</p>
                  <p className="default-text">Dann teilen Sie uns noch Ihr Preisgeld für den Gewinner mit und los geht`s.</p>
                </Fragment>,
  },
  {
    image: step_2,
    title: 'Architekturkonzepte von Architekten.',
    description: <Fragment>
                  <p className="default-text">Zahlreiche Architekten stehen im Wettbewerb um Ihre Bauaufgabe und erstellen individuelle Projekt-Entwürfe für Sie.</p>
                  <p className="default-text">Der Wettbewerb ist absolut anonym. Sie können die Konzepte der Architekten laufend per Sterne-Rating oder Chat beurteilen.</p>
                </Fragment>,
  },
  {
    image: step_3,
    title: 'Gewinner küren und Konzeptdateien erhalten.',
    description: <Fragment>
                  <p className="default-text">Nach der Wettbewerbsfrist küren Sie einen Gewinner. Das gesamte Konzept wird Ihnen nach unserem Qualitätsstandard sicher auf unserer Plattform zum Download zur Verfügung gestellt. Sie erhalten die Nutzungsrechte für den Projekt-Entwurf.</p>
                  <p className="default-text">Erst dann wird das Preisgeld ausbezahlt.</p>
                  <p className="default-text">Glückwunsch zu «mehr Architektur zu einem fairen Fixpreis»!</p>
                </Fragment>,
  },
];

export const additionalPriceInfo = [
  {
    title: 'Ein Preis für unbeschränkte Nutzungsrechte nach deutschem Recht',
    description: 'Die dargestellten Preise beinhalten die Einstellgebühr, sowie das Preisgeld für die unbeschränkten Nutzungsrechte am gewählten Design. Die Rechteübertragung erfolgt nach deutschem Recht.',
    isMobile: true,
  },
  {
    title: 'Kein Risiko',
    description: 'Sollte Ihnen kein Design gefallen, so erhalten Sie Ihr Preisgeld zu 100% zurück.',
    isMobile: false,
  },
  {
    title: 'Deutscher Unternehmenssitz',
    description: 'Kunden mit Sitz im Nicht-EU-Ausland (z.B. Schweiz) oder EU-Ausland mit UStID (z.B. Österreich) zahlen rein Netto. Kunden mit deutschem Unternehmenssitz zahlen Brutto und können die Umsatzsteuer in der Umsatzsteuervoranmeldung geltend machen. Bitte beachten Sie bei Preisvergleichen mit ausländischen Mitbewerbern, dass diese reine Netto-Preise anzeigen.',
    isMobile: false,
  }
];

export const articles = [
  {
    id: 1,
    date: '28. January 2020',
    title: 'Gebühren und Abwicklung der Preisgeldzahlungen',
    text: 'Für die Einstellung eines Projektes fallen Einstellgebühren an, diese werden beim Projektstart angezeigt. Derzeit liegt die Mindestgebühr bei 19 EUR (zzgl. MwSt.) für ein Standard-Projekt mit Vorauskasse und garantiertem Preisgeld. Gebühr Betrag i..',
    image: article_1,
  },
  {
    id: 2,
    date: '28. January 2020',
    title: 'Gebühren und Abwicklung der Preisgeldzahlungen',
    text: 'Für die Einstellung eines Projektes fallen Einstellgebühren an, diese werden beim Projektstart angezeigt. Derzeit liegt die Mindestgebühr bei 19 EUR (zzgl. MwSt.) für ein Standard-Projekt mit Vorauskasse und garantiertem Preisgeld. Gebühr Betrag i..',
    image: article_2,
  },
  {
    id: 3,
    date: '28. January 2020',
    title: 'Preisgeldzahlungen',
    text: 'Für die Einstellung eines Projektes fallen Einstellgebühren an, diese werden beim Projektstart angezeigt. Derzeit liegt die Mindestgebühr bei 19 EUR (zzgl. MwSt.) für ein Standard-Projekt mit Vorauskasse und garantiertem Preisgeld. Gebühr Betrag i..',
    image: article_3,
  },
  {
    id: 4,
    date: '28. January 2020',
    title: 'Gebühren und Abwicklung der Preisgeldzahlungen',
    text: 'Für die Einstellung eines Projektes fallen Einstellgebühren an, diese werden beim Projektstart angezeigt. Derzeit liegt die Mindestgebühr bei 19 EUR (zzgl. MwSt.) für ein Standard-Projekt mit Vorauskasse und garantiertem Preisgeld. Gebühr Betrag i..',
    image: null,
  },
  {
    id: 5,
    date: '28. January 2020',
    title: 'Gebühren und Abwicklung der Preisgeldzahlungen',
    text: 'Für die Einstellung eines Projektes fallen Einstellgebühren an, diese werden beim Projektstart angezeigt. Derzeit liegt die Mindestgebühr bei 19 EUR (zzgl. MwSt.) für ein Standard-Projekt mit Vorauskasse und garantiertem Preisgeld. Gebühr Betrag i..',
    image: null,
  },
  {
    id: 6,
    date: '28. January 2020',
    title: 'Gebühren und Abwicklung der Preisgeldzahlungen',
    text: 'Derzeit liegt die Mindestgebühr bei 19 EUR (zzgl. MwSt.) für ein Standard-Projekt mit Vorauskasse und garantiertem Preisgeld. Gebühr Betrag i..',
    image: null,
  },
  {
    id: 7,
    date: '28. January 2020',
    title: 'Abwicklung',
    text: 'Mindestgebühr bei 19 EUR (zzgl. MwSt.) für ein Standard-Projekt mit Vorauskasse und garantiertem Preisgeld. Gebühr Betrag i..',
    image: article_3,
  },
  {
    id: 8,
    date: '28. January 2020',
    title: 'Gebühren und Abwicklung der Preisgeldzahlungen',
    text: 'Für die Einstellung eines Projektes fallen Einstellgebühren an, diese werden beim Projektstart angezeigt. Derzeit liegt die Mindestgebühr bei 19 EUR (zzgl. MwSt.) für ein Standard-Projekt mit Vorauskasse und garantiertem Preisgeld. Gebühr Betrag i..',
    image: article_1,
  },
  {
    id: 9,
    date: '28. January 2020',
    title: 'Gebühren und Abwicklung der Preisgeldzahlungen',
    text: 'Für die Einstellung eines Projektes fallen Einstellgebühren an, diese werden beim Projektstart angezeigt. Derzeit liegt die Mindestgebühr bei 19 EUR (zzgl. MwSt.) für ein Standard-Projekt mit Vorauskasse und garantiertem Preisgeld. Gebühr Betrag i..',
    image: article_2,
  },
  {
    id: 10,
    date: '28. January 2020',
    title: 'Gebühren und Abwicklung der Preisgeldzahlungen',
    text: 'Für die Einstellung eines Projektes fallen Einstellgebühren an, diese werden beim Projektstart angezeigt. Derzeit liegt die Mindestgebühr bei 19 EUR (zzgl. MwSt.) für ein Standard-Projekt mit Vorauskasse und garantiertem Preisgeld. Gebühr Betrag i..',
    image: article_2,
  },
  {
    id: 11,
    date: '28. January 2020',
    title: 'Gebühren und Abwicklung der Preisgeldzahlungen',
    text: 'Für die Einstellung eines Projektes fallen Einstellgebühren an, diese werden beim Projektstart angezeigt. Derzeit liegt die Mindestgebühr bei 19 EUR (zzgl. MwSt.) für ein Standard-Projekt mit Vorauskasse und garantiertem Preisgeld. Gebühr Betrag i..',
    image: null,
  },
  {
    id: 12,
    date: '28. January 2020',
    title: 'Abwicklung',
    text: 'Mindestgebühr bei 19 EUR (zzgl. MwSt.) für ein Standard-Projekt mit Vorauskasse und garantiertem Preisgeld. Gebühr Betrag i..',
    image: article_3,
  },
  {
    id: 13,
    date: '28. January 2020',
    title: 'Abwicklung',
    text: 'Mindestgebühr bei 19 EUR (zzgl. MwSt.) für ein Standard-Projekt mit Vorauskasse und garantiertem Preisgeld. Gebühr Betrag i..',
    image: article_3,
  },
  {
    id: 14,
    date: '28. January 2020',
    title: 'Gebühren und Abwicklung der Preisgeldzahlungen',
    text: 'Für die Einstellung eines Projektes fallen Einstellgebühren an, diese werden beim Projektstart angezeigt. Derzeit liegt die Mindestgebühr bei 19 EUR (zzgl. MwSt.) für ein Standard-Projekt mit Vorauskasse und garantiertem Preisgeld. Gebühr Betrag i..',
    image: article_1,
  },
];

export const faqsItems = {
  architects: {
    title: 'Architekten und Architektinnen',
    alt_title: 'Häufige Fragen von Architekten und Architektinnen:',
  },
  builders: {
    title: 'Bauherren und Bauherrinnen',
    alt_title: 'Häufige Fragen von Bauherren und Bauherrinnen:',
  },
  tipItem: {
    title: 'Tipps von Faberling.ch',
    alt_title: '',
    tips: [
      {
        title: 'Tipps für Bauherrinnen + Bauherren:',
        description: <Fragment>
                      <p className="default-text">Nehmen Sie sich beim Briefing genug Zeit. Desto genauer Sie Ihre Bedürfnisse definieren, umso massgeschneiderter können die Architekten für Sie entwerfen. Sie können Ihr Briefing während der Projektlaufzeit mit Text ergänzen, jedoch nicht komplett neu erstellen.</p>
                      <p className="default-text">Geben Sie den Architekten unbedingt Feedback und beantworten Sie deren Fragen zügig. Der Weg ist das Ziel und die Architekten können so spüren, ob sie Ihren «Geschmack» getroffen haben.</p>
                      <p className="default-text">Wenn Sie Option Preisgeld-zurück-Garantie gewählt haben, seien Sie bitte nicht enttäuscht, wenn nicht viele und weniger hochwertige Projekt-Entwürfe für Ihr Projekt abgegeben werden. Der Aufwand für ein Projekt-Entwurf der Architekten ist recht hoch.</p>
                    </Fragment>,
      },
      {
        title: 'Architekturkonzepte von Architekten.',
        description: <Fragment>
                      <p className="default-text">Der Auftraggeber bewertet Ihr Verständnis des Briefings, Ihre Kreativität und Ihre Darstellungsfertigkeiten:</p>
                      <p className="default-text">Lesen Sie das Projekt-Briefing und studieren Sie das Zonenreglement genau.</p>
                      <p className="default-text">Seien Sie sich bewusst, dass Ihr Projekt-Entwurf ohne ein persönliches Gespräch erfolgen wird. Beschreiben Sie Ihr Konzept möglichst genau. Arbeiten Sie auch mit Plankommentaren.</p>
                      <p className="default-text">Die Darstellung Ihres Projekt-Entwurfes ist nebst Ihrer Idee und deren Erklärung das Wichtigste: Zeichnen Sie z.B. Türflügel ein, setzen Sie Raumstempel, vermassen Sie grob und möblieren Sie rudimentär. Natürlich sind CAD-Pläne und 3D-Ansichten immer ein grosser Vorteil um eine Idee konkreter darzustellen, auch verschaffen Sie sich somit einen Wettbewerbsvorteil zu hangefertigten Skizzen.</p>
                      <p className="default-text">Machen Sie die Bauherrschaft darauf aufmerksam, wenn ein Wunsch aus dem Briefing nicht dem Zonenreglement entspricht. Machen Sie Änderungsvorschläge.</p>
                      <p className="default-text">Zeigen Sie der Bauherrschaft, dass Sie sich um das Projekt «kümmern» wollen.</p>
                      <p className="default-text">Stellen Sie der Bauherrschaft Fragen, sollte etwas unklar sein.</p>
                    </Fragment>,
      },
    ],
  }
};



export const faq_search = <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.6625 10.6156L15.5469 15.5M12.5 6.5C12.5 9.53757 9.53757 12.5 6.5 12.5C3.46243 12.5 0.5 9.53757 0.5 6.5C0.5 3.46243 3.46243 0.5 6.5 0.5C9.53757 0.5 12.5 3.46243 12.5 6.5Z"
                      stroke="black"
                      strokeLinecap="round"
                    />
                  </svg>

export const articleLinkTitles = {
  // HOME_PAGE: "Startseite",
  HOME_PAGE: "Home",
  BLOG: "Blog",
  BASIC_INFO: "Basisinformation",
  PREVIOUS_ARTICLE: "Vorheriger Artikel",
  NEXT_ARTICLE: "Nächster Artikel"
}

export const buttons = {
  START_PROJECT: "JETZT STARTEN",
  LOAD_MORE: "Load more",
  ALS_ARCHITEKT_BEWERBEN: "ALS ARCHITEKT BEWERBEN"
}
