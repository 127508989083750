import React from "react";
import Router from "./router";
import Snackbar from "./shared/Snackbar";
import { Provider } from 'react-redux';
import store from './redux/store';

function App() {
  // fetch('https://faberling.ch/wp-json/wp/v2/posts?categories=7');

  const script = document.createElement("script");

  script.id = "hs-script-loader"
  script.src = "//js.hs-scripts.com/8066477.js";
  script.async = true;
  script.defer = true;

  document.body.appendChild(script);

  console.log('Developed by %c ', 'font-size: 1px; padding: 8px 20px; line-height: 0; background: url("https://mindset.swiss/wp-content/uploads/2020/12/mindset_logo.png"); background-size: contain; background-repeat: no-repeat; color: transparent;');
  console.log('https://mindset.swiss/')

  return (
    <div className="App">
      <Provider store={store}>
        <Router />
        <Snackbar />
      </Provider>
    </div>
  );
}

export default App;
