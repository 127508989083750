import React from 'react';
import { Link } from 'react-router-dom';
import AddLogo from '../../../../../assets/add.svg';

export default function InProfile({languageID}) {
  return (
    <div className="in-profile">
      <Link to={`/${languageID}/create-project/style`}>
        <p>Neues Projekt</p>
        <img src={AddLogo} alt="Add Logo" />
      </Link>
    </div>
  );
}
