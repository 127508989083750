import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import upload from '../../assets/upload.svg';
import Button from '../Button';
import FileItem from '../FileItem/FileItem';
import close from '../../assets/close.svg';
import ErrorSection from '../ErrorsForUploadDoc/ErrorsForUploadDoc';
import LinearProgressWithLabel from '../LinearProgressWithLabel';
import moment from 'moment';

import {
  onUpload,
  deleteFile,
  onSubmit,
  onChange,
  onFocus,
  clearAdditionalStore,
} from '../../redux/actions/submitConceptActions'
import { setProjectId } from '../../redux/actions/conceptActions'

class SubmitConcept extends Component {
  successRequest = status => {
    if (status) {
      this.props.onClose();
      this.props.setProjectId(this.props.projectReducer.detailedProject._id);
      this.props.clearAdditionalStore();
    }
  };

  render() {
    const {
      onUpload,
      deleteFile,
      onSubmit,
      onChange,
      onFocus,
      submitConceptReducer
    } = this.props
    const {
      formFields,
      errors,
      isLoading,
      success,
      isDocValid,
      files,
      loadingPercentage
    } = submitConceptReducer;
    const project = this.props.projectReducer.detailedProject
    const currentDate = new Date().getTime()
    const projectExpired = currentDate > project.endDate ? true : false

    return (
      <Fragment>
        <div className="close" onClick={() => this.successRequest(true)}>
          <img src={close} alt="close" />
        </div>
        <div className="submit-concept-wrapper">
          <p className="main-title">Konzept einreichen</p>
          <p className="error-be">{errors.fromBE}</p>
          {projectExpired ? (
            <div style={{ color: 'red' }}>Dieses Projekt ist beendet.</div>
          ) : (
            <form className="submit-concept-form" onSubmit={onSubmit}>
              <p className="title">Vorschau</p>
              {loadingPercentage ? (
                <LinearProgressWithLabel value={loadingPercentage} />
              ) : null}
              <p className="sub-title">
                Um das Konzept optimal zu präsentieren, empfehlen wir Ihnen, eine
                zusätzliche Datei zur Vorschau herunterzuladen (PNG, JPG max. 200
                MB)
              </p>
              {formFields.preview ? (
                <div style={{ margin: '16px 0 32px' }}>
                  <FileItem
                    name={formFields.preview.name}
                    link={formFields.preview.url}
                    path={formFields.preview.path}
                    deleteFile={deleteFile}
                    id={'preview'}
                  />
                </div>
              ) : (
                  <Fragment>
                    <div className="file-wrapper">
                      <input
                        name="preview"
                        id="loading_preview"
                        type="file"
                        onChange={(event) => {
                          onUpload(event.target.files[0], 'preview');
                        }}
                      />
                      <label htmlFor="loading_preview">
                        <span>VORSCHAU HOCHLADEN</span>
                        <img src={upload} alt="upload"></img>
                      </label>
                    </div>
                    <p className="error">
                      <ErrorSection
                        isReq={errors.preview}
                        document={formFields.preview.name}
                        isFileValid={isDocValid.preview.isFileValid}
                        isSizeValid={isDocValid.preview.isSizeValid}
                      />
                    </p>
                  </Fragment>
                )}
              <p className="title">Konzept</p>
              <p className="sub-title">
                Laden Sie hier ggf. Daten, wie z. B. Bilder und Dokumente.
                Dateiupload (max. 512 MB).
              </p>
              <div className="file-list">
                {files &&
                  files.map((item, index) => {
                    return (
                      <FileItem
                        key={item.id}
                        name={item.name}
                        link={item.url}
                        path={item.path}
                        id={index}
                        deleteFile={deleteFile}
                      />
                    );
                  })}
              </div>
              <div className="file-wrapper">
                <input
                  name="files"
                  id="loading_concept"
                  type="file"
                  onChange={(event) => {
                    onUpload(event.target.files[0], 'files');
                  }}
                />
                <label htmlFor="loading_concept">
                  <span>DATEI HINZUFÜGEN</span>
                  <img src={upload} alt="upload"></img>
                </label>
              </div>
              <p className="error">
                <ErrorSection
                  isReq={errors.files}
                  document={files.length}
                  isFileValid={isDocValid.files.isFileValid}
                  isSizeValid={isDocValid.files.isSizeValid}
                />
              </p>
              <textarea
                className={errors.comments ? 'error' : ''}
                value={formFields.comments}
                onChange={(e) => onChange(e)}
                onFocus={() => onFocus()}
                placeholder="Kommentare"
              />
              <p className="error">{errors.comments}</p>
              <Button isLoading={isLoading} type="submit" text="Speichern" />
              {this.successRequest(success)}
            </form>
          )
        }
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { projectReducer, submitConceptReducer } = state
  return {
    projectReducer,
    submitConceptReducer
  }
};

export default connect(mapStateToProps, {
  onUpload,
  deleteFile,
  onSubmit,
  onChange,
  onFocus,
  clearAdditionalStore,
  setProjectId,
})(SubmitConcept);