import { GET_EXTRA_TARIFFS, LOGOUT } from '../types'
import { defaultValuesCreateProject } from '../../constants/defaultValueCreateProject';

const initialState = () => {
    return {
        extraTariffs: []
    }
}

export default function (state = initialState(), action) {
    const { type, payload } = action;
    switch (type) {
        case GET_EXTRA_TARIFFS:
            return {
                ...state,
                extraTariffs: payload,
                loading: false,
            }        
        case LOGOUT:
            return initialState
        default:
            return state

    }
}