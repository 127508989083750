export default {
  phoneNumber: 'validatorPhone',
  email: 'validatorEmail',
  password: 'validatorPassword',
  lastName: 'validatorName',
  firstName: 'validatorName',
  companyName: 'validatorName',
  login: 'validatorLogin',
  person_city: 'validatorStr',
  person_state: 'validatorStr',
  person_country: 'validatorStr',
  company_city: 'validatorStr',
  company_state: 'validatorStr',
  company_country: 'validatorStr',
  company_postalCode: 'validatorZIP',
  person_postalCode: 'validatorZIP',
  company_phone: 'validatorPhone',
  person_phone: 'validatorPhone',
  personDataDob: 'validatorDob',
  company_vat_id: 'validatorTaxandVat',
  company_tax_id: 'validatorTaxandVat',
  businessProfile_name: 'validatorName',
  iban: 'validatorIBAN',
  postalCode: 'validatorZIP',
  plotNo: 'validatorPlotNo',
};
