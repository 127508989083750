import { ADDITIONAL_LOADING, CHANGE_ADDITIONAL_VALUES, UPLOAD_FAIL, ADDITIONAL_DELETE_FILE, ADDITIONAL_FILE_DOWNLOADING, ADDITIONAL_LOADING_PERCENTAGE, RESET_ADDITIONAL, ADDITIONAL_FORM_VALIDITY, ADDITIONAL_FOCUS, ADDITIONAL_SET_FILES, ADDITIOANAL_RESET_ERRORS, ADDITIONAL_TOGGLE_DOCUMENT } from '../types'
import { instance } from "../http";
import { closeModal } from './transactionActions'
import { resetProjectData, setRedirect } from './createProjectActions'
import { getDetailedProject } from './ProjectActions'

export const onFocus = () => dispatch => {
    dispatch({
        type: ADDITIONAL_FOCUS,
    })
};

export const onChange = ({ target }) => dispatch => {
    dispatch({
        type: CHANGE_ADDITIONAL_VALUES,
        payload: target
    })
};

export const setLoading = (state) => dispatch => {
    dispatch({
        type: ADDITIONAL_LOADING,
        payload: state
    })
}

export const setDownloading = state => dispatch => {
    dispatch({
        type: ADDITIONAL_FILE_DOWNLOADING,
        payload: state
    })
}

export const checkFile = (file) => {
    var isDocValid = {
        isSizeValid: false,
        isFileValid: false,
        download: false
    }
    var supportedFileTypes = ['image/png', 'image/jpeg', 'application/pdf'];

    if (file.size < 512000000) isDocValid.isSizeValid = true;
    if (supportedFileTypes.indexOf(file.type) !== -1)
        isDocValid.isFileValid = true;
    return (
        supportedFileTypes.indexOf(file.type) !== -1 && file.size < 512000000
    );
};

export const setFiles = (name, path, id, url) => dispatch => {
    dispatch({
        type: ADDITIONAL_SET_FILES,
        payload: {
            name: name,
            path: path,
            id: id,
            url: url,
        }
    })
};

export const onUpload = (docFile) => (dispatch, getState) => {
    let formData = new FormData();
    let isFileSupported;
    if (docFile) {
        isFileSupported = checkFile(docFile)
    }
    if (!isFileSupported) return;
    formData.append('file', docFile);
    if (getState().additionalInfoProjectReducer.loadingPercentage > 0) return;
    dispatch(setDownloading(true))
    dispatch(setLoading(true))
    const config = {
        onUploadProgress: (progressEvent) => {
            dispatch({
                type: ADDITIONAL_LOADING_PERCENTAGE,
                payload: Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                )
            })
        },
    };
    instance.post(`/upload`, formData, config)
        .then(response => {
            const { fileName, path, _id, fullURL } = response.data
            dispatch(setFiles(fileName, path, _id, fullURL))
            dispatch(setDownloading(false))
            dispatch(setLoading(false))
        })
        .catch(error => {
            dispatch({
                type: UPLOAD_FAIL,
            })
            throw error;
        })
};

export const deleteFile = (index) => dispatch => {
    dispatch({
        type: ADDITIONAL_DELETE_FILE,
        payload: index
    })
};

export const clearAdditionalStore = () => dispatch => {
    dispatch({
        type: RESET_ADDITIONAL
    })
};


export const onSubmit = (event) => (dispatch, getState) => {
    event.preventDefault();
    const { isDocumentOnly, formFields, files, errors } = getState().additionalInfoProjectReducer

    var proceed = true
    if (!isDocumentOnly) {
        const isSomeFieldValid =
            Object.values(formFields).some((field) => field) ||
            files.length;
        dispatch({
            type: ADDITIONAL_FORM_VALIDITY,
            payload: isSomeFieldValid && !errors.postalCode
        })
        proceed = isSomeFieldValid && !errors.postalCode
    }
    if (!proceed) return dispatch(closeModal())

    const idArr = files.map((e) => e.id);
    const { createdProjectData, detailedProject } = getState().projectReducer
    const id =
        createdProjectData._id || detailedProject._id;
    const additionalInfo = {
        ...formFields,
        files: idArr,
    };
    dispatch(setLoading(true))
    console.log(files, 'FILES');

    if (!isDocumentOnly) {
        instance.put(`/projects/${id}/additional-info`, { ...additionalInfo })
            .then(response => {
                dispatch(setLoading(false))
                dispatch(clearAllStores(id))
            })
            .catch(error => {
                dispatch(setLoading(false))
                if (error.response) {
                    if (error.response.status === 400) {
                        console.log(error.response.data.message);
                    }
                }
                throw error;
            })
    } else {
        instance.put(`/projects/${id}/details-files`, {
            files: files.map((file) => file.id),
        })
            .then(response => {
                dispatch(setLoading(false))
                dispatch(clearAllStores(id))
            })
            .catch(error => {
                dispatch(setLoading(false))
                if (error.response) {
                    if (error.response.status === 400) {
                        console.log(error.response.data.message);
                    }
                }
                throw error;
            })
    }
};

export const clearAllStores = (id) => dispatch => {
    dispatch(resetProjectData())
    dispatch(closeModal())
    dispatch(setRedirect(true))
    dispatch(getDetailedProject(id))
}


export const resetErrors = () => dispatch => {
    dispatch({
        type: ADDITIOANAL_RESET_ERRORS
    })
};

export const setDocumentOnly = (flag) => dispatch => {
    dispatch({
        type: ADDITIONAL_TOGGLE_DOCUMENT,
        payload: flag
    })
};
