import { CLEAR_CONCEPTS, GET_ALL_CONCEPTS, GET_CONCEPTS, GET_CONCEPT_ID, LOGOUT, SET_PAGE, SET_PROJECT_ID } from '../types'

const defaultParams = () => {
    return {
        page: 1,
        limit: 12,
        sort: 'desc',
        sort_by: '_id',
        id: '',
    }
};

function initialState() {
    return {
        concepts: null,
        detailedConcept: null,
        allConcepts: null,
        offerId: null,
        params: defaultParams(),
    };
}

export default function (state = initialState(), action) {
    const { type, payload } = action;
    switch (type) {
        case GET_CONCEPT_ID:
            return {
                ...state,
                detailedConcept: payload
            }
        case GET_CONCEPTS:
            return {
                ...state,
                concepts: payload
            }
        case GET_ALL_CONCEPTS:
            return {
                ...state,
                allConcepts: payload
            }
        case SET_PROJECT_ID:
            var params = state.params
            params.id = payload
            return {
                ...state,
                params
            }
        case SET_PAGE:
            var params = state.params
            params.page = payload
            return {
                ...state,
                params
            }
        case CLEAR_CONCEPTS:
            return {
                ...state,
                concepts: null,
                allConcepts: null,
                params: defaultParams(),
            }
        case LOGOUT:
            return initialState()
        default:
            return {
                ...state,
            }
    }
}
