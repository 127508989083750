import React from 'react';
import noImageCustomer from '../../assets/noImage.svg';
import noAccess from '../../assets/noAccess.svg';
import Rating from '../Rating';
import { Link } from 'react-router-dom';

export default function OfferCard({
  id,
  description,
  rating = 0,
  setRating,
  isActiveRating,
  star,
  isDesigner,
  isGray,
  account,
  img,
  isSetWinner,
  handleShow,
  setIdWinner,
  isWinner,
  hasWinner,
  isNew,
}) {
  const addZero = (number, maxLength) => {
    number = number.toString();
    return number.length < maxLength
      ? addZero('0' + number, maxLength)
      : number;
  };
  let linkToConcept = `concepts/${id}`;
  let linkToWinner = `winner/`;
  console.log(account)
  return (
    <div
      className={`offer-wrapper ${isNew ? 'new' : ''} ${
        hasWinner ? 'has-winner' : 'has-not-winner'
      }  ${isWinner ? `is-winner-true` : ''} `}>
      <div className="offer-header">
        <div className={`left-side ${isGray}`}>{addZero(id, 3)}</div>
        {account && <div className="right-side">{account.login}</div>}
      </div>
      {isGray ? (
        <div className="offer-main">
          <img src={noAccess} alt="No" />
        </div>
      ) : (
        <Link to={`${isWinner ? linkToWinner : linkToConcept}`}>
          <div className={`offer-main ${img ? 'true' : 'false'}`}>
            <img src={img || noImageCustomer} alt="No" />
          </div>
        </Link>
      )}

      <div className={`offer-footer ${isDesigner ? 'designer' : ''}`}>
        {isSetWinner ? (
          <div onClick={handleShow} className="set-winner-title">
            Bestätigung Gewinner
          </div>
        ) : isWinner ? (
          <div className="winner-title">
            Gewinner
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18.4962 3.5C18.4383 7.25568 17.7327 10.7398 16.6365 13.3029C16.0637 14.6421 15.3942 15.7064 14.6757 16.4283C13.9584 17.149 13.2235 17.5 12.5 17.5C11.7765 17.5 11.0416 17.149 10.3243 16.4283C9.60577 15.7064 8.93632 14.6421 8.36352 13.3029C7.2673 10.7398 6.56167 7.25568 6.50385 3.5H18.4962Z"
                stroke="white"
                strokeLinecap="round"
              />
              <path
                d="M17.5 12.5C20.5 12.5 22 10.5 22 6.5H18.5"
                stroke="white"
                strokeLinecap="round"
              />
              <path d="M12.5 17L12.5 21" stroke="white" />
              <line x1="15" y1="21.5" x2="10" y2="21.5" stroke="white" />
              <path
                d="M7.5 12.5C4.5 12.5 3 10.5 3 6.5H6.5"
                stroke="white"
                strokeLinecap="round"
              />
            </svg>
          </div>
        ) : (
          <Rating
            setRating={(rating) => setRating(id, rating)}
            value={rating}
            isActive={isActiveRating}
            star={star}
          />
        )}

        {!isDesigner && !hasWinner && (
          <div className="winner" onClick={setIdWinner}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M18.4962 3.5C18.4383 7.25568 17.7327 10.7398 16.6365 13.3029C16.0637 14.6421 15.3942 15.7064 14.6757 16.4283C13.9584 17.149 13.2235 17.5 12.5 17.5C11.7765 17.5 11.0416 17.149 10.3243 16.4283C9.60577 15.7064 8.93632 14.6421 8.36352 13.3029C7.2673 10.7398 6.56167 7.25568 6.50385 3.5H18.4962Z"
                stroke="white"
                strokeLinecap="round"
              />
              <path
                d="M17.5 12.5C20.5 12.5 22 10.5 22 6.5H18.5"
                stroke="white"
                strokeLinecap="round"
              />
              <path d="M12.5 17L12.5 21" stroke="white" />
              <line x1="15" y1="21.5" x2="10" y2="21.5" stroke="white" />
              <path
                d="M7.5 12.5C4.5 12.5 3 10.5 3 6.5H6.5"
                stroke="white"
                strokeLinecap="round"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
}
