import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
export default function Designer({ titles, languageID }) {
  return (
    <Fragment>
      {titles.map(item => (
        <div key={item.value} className='title'>
          <Link to={`/${languageID}/profile/${item.value}`}>{item.title}</Link>
        </div>
      ))}
    </Fragment>
  );
}
