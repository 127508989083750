import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import OutcomingMessage from '../OutcomingMessage/OutcomingMessage';
import IncomingMessage from '../IncomingMessage/IncomingMessage';
import moment from 'moment/min/moment-with-locales';
import UserIcon from '../../../../../assets/user.svg';
import { communicationTitles } from '../../../../../constants/communicationTitles';

moment.locale('de');
class MessageList extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    this.onScrollToBottom();
  }

  componentDidUpdate() {
    this.onScrollToBottom();
  }

  onScrollToBottom = () => {
    this.ref.current.scrollTop =
      this.ref.current.scrollHeight - this.ref.current.clientHeight;
  };

  updateList = list => {
    const dates = [
      ...new Set(
        list.map(item => moment(item.creationDate).format('DD_MM_YYYY'))
      ),
    ];
    const sortedDates = dates.sort(
      (a, b) => moment(a, 'DD_MM_YYYY') - moment(b, 'DD_MM_YYYY')
    );
    let updatedList = [];

    for (let i = 0; i < sortedDates.length; i++) {
      let dateListObj = {};
      const filteredByDateList = list.filter(
        item => moment(item.creationDate).format('DD_MM_YYYY') === dates[i]
      );

      dateListObj[dates[i]] = filteredByDateList;
      updatedList.push(dateListObj);
    }

    return updatedList;
  };

  render() {
    const {
      globalReducer
    } = this.props
    const { EMPTY_COMMENTLIST } = communicationTitles;
    const newList = this.updateList((this.props.comments));
    const { User } = globalReducer;
    const currentUserId = User._id;

    return (
      <div className="message-list" ref={this.ref}>
        {!newList.length ?
          <div className="no-comments">{EMPTY_COMMENTLIST}</div>
          :
          newList.map(item => {
            return Object.entries(item).map(([key, value], i) => {
              return (
                <div className="date-list" key={i}>
                  <div className="date-label">
                    {moment(key, 'DD_MM_YYYY').format('DD. MMM YYYY')}
                  </div>
                  <div>
                    {value.map((message, i) => {
                      if (
                        message.from &&
                        message.from._id === currentUserId
                      ) {
                        return (
                          <OutcomingMessage text={message.message} key={i} />
                        );
                      } else {
                        const name = message.from.login;
                        return (
                          <IncomingMessage
                            icon={UserIcon}
                            name={name}
                            creationDate={moment(message.creationDate).format(
                              'HH:mm'
                            )}
                            text={message.message}
                            key={i}
                          />
                        );
                      }
                    })}
                  </div>
                </div>
              );
            });
          })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { globalReducer } = state
  return {
    globalReducer,
  }
};

export default connect(mapStateToProps, {
  
})(withRouter(MessageList));